<template>
  <div
    class="flex text-left border-gray-700 py-3"
    :class="{
      'px-5': $store.state.navState && !inline,
      'justify-center': !$store.state.navState && !inline,
      'border-t-2 border-b-2 py-3 w-full': !inline,
    }"
  >
    <button
      class="text-right py-0 h-10 dark:text-gray-300 flex items-center"
      @click="activeDarkMode()"
      :class="{
        'bg-gray-700  rounded px-2': darkMode,
      }"
    >
      <i class="fas mr-1 fa-moon"></i>
      <span class="text-xs ml-2"> {{ $t("general.darkModeTitle") }}</span>
    </button>
    <button
      @click="activeLightMode"
      class="w-6/12 text-left py-0 px-2 h-10 text-xs flex items-center ml-4"
      :class="{
        'bg-gray-700 rounded px-2': !darkMode,
      }"
    >
      <i class="fas fa-sun mr-2"></i>
      {{ $t("general.lightModeTitle") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "darkmode-panel",
  props: ["inline"],
  data() {
    return {
      darkMode: false,
    };
  },
  methods: {
    changeDarkMode() {
      if (this.darkMode) this.activeLightMode();
      else this.activeDarkMode();
    },
    activeDarkMode() {
      this.darkMode = true;
      this.$store.commit("activeDarkMode");
      document.documentElement.classList.add("dark");
    },
    activeLightMode() {
      this.darkMode = false;
      this.$store.commit("activeLightMode");
      document.documentElement.classList.remove("dark");
    },
    controlDarkMode() {
      if (this.$store.state.darkMode) {
        document.documentElement.classList.add("dark");
      } else document.documentElement.classList.remove("dark");
    },
  },
  created() {
    this.darkMode = this.$store.state.darkMode;
    this.controlDarkMode();
  },
};
</script>

<style></style>
