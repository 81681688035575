<template>
  <modal
    name="change-vehicle-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    @opened="getDetail()"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12 text-2xl text-black">Araç Düzenle</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('change-vehicle-modal')">
        <i class="fas fa-times text-black text-2xl"></i>
      </button>
    </div>
    <form
      v-if="!getLoad"
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
    >
      <div class="w-full mb-5 border rounded px-2 py-3 relative">
        <label class="absolute -top-3 left-3 text-sm block w-auto bg-white"
          >Çekici Plakası</label
        >
        <vehicleInputVue :optList="getFilterVehicle" v-model="vehicle" @changeDorse="(val) => (dorseName = val)"/>
      </div>
      <div class="w-full pb-5">
        <diffInput :value="dorseName" :disabled="true" title="Dorse" />
      </div>
      <div class="w-full mb-5">
        <personelSelect
          :value="driverName ? driverName : ''"
          @changeTC="(val) => (driverTC = val)"
          @changeName="(val) => (driverName = val)"
        />
      </div>
      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
    <div
      v-else
      class="text-center flex items-center justify-center w-full h-72"
    >
      <div class="text-center">
        <i class="fas fa-spinner fa-spin fa-2x"></i>
        <p class="mt-2 block">Yükleniyor</p>
      </div>
    </div>
  </modal>
</template>

<script>
//Global Components

import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import axios from "axios";
import personelSelect from "@/components/devItem/personnelInput.vue";
import vehicleInputVue from "../../orders/components/vehicle-select.vue";
import { bulkPlanning } from "@/networking/urlmanager";
import { getLocationAll } from "../../orders/utils/detailData";
import { planning } from "@/networking/urlmanager";
export default {
  props: ["detail"],
  components: {
    diffInput,
    asyncBtn,
    vehicleInputVue,
    personelSelect,
  },
  data() {
    return {
      load: false,
      getLoad: true,
      vehicle: "",
      dorse: "",
      driverName: "",
      driverTC: "",
      vehicleList: [],
      dorseName: "",
    };
  },
  methods: {
    async getDetail() {
      setTimeout(async () => {
        this.getLoad = true;
        this.vehicleList = await getLocationAll(
          this.$store.state.userData.token
        );
        const findItem = this.getFilterVehicle.find(
          (r) => r.name == this.detail.vehicle
        );
        this.vehicle = findItem;
        this.dorse = this.detail.dorse;
        this.driverName = this.detail.driver;
        setTimeout(() => {
          this.getLoad = false;
        }, 50);
      }, 100);
    },
    save() {
      this.load = true;
      axios
        .post(
          planning.changeVehicle,
          {
            vehicle: this.vehicle.name,
            driverName: this.driverName,
            driverTC: this.driverTC,
            dorse: this.dorse,
            rowId: this.detail.id,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );

          this.$modal.hide('change-vehicle-modal');
          this.$emit("refresh");
          this.vehicle = "";
          this.dorse = "";
          this.driverName = "";
          this.driverTC = "";
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  computed: {
    getFilterVehicle() {
      return this.vehicleList.map((item) => {
        return {
          name: item.vehicle,
          id: item.id,
          dorse: item.dorse,
          driverName: item.driverName,
        };
      });
    },
  },
  watch: {
    vehicle(val) {
      if (typeof val == "object" && !this.getLoad) {
        this.dorse = val.dorse;
        this.driverName = val.driverName;
        if (!val.driverName) {
          this.driverTC = "";
        }
      }
    },
  },
};
</script>
