const { tracking, customer, product,routePlanner }=require("@/networking/urlmanager");
const axios = require("axios");

async function getLocationAll(token) {
  try {
    const result = await axios.get(tracking.getAll, {
      headers: {
        Authorization: "Bareer " + token,
      },
    });
    return result.data.data.map((item) => {
      return {
        ...item,
        isAvailable: true,
      };
    });
  } catch (error) {
    console.log(error.message);
    return [];
  }
}
async function getCustomers(token) {
  try {
    const result = await axios.get(customer.getAllNotPagination, {
      headers: {
        Authorization: "Bareer " + token,
      },
    });
    return Array.isArray(result.data.data) ? result.data.data : [];
  } catch (error) {
    console.log(error.response);
    return [];
  }
}
async function getProducts(token) {
  try {
    const result = await axios.get(product.getWithFilterAll, {
      headers: {
        Authorization: "Bareer " + token,
      },
    });
    return Array.isArray(result.data.data) ? result.data.data : [];
  } catch (error) {
    console.log(error);
    return [];
  }
}
async function getRoute(from={lat:0,lng:0},to={lat:0,lng:0},isAdr=false,token) {
  try {
      if(from.lat==0 || from.lng==0 || to.lat==0 || to.lng==0) {
        console.log("Başlangıç ve bitiş konumları boş olduğundan dolayı rota hesaplaması yapılamadı")
        return;
      }
  
  const detail=await axios
    .post(
      routePlanner.calculate,
      {
        from: from,
        to: to,
        waypoints: JSON.stringify([]),
        isToll: true,
        isShip: true,
        isAsphalt: true,
        isExplosive: isAdr,
        routeType: "fast",
      },
      {
        headers: {
          Authorization: "Bareer " +token,
        },
      }
    )
    return detail.data.data;
  } catch (error) {
      console.warn(error);
      return null;
  }
};
module.exports = {  
    getLocationAll,
    getCustomers,
    getProducts,
    getRoute
  };
  