<template>
  <diffInput
    :optList="filterType"
    v-model="select"
    type="select"
    title="Bölge"
  />
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
export default {
  name: "geo-filter-input",
  props: ["value"],
  model: {
    event: "change",
    prop: "value",
  },
  components: {
    diffInput,
  },
  data() {
    return {
      select: "",
      filterType: [
        {
          id:1,
          key:null,
          name: "Tümü",
        },
        {
          id:2,
          key:'Marmara',
          name: "Marmara Bölgesi",
        },
        {
          id:3,
          key: "Ege",
          name: "Ege Bölgesi",
        },
        {
          id: 4,
          key: "Akdeniz",
          name: "Akdeniz Bölgesi",
        },
        {
          id: 5,
          key: "Karadeniz",
          name: "Karadeniz Bölgesi",
        },
        {
          id: 6,
          key: "İç Anadolu",
          name: "İç Anadolu Bölgesi",
        },
        {
          id:7,
          key: "Doğu Anadolu",
          name: "Doğu Anadolu Bölgesi",
        },
        {
          id:8,
          key: "Güneydoğu Anadolu",
          name: "Güneydoğu Anadolu Bölgesi",
        },
      ],
    };
  },
  created() {
    this.select = this.value.id;
  },
  watch: {
    value(val) {
      this.select = val.id;
    },
    select(val) {
      if(val!=undefined)
        this.$emit("change",this.filterType.find(item=>item.id==val));
    },
  },
};
</script>

<style></style>
