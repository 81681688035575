<template>
  <div
    class="w-full top-0 h-16 text-black shadow-lg px-4 align-middle sticky z-30 bg-white dark:bg-gray-800"
    align="right"
  >
    <div class="w-full h-full flex items-center md:justify-between">
      <div class="w-4/12 flex justify-start">
        <div class="flex items-center w-full">
          <router-link
            to="/vehicle-tracking"
            class="px-2 py-3 w-28 text-sm capitalize text-gray-800 dark:text-gray-100 hover-custom-bg hover:text-gray-500 flex justify-between"
          >
            <i class="fas fa-map mt-1 mr-2"></i> Araç Takip
          </router-link>
          <router-link
            to="/users"
            class="px-2 py-3 w-28 text-sm capitalize text-gray-800 dark:text-gray-100 hover-custom-bg hover:text-gray-500 flex justify-between"
          >
            <i class="fas fa-users mt-1 mr-1"></i> Kullanıcılar
          </router-link>

          <button
            class="px-2 py-3 w-48 text-sm capitalize text-gray-800 dark:text-gray-100 hover-custom-bg hover:text-gray-500 flex justify-between"
            @click="$modal.show('kilometer-rapor-modal')"
          >
            <i class="fas fa-archive mt-1 mr-1"></i>
            Aylık Kilometre Raporu
          </button>
        </div>

        <!-- <input
          type="search"
          class="w-96 border-b mx-6 bg-transparent dark:border-opacity-30 focus:outline-none border-dotted hidden xl:inline-block"
          placeholder="Hızlı Ara...."
        /> -->
      </div>

      <div class="relative flex items-center xl:w-5/12 w-10/12 justify-end">
        <div
          class="w-3/12 flex justify-end mb-1 mr-3"
          v-if="$store.state.userData.typeId == 2"
        >
          <darkMode :inline="true" />
        </div>

        <button class="w-6 mr-4" @click="() => loadFunc()">
          <img
            :src="
              require($store.state.darkMode
                ? '@/assets/refresh-dark.png'
                : '@/assets/refresh-light.png')
            "
            :class="load && 'fa-spin'"
            class="w-full"
            alt=""
          />
        </button>

        <feedbackMenu />

        <languagePanel :value="1" />

        <span class="relative mr-6 mb-1 xl:w-1/12 hidden xl:inline-block">
          <i
            class="fas fa-bell text-2xl text-gray-600 dark:text-gray-300 text-opacity-80"
          ></i>
          <i
            class="fas fa-dot-circle text-xs text-red-600 rounded-full absolute -top-0.5 -right-2"
          ></i>
        </span>
        <profilePanel />
      </div>
    </div>
  </div>
</template>

<script>
import darkMode from "./components/darkmode.item.vue";
import profilePanel from "./components/profile.panel.vue";
import languagePanel from "./components/languages.item.vue";
import feedbackMenu from "../components/feedback.vue";

export default {
  name: "header-item",
  components: {
    profilePanel,
    languagePanel,
    darkMode,
    feedbackMenu,
  },

  data() {
    return {
      load: false,
    };
  },
  methods: {
    loadFunc() {
      this.load = true;
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
  },
};
</script>
