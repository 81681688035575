import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'


//pages

//  user pages and auth pages
import loginPage from "../views/auth/index.vue"
import profilePage from "../views/profile/index.vue"

// general pages
import dashboardPage from '../views/dashboard/index.vue'
import vehicleTrackingPage from "../views/vehicle-tracking/index.vue"

// Operations Pages
import planningPage from "../views/operations/planning/index.vue"

import planningOrderPage from "../views/operations/planning/planningOrder/index.vue"

import calculatorPage from "../views/operations/calculator/index.vue"
import customersPage from "../views/operations/customers/index.vue"
import productsPage from "../views/operations/products/index.vue"


// Human Resources Pages
import staffPage from "../views/human-resources/staff/index.vue"

import usersPage from "../views/control-panel/users/index.vue"
import costingPage from '../views/control-panel/costing/index.vue'

// errors page
import networkErrorPage from "../views/errors/network-error.vue"
import permErrorPage from "../views/errors/perm-error.vue"
import generalPermErrorPage from '../views/errors/general-perm-error.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loginPage',
    component: loginPage,
    meta: { requiresAuth: false }
       
  },
  {
    path: '/dashboard',
    name: 'dashboardPage',
    component: dashboardPage,
    meta: { requiresAuth: true }  
  },
  {
    path: '/vehicle-tracking',
    name: 'vehicleTrackingPage',
    component: vehicleTrackingPage,
    meta: { requiresAuth: true,module:1 }  
  },
  {
    path: '/profile',
    name: 'profilePage',
    component: profilePage,
    meta: { requiresAuth: true }  
  },
 
  // Operations
  {
    path: '/planning',
    name: 'planningPage',
    component: planningPage,
    meta: { requiresAuth: true,module:3 }  
  },
  {
    path: '/planning/orders',
    name: 'planningOrder',
    component: planningOrderPage,
    meta: { requiresAuth: true,module:3 }  
  },
  {
    path: '/calculator',
    name: 'calculatorPage',
    component: calculatorPage,
    meta: { requiresAuth: true,module:2 }  
  },
  {
    path: '/customers',
    name: 'customersPage',
    component: customersPage,
    meta: { requiresAuth: true,module:4 }  
  },
  {
    path: '/products',
    name: 'productsPage',
    component: productsPage,
    meta: { requiresAuth: true,module:5 }  
  },


  // Human Resources Pages
  {
    path: '/staff',
    name: 'staffPage',
    component: staffPage,
    meta: { requiresAuth: true,module:6 }  
  },

  // Control Panel
  {
    path: '/users',
    name: 'usersPage',
    component: usersPage,
    meta: { requiresAuth: true,module:7 }  
  },
  {
    path:'/management/costing',
    name:'managementCostingPage',
    component:costingPage,
    meta: { requiresAuth: true,module:8 }  
  },


  // error pages
  {
    path:'/network-error',
    name:'network-error',
    component: networkErrorPage,
    meta: { requiresAuth: true }  
  },
  {
    path:'/permission-error',
    name:'permission-error',
    component: permErrorPage,
    meta: { requiresAuth: true }  
  },
  { 
    path:'/general-perm-error',
    name:'general-perm-error',
    component:generalPermErrorPage,
    meta: { requiresAuth: true}  
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.state.isAuth) {
    next('/');
  } else {
    if(store.state.isAuth){
      if(to.matched[0].meta.module ? store.state.userData.perms.some(r =>r.editState != 0 && r.permtypeId == to.matched[0].meta.module) : true){
        next()
      }else{
        next(false);
      }
    }else{
      next();
    }
  }
});

export default router
