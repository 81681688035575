<template>
  <div class="w-full h-full bg-gray-200 dark:bg-gray-900">
    <div class="w-full">
      <mapItem />
    </div>
  </div>
</template>

<script>
// local Components
import mapItem from "./components/map.item.vue";

export default {
  name: "vehicle-tracking",
  components: {
    mapItem,
  },
};
</script>
