const moment = require("moment/moment");
const {
    getLocationAll
  } =require("../../../orders/utils/detailData");


const {
    recommendMultipleVehiclesForOrder,
    calculateDistancePriority,
    recommendSingleVehicleForOrder,
  } =require("../../../orders/utils/recomendation");

const operationTypes={
    normal:1,
    shipEvacuation:2,
    shipLoading:3
}


module.exports=class calculateBulkModel{

    // utils
    calculateTimeDifference(startTime, endTime){
        // Create Date objects for today with the given start and end times
        var startDate = new Date(`1970-01-01T${startTime}:00`);
        var endDate = new Date(`1970-01-01T${endTime}:00`);
    
        // Calculate the difference in milliseconds
        var differenceInMilliseconds = endDate - startDate;
    
        // Convert milliseconds to minutes (1 minute = 60,000 milliseconds)
        var differenceInMinutes = differenceInMilliseconds / 60000;
    
        return differenceInMinutes;
    }

    // process functions

    // normal bulk function
    calculateNormalBulk(TransitionType,fillingDate,deliveryDate,roadMinutes,totalTime,totalTonnage,loadPerVehicle,maxDailyDrivingTime){
            if (TransitionType == 1) { // çift yön yani gidiş dönüş

                let roundTripTime = Number(roadMinutes) * 2;
                roundTripTime += Number(fillingDate);
                roundTripTime += Number(deliveryDate);


                var differentDate = parseInt(
                  moment
                    .duration(moment(new Date(totalTime)).diff(moment()))
                    .asMinutes()
                    .toFixed(2)
                );
    
                console.log("Operasyon Süresi ", roundTripTime);
    
                // Bir aracın bir günde yapabileceği maksimum gidiş-dönüş sefer sayısı
                let tripsPerDay = Math.floor(maxDailyDrivingTime / roundTripTime);
    
                if (tripsPerDay == 0) tripsPerDay = 1;

    

    
                // Bir aracın bir günde taşıyabileceği maksimum tonaj
                const dailyLoadPerVehicle = tripsPerDay * loadPerVehicle;

               
                console.log(
                  " Bir aracın bir günde taşıyabileceği maksimum tonaj",
                  dailyLoadPerVehicle
                );



    
                // Verilen süre içinde bir aracın taşıyabileceği maksimum tonaj
                const totalLoadPerVehicleWithinTimeLimit =
                  dailyLoadPerVehicle * (differentDate / (24 * 60)); // availableTime, dakika cinsindendir
               
                console.log(
                  "Verilen süre içinde bir aracın taşıyabileceği maksimum tonaj",
                  totalLoadPerVehicleWithinTimeLimit
                );
    
                // Toplam gereken araç sayısı
                let requiredVehicles =
                  totalLoadPerVehicleWithinTimeLimit > 0
                    ? totalTonnage / totalLoadPerVehicleWithinTimeLimit
                    : 0;


                console.log("araç sayısı", requiredVehicles);
                // if (requiredVehicles == 0 && this.flag) this.productDeliveryTimeError = true;
                // else this.productDeliveryTimeError = false;
    
                // Araç sayısını yuvarlama
                requiredVehicles = Math.ceil(requiredVehicles);
                    
                return requiredVehicles;
                
            } else {
              // tek yön
              console.log(totalTonnage , loadPerVehicle)
              return Math.floor(totalTonnage / loadPerVehicle);
            }
    }

   // ship evacation function
    calculateShipEvacation(roadMinutes,totalTime,fillingDate,deliveryDate,totalTonnage,loadPerVehicle,maxDailyDrivingTime,startEvacationTime,endDailyTime,horAtDeliveryTonnage,fillingPoint,token){

      if(Number(totalTonnage)<Number(horAtDeliveryTonnage)) {
        alert("UYARI,SAAT BAŞI BOŞALTILACAK TONAJ DEĞERİ TOPLAM TONAJDAN BÜYÜK OLAMAZ!");
        return;
      }

        let remainingTonnage=totalTonnage;
        // start day calculate
        const dailyShipTime=this.calculateTimeDifference(startEvacationTime,endDailyTime);
        const firstDayTransitionCount=Math.ceil((((dailyShipTime*horAtDeliveryTonnage)/60)/loadPerVehicle));



        // between day calculate
        const betweenStartAndEndDateDay=moment(totalTime).diff(moment(),'days')-1;
        let betweenStartAndEndDateDayTransitionCount=0;
        if(betweenStartAndEndDateDay>0){
          for (let index = 0; index < betweenStartAndEndDateDay; index++) {
            let shipTime=this.calculateTimeDifference("00:00",endDailyTime);
            betweenStartAndEndDateDayTransitionCount+=Math.ceil((((shipTime*horAtDeliveryTonnage)/60)/loadPerVehicle));
          }
        }


        // last day calculate

       const lastDayTime=this.calculateTimeDifference("00:00",moment(totalTime).format('HH:mm'))
       const lastDayTransitionCount=Math.ceil((((lastDayTime*horAtDeliveryTonnage)/60)/loadPerVehicle));


       // operation time
       let roundTripTime = Number(roadMinutes) * 2;
       roundTripTime += Number(fillingDate);
       roundTripTime += Number(deliveryDate);


       let dailyTripCount=Math.ceil(maxDailyDrivingTime/roundTripTime);
       
       if(dailyTripCount==0) dailyTripCount=1;

       
       
       // first day transition tonnage
       remainingTonnage-=(firstDayTransitionCount*loadPerVehicle);
       const firstDayVehicleCount=Math.ceil(firstDayTransitionCount/dailyTripCount)



       let betweenVehicleCount=0;
       if(betweenStartAndEndDateDay>0){
          // between day transition tonnage
        remainingTonnage-=(betweenStartAndEndDateDayTransitionCount-loadPerVehicle);
        betweenVehicleCount=Math.ceil(betweenStartAndEndDateDayTransitionCount/dailyTripCount) / betweenStartAndEndDateDay;
      }


      // last day transition tonnage
      remainingTonnage-=(lastDayTransitionCount-loadPerVehicle);
      const lastDayVehicleCount=Math.ceil(lastDayTransitionCount/dailyTripCount)




        console.log("total tonnage : ",remainingTonnage)
        console.log("first Day : ",firstDayVehicleCount)
        console.log("Between Day : ",betweenVehicleCount)
        console.log("last Day : ",lastDayVehicleCount)




    }
    

    async calculateRecomendedCount(TransitionType,roadMinutes,fillingDate,deliveryDate,totalTime,totalTonnage,loadPerVehicle,maxDailyDrivingTime,typeId,endDailyTime,startEvacationTime,horAtDeliveryTonnage,fillingPoint,token,){
        if(typeId==operationTypes.normal){
            const recomendedCount=this.calculateNormalBulk(
                TransitionType,
                fillingDate,
                deliveryDate,
                roadMinutes,
                totalTime,
                totalTonnage,
                loadPerVehicle,
                maxDailyDrivingTime
            );
            return recomendedCount;
        }
        else if(typeId==operationTypes.shipEvacuation){
            return await this.calculateShipEvacation(
                roadMinutes,
                totalTime,
                fillingDate,
                deliveryDate,
                totalTonnage,
                loadPerVehicle,
                maxDailyDrivingTime,
                endDailyTime,
                startEvacationTime,
                horAtDeliveryTonnage,
                fillingPoint,
                token
            );
        }
    }
}