<template>
  <modal
    name="detail-repair-vehicle-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '70%'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ plaque }}
        BAKIM ONARIM DETAYI
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('detail-repair-vehicle-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
    >
      <div
        class="shadow overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg"
        style="min-height: 300px !important; max-height: 500px !important"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-white">
            <tr>
              <th scope="col" class="table-th text-left">#</th>
              <th scope="col" class="table-th text-left border-l">Tarih</th>
              <th scope="col" class="table-th text-left border-l">Tür</th>
              <th scope="col" class="table-th text-left border-l">İşlem Açıklaması</th>
              <th scope="col" class="table-th text-left border-l">Tutar</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 tbody-bg-color">
            <tr class="pt-8" v-for="(item, index) in List" :key="index">
              <td class="table-td">
                <span class="block">
                  #
                  {{ item.receiptNo }}
                </span>
              </td>
              <td class="table-td border-l w-32">
                {{ item.receiptDate }}
              </td>
              <td class="table-td border-l">
                {{ item.typeName }}
              </td>
              <td class="table-td border-l">
                {{ item.explanation }}
              </td>
              <td class="table-td border-l w-28">{{ Money(item.total) }} ₺</td>
            </tr>
            <tableLoader :load="load" :length="List.length" colspan="5" />
          </tbody>
        </table>
      </div>
      <PaginationComponents
        v-if="totalCount > 0"
        :totalRowCount="totalCount"
        :currentPage="currentPage + 1"
        :pageCount="pageCount"
        @changePage="(r) => (currentPage = r - 1)"
      />
    </div>
  </modal>
</template>

<script>
import PaginationComponents from "@/components/general/pagination.vue";
import tableLoader from "@/components/general/table-loader.vue";
import axios from "axios";
import { rapor } from "@/networking/urlmanager";
export default {
  name: "vehicle-plan-detail-detail-modal",
  props: ["plaque", "vehicleId", "typeId"],
  components: {
    PaginationComponents,
    tableLoader,
  },
  data() {
    return {
      List: [],
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      load: false,
    };
  },
  methods: {
    getDetail() {
      this.load = true;
      axios
        .get(
          rapor.expense.repair.getVehicleDetail +
            "?startDate=" +
            this.$store.state.raporPeriot.startDate +
            "&endDate=" +
            this.$store.state.raporPeriot.endDate +
            "&vehicleId=" +
            this.vehicleId +
            "&vehicleTypeId=1",
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
};
</script>

<style></style>
