<template>
  <div class="px-2 max-h-96 overflow-y-auto">
    <operationDetail :vehicleId="vehicle.id" :plaque="vehicle.name" />
    <div>
      <cardItem
        title="SEVKİYAT GELİRİ"
        :value="Money(totalPrice) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showPlanBreakOne = !showPlanBreakOne"
        :loading="loadPlanBreakOne"
      />
      <planBreakOne
        v-if="showPlanBreakOne"
        @openVehicleDetail="(r) => vehicleDetail(r)"
        @loading="(r)=>(loadPlanBreakOne = r)"
      />
    </div>
    <cardItem
      title="KİRA GELİRİ"
      value="AKTİF DEĞİL"
      icon="fas fa-money-bill fa-3x opacity-80"
    />
  </div>
</template>

<script>
import cardItem from "@/components/general/card.item.vue";
import operationDetail from "../vehicle-based/operation-detail.vue";
import planBreakOne from "./planning/step.one.vue";
export default {
  name: "income-break-step-one",
  props: ["totalPrice"],
  components: {
    cardItem,
    planBreakOne,
    operationDetail,
  },
  data() {
    return {
      showPlanBreakOne: false,
      loadPlanBreakOne:false,
      vehicle: { id: "", plaque: "" },
    };
  },
  methods: {
    vehicleDetail(detail) {
      this.vehicle = detail;
      this.$modal.show("detail-planning-vehicle-modal");
    },
  },
};
</script>

<style></style>
