<template>
  <div id="app" class="flex min-h-screen w-full bg-gray-100 relative">
    <div class="w-full max-h-screen overflow-y-auto bg-gray-200 dark:bg-gray-900">
      <headerItem v-if="$store.state.isAuth" />
      <kilometerRaports v-if="$store.state.isAuth" />
      <router-view />
    </div>
  </div>
</template>

<script>
import headerItem from "../src/partition/header.vue";
import axios from "axios";
import { user } from "./networking/urlmanager";
import kilometerRaports from "./views/vehicle-tracking/components/detail/components/kilometerRaports.vue";

export default {
  name: "app",
  components: {
    headerItem,
    kilometerRaports,
  },
  created() {
    if (this.$route.name == "loginPage") return;
    axios
      .get(user.signControl, {
        headers: {
          Authorization: "Bareer " + this.$store.state.userData.token,
        },
      })
      .catch((err) => {
        this.errorBox(err.response);
      });
  },
};
</script>
