const similarity = require("similarity");

const isAdr = 2;

/**
 * Araç kullanılabilirliğini günceller.
 * @param {number} vehicleId - Güncellenecek aracın ID'si.
 * @param {Array} vehicles - Araçlar listesi.
 * @param {boolean} isAvailable - Araç için kullanılabilirlik durumu.
 */
function updateVehicleAvailability(vehicleId, vehicles, isAvailable) {
  const vehicleIndex = vehicles.findIndex((v) => v.id === vehicleId);
  if (vehicleIndex !== -1) {
    vehicles[vehicleIndex].isAvailable = isAvailable;
  }
}

/**
 * Haversine formülünü kullanarak iki koordinat arasındaki mesafeyi hesaplar.
 * @param {Object} coords1 - İlk koordinat (lat, lng şeklinde).
 * @param {Object} coords2 - İkinci koordinat (lat, lng şeklinde).
 * @returns {number} İki koordinat arasındaki mesafe (metre cinsinden).
 */
function haversineDistance(coords1, coords2 )  {
  const R = 6371e3;
  const lat1Rad = (Math.PI * coords1.lat) / 180;
  const lat2Rad = (Math.PI * coords2.lat) / 180;
  const deltaLat = ((coords2.lat - coords1.lat) * Math.PI) / 180;
  const deltaLon = ((coords2.lng - coords1.lng) * Math.PI) / 180;

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1Rad) *
      Math.cos(lat2Rad) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return (R * c)/1000;
}

/**
 * Sipariş ve araç için uygunluk kontrolü yapar.
 * @param {Object} order - Sipariş detayları.
 * @param {Object} vehicle - Araç detayları.
 * @param {Array} products - Ürünler listesi.
 * @param {boolean} [isBulk=false] - Toplu taşıma kontrolü.
 * @param {boolean} [skipTheTonnage=false] - Taşıma kapasitesini es geçme kontrolü.
 * @returns {boolean} Araçın sipariş için uygun olup olmadığı bilgisi.
 */
function isVehicleSuitableForOrder(
  order,
  vehicle,
  products,
  isBulk = false,
  skipTheTonnage = false
) {
  let capacity = vehicle.capacity / 1000;

  if (capacity >= 26 && capacity <= 28) {
    capacity = 28.5;
  }

  const isTonnageSuitable =
    order.tonnage <= capacity || isBulk || skipTheTonnage;

  const isVehicleAvailable = vehicle.isAvailable;

  const findProduct = products.find((pValue) => {
    return similarity(pValue.name, order.productName) >= 0.8;
  });


  if (!findProduct) return false; // Ürün bulunamazsa, araç uygun değildir.

  // Ürünün ADR'li olup olmadığını kontrol et
  const productRequiresADR = findProduct.typeId === isAdr;

  //  Araçta bu dorse türü olup olmadığını kontrol et

  
  const findDorseType = findProduct.dorseTypes.filter((find) => {
    if (typeof find.name == "string" && typeof vehicle.romorkType == "string")
      if (
        find.name.toLowerCase().replace(/\s/g, "") ==
        vehicle.romorkType.toLowerCase().replace(/\s/g, "")
      )
        return find;
  });

  // ADR kontrolü: findDorseType içindeki tüm türler ADR'li mi?
  if (findDorseType.every((dorse) => dorse.name == null || dorse.name == ""))
    return false;

  const allADRDorse = findDorseType.every((dorse) =>
    dorse.name.includes("ADR")
  );

  const isADRCompatible =
    !productRequiresADR || (productRequiresADR && allADRDorse);


  
  return (
    isTonnageSuitable &&
    isVehicleAvailable &&
    findDorseType.length > 0 &&
    isADRCompatible
  );
}

/**
 * Sipariş ve araç arasındaki mesafeyi hesaplar.
 * @param {Object} order - Sipariş detayları.
 * @param {Object} vehicle - Araç detayları.
 * @returns {number} Sipariş ve araç arasındaki mesafe (metre cinsinden).
 */
function calculateDistancePriority(order, vehicle) {
  const distance = haversineDistance(
    { lat: parseFloat(order.startLat), lng: parseFloat(order.startLng) },
    { lat: parseFloat(vehicle.latitude), lng: parseFloat(vehicle.longitude) }
  );
  return distance;
}

/**
 * Müşteri önceliğini hesaplar.
 * @param {Object} order - Sipariş detayları.
 * @param {Array} customers - Müşteriler listesi.
 * @returns {number} Müşteri önceliği.
 */
function calculateCustomerPriority(order, customers) {
  const customer = customers.find((c) => c.id === order.customerID);
  return customer ? customer.priority : 1;
}

// function calculateFuelCost(order, vehicle, fuelCostPerLitre=35.09) {
//     const distance = calculateDistancePriority(order, vehicle);
//     const fuelNeeded = (distance / 1000) *  34;  //vehicle.fuelConsumptionPerKm; // as distance is in meters
//     return fuelNeeded * fuelCostPerLitre;
// }

/**
 * Tek bir araç için öneri oluşturur.
 * @param {Object} order - Sipariş detayları.
 * @param {Array} vehicles - Araçlar listesi.
 * @param {Array} customers - Müşteriler listesi.
 * @param {Array} products - Ürünler listesi.
 * @param {boolean} [isBulk=false] - Toplu taşıma kontrolü.
 * @param {boolean} [skipTheTonnage=false] - Taşıma kapasitesini es geçme kontrolü.
 * @returns {Object|null} Önerilen araç veya null.
 */

function recommendSingleVehicleForOrder(
  order,
  vehicles,
  customers,
  products,
  isBulk = false,
  skipTheTonnage = false
) {
  const isSuitable = (vehicle) =>
    isVehicleSuitableForOrder(order, vehicle, products, isBulk, skipTheTonnage);

  const suitableVehicles = vehicles.filter(isSuitable);

  if (suitableVehicles.length === 0) return null;

  const prioritizedVehicles = suitableVehicles.map((vehicle) => {
    const distancePriority = calculateDistancePriority(order, vehicle);
    const customerPriority = calculateCustomerPriority(order, customers);
    const combinedPriority = distancePriority * customerPriority;

    return {
      ...vehicle,
      priority: combinedPriority,
      distance: parseFloat(distancePriority / 1000).toFixed(2),
    };
  });

  const topVehicle = prioritizedVehicles.sort(
    (a, b) => a.priority - b.priority
  )[0];

  updateVehicleAvailability(topVehicle.id, vehicles, false);

  return topVehicle;
}

/**
 * Birden fazla araç için öneri oluşturur.
 * @param {Object} order - Sipariş detayları.
 * @param {Array} vehicles - Araçlar listesi.
 * @param {Array} customers - Müşteriler listesi.
 * @param {Array} products - Ürünler listesi.
 * @returns {Array} Önerilen araçlar listesi.
 */
// function recommendMultipleVehiclesForOrder(
//   order,
//   vehicles,
//   customers,
//   products,
//   totalCount
// ) {
//   const recommendedVehicles = [];
//   for (let index = 0; index < totalCount; index++) {
//     const vehicle = recommendSingleVehicleForOrder(
//       order,
//       vehicles,
//       customers,
//       products,
//       true
//     );
//     if (!vehicle) break;
//     recommendedVehicles.push(vehicle);
//   }
//   return recommendedVehicles;
// }
function recommendMultipleVehiclesForOrder(
  order,
  vehicles,
  customers,
  products,
  totalCount
){
  const recommendedVehicles = [];
  const uniqueVehicles = new Set(); // Set kullanarak veri tekrarı önleme

  while (recommendedVehicles.length < totalCount) {
    const vehicle = recommendSingleVehicleForOrder(
      order,
      vehicles,
      customers,
      products,
      true
    );

    if (!vehicle || uniqueVehicles.has(vehicle.id)) {
      break;
    }
    uniqueVehicles.add(vehicle.id);
    recommendedVehicles.push(vehicle);
  }

  return recommendedVehicles;
}

/**
 * Sipariş için en uygun araç veya araçları önerir.
 * @param {Object} order - Sipariş detayları.
 * @param {Array} vehicles - Araçlar listesi.
 * @param {Array} products - Ürünler listesi.
 * @param {Array} customers - Müşteriler listesi.
 * @returns {Array} Önerilen araç veya araçlar listesi.
 */
function recommendVehicleForOrder(
  order,
  vehicles,
  products,
  customers,
  totalCount
) {
  if (order.tonnage <= 28) {
    const vehicle = recommendSingleVehicleForOrder(
      order,
      vehicles,
      customers,
      products,
      false
    );
    if (vehicle) return [vehicle];
    else return [];
  } else {
    const recommendedVehicles = recommendMultipleVehiclesForOrder(
      order,
      vehicles,
      customers,
      products,
      totalCount
    );
    return recommendedVehicles;
  }
}

module.exports = {
  recommendSingleVehicleForOrder,
  recommendVehicleForOrder,
  recommendMultipleVehiclesForOrder,
  haversineDistance,
  isVehicleSuitableForOrder,
  calculateDistancePriority,
  calculateCustomerPriority,
  updateVehicleAvailability,
};
