<template>
  <modal
    name="addCalculateroute-modal"
    :height="isMobile ? '100%' : '100%'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
  >
    <div
      class="w-full text-2xl dark:text-white dark:bg-gray-900 h-full overflow-y-scroll"
    >
      <div class="dark:bg-gray-800 px-5 shadow-sm rounded">
        <form @submit.prevent="save" class="h-full bg-white p-2">
          <div class="flex items-center mb-4 w-full">
            <i class="fas fa-calculator fa-2x text-xl"></i>
            <h5 class="font-bold ml-4 text-xl">Yeni Rota Hesapla</h5>
            <button
              class="p-4 w-1/12 ml-auto"
              @click="$modal.hide('addCalculateroute-modal')"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="w-full full mt-4 md:mt-0">
            <diffInput
              type="text"
              max="255"
              min="1"
              v-model="name"
              :required="true"
              title="Hasap Adı"
              class="text-base"
            />
          </div>
          <div class="md:flex">
            <div class="w-full md:w-6/12 mt-4">
              <gmapInputVue
                @change="(r) => changeFillingPoint(r)"
                :defaultAdress="fillingPoint.adress"
                :lat="fillingPoint.lat"
                :lang="fillingPoint.lng"
                height="150px"
                placeHolder="Başlangıç"
                :hideMap="true"
                class="text-base"
              />
            </div>
            <div class="w-full md:w-6/12 mt-4 md:pl-2">
              <gmapInputVue
                @change="(r) => changeDeliveryPoint(r)"
                :defaultAdress="deliveryPoint.adress"
                :lat="deliveryPoint.lat"
                :lang="deliveryPoint.lng"
                height="150px"
                :hideMap="true"
                placeHolder="Bitiş"
                class="text-base"
              />
            </div>
          </div>
          <div class="w-full my-6">
            <routeViewiver
              v-if="
                // changeLocationState &&
                fillingPoint.lat > 0 &&
                fillingPoint.lng > 0 &&
                deliveryPoint.lat > 0 &&
                deliveryPoint.lng > 0
              "
              ref="routerViev"
              :customer="receivedName"
              :outpoint="deliveredName"
              :center="{
                lat: Number(fillingPoint.lat),
                lng: Number(fillingPoint.lng),
              }"
              :startPosition="{
                adress: fillingPoint.adress,
                lat: Number(fillingPoint.lat),
                lng: Number(fillingPoint.lng),
              }"
              @changeStartPosition="(val) => (fillingPoint = val)"
              @changeEndPosition="(val) => (deliveryPoint = val)"
              @changeOutpointId="(r) => (receivedName = r)"
              @changeCustomerId="(r) => (deliveredName = r)"
              @changePolyline="(r) => changePolyline(r)"
              :endPosition="{
                adress: deliveryPoint.adress,
                lat: Number(deliveryPoint.lat),
                lng: Number(deliveryPoint.lng),
              }"
              :height="'250px'"
              @change="(r) => (costDistribution = r)"
            />
          </div>

          <div class="md:flex my-6">
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="number"
                v-model="hakedisKilometer"
                :required="true"
                title="Tek yön Kilometresi"
                disabled="true"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
              <diffInput
                type="number"
                v-model="transitionCount"
                :required="true"
                title="Ay içi Sefer Sayısı"
                :max="99"
                :min="1"
                :step="1"
              />
            </div>
            <!-- v-model="" -->
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                :value="Money(hakedisKilometer * transitionCount * 2)"
                :required="true"
                :disabled="true"
                title="Toplam Kilometre"
              />
            </div>
          </div>

          <div class="md:flex items-center my-6">
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                :value="Money(totalFuelPrice) + ' ₺'"
                :disabled="true"
                title="Yakıt Maliyeti"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
              <diffInput
                type="text"
                :value="Money(otherExpensePrice) + ' ₺'"
                :disabled="true"
                title="Diğer Masraflar"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                :disabled="true"
                :value="Money(tonnagePrice) + ' ₺'"
                title="Ton Maliyeti"
              />
            </div>
          </div>

          <div class="md:flex items-center my-6">
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="text"
                @click="$modal.show('add-hgs-detail-modal')"
                :value="Money(costDistribution.cost.cash) + ' ₺'"
                :disabled="true"
                :title="$t('operations.planning.modal.tollHighwayCost')"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
              <diffInput
                type="text"
                :value="expensivePrice + ' ₺'"
                :disabled="true"
                title="Maliyet Fiyatı"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0">
              <diffInput
                type="price"
                v-model="totalPrice"
                :digitCount="2"
                title="Gerçek Satış Fiyatı"
              />
            </div>
          </div>

          <div class="my-6 md:flex items-center">
            <div
              class="w-full mt-4 md:mt-0"
              :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12'"
            >
              <diffInput
                type="select"
                :optList="[
                  {
                    id: 1,
                    name: 'Eklensin',
                  },
                  {
                    id: 2,
                    name: 'Eklenmesin',
                  },
                ]"
                v-model="isAmortisman"
                title="Amortisman Maliyeti"
              />
            </div>
            <div
              class="w-full mt-4 md:mt-0 md:px-2"
              :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12 md:pl-2'"
            >
              <diffInput
                type="select"
                :optList="[
                  {
                    id: 1,
                    name: 'Evet',
                  },
                  {
                    id: 2,
                    name: 'Hayır',
                  },
                ]"
                v-model="isOneWay"
                title="Tek Yön"
              />
            </div>
            <div class="w-full md:w-4/12 mt-4 md:mt-0" v-if="isOneWay == 1">
              <diffInput
                type="number"
                v-model="dayCount"
                :step="1"
                :min="0"
                :max="150"
                title="Gün Sayısı"
              />
            </div>
          </div>
          <div class="w-full mt-4">
            <diffInput
              type="textarea"
              v-model="explanation"
              :title="$t('general.note')"
              maxlength="500"
              :required="isNoteRequired"
            />
          </div>

          <div>
            <asyncBtn
              icon="fas fa-check"
              :text="$t('general.saveButtonTitle')"
              :loadTitle="$t('general.saveButtonLoadTitle')"
              :loadState="load"
              class="w-full py-1 mt-4"
            />
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import asyncBtn from "@/components/general/asyncBtn.vue";
import gmapInputVue from "@/components/general/gmapInput.vue";
import diffInput from "@/components/general/diffInput.vue";
import axios from "axios";
import { calculate } from "@/networking/urlmanager";
import routeViewiver from "../../planning/plan/components/route-viewiver.vue";
import polyLine from "google-polyline";
import { calculator } from "../../planning/plan/components/utils/cost";
export default {
  name: "calculPage",
  components: {
    routeViewiver,
    diffInput,
    gmapInputVue,
    asyncBtn,
  },
  data() {
    return {
      orderNo: "",
      name: "",
      orderDate: "",
      complateDate: "",
      customer: "",
      invoicedCompany: "",
      receivedName: "",
      deliveredName: "",
      fillingPoint: { lat: 0, lng: 0, adress: "" },
      deliveryPoint: { lat: 0, lng: 0, adress: "" },
      productType: "",
      amount: "",
      unitType: "",
      fillingDate: "",
      deliveryDate: "",
      hakedisKilometer: "",
      transitionCount: 1,
      personnel: "",
      vehicle: "",
      dorse: "",
      driver: "",
      costDistribution: { cost: { cash: 0 } },
      explanation: "",
      expensivePrice: 0,
      totalPrice: 0,
      totalFuelPrice: 0,
      otherExpensePrice: 0,
      tonnagePrice: 0,
      polyline: "",
      isAmortisman: 1,
      isOneWay: 1,
      dayCount: 1,
      expenseDetail: {},
      isNoteRequired: false,
      getLoad: false,
      changeLocationState: false,
      explosiveState: false,
      load: false,
    };
  },
  methods: {
    addRefresh() {
      this.name = "";
      this.receivedName = "";
      this.deliveredName = "";
      this.fillingPoint = { lat: 0, lng: 0, adress: "" };
      this.deliveryPoint = { lat: 0, lng: 0, adress: "" };
      this.hakedisKilometer = 0;
      this.transitionCount = 1;
      this.costDistribution = { cost: { cash: 0 } };
      this.explanation = "";
      this.expensivePrice = 0;
      this.totalPrice = 0;
      this.totalFuelPrice = 0;
      this.otherExpensePrice = 0;
      this.tonnagePrice = 0;
      this.isAmortisman = 1;
      this.isOneWay = 1;
      this.dayCount = 1;
      this.load = false;
    },

    //İKİNCİ DEFA EKLEME YAPTIKTAN SONRA NAN GELİYORDU BU YÜZDEN BU KISMI EKLEDİM SALİM
    Money(amount) {
      if (isNaN(amount) || !isFinite(amount)) {
        return "0";
      } else return amount.toFixed(2);
    },
    hide() {
      this.$modal.hide("addCalculateroute-modal");
    },
    save() {
      this.load = true;
      axios
        .post(
          calculate.add,
          {
            name: this.name,
            startPosition: this.receivedName, //BAŞLANGIÇ NOKTASI++++++++++++++
            finishPosition: this.deliveredName, //BİTİŞ NOKTASI +++++++++++++++
            oneWayKm: this.hakedisKilometer, //TEK YÖN KM ++++++++++++++++
            montlytransitionCount: this.transitionCount, //AY İÇİ SEFER SAYISI      +++++++++++
            totalKm: this.Money(this.hakedisKilometer * this.transitionCount * 2), //TOPLAM KM     ++++++++++++
            fuelPrice: this.Money(this.totalFuelPrice), //yakıt maliyeti  ++++++++++++++++
            otherPrice: this.Money(this.otherExpensePrice), //DİĞER MASRAFLAR  ++++++++++++
            tonPrice: this.Money(this.tonnagePrice), //ton maliyeti+++++++++++++++
            motorwayPrice: this.Money(this.costDistribution.cost.cash), //OTOYOL MALİYETİ++++++++++0
            cost: this.expensivePrice, //maliyet fıyatı  +++++++
            salePrice: this.totalPrice, //GERÇEK SATIŞ FİYATI  +++++++++++++
            amortismanPrice: this.isAmortisman, //Amortisman  Maliyeti +++++++++++
            oneWay: this.isOneWay, //TEK YÖN ++++++++++++++++
            dayCount: this.dayCount, //GÜN SAYISI +++++++++++++++
            explanation: this.explanation, //NOT ++++++++++++++++
            outpointLat: this.fillingPoint.lat,
            outpointLng: this.fillingPoint.lng,
            targetPointLat: this.deliveryPoint.lat,
            targetPointLng: this.deliveryPoint.lng,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.addRefresh();
            this.$emit("refresh", true);
            this.hide();
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          console.log(err);
          this.load = false;
        });
    },
    changeFillingPoint(item) {
      this.changeLocationState = false;
      this.fillingPoint = item;
      this.receivedName = item.adress;
      if (this.deliveryPoint.lat > 0 && this.deliveryPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeDeliveryPoint(item) {
      this.changeLocationState = false;
      this.deliveryPoint = item;
      this.deliveredName = item.adress;
      if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeLocation() {
      this.changeLocationState = false;
      if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    getTimeDetail(mins) {
      var h = (mins / 60) | 0,
        m = mins % 60 | 0;
      return h + " Saat" + " " + m + " Dakika";
    },
    changePolyline(val) {
      this.polyLine = polyLine.encode(val);
    },
    async calculatePrice() {
      let montlyKilometer = this.hakedisKilometer * this.transitionCount * 2;
      let hgsPrice =
        parseFloat(this.costDistribution.cost.cash) * this.transitionCount * 2;
      if (this.isOneWay == 1) {
        montlyKilometer = this.hakedisKilometer * this.transitionCount;
        hgsPrice = parseFloat(this.costDistribution.cost.cash);
      }
      this.expenseDetail = await calculator(
        this.$store.state.userData.token,
        1,
        montlyKilometer * 12,
        montlyKilometer,
        hgsPrice,
        this.isAmortisman == 1,
        this.isOneWay == 1,
        this.dayCount
      );

      const { total, fuelTotalPrice, totalAdbluePrice } = this.expenseDetail;

      this.totalFuelPrice = fuelTotalPrice + totalAdbluePrice;
      this.otherExpensePrice = total - this.totalFuelPrice;

      if (this.isOneWay == 1) {
        this.tonnagePrice = (total / montlyKilometer / 27) * this.hakedisKilometer;
      } else {
        this.tonnagePrice = (total / montlyKilometer / 27) * this.hakedisKilometer * 2;
      }

      if (!Number.isNaN(total) && total != "NaN") {
        this.expensivePrice = total;
        this.expensivePrice = new Intl.NumberFormat("tr-TR", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(this.expensivePrice);
      }
    },
  },
  watch: {
    async transitionCount() {
      await this.calculatePrice();
    },
    async costDistribution(val) {
      this.hakedisKilometer = val.totalKilometer;
      await this.calculatePrice();
    },
    async isAmortisman() {
      await this.calculatePrice();
    },
    isOneWay() {
      this.calculatePrice();
    },
    dayCount() {
      this.calculatePrice();
    },

    totalPrice(newTotalPrice) {
      if (newTotalPrice == 0 || newTotalPrice == "" || newTotalPrice == undefined) return;
      // bu olmazsa js noktayı ondalık ayrımı olarak algılıyor. ör:1.500 binBeşYüzü BirBuçuk olarak algılıyor
      const formattedPrice = newTotalPrice.includes(".")
        ? newTotalPrice.replace(".", "")
        : newTotalPrice;
      this.totalPrice = formattedPrice;

      const numericTotalPrice = parseFloat(formattedPrice.replace(",", "."));
      const numericExpensivePrice = parseFloat(
        this.expensivePrice.replace(",", ".").replace(".", "")
      );
      this.isNoteRequired = numericTotalPrice < numericExpensivePrice;
      if (this.isNoteRequired) {
        this.$swal.fire({
          icon: "warning",
          title: "UYARI!",
          text:
            "Maliyet Fiyatının altında bir Satış Fiyatı girildi. Lütfen açıklamanızı Not alanına ekleyin.",
          showConfirmButton: true,
          confirmButtonText: "TAMAM",
        });

        this.isNoteRequired = false;
      }
    },
  },
};
</script>
