<template>
  <diffInput
    :loading="load"
    type="select"
    title="Planalanmayı Bekleyen Siparişler"
    label="filterName"
    :optList="List"
    v-model="selected"
    :lightDark="lightDark"
  />
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import axios from "axios";
import { orders } from "@/networking/urlmanager";
import moment from "moment";

export default {
  name: "order-input",
  props: ["value", "title", "lightDark", "isBulk"],
  components: {
    diffInput,
  },
  data() {
    return {
      load: false,
      List: [],
      selected: "",
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    getAll() {
      this.load = true;
      axios.get(
          orders.getNotPaginationAll + "?multiple=" + (this.isBulk ? "1" : "2"),
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((result) => {
          this.List = result.data.data.map((item) => {
            var differentDate = parseInt(
              moment
                .duration(moment(new Date(item.deliveryDate)).diff(moment()))
                .asMinutes()
                .toFixed(2)
            );

            return {
              ...item,
              filterName: item.orderNo + " / " + item.name + (differentDate > 100 ?  '' : ' - Teslim Tarihi Gecikti'),
              $isDisabled: differentDate > 100 ? false : true
            };
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.selected = this.value;
    this.getAll();
  },
  watch: {
    selected(val) {
      this.$emit("change", val);

      const findItem = this.List.find((item) => item.id == val);
     
      if (findItem) {
      this.$emit("changeObject", findItem);
      this.$emit("updateFlag", true); 
    }    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>

<style></style>
