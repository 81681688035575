<template>
  <modal
    name="kilometer-rapor-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '70%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">Araç Kilometre Raporu</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('kilometer-rapor-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div
      class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-white dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
    >
      <div class="flex items-center justify-between px-4 bg-white py-2">
        <div class="flex items-center">
          <div>
            <label class="block text-xs">Başlangıç</label>
            <input
              type="date"
              v-model="startDate"
              :max="endDate"
              class="p-2 rounded border-b"
            />
          </div>
          <div class="ml-4 border-l pl-4">
            <label class="block text-xs">Bitiş</label>
            <input
              type="date"
              :min="startDate"
              :max="maxDate"
              v-model="endDate"
              class="p-2 rounded border-b"
            />
          </div>
        </div>
        <div class="flex items-center">
          <div class="ml-4 border-l pl-4">
            <label class="block text-xs"> *Araç Ara</label>
            <input
              type="text"
              v-model="searchTerm"
              placeholder="Araç plakası giriniz"
              class="p-2 rounded border-b"
            />
          </div>
        </div>
        <div>
          <button
            :disabled="load"
            class="px-2 py-2 rounded bg-red-500 text-white"
            @click="getAll"
          >
            {{ load ? "Oluşturuluyor" : "Yeninden Oluştur" }}
          </button>
          <button
            :disabled="excelLoad"
            @click="excelExport"
            class="hover:bg-green-500 px-4 py-2 ml-2 bg-green-600 text-white"
          >
            <span v-if="!excelLoad">
              <i class="fas fa-file-excel"></i>
              EXCEL'E AKTAR
            </span>
            <span v-else>
              <i class="fas fa-spinner fa-spin"></i>
              AKTARILIYOR
            </span>
          </button>
        </div>
      </div>
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
          <tr>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 150px !important"
            >
              Araç
            </th>

            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 200px !important"
            >
              Başlangıç
            </th>

            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 200px !important"
            >
              Bitiş
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 200px !important"
            >
              Alınan Yol
            </th>
          </tr>
        </thead>
        <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
          <tr v-for="(item, index) in filteredList" :key="index">
            <td class="table-td border-r border-opacity-20">
              <p>
                {{ item.vehicle }}
              </p>
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.startKilometer }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.endKilometer }}
            </td>
            <td class="table-td border-r border-opacity-20">{{ item.diffrent }}</td>
          </tr>
        </tbody>
        <tableLoader :load="load" colspan="4" :length="filteredList.length" />
      </table>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import { tracking } from "../../../../../networking/urlmanager";
import tableLoader from "../../../../../components/general/table-loader.vue";
import moment from 'moment';

export default {
  name: "detail-components",
  components: {
    tableLoader,
  },
  data() {
    return {
      List: [],
      load: false,
      startDate: "",
      endDate: "",
      maxDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      excelLoad: false,
      searchTerm: "",
    };
  },
  computed: {
    filteredList() {
      return this.List.filter(item =>
        item.vehicle.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  },
  methods: {
    async getAll() {
      try {
        this.List = [];
        this.load = true;
        const result = await axios.get(
          tracking.TrackingMonthlyKilometerRaport +
            "?start=" +
            this.startDate +
            "&end=" +
            this.endDate,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        );
        this.List = result.data.data.map((item) => {
          return {
            ...item,
            endKilometer: parseInt(item.endKilometer),
            startKilometer: parseInt(item.startKilometer),
            diffrent: parseInt(item.diffrent),
          };
        });

        this.$emit("changeRaport", result.data.data);
        this.load = false;
      } catch (error) {
        this.load = false;
        console.log("hata : ", error);
        this.$swal(
          "warning",
          "UYARI!",
          "işlem başarısız oldu lütfen daha sonra tekrar deneyiniz!"
        );
      }
    },
    excelExport() {
      this.excelLoad = true;
      axios
        .post(
          tracking.excelExport,
          {
            list: this.List,
            type: 2,
          },

          {
            responseType: "blob",
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);

          // Dosyayı indirme bağlantısını oluşturun
          const a = document.createElement("a");
          a.href = url;
          a.download = "exportedExcel.xlsx";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          // alert("İŞLEM BAŞARISIZ OLDU ANKET VERİLERİ GETİRİLEMEDİ !");
        })
        .finally(() => {
          this.excelLoad = false;
        });
    },
  }
};
</script>
