<template>
  <modal
    name="edit-plan-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">YENİ TOPLU SEVKİYAT</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('edit-plan-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full mb-2">
        <orderInput :isBulk="true" @changeObject="(item) => getDetail(item)" />
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="date"
            minlength="1"
            :required="true"
            v-model="orderDate"
            title="Sipariş Tarihi"
            :disabled="true"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <diffInput
            type="date"
            minlength="1"
            :required="true"
            v-model="complateDate"
            title="Kapanış Tarihi"
            :disabled="true"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="text"
            minlength="1"
            :required="true"
            v-model="customer"
            :disabled="true"
            title="Müşteri"
          />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            :title="$t('operations.planning.modal.billedCompany')"
            v-model="invoicedCompany"
            :disabled="true"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput type="text" :disabled="true" title="Alıcı" v-model="receivedName" />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            :disabled="true"
            title="Gönderici"
            v-model="deliveredName"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full mt-4">
          <diffInput
            type="text"
            v-model="productType"
            :required="true"
            title="Mal Sınıfı"
            :disabled="true"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-6/12">
          <diffInput
            type="number"
            v-model="fillingDate"
            :required="true"
            :title="'Dolum Süresi (Dakika)'"
            :min="0"
            :max="10000"
          />
        </div>
        <div class="w-full md:w-6/12 md:pl-2">
          <diffInput
            :min="0"
            type="number"
            v-model="deliveryDate"
            :required="true"
            :title="'Tahliye Süresi (Dakika)'"
            :max="10000"
          />
        </div>
      </div>

      <div class="w-full mt-4">
        <routeViewiver
          v-if="
            // changeLocationState &&
            fillingPoint.lat > 0 &&
            fillingPoint.lng > 0 &&
            deliveryPoint.lat > 0 &&
            deliveryPoint.lng > 0
          "
          ref="routerViev"
          :customer="receivedName"
          :outpoint="deliveredName"
          :center="{
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          :startPosition="{
            adress: fillingPoint.adress,
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          @changeStartPosition="(val) => (fillingPoint = val)"
          @changeEndPosition="(val) => (deliveryPoint = val)"
          @changeOutpointId="(r) => (receivedName = r)"
          @changeCustomerId="(r) => (deliveredName = r)"
          @changePolyline="(r) => changePolyline(r)"
          :endPosition="{
            adress: deliveryPoint.adress,
            lat: Number(deliveryPoint.lat),
            lng: Number(deliveryPoint.lng),
          }"
          :height="'200px'"
          @change="(r) => (costDistribution = r)"
        />
      </div>

      <div class="md:flex mt-4">
        <div class="w-full flex items-center justify-center">
          <diffInput
            type="number"
            v-model="hakedisKilometer"
            :required="true"
            :disabled="true"
            title="Kilometre"
          />
          <diffInput
            type="number"
            v-model="recomendedVehicleCount"
            :required="true"
            min="0"
            class="ml-2"
            title="Önerilen Araç Sayısı"
            :step="1"
            :min="0"
            :max="1000"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full flex items-center justify-center">
          <diffInput
            type="number"
            v-model="tonnage"
            :required="true"
            title="Toplam Tonaj"
            :disabled="true"
          />
          <diffInput
            type="datetime-local"
            v-model="totalTime"
            class="ml-2"
            title="Tahmini Tamamlamanma süresi"
            :disabled="true"
          />
        </div>
      </div>

      <vehiclesTable
        :totalCount="recomendedVehicleCount"
        :center="calculateCenter()"
        @change="(r) => (vehicleList = r)"
        :order="detail"
      />

      <div class="w-full mt-4">
        <diffInput
          type="textarea"
          v-model="explanation"
          :title="$t('general.note')"
          maxlength="500"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </modal>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import gmapInputVue from "@/components/general/gmapInput.vue";
import axios from "axios";
import { bulkPlanning } from "@/networking/urlmanager";
import orderInput from "@/components/devItem/orderInput.vue";

import polyLine from "google-polyline";

// local Components
import routeViewiver from "../../../plan/components/route-viewiver.vue";
import moment from "moment";
import vehiclesTable from "../add/vehicles.vue";

export default {
  name: "add-plan-modal",
  components: {
    diffInput,
    asyncBtn,
    gmapInputVue,
    routeViewiver,
    vehiclesTable,
    orderInput,
  },
  data() {
    return {
      orderNo: "",
      orderName: "",
      orderDate: "",
      complateDate: "",
      customer: "",
      invoicedCompany: "",
      receivedName: "",
      deliveredName: "",
      productType: "",
      tonnage: 0,
      detail: {},
      fillingDate: 0,
      deliveryDate: 0,
      explanation: "",
      hakedisKilometer: "0",
      recomendedVehicleCount: 0,
      roadMinutes: 0,
      totalTime: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      costDistribution: null,
      fillingAdress: "",
      deliveryAdress: "",
      fillingPoint: {
        adress: "",
        lat: 0,
        lng: 0,
      },
      deliveryPoint: {
        adress: "",
        lat: "",
        lng: "",
      },
      polyLine: "",
      load: false,
      changeLocationState: false,
      explosiveState: true,
      getLoad: false,
    };
  },
  methods: {
    getDetail(item) {
      this.detail = item;
      this.orderNo = item.orderNo;
      this.orderName = item.name;
      this.orderDate = moment(item.orderDate).format("YYYY-MM-DD");
      this.customer = item.customer;
      this.invoicedCompany = item.invoicedCompany;
      this.receivedName = item.receivedName;
      this.deliveredName = item.deliveredName;
      this.productType = item.productType;
      this.tonnage = item.amount;
      this.unitType = item.unitType;
      (this.fillingAdress = item.outpointAdress),
        (this.deliveryAdress = item.targetAdress),
        (this.fillingPoint = {
          lat: item.outpointLat,
          lng: item.outpointLng,
          adress: item.outpointAdress,
        });
      this.deliveryPoint = {
        lat: item.targetPointLat,
        lng: item.targetPointLng,
        adress: item.targetAdress,
      };

      this.vehicle = item.vehicle;
      this.dorse = item.dorse;
      this.personnel = item.driverName;
      this.totalTime = item.deliveryDate ? item.deliveryDate : "2023-12-02T15:05";

      this.changeLocation();
    },
    changePolyline(val) {
      this.polyLine = polyLine.encode(val);
    },
    save() {
      this.load = true;

      axios
        .post(
          bulkPlanning.add,
          {
            orderNo: this.orderNo,
            polyline: this.polyLine,
            orderName: this.orderName,
            orderDate: this.orderDate,
            complateDate: this.complateDate,
            customer: this.customer,
            invoicedCompany: this.invoicedCompany,

            tonnage: this.tonnage,
            Kilometer: this.hakedisKilometer.toString(),
            recomendedVehicleCount: this.recomendedVehicleCount,
            productType: this.productType,

            receivedName: this.receivedName,
            fillingLatitude: this.fillingPoint.lat,
            fillingLongitude: this.fillingPoint.lng,
            estimatedFillingTime: this.fillingDate,

            deliveredName: this.deliveredName,
            targetpoint: this.deliveryPoint.adress,
            deliveryLatitude: this.deliveryPoint.lat,
            deliveryLongitude: this.deliveryPoint.lng,
            estimatedDeliveryTime: this.deliveryDate,

            estimatedComplateDate: this.totalTime,

            note: this.explanation,
            vehicleList: this.vehicleList ? JSON.stringify(this.vehicleList) : [],
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.resetForm();
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("add-plan-modal");
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          console.log(err);
          this.load = false;
        });
    },
    resetForm() {
      this.polyLine = "";
      this.orderNo = "";
      this.orderName = "";
      this.orderDate = "";
      this.complateDate = "";
      this.customer = "";
      this.invoicedCompany = "";
      this.receivedName = "";
      this.deliveredName = "";
      this.product = "";
      this.tonnage = "";

      this.hakedisKilometer = "";
      this.recomendedVehicleCount = "";

      this.outpointStateId = "";
      this.fillingDate = "";
      this.targetpoint = "";
      this.targetpointLat = "";
      this.targetpointLng = "";

      this.deliveryDate = "";
      this.vehicleList = [];
      this.explanation = "";

      this.fillingPoint = {
        adress: "",
        lat: 0,
        lng: 0,
      };
      this.deliveryPoint = {
        adress: "",
        lat: "",
        lng: "",
      };
      this.changeLocationState = false;
    },

    changeFillingPoint(item) {
      this.changeLocationState = false;
      this.fillingPoint = item;
      this.receivedName = item.adress;
      if (this.deliveryPoint.lat > 0 && this.deliveryPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeDeliveryPoint(item) {
      this.changeLocationState = false;
      this.deliveryPoint = item;
      this.deliveredName = item.adress;

      if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeLocation() {
      this.changeLocationState = false;
      if (
        (this.fillingPoint.lat != 0 && this.fillingPoint.lng != 0) ||
        (this.deliveryPoint.lat != 0 && this.deliveryPoint.lng != 0)
      ) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },

    changeProductType(state) {
      this.changeLocationState = false;
      this.explosiveState = state;
      if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    getTimeDetail(mins) {
      var h = (mins / 60) | 0,
        m = mins % 60 | 0;
      return h + " Saat" + " " + m + " Dakika";
    },

    calculateTotalTime() {
      const val = this.costDistribution;
      if (typeof val == "object" && val != null) {
        this.roadMinutes = val.totalMinute;
        let unitTransition = Number(val.totalMinute) * 2;
        unitTransition += Number(this.fillingDate);
        unitTransition += Number(this.deliveryDate);

        const diffrentDate =
          parseInt(
            moment
              .duration(moment(new Date(this.totalTime)).diff(moment()))
              .asMinutes()
              .toFixed(2)
          ) / 7;

        const totalTransitionCount = parseFloat(this.tonnage) / 28;

        const vehicleCount = Math.ceil(
          (totalTransitionCount * unitTransition) / diffrentDate
        );

        this.recomendedVehicleCount = vehicleCount > 0 ? vehicleCount : 1;
      }
    },
    calculateCenter() {
      return {
        lat: (this.fillingPoint.lat + this.deliveryPoint.lat) / 2,
        lng: (this.fillingPoint.lng + this.deliveryPoint.lng) / 2,
      };
    },
  },
  watch: {
    receiptState(val) {
      if (val == 2) {
        this.receiptDate = "";
        this.receiptNo = "";
      }
    },
    productStateId() {
      this.productId = "";
    },
    costDistribution(val) {
      if (val) {
        this.hakedisKilometer = val.totalKilometer.toFixed(2);
        this.roadMinutes = val.totalMinute ? val.totalMinute : 0;
        this.calculateTotalTime();
      }
    },
    deliveryDate() {
      this.calculateTotalTime();
    },
    fillingDate() {
      this.calculateTotalTime();
    },
    totalTime() {
      this.calculateTotalTime();
    },
    tonnage() {
      this.calculateTotalTime();
    },
  },
};
</script>

<style></style>
