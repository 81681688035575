<template>
  <div class="px-2">
    <div v-for="item in List" :key="item.id">
      <cardItem
        :title="item.name"
        :value="Money(item.total) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showDetail(item)"
        :loading="loadBreak && item.id == selected"
      />
      <stepTwo
        v-if="showBreak && item.id == selected"
        :supplyId="item.id"
        @openVehicleDetail="(r) => $emit('openVehicleDetail', r)"
        @loading="r=>loadBreak=r"
      />
    </div>
  </div>
</template>

<script>
import cardItem from "@/components/general/card.item.vue";
import stepTwo from "./step.two.vue";
import axios from "axios";
import { rapor } from "@/networking/urlmanager";
export default {
  name: "income-plan-step-two",
  components: {
    cardItem,
    stepTwo,
  },
  data() {
    return {
      showBreak: false,
      List: [],
      load: false,
      selected: "",
      loadBreak:false
    };
  },
  methods: {
    showDetail(item) {
      this.selected = item.id;
      this.showBreak = !this.showBreak;
    },
    getDetail() {
      this.load = true;
      axios
        .get(
          rapor.income.transition.getSupplyDistribution +
            "?startDate=" +
            this.$store.state.raporPeriot.startDate +
            "&endDate=" +
            this.$store.state.raporPeriot.endDate,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getDetail();
  },
  watch: {
    "$store.state.raporPeriot.startDate"() {
      this.getDetail();
    },
    "$store.state.raporPeriot.endDate"() {
      this.getDetail();
    },
    load(val) {
      this.$emit("loading", val);
    },
  },
};
</script>

<style></style>
