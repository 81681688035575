<template>
  <div class="h-80 p-6 bg-gray-200 dark:bg-gray-900">
    <addModal @refresh="getAll" />
    <editModal @refresh="getAll" :detail="selected" />

    <div class="h-full p-4 bg-gray-50 dark:bg-gray-800 rounded shadow-sm mt-4">
      <div class="w-full flex items-center dark:text-gray-400">
        <i class="fas fa-bars mr-2 fa-lg"></i>
        <h5 class="font-bold text-xl">Sürücüler</h5>
      </div>
      <div class="w-full pt-5">
        <div class="md:flex items-center">
          <div class="w-full md:w-9/12 text-left">
            <div class="w-full inline-block">
              <button
                @click="() => $modal.show('add-personnel-modal')"
                class="btn hover:bg-gray-500 hover:text-gray-200 p-2"
              >
                <i class="fas fa-plus mr-2"></i> Yeni Sürücü
              </button>
            </div>
          </div>
          <searchInput v-model="search" @searcing="() => getSearch()" />
        </div>

        <div
          class="shadow table-height-value overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead
              class="bg-white dark:bg-gray-700 border-black border-dotted border-b-2 border-opacity-20"
            >
              <tr>
                <th scope="col" class="table-th text-left">#</th>
                <th
                  scope="col"
                  class="table-th text-left sticky left-0 bg-gray-800 z-20"
                  style="min-width: 120px !important"
                >
                  {{ $t("humanResources.staff.employee") }}
                </th>
                <th
                  scope="col"
                  class="table-th text-left"
                  style="min-width: 150px !important"
                >
                  TC
                </th>
                <th scope="col" class="table-th text-left">
                  {{ $t("humanResources.staff.phoneNumber") }}
                </th>

                <th
                  scope="col"
                  class="table-th text-center"
                  style="min-width: 150px !important"
                >
                  Araç Plakası
                </th>
                <th
                  scope="col"
                  class="table-th text-center"
                  style="min-width: 150px !important"
                >
                  Sisteme Giriş Durumu
                </th>
                <th
                  scope="col"
                  class="table-th text-center"
                  style="min-width: 150px !important"
                >
                  {{ $t("humanResources.staff.process") }}
                </th>
              </tr>
            </thead>
            <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
              <tr v-for="(item, index) in List" :key="index">
                <td class="table-td text-left">
                  {{ currentPage * 18 + (index + 1) }}
                </td>
                <td class="table-td text-left sticky left-0 tbody-bg-color">
                  {{ item.fullname }}
                </td>
                <td class="table-td text-left">
                  {{ item.tcno }}
                </td>
                <td class="table-td text-left">
                  {{ item.phone }}
                </td>
                <td class="table-td text-center text-red-700">
                  {{ item.plaque || "---------" }}
                </td>
                <td class="table-td text-center font-semibold">
                  <p :class="item.notifyDeviceId ? '' : 'text-red-400'">
                    {{ item.notifyDeviceId ? "Aktif" : "Pasif" }}
                  </p>
                </td>
                <td class="table-td flex items-center justify-center">
                  <button class="process-btn mr-2" @click="showEditModal(item)">
                    <i class="fas fa-pen"></i>
                  </button>

                  <button
                    class="process-btn"
                    @click="item.activeState == 1 ? passive(item) : active(item)"
                    :title="
                      item.activeState == 2 ? 'Tekrar Aktif Hale Getir' : 'Personel Çıkar'
                    "
                  >
                    <i class="fas fa-trash" v-if="item.activeState == 1"></i>
                    <i class="fas fa-reply" v-if="item.activeState == 2"></i>
                  </button>
                </td>
              </tr>
              <tableLoader :load="load" :length="List.length" colspan="10" />
            </tbody>
          </table>
        </div>
        <PaginationComponents
          class="h-8"
          v-if="totalCount > 0"
          :totalRowCount="totalCount"
          :currentPage="currentPage + 1"
          :pageCount="pageCount"
          @changePage="(r) => (currentPage = r - 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Global Components
import PaginationComponents from "@/components/general/pagination.vue";
import tableLoader from "@/components/general/table-loader.vue";
import searchInput from "@/components/general/search-input.vue";
import dropdownButton from "@/components/general/dropdown.button.vue";

// Local Components
import addModal from "./components/add.modal.vue";
import editModal from "./components/edit.modal.vue";

// networking
import axios from "axios";
import { personnelManagement } from "@/networking/urlmanager";

export default {
  name: "personnel-module",
  components: {
    PaginationComponents,
    dropdownButton,
    tableLoader,
    searchInput,

    addModal,
    editModal,
  },
  data() {
    return {
      List: [],
      load: false,
      search: "",
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,

      selected: { id: "" },
    };
  },
  methods: {
    getSearch() {
      (this.currentPage = 0), this.getAll();
    },
    showFileModal(item) {
      this.selected = item;
      this.$modal.show("files-modal");
    },
    showEditModal(item) {
      this.selected = item;
      this.$modal.show("edit-personnel-modal");
    },
    getAll(load = true) {
      this.List = load ? [] : this.List;
      this.load = load;
      axios
        .get(
          personnelManagement.getAll +
            "?page=" +
            this.currentPage +
            "&search=" +
            this.search,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    passive(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Personeli Silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              personnelManagement.delete,
              {
                rowId: item.id.toString(),
                tcno: item.tcno,
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    active(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Personeli tekrar aktif hale getirmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              personnelManagement.reactive,
              {
                rowId: item.id.toString(),
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
  },
  created() {
    this.getAll();

    this.refreshInterval = setInterval(() => {
      this.getAll(false);
    }, 3000);
  },
  destroyed() {
    // Component yok edildiğinde interval durdurulur.
    clearInterval(this.refreshInterval);
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    search(val) {
      if (val.length == 0) this.getAll();
    },
  },
};
</script>

<style></style>
