<template>
  <div
    class="w-full mt-2 md:m-1 p-4 bg-gray-700 text-white shadow-xl rounded-lg border-b-4 border-red-600 flex items-center justify-center cursor-pointer select-none"
    @click="$emit('click', true)"
  >
    <div class="w-9/12">
      <label class="text-xs pb-1">{{ title }}</label>
      <h3 class="font-bold mt-1 border-t pt-1">{{ value }}</h3>
    </div>
    <div class="w-3/12 ml-4">
      <i v-if="!loading" :class="icon"></i>
      <i class="fas fa-spinner fa-3x text-white fa-spin" v-if="loading"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "card-item",
  props: {
    title: {
      type: String,
      required: true,
      default: "Card Title",
    },
    value: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: "fas fa-money-bill fa-3x opacity-30",
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
