var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full relative"},[(_vm.load)?_c('div',{staticClass:"w-full flex items-center justify-center",staticStyle:{"height":"67vh"}},[_c('i',{staticClass:"fas fa-spinner fa-3x fa-spin"})]):_vm._e(),(!_vm.load && _vm.markers.length)?_c('div',[_c('GmapMap',{ref:"gmap",staticStyle:{"width":"100%","height":"67vh !important"},attrs:{"center":_vm.position,"zoom":_vm.zoomLevel,"options":{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      }}},_vm._l((_vm.markers),function(m){return _c('GmapMarker',{key:m.id,attrs:{"title":m.title,"label":{
          text: m.title, // + '-' + m.speedDirection
          color: 'white',
          className:
            'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
          fontSize: '8.5px',
        },"position":m.position,"icon":m.icon}})}),1)],1):_vm._e(),(!_vm.load && _vm.markers.length == 0)?_c('div',{staticClass:"w-full flex items-center justify-center",staticStyle:{"height":"50vh"}},[_vm._m(0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inline text-center"},[_c('i',{staticClass:"fas fa-exclamation-triangle fa-4x"}),_c('p',{staticClass:"block mt-5 px-20 leading-7"},[_vm._v(" Araç konumu bulunamadı lütfen aracın takip sisteminin doğru çalıştığından emin olun ! ")])])
}]

export { render, staticRenderFns }