<template>
  <div
    class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
  >
    <div class="flex items-center justify-between bg-white px-4 pb-4">
      <div class="flex items-center">
        <div>
          <label class="block text-xs">Başlangıç</label>
          <input
            type="date"
            v-model="startDate"
            :max="endDate"
            class="p-2 rounded border-b"
          />
        </div>
        <div class="ml-4 border-l pl-4">
          <label class="block text-xs">Bitiş</label>
          <input
            type="date"
            :min="startDate"
            :max="maxDate"
            v-model="endDate"
            class="p-2 rounded border-b"
          />
        </div>
        <button @click="clearDates" class="ml-4 px-2 py-1 bg-red-600 text-white rounded">
          Temizle
        </button>
      </div>
      <div class="relative">
        <input
          type="text"
          v-model="searchTerm"
          placeholder="Ara..."
          class="p-2 rounded border-b"
        />
      </div>
    </div>
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
        <tr>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 100px !important"
          >
            Sefer No
          </th>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 150px !important"
          >
            Araç
          </th>

          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 200px !important"
          >
            Müşteri
          </th>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 200px !important"
          >
            Durum
          </th>

          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 200px !important"
          >
            Tamamlanma Tarihi
          </th>
        </tr>
      </thead>
      <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
        <tr v-for="(item, index) in filteredList" :key="index">
          <td class="table-td border-r border-opacity-20">
            {{ item.orderNo }}
          </td>
          <td class="table-td border-r border-opacity-20">
            <p>
              {{ item?.plate ? item?.plate : item?.vehicle }}
            </p>
          </td>
          <td class="table-td border-r border-opacity-20">
            {{ item?.planning?.customer ? item?.planning?.customer  : item?.customer }}
          </td>
          <td class="table-td border-r border-opacity-20">
            <!-- {{ getItemState(item) }} -->
            {{ item.stateName }}
          </td>
          <td class="table-td border-r border-opacity-20">
            <p>
              <!-- {{ formattedDate(getItemDate(item)) }} -->
              {{ item?.changeStateDate ? formattedDate(item?.changeStateDate ): item.endTime}}
            </p>
          </td>
        </tr>
        <tableLoader :load="load" colspan="5" :length="filteredList.length" />
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { tracking } from "../../../../../networking/urlmanager";
import tableLoader from "../../../../../components/general/table-loader.vue";

export default {
  name: "complated-components",
  components: {
    tableLoader,
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      maxDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      searchTerm: "",
      List: [],
      load: false,
    };
  },
  methods: {
    async getAll() {
      try {
        this.load = true;
        const result = await axios.get(tracking.getComplatedList, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        });
        this.List = result.data.data;
        console.log("veri geldi:",this.List);
        this.$emit("completed-vehicles", result.data.data);
        this.load = false;
      } catch (error) {
        console.log("hata : ", error);
        this.$swal(
          "warning",
          "UYARI!",
          "işlem başarısız oldu lütfen daha sonra tekrar deneyiniz!"
        );
        this.load = false;
      }
    },
    getItemState(item) {
      if (!Array.isArray(item?.stateHistory)) return "";
      if (item.stateHistory.length == 0) return "";
      return item.stateHistory[item.stateHistory?.length - 1]?.state;
    },
    getItemDate(item) {
      if (!Array.isArray(item?.stateHistory)) return "";
      if (item.stateHistory.length == 0) return "";
      return item.stateHistory[item.stateHistory?.length - 1]?.date;
    },
    formattedDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("DD MMMM YYYY HH:mm");
    },
    clearDates() {
      this.startDate = "";
      this.endDate = "";
    },
  },
  created() {
    this.getAll();
  },
  computed: {
    getDetailList() {
      return this.List.slice().sort((a, b) => moment(a.orderDate, "YYYY-MM-DD HH:mm").toDate() - moment(b.orderDate, "YYYY-MM-DD HH:mm").toDate());
    },
    filteredList() {
      const searchTermLower = this.searchTerm.toLowerCase();
      return this.getDetailList.filter(item => {
        // const itemDate = this.getItemDate(item);
        const itemDate = item?.changeStateDate ?  item?.changeStateDate  : item.endTime
        const vehicle = item.vehicle ? item.vehicle.toLowerCase() : '';
        const orderNo = item.orderNo ? String(item.orderNo) : '';
        const customer = item && item.customer ? item.customer.toLowerCase() : '';

        const matchesSearch = vehicle.includes(searchTermLower) || orderNo.includes(searchTermLower) || customer.includes(searchTermLower);
        const withinStartDate = !this.startDate || moment(itemDate).isSameOrAfter(this.startDate, 'day');
        const withinEndDate = !this.endDate || moment(itemDate).isSameOrBefore(this.endDate, 'day');

        return matchesSearch && withinStartDate && withinEndDate;
      });
    },
  },
};
</script>
