<template>
  <div class="px-2">
    <div>
      <cardItem
        title="Sevkiyat Geliri"
        :value="Money(detail.total) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        :loading="load"
        @click="showDetail = !showDetail"
      />
      <breakThree v-if="showDetail" :supplyId="detail.id" />
    </div>
    <cardItem
      title="Yakıt Gideri"
      :value="Money(detail.fuelTotal) + ' ₺'"
      icon="fas fa-money-bill fa-3x opacity-80"
    />
    <cardItem
      title="Bakım Onarım Gideri"
      :value="Money(detail.repairTotal) + ' ₺'"
      icon="fas fa-money-bill fa-3x opacity-80"
    />
    <cardItem
      title="Yıkama Gideri"
      :value="Money(detail.washTotal) + ' ₺'"
      icon="fas fa-money-bill fa-3x opacity-80"
    />
    <cardItem
      title="Genel Giderler"
      :value="Money(detail.generalExpenseTotal) + ' ₺'"
      icon="fas fa-money-bill fa-3x opacity-80"
    />
  </div>
</template>

<script>
import cardItem from "@/components/general/card.item.vue";
import breakThree from "./break.three.vue";
export default {
  name: "expense-subscontructor-step-two",
  props: ["detail"],
  components: {
    cardItem,
    breakThree,
  },
  data() {
    return {
      load: false,
      showDetail: false,
    };
  },
};
</script>

<style></style>
