<template>
  <div class="min-h-80 p-6 bg-gray-200 dark:bg-gray-900">
    <div class="h-full p-4 bg-gray-50 dark:bg-gray-800 rounded shadow-sm mt-4">
      <div class="w-full flex items-center dark:text-gray-400">
        <i class="fas fa-money-bill-wave mr-2 fa-lg"></i>
        <h5 class="font-bold text-xl">MALİYETLENDİRME</h5>
      </div>
      <div class="w-full pt-5">
        <div
          class="shadow  overflow-y-scroll  border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4"
          style="height:60vh !important"
        >
          <table class="min-w-full  divide-y divide-gray-200 relative">
            <thead
              class="bg-white dark:bg-gray-700 border-black border-dotted border-b-2 border-opacity-20"
            >
              <tr>
                <th scope="col" class="table-th text-left">#</th>
                <th scope="col" class="table-th text-left">
                  {{ $t("managementPrices.name") }}
                </th>
                <!-- <th scope="col" class="table-th text-left z-50">
                    Miktar
                </th> -->
                <th scope="col" class="table-th text-left z-50">
                  {{ $t("managementPrices.price") }}
                </th>
              </tr>
            </thead>
            <tbody
              class="divide-y-2 divide-gray-500"
              style="background-color: #3a3a3a"
            >
              <tr v-show="!load"  v-for="(item, index) in List" :key="item.id">
                <td class="table-td text-left ">
                  {{ index + 1 }}
                </td>
                <td class="table-td text-left">{{ item.name }}</td>
                <!-- <td class="table-td text-left">
                  <diffInput
                    type="price"
                    v-model="item.amount"
                    :hideContainer="true"
                    :required="true"
                    class="py-1"
                    :isOpacity="true"
                  />
                </td> -->
                <td class="table-td text-left">
                  <diffInput
                    type="price"
                    v-model="item.price"
                    :hideContainer="true"
                    :required="true"
                    class="py-1"
                    :isOpacity="true"
                  />
                </td>
              </tr>

              <tableLoader :load="load" :length="List.length" colspan="5" />
            </tbody>
          </table>
        </div>
        <div class="text-right mt-4">
          <asyncBtn
            icon="fas fa-check"
            :text="$t('general.saveButtonTitle')"
            :loadTitle="$t('general.saveButtonLoadTitle')"
            :loadState="load"
            @click="()=>save()"
            class="w-full md:w-56"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Global Components
import searchInput from "@/components/general/search-input.vue";
import tableLoader from "@/components/general/table-loader.vue";
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

// netoworking
import axios from "axios";
import { managementCosting } from "@/networking/urlmanager";
export default {
  name: "manamgenet-costing-page",
  components: {
    //Global
    searchInput,
    tableLoader,
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      List: [],
      load: false,
      search: "",
    };
  },
  methods: {
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(managementCosting.getAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    save() {
      this.load=true;
      axios
        .post(
          managementCosting.save,
          {
            list: JSON.stringify(this.List),
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );
          this.getAll();
        })
        .catch(err=>{
          this.load=false;
          console.log(err);
          this.errorBox(err.response);
        })
    },
  },
  created() {
    this.getAll();
  },
};
</script>

<style></style>
