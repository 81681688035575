<template>
  <modal
    :name="name ? name : 'add-hgs-detail-modal'"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">ÜCRETLİ OTOYOL KULLANIM DETAYI</h4>
      <button
        type="button"
        class="p-2 w-1/12"
        @click="$modal.hide(name ? name : 'add-hgs-detail-modal')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div
      class="shadow overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900"
      style="min-height: 200px !important; max-height: 400px !important"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead
          class="bg-white dark:bg-gray-800 border-black border-dotted border-b-2 border-opacity-20"
        >
          <tr>
            <th
              scope="col"
              class="table-th text-center"
              style="min-width: 200px !important"
            >
              {{ $t("operations.planning.transitionModal.transitionType") }}
            </th>
            <th class="table-th text-center" style="min-width: 150px !important">
              {{ $t("operations.planning.transitionModal.price") }}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-500 bg-white dark:bg-gray-800">
          <tr
            v-for="(item, index) in List"
            :key="index"
            class="text-black dark:text-white text-left"
          >
            <td class="table-td-not-color">
              {{
                item.type == "Barrier"
                  ? item.name + "( Barier )"
                  : item.start.name + "-" + item.end.name
              }}
            </td>
            <td
              class="table-td-not-color border-l divide-gray-500 text-black dark:text-white"
            >
              {{ Money(item.price) }} ₺
            </td>
          </tr>

          <tr
            v-for="(item, index) in bridges"
            :key="'bridge-' + index"
            class="text-black dark:text-white text-left"
          >
            <td class="table-td-not-color">
              {{ item.name }}
            </td>
            <td
              class="table-td-not-color border-l divide-gray-500 text-black dark:text-white"
            >
              {{ Money(item.price.replace(",", ".")) }} ₺
            </td>
          </tr>

          <tr class="text-white text-left bg-red-500 p-3 font-bold">
            <td class="table-td-not-color">ALT TOPLAM</td>
            <td class="table-td-not-color border-l divide-gray-500 text-white">
              {{ Money(getTotal) }} ₺
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </modal>
</template>

<script>
export default {
  name: "hgs-detail-modal",
  props: ["List", "bridges", "name"],
  computed: {
    getTotal() {
      let total = 0;
      this.List.forEach((el) => {
        total += parseFloat(el.price);
      });

      if (this.bridges) {
        this.bridges.forEach((el) => {
          total += parseFloat(el.price);
        });
      }

      return total;
    },
  },
};
</script>

<style></style>
