<template>
  <modal
    name="add-product-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("operations.products.modal.newProduct") }}
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('add-product-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          :title="$t('operations.products.modal.productName')"
          v-model="name"
          type="Text"
        />
      </div>
      <div class="w-full mt-4">
        <typeSelect v-model="typeId" :multiple="false" />
      </div>
      <div class="mt-5">
        <propertiesSelect :isEdit="false" v-model="properties" />
      </div>
      <div class="mt-5">
        <dorseTypeSelect :isEdit="false" v-model="dorseTypes" />
      </div>
      <div class="mt-5">
        <immovablesSelect :isEdit="false" v-model="immovables" />
      </div>

      <div class="w-full mt-4">
        <diffInput
          :title="$t('operations.products.modal.explanation')"
          v-model="explanation"
          type="textarea"
        />
      </div>
      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import specItem from "@/components/general/specsItem.vue";
// local Components
import typeSelect from "./selectors/types.select.vue";
import dorseTypeSelect from "./selectors/dorse.type.select.vue";
import propertiesSelect from "./selectors/properties.select.vue";
import immovablesSelect from "./selectors/immavables.select.vue";

import axios from "axios";
import { product } from "@/networking/urlmanager";
export default {
  name: "add-product-modal",
  components: {
    diffInput,
    asyncBtn,

    specItem,
    typeSelect,
    propertiesSelect,
    dorseTypeSelect,
    immovablesSelect,
  },
  data() {
    return {
      load: false,
      name: "",
      typeId: "",
      sdsId: "0",

      tables: [],
      properties: [],
      requiments: [],
      immovables: [],
      dorseTypes: [],

      explanation: "",
    };
  },
  methods: {
    show() {
      this.name = "";
      this.sdsId = "1";
      this.typeId = "";
      this.properties = [];
      this.requiments = [];
      this.immovables = [];
      this.dorseTypes = [];
      this.explanation = "";

      this.$modal.show("add-product-modal");
    },
    hide() {
      this.$modal.hide("add-product-modal");
    },
    save() {
      this.load = true;
      axios
        .post(
          product.add,
          {
            name: this.name,
            sdsId: this.sdsId.toString(),
            typeId: this.typeId.toString(),

            explanation: this.explanation,

            dorseTypes: this.dorseTypes,
            properties: this.properties,
            immovables: this.immovables,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.name = "";
            this.sdsId = "1";
            this.typeId = "";
            this.properties = [];
            this.requiments = [];
            this.immovables = [];
            this.dorseTypes = [];
            this.explanation = "";

            this.load = false;
            this.$emit("refresh", true);
            this.hide();
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
  },
  watch: {
    typeId(val) {
      if (val != 4) this.sdsId = "0";
    },
  },
};
</script>

<style></style>
