<template>
  <div>
    <div class="w-full mt-4">
      <table class="min-w-full divide-y divide-gray-200 rounded">
        <thead class="bg-white border-black border border-b border-opacity-10">
          <tr>
            <th scope="col" class="table-th text-left border-r">Gereksinim</th>
            <th scope="col" class="table-th text-center">#</th>
          </tr>
        </thead>
        <tbody class="divide-y-2 divide-gray-500 bg-gray-700">
          <tr v-for="(item, index) in List" :key="index">
            <td class="table-td text-left border-r">
              {{ item.name }}
            </td>
            <td class="table-td text-center">
              <button class="process-btn" type="button" @click="() => removeItem(item)">
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>

          <td
            v-if="List.length == 0"
            colspan="2"
            class="px-2 py-2 text-left dark:text-white bg-white dark:bg-gray-500"
          >
            Lislenecek herhangi bir gereksinim bulunamadı !
          </td>
        </tbody>
      </table>
    </div>
    <div class="w-full mt-4">
      <div class="flex items-center justify-center">
        <div class="xl:w-10/12 w-8/12">
          <diffInput title="Yeni Gereksinim" v-model="newSpecs" type="Text" />
        </div>
        <button
          type="button"
          @click="() => save()"
          class="xl:w-2/12 w-4/12 bg-red-700 text-white h-16 rounded ml-2"
        >
          <i class="fas fa-plus"></i> Ekle
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";

export default {
  name: "specs-items",
  props: ["default", "value"],
  components: {
    diffInput,
  },
  model: {
    event: "change",
    prop: "value",
  },
  data() {
    return {
      List: [],
      newSpecs: "",
    };
  },
  methods: {
    save() {
      if (this.newSpecs.length > 1) {
        if (this.List.length <= 6) {
          this.List.push({
            id: this.List.length + 1,
            name: this.newSpecs,
          });
          this.newSpecs = "";
          this.$emit("change", this.List);
        } else {
          this.swalBox(
            "warning",
            this.$t("general.warningTitle"),
            "En fazla 6 gereksinim eklenebilir",
            false,
            this.$t("general.OkayTitle")
          );
        }
      } else {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Boş gereksinim eklenemez !",
          false,
          this.$t("general.OkayTitle")
        );
      }
    },
    removeItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Özelliği silmek istediğinize emin misiniz ",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          const findIndex = this.List.findIndex(
            (r) => parseInt(r.id) == parseInt(item.id)
          );
          this.List.splice(findIndex, 1);
          this.$emit("change", this.List);
        }
      });
    },
  },
  created() {
    this.List = this.value ? this.value : [];
  },
  watch: {
    value(val) {
      this.List = val;
    },
    default(val) {
      this.List = val ? val : [];
    },
  },
};
</script>

<style></style>
