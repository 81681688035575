import Vue from 'vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueTour from 'vue-tour'

import 'vue-multiselect/dist/vue-multiselect.min.css';

import App from './App.vue'
import VModal from 'vue-js-modal'
Vue.use(VModal, { componentName: 'modal', dynamicDefault: { draggable: true, resizable: false } })


import '../public/vue-multiselect-light.min.css'

import VueI18n from 'vue-i18n'
import TURKISH_TRANSLATIONS from './localization/tr';

Vue.use(VueI18n)
import './assets/main.css'
import 'vue-tour/dist/vue-tour.css'

import moment from 'moment'
moment.locale("tr")

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import * as VueGoogleMaps from 'vue2-google-maps'

import GmapCluster from 'vue2-google-maps/dist/components/cluster'
Vue.component('GmapCluster', GmapCluster)

Vue.use(VueGoogleMaps, {
  load: {
    libraries: 'places,drawing,visualization',
    key: 'AIzaSyBsYLB5kYbjmSX1NZo0aPcWUt7iMEyY5cQ',
  },
})

Vue.use(VueSweetalert2);
Vue.use(VueTour)

Vue.mixin({
  computed: {
    getProfileImage() {
      return 'https://cdn-icons-png.flaticon.com/512/149/149071.png';
    },
    isMobile() {
      return window.innerWidth < 1200;
    },
    getNowDate() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    dateFormatter(date, time, isOnly = true) {
      if (isOnly) return moment(date).format('DD-MM-YYYY')
      else return moment(date + "T" + time).format('DD-MM-YYYY HH:mm')
    },
    Money(value) {
      let val =0;
      if(value!=0 && value!=undefined){

        if(value.toString().includes('.')){
          val = Number(value.toString().replace(',', '.')).toFixed(2);
        } 
        else{
          val=Number(value).toFixed(2);
        } 
      }
      const returnVal = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return returnVal;
    },
    async swalBox(icon, title, text, isConfirm = false, confirmText="Tamam", rejectText="İptal",isInput=false) {
      if (isConfirm) {
        if(isInput){
        return this.$swal.fire({
          icon: icon,
          title: title,
          text: text,
          input:'text',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: confirmText,
          cancelButtonText: rejectText,
        });

      }else{
        return this.$swal.fire({
          icon: icon,
          title: title,
          text: text,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: confirmText,
          cancelButtonText: rejectText,
        });
      }

      } else {
        return this.$swal.fire({
          icon: icon,
          title: title,
          text: text,
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: confirmText,
        });
      }
    },
    errorBox(result, title = "HATA !") {
      if (result.status == 403 || result.status == 401) {
        this.$swal.fire({
          icon: 'error',
          title: title,
          text: "Oturum süreniz doldu giriş sayfasına yönlendiriliyorsunuz !",
        }).then(acceptState => {
          if (acceptState.isConfirmed) {
            this.$store.commit("signOut");
            this.$router.push("/");
          }
        });

      } else {
        this.$swal.fire({
          icon: 'error',
          title: title,
          text: result.data.message
        });
      }

    },
    signOut() {
      this.swalBox('warning', "UYARI !", "Çıkış yapmak istediğinize emin misiniz", true, "Evet,eminim", "Hayır Değilim")
        .then(acc => {
          if (acc.isConfirmed) {

            this.$store.commit("activeLightMode");
            document.documentElement.classList.remove("dark");
            this.$store.commit('signOut');
            this.$router.push('/')
          }
        })

    }
  },
})

Vue.config.productionTip = false
const TRANSLATIONS = {
  tr: TURKISH_TRANSLATIONS
}

const i18n = new VueI18n({
  locale: 'tr',
  messages: TRANSLATIONS,
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
