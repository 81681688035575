<template>
  <modal
    name="vehicleState-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    style="z-index: 99999999999 !important"
    :scrollable="true"
    @opened="show"
  >
    <div class="items-center">
      <div
        class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
      >
        <h4 class="inline-block w-11/12 font-bold">Durum Geçmişi</h4>
        <button class="p-2 w-1/12" @click="$modal.hide('vehicleState-modal')">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="w-full">
        <div
          class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead
              class="bg-white border-black border-dotted border-b-2 border-opacity-20"
            >
              <tr>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 100px !important"
                >
                  #
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 150px !important"
                >
                  Durum
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 200px !important"
                >
                  Kilometre/Tonaj
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 200px !important"
                >
                  Tarih
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 100px !important"
                >
                  İrsaliye
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 100px !important"
                >
                  Kantar Fişi
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20"
                  style="min-width: 100px !important"
                >
                  Diğer
                </th>
              </tr>
            </thead>
            <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
              <tr v-for="(item, index) in List" :key="index">
                <td class="table-td border-r border-opacity-20">
                  <span>
                    {{ index + 1 }}
                  </span>
                </td>
                <td class="table-td border-r border-opacity-20">
                  <p>
                    {{ item.name }}
                  </p>
                </td>
                <td class="table-td border-r border-opacity-20">
                  <p v-if="item.kilometer && item.tonnage">
                    {{ item.kilometer + "/" + item.tonnage }}
                  </p>
                </td>
                <td class="table-td border-r border-opacity-20">
                  <p>
                    {{ formattedDate(item.addDate) }}
                  </p>
                </td>
                <td class="table-td border-r border-opacity-20">
                  <linkBtn :filekey="item.fileUrl" />
                </td>
                <td class="table-td border-r border-opacity-20">
                  <linkBtn :filekey="item.fileTwoUrl" />
                </td>
                <td class="table-td border-r border-opacity-20">
                  <linkBtn :filekey="item.fileThreeUrl" />
                </td>
              </tr>
              <tableLoader :load="load" :length="List.length" colspan="7" />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
// networking
import axios from "axios";
import { planning } from "@/networking/urlmanager";
import moment from "moment";

// local component
import linkBtn from "@/components/devItem/link-btn.vue";

//global components
import tableLoader from "@/components/general/table-loader.vue";
import diffInput from "@/components/general/diffInput.vue";
export default {
  components: {
    diffInput,
    tableLoader,
    moment,
    linkBtn,
  },
  name: "detail-table",
  props: ["Detail", "type", "orderNo"],
  data() {
    return {
      load: false,
      fileLoad: false,
      List: [],
      status: "",
      addDate: "",
    };
  },
  methods: {
    formattedDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("DD MMMM YYYY HH:mm");
    },
    show() {
      this.load = true;
      this.List = [];
      setTimeout(() => {
        this.getVehiceStatus();
      }, 50);
    },
    getVehiceStatus() {
      this.List = [];
      this.load = true;
      axios
        .get(
          planning.getVehiceStatus +
            "?planId=" +
            this.Detail.id +
            "&typeId=" +
            this.type +
            "&orderNo=" +
            this.orderNo,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((response) => {
          this.List = response.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.swalBox(
            "warning",
            "Uyarı !",
            err.response.data.message,
            false,
            this.$t("general.OkayTitle")
          );
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
};
</script>

<style>
.modal-content {
  z-index: 9999999999999 !important;
}
</style>
