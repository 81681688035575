<template>
  <modal
    name="detail-vehicle-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        <b>{{ detail.plaque }}</b> PLAKALI ARAÇ DETAYI
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('detail-vehicle-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Modals -->
    <operationDetailModal :plaque="detail.plaque" :vehicleId="detail.id" />
    <repairModal :plaque="detail.plaque" :vehicleId="detail.id" :typeId="detail.typeId" />
    <washModal :plaque="detail.plaque" :vehicleId="detail.id" />
    <fuelModal :plaque="detail.plaque" :vehicleId="detail.id" />
    <transitionModal />

    <!-- Modals -->

    <div
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="md:flex">
        <div class="md:w-6/12">
          <cardItem
            title="SEVKİYAT GELİRİ"
            :value="Money(detail.income.planningIncome) + ' ₺'"
            @click="$modal.show('detail-planning-vehicle-modal')"
          />
        </div>
        <div class="md:w-6/12 xl:pl-2">
          <cardItem title="KİRA GELİRİ" value="0.00 ₺" />
        </div>
      </div>

      <div class="w-full" v-if="detail.haveType == 3">
        <cardItem
          title="TAŞERON GİDERİ"
          :value="Money(detail.expense.subscontructor) + ' ₺'"
        />
      </div>

      <div class="md:flex">
        <div class="md:w-6/12">
          <cardItem
            title="YAKIT GİDERİ"
            :value="Money(detail.expense.fuelExpense.totalprice) + ' ₺'"
            @click="$modal.show('detail-fuel-vehicle-modal')"
          />
        </div>
        <div class="md:w-6/12 xl:pl-2">
          <cardItem
            title="BAKIM ONARIM GİDERİ"
            @click="$modal.show('detail-repair-vehicle-modal')"
            :value="Money(detail.expense.repairExpense) + ' ₺'"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="md:w-6/12">
          <cardItem
            title="YIKAMA GİDERİ"
            @click="$modal.show('detail-wash-vehicle-modal')"
            :value="Money(detail.expense.washExpense) + ' ₺'"
          />
        </div>
        <div class="md:w-6/12 xl:pl-2">
          <cardItem
            title="HGS VE ÜCRETLİ GEÇİŞLER"
            :value="Money(detail.expense.hgsExpense + detail.expense.transition) + ' ₺'"
          />
          <!-- @click="$modal.show('detail-transition-vehicle-modal')" -->
        </div>
      </div>

      <div class="md:flex">
        <div class="md:w-6/12">
          <cardItem
            title="PERSONEL GİDERİ"
            :value="Money(detail.expense.personnelExpense) + ' ₺'"
          />
        </div>
        <div class="md:w-6/12 xl:pl-2">
          <cardItem
            title="KİRA GİDERİ"
            :value="Money(detail.expense.rentExpense) + ' ₺'"
            @click="rentDetail = !rentDetail"
            :icon="
              rentDetail
                ? 'fas fa-arrow-down fa-3x opacity-30'
                : 'fas fa-money-bill fa-3x opacity-30'
            "
          />
        </div>
      </div>

      <div class="md:flex" v-if="rentDetail == true">
        <div class="md:w-4/12">
          <cardItem
            title="ARAÇ KİRA GİDERİ"
            :value="Money(detail.expense.rentDetail.vehicle) + ' ₺'"
          />
        </div>

        <div class="md:w-4/12 md:mx-2">
          <cardItem
            title="DORSE KİRA GİDERİ"
            :value="Money(detail.expense.rentDetail.dorse) + ' ₺'"
          />
        </div>
        <div class="md:w-4/12">
          <cardItem
            title="TANK KİRA GİDERİ"
            :value="Money(detail.expense.rentDetail.tank) + ' ₺'"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
// global Components
import cardItem from "@/components/general/card.item.vue";

// local
import operationDetailModal from "./operation-detail.vue";
import repairModal from "./repair-detail.vue";
import washModal from "./wash-detail.vue";
import fuelModal from "./fuel-detail.vue";
import transitionModal from "./transition-detail.vue";

export default {
  name: "vehicle-detail-modal",
  props: ["detail"],
  components: {
    cardItem,
    operationDetailModal,
    repairModal,
    washModal,
    fuelModal,
    transitionModal,
  },
  data() {
    return {
      rentDetail: false,
    };
  },
};
</script>

<style></style>
