<template>
  <div class="w-full mt-4">
    <label class="block my-2 font-bold text-sm">Son Taşınan 3 Yük</label>

    <div class="h-full overflow-y-auto border-t pt-2 border-black mt-2 border-opacity-40">
      <div v-for="item in List" :key="item.id"
        class="border border-black border-opacity-10 rounded p-4 bg-gray-100 mt-4 mr-4">
        <label class="text-sm block">#{{ item.orderNo }}</label>
        <label class="text-xs my-2 block">TAŞINAN ÜRÜN :<br />
          <b class="mt-1 block"> {{ item.productType }} </b></label>

        <label class="text-xs my-2 block">
          MÜŞTERİ :
          <b class="block mt-1">
            {{ item.customer }}
          </b>
        </label>
        <label class="text-xs my-2 block">
          DOLUM NOKTASI :
          <b class="block mt-1">
            {{ item.receivedName}}
          </b>
          <b class=" opacity-60">{{ item.fillingDate | dateFormat }}</b>

        </label>
        <label class="text-xs my-2 block">
          TESLİM NOKTASI :
          <b class="block mt-1">
            {{ item.deliveredName}}
          </b>
          <b class="opacity-60"> {{ item.deliveryDate | dateFormat }}</b>
        </label>

      </div>
      <label v-if="load" class="block text-center">
        <i class="fas fa-spinner  fa-spin"></i>
        <span class="block"> Yükleniyor</span>
      </label>
      <label v-if="!load && List.length == 0" class="block text-center">
        <span class="block text-xs">Bu Araç Henüz Herhangi bir sevkiyat gerçekleştirmedi</span>
      </label>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import moment from "moment";

import { tracking } from '../../../../networking/urlmanager';
export default {
  name: "oldThreeProduct",
  props: ["vehicleId"],
  data() {
    return {
      List: [],
      load: false,
    };
  },
  created() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.load = true;
      axios
        .get(tracking.getLastTransition + "?vehicleId=" + this.vehicleId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((response) => {
          const list = response.data.data;
          this.List = list;
          this.load = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    }
  },
  filters: {
    dateFormat(val) {
      return moment(val).format("LLLL");
    },
  },
};
</script>
