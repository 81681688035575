<template>
  <div
    v-if="pageCount > 0"
    class="pt-5 xl:flex items-center justify-between border-white-custom md:px-6 xl:px-3 py-3 w-full text-black dark:text-gray-200 dark:bg-gray-800 text-sm xl:text-base"
  >
    {{ totalRowCount }} Adet kayıt listelendi 
    <paginate
      v-if="pageCount > 1"
      :page-count="pageCount"
      :value="currentPage"
      :page-range="isMobile ? 2 : 6"
      :click-handler="(r) => $emit('changePage', r)"
      active-class="w-full bg-red-500 rounded "
      :prev-text="'Önceki'"
      :next-text="'Sonraki'"
      container-class="flex dark:bg-gray-800 text-sm xl:text-base mt-4 xl:mt-0"
      page-class="mx-2  px-3"
    >
    </paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
export default {
  props: ["pageCount", "currentPage", "totalRowCount"],
  name: "pagination",
  model: {
    prop: "currentPage",
    event: "changePage",
  },
  components: {
    Paginate,
  },
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    changepage(val) {},
  },
  watch: {
    current(val) {
      if (val != this.currentPage) {
        this.$emit("changePage", val);
      }
    },
    currentPage(val) {
      this.current = val;
    },
  },
};
</script>

<style></style>
