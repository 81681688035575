<template>
  <diffInput
    :loading="load"
    type="select"
    :title="title ? title : $t('devItems.supplyTitle')"
    :optList="List"
    v-model="selected"
    :lightDark="lightDark"
    :required="required"
    :disabled="disabled"
  />
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import axios from "axios";
import { supply } from "@/networking/urlmanager";
export default {
  name: "supply-input",
  props: ["typeId", "value", "title", "lightDark", "required", "disabled"],
  components: {
    diffInput,
  },
  data() {
    return {
      load: false,
      List: [],
      selected: "",
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    getAll() {
      this.load = false;
      axios
        .get(
          this.typeId
            ? supply.getTypeAll + "?rowId=" + this.typeId.toString()
            : supply.getAllNotPagination,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((result) => {
          this.List = result.data.data;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.selected = this.value;
    this.getAll();
  },
  watch: {
    typeId(val) {
      if (val) this.getAll();
    },
    selected(val) {
      this.$emit("change", val);
      this.$emit("changeName", this.List.find((r) => r.id == val).name);
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>

<style></style>
