<template>
  <div>
    <label class="block my-2 font-bold text-sm border-b pb-2 border-black"
      >Aktif Yük</label
    >

    <div v-if="!load">
      <StateHistory
        :type="item.isBulkOperation"
        :orderNo="item.orderNo"
        ref="stateModal"
        :Detail="detail"
      />
      <h4 class="w-full block mb-2 pb-2 border-b text-sm">
        Yük Türü
        <b class="block">
          {{ item.isBulkOperation == 1 ? "Toplu Sevkiyat" : "Spot Sevkiyat" }}</b
        >
      </h4>
      <h4 class="w-full block mb-2 pb-2 border-b text-sm">
        Sipariş Numarası
        <b class="block"> {{ item.orderNo }}</b>
      </h4>
      <h4 class="w-full block mb-2 pb-2 border-b text-sm">
        Müşteri
        <b class="block"> {{ detail.customer }}</b>
      </h4>
      <h4 class="w-full block mb-2 pb-2 border-b text-sm">
        Ürün
        <b class="block"> {{ detail.product }}</b>
      </h4>
      <!-- <h4 class="w-full block mb-2 pb-2 border-b text-sm">
        Sürücü
        <b class="block"> {{ detail.driverName }}</b>
      </h4> -->
      <button
        @click="showStatusHistory()"
        class="w-full block mb-2 pb-2 border-b text-sm text-left hover:bg-opacity-10 hover:bg-black hover:rounded transition-all duration-200"
      >
        <label>Durum</label>
        <div class="flex justify-between items-center">
          <b class="block text-red-500"> {{ detail.stateName }}</b>
          <i class="fas fa-search text-xl text-black text-opacity-50"></i>
        </div>
      </button>
      <!-- <h4 class="w-full block mb-2 pb-2 border-b text-sm">
        Dorse
        <b class="block"> {{ detail.dorsePlaque }}</b>
      </h4> -->
      <!-- <h4 class="w-full block mb-2 pb-2 border-b text-sm">
        Yetişme Durumu
        <b
          class="block uppercase animate-pulse"
          :class="diffStatus ? 'text-green-600' : 'text-red-600'"
        >
          {{
            (detail.state < 3 ? "Doluma " : "Teslime ") +
            (diffStatus ? "Yetişecek" : "Yetişemez")
          }}</b
        >
      </h4> -->
      <!-- <h4 class="w-full block mb-2 pb-2 border-b text-sm">
        {{
          detail.state <= 3
            ? "Hedeflenen Doluma Giriş Zamanı"
            : "Hedeflenen Teslim Tarihi"
        }}
        <b class="block"> {{ detail.targetDate | dateFormat }}</b>
      </h4> -->
      <h4 class="w-full block mb-2 pb-2 border-b text-sm">
        Tahmini Teslim zamanı
        <b class="block"> {{ calculatePosition.totalMinute | dateFormat }}</b>
      </h4>

      <!-- <h4 class="w-full block mb-2 pb-2 border-b">
        {{ $t("vehicleTracking.address") }}
        <label class="text-xs">{{ detail.adress }}</label>
      </h4> -->
    </div>

    <label v-else class="text-center block">
      <i class="fas fa-spinner fa-spin"></i>
      <span class="text-center mt-1 block"> Yükleniyor </span>
    </label>
  </div>
</template>

<script>
import axios from "axios";
import { routePlanner } from "@/networking/urlmanager";
import polyLine from "google-polyline";
import moment from "moment";
import StateHistory from "../../../operations/planning/plan/components/vehicleStateModel/index.vue";

export default {
  name: "current-detail",
  props: ["item"],
  components: {
    StateHistory,
  },
  data() {
    return {
      showDetail: false,
      detail: {
        customer: "",
        product: "",
        driverName: "",
        stateName: "",
        dorsePlaque: "",
        state: "",
      },
      calculatePosition: {},
      diffStatus: false,
      load: false,
    };
  },
  created() {
    this.detail = this.item.planning;
    this.getRoute();
  },
  methods: {
    getRoute() {
      this.load = true;
      axios
        .post(
          routePlanner.calculate,
          {
            from: this.item.position,

            to:
              this.detail.state < 5
                ? {
                    lat: parseFloat(this.item.outpointLat),
                    lng: parseFloat(this.item.outpointLng),
                  }
                : {
                    lat: parseFloat(this.item.targettLat),
                    lng: parseFloat(this.item.targettLng),
                  },
            waypoints: JSON.stringify([]),
            isToll: true,
            isShip: true,
            isAsphalt: true,
            isExplosive: false,
            routeType: "fast",
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.load = true;
          const [detail] = res.data.data;
          this.calculatePosition = detail;
          this.calculatePosition.totalMinute = moment().add(
            detail.totalMinute,
            "minutes"
          );

          var duration = moment.duration(
            moment(this.detail.targetDate).diff(this.calculatePosition.totalMinute)
          );
          this.diffStatus = duration.asMinutes() > 0;

          this.$emit("selectedActiveDetail", {
            state: this.detail.state,
            targetPoint:
              this.detail.state < 5
                ? {
                    lat: parseFloat(this.item.outpointLat),
                    lng: parseFloat(this.item.outpointLng),
                  }
                : {
                    lat: parseFloat(this.item.targettLat),
                    lng: parseFloat(this.item.targettLng),
                  },

            outpoint: {
              lat: parseFloat(this.item.outpointLat),
              lng: parseFloat(this.item.outpointLng),
            },
            polyline: detail.polyLine,
            customer: this.detail.customer,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    showStatusHistory() {
      this.$modal.show("vehicleState-modal");
    },
  },
  filters: {
    dateFormat(val) {
      return moment(val).format("LLL");
    },
  },
};
</script>
