<template>
  <div
    class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
  >
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
        <tr>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 40px !important"
          >
            #
          </th>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 200px !important"
          >
            Araç
          </th>
          <!-- <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 200px !important"
          >
            Gemi Yükleme 
          </th>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 200px !important"
          >
            Sevkiyat Siparişi 
          </th> -->
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 250px !important"
          >
            Konum
          </th>
          <th
            scope="col"
            class="table-th text-left border-r border-opacity-20"
            style="min-width: 100px !important"
          ></th>
        </tr>
      </thead>
      <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
        <!-- <NewVehicle /> -->
        <newVehicle
          v-if="changeState == false"
          @addItem="(r) => addItem(r)"
          :order="order"
        />
        <tr v-show="!changeState" v-for="(item, index) in List" :key="index">
          <td class="table-td border-r border-opacity-20">
            <span>#{{ index + 1 }} </span>
          </td>
          <td class="table-td border-r border-opacity-20 flex justify-between">
            {{ item.vehicle }}
            <span
              class="px-2 bg-red-600 rounded-xl text-white text-xs inline-block font-extralight"
              v-if="item.isCustom == 1"
            >
              Manuel Atama
            </span>
          </td>
          <td class="table-td border-r border-opacity-20">
            <p>{{ item.address + " / " + Money(item.distance) + " KM" }}</p>
          </td>
          <td class="flex justify-center items-center">
            <button type="button" class="process-btn" @click="deleteItem(item, index)">
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
        <td colspan="4" class="text-center p-4 text-white" v-if="changeState == true">
          <i class="fas fa-recycle fa-3x"></i>
          <h3 class="text-2xl mt-2">ARAÇ PLANLA</h3>
          <p class="text-sm my-2 text-white text-opacity-75">
            bu sipariş için araç planlamak için planla butonuna basınız
          </p>
          <button
            class="bg-red-500 px-4 py-1 rounded"
            type="button"
            @click="calculateVehicle()"
          >
            Yeniden Öner
          </button>
        </td>
        <tableLoader :load="load" colspan="5" />
      </tbody>
    </table>
  </div>
</template>
<script>
import tableLoader from "@/components/general/table-loader.vue";
import newVehicle from "./new.vehicle.vue";
import {
  getCustomers,
  getLocationAll,
  getProducts,
} from "../../../orders/utils/detailData";
import {
  recommendMultipleVehiclesForOrder,
  calculateDistancePriority,
  recommendSingleVehicleForOrder,
} from "../../../orders/utils/recomendation";

export default {
  name: "vehicle-table",
  props: [
    "center",
    "totalCount",
    "order",
    "fillingDate",
    "deliveryDate",
    "reserveCount",
    "shipFillingTime",
    "costDistribution",
  ],
  components: {
    tableLoader,
    newVehicle,
  },
  data() {
    return {
      load: false,
      changeState: true,
      List: [],

      vehicleList: [],
      customers: [],
      products: [],

      new: {
        vehicle: "",
        dorse: "",
        driverName: "",
        driverTC: "",
      },
      isDeleted: false,
    };
  },

  methods: {
    addItem(item) {
      if (item) {
        let index = this.List.some((r) => parseInt(r.id) == parseInt(item.id));

        if (!index) {
          this.isDeleted = true;
          const vehicleIndex = this.vehicleList.findIndex((vitem) => vitem.id == item.id);

          this.vehicleList[vehicleIndex].isAvailable = false;

          this.List.push({
            id: item.id,
            vehicle: item.name,
            address: item.address,
            dorse: item.dorse,
            driverName: item.driverName,
            driverTC: item.driverTC,
            distance: calculateDistancePriority(
              { startLat: this.order.outpointLat, startLng: this.order.outpointLng },
              { latitude: item.latitude, longitude: item.longitude }
            ),
            isCustom: 1,
          });
          this.$emit("addVehicle", true);

          setTimeout(() => {
            this.isDeleted = false;
          }, 100);
        } else {
          this.swalBox(
            "warning",
            this.$t("general.warningTitle"),
            "Bu Araç Zaten Litede Var",
            false,
            this.$t("general.OkayTitle")
          );
        }
      }
    },
    deleteItem(detail, index) {
      if (confirm("ARACI LİSTEDEN ÇIKARMAK İSTEDİĞİNİZE EMİN MİSİNİZ?")) {
        this.isDeleted = true;
        const vehicleIndex = this.vehicleList.findIndex((item) => item.id == detail.id);

        if (vehicleIndex > -1) {
          this.vehicleList[vehicleIndex].isAvailable = true;
        }

        this.List.splice(index, 1);
        this.$emit("removeVehicle", true);

        setTimeout(() => {
          this.isDeleted = false;
        }, 50);
      }
    },
    async vehicleRecomended(item) {
      const order = {
        startLat: item.outpointLat,
        startLng: item.outpointLng,
        endLat: item.targetPointLat,
        endLng: item.targetPointLng,
        deliveryDate: item.deliveryDate,
        tonnage: parseFloat(item.tonnage),
        productName: item.productType,
        token: this.$store.state.userData.token,
      };

      const recommendedVehicles = recommendMultipleVehiclesForOrder(
        order,
        this.vehicleList,
        this.customers,
        this.products,
        this.totalCount - this.List.length
      );

      return recommendedVehicles;
    },
    async processOrderItem() {
      this.List = this.List.filter((item) => item.isCustom == 1);
      if (this.totalCount < this.List.length) {
        this.List = this.List.splice(
          this.List.length - 1,
          this.List.length - this.totalCount
        );
      }

      this.vehicleList.map((item) => {
        item.isAvailable = true;
      });

      const item = this.order;
      if (!item && !item.isCustom) return;

      const recommendedVehicles = await this.vehicleRecomended(item);

      // Şu anda sadece ilk önerilen aracı işliyoruz, birden fazla araç işlemesi gerekiyorsa bu bölümü genişletmeliyiz.
      this.List.push(...recommendedVehicles);
    },
    resetItem() {
      this.vehicleList = [];
    },
    async calculateVehicle() {
      if (this.isDeleted == true) return;

      await this.processOrderItem();
      this.changeState = false;
    },
    calculateRecomendedCount() {
      if (!this.costDistribution && this.isDeleted == true) return;

      const item = this.order;
      const order = {
        startLat: item.outpointLat,
        startLng: item.outpointLng,
        endLat: item.targetPointLat,
        endLng: item.targetPointLng,
        deliveryDate: item.deliveryDate,
        tonnage: 28,
        productName: item.productType,
        token: this.$store.state.userData.token,
      };

      const recommendedVehicles = recommendSingleVehicleForOrder(
        order,
        this.vehicleList,
        this.customers,
        this.products
      );

      console.log("önerilen araç :", recommendedVehicles);

      const shippingDate = (recommendedVehicles.distance * 1000) / 40;

      console.log("dolum tesisine süre :", shippingDate);

      const totalTransitionTime =
        parseInt(shippingDate) +
        parseInt(this.shipFillingTime) +
        parseInt(this.fillingDate) +
        parseInt(this.deliveryDate) +
        parseInt(this.costDistribution.totalMinute);

      console.log("operasyon süresi : ", totalTransitionTime);

      console.log(
        "önerilen araç sayısı ",
        totalTransitionTime / this.shipFillingTime + this.reserveCount
      );
    },
  },
  async created() {
    this.load = true;
    if (this.vehicleList.length == 0) {
      let vehicleList = await getLocationAll(this.$store.state.userData.token);
      this.vehicleList = vehicleList;
    }

    if (this.customers.length == 0)
      this.customers = await getCustomers(this.$store.state.userData.token);
    if (this.products.length == 0)
      this.products = await getProducts(this.$store.state.userData.token);
    this.load = false;

    // this.calculateRecomendedCount();

    // if (this.totalCount > 0) {
    //   this.changeState = true;
    // }
  },
  watch: {
    async changeState(val) {
      if (this.isDeleted) {
        this.changeState = false;
      } else if (this.totalCount > 0 && val == false) await this.calculateVehicle();
    },
    center() {
      if (!this.load) {
        this.changeState = true;
      }
    },
    totalCount(val) {
      if (!this.load && val > 0 && !this.isDeleted) {
        this.changeState = true;
      } else if (!this.isDeleted) {
        this.List = [];
      }
    },
    List(val) {
      return this.$emit("change", val);
    },
    // order(){
    //   this.calculateRecomendedCount();
    // },
    // costDistribution(){
    //   this.calculateRecomendedCount();
    // }
  },
  filters: {
    calculateTime(val) {
      this.calculateRecomended();
      // return (totalTransitionTime/shipFillingTime)+this.reserveCount;
    },
  },
};
</script>
