<template>
  <div class="px-2">
    <cardItem
      v-for="item in List"
      :key="item.id"
      :title="item.name"
      :value="Money(item.totalPrice) + ' ₺'"
      icon="fas fa-money-bill fa-3x opacity-80"
    />
  </div>
</template>

<script>
import cardItem from "@/components/general/card.item.vue";
import axios from "axios";
import { rapor } from "../../../../../networking/urlmanager";
export default {
  name: "expense-fuel-step-two",
  components: {
    cardItem,
  },
  data() {
    return {
      showBreak: false,
      List: [],
      load: false,
    };
  },
  methods: {
    getDetail() {
      this.load = true;
      axios
        .get(
          rapor.expense.fuel.getSupplyDistribution +
            "?startDate=" +
            this.$store.state.raporPeriot.startDate +
            "&endDate=" +
            this.$store.state.raporPeriot.endDate,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getDetail();
  },
  watch: {
    "$store.state.raporPeriot.startDate"() {
      this.getDetail();
    },
    "$store.state.raporPeriot.endDate"() {
      this.getDetail();
    },
    load(val) {
      this.$emit("loading", val);
    },
  },
};
</script>

<style></style>
