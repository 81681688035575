var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"fixed xl:top-16 top-0 h-screen xl:h-full w-full bg-white p-5 shadow xl:w-72",staticStyle:{"z-index":"22 !important"}},[(_vm.detail)?_c('div',{staticClass:"w-full border-b py-2 relative flex justify-between"},[_c('button',{staticClass:"text-black text-3xl"},[_vm._v("Geri")]),_c('span',[_c('span',{staticClass:"font-bold block mt-2"},[_vm._v(_vm._s(_vm.detail.title))]),_vm._v(" "+_vm._s(_vm.$t("vehicleTracking.licensePlateDetail")))]),_c('button',{staticClass:"text-black rounded-full w-7 h-7 flex items-center justify-center",on:{"click":function($event){return _vm.hideModal()}}},[_c('i',{staticClass:"fas fa-times"})])]):_vm._e(),(_vm.detail)?_c('div',{staticClass:"w-full py-2 overflow-y-scroll",staticStyle:{"height":"80vh !important"}},[_c('div',{staticClass:"w-full flex items-center justify-between mb-5"},[_c('button',{staticClass:"text-xs shadow-sm rounded-l p-2",class:{
          'bg-red-500 text-white': _vm.activeMenu == 1,
          'w-4/12 bg-gray-200': Number(_vm.detail.state) == 1,
          'w-6/12 bg-gray-200': Number(_vm.detail.state) == 0,
        },on:{"click":function($event){_vm.activeMenu = 1}}},[_vm._v(" GENEL ")]),(Number(_vm.detail.state) == 1)?_c('button',{staticClass:"text-xs shadow-sm w-4/12 p-2",class:_vm.activeMenu == 2
            ? 'bg-red-500 text-white'
            : Number(_vm.detail.state) == 0
            ? 'bg-gray-50 bg-opacity-60 text-opacity-40 text-black'
            : 'bg-gray-200',on:{"click":function($event){_vm.activeMenu = 2}}},[_vm._v(" MEVCUT ")]):_vm._e(),_c('button',{staticClass:"text-xs shadow-sm rounded-r w-4/12 p-2",class:{
          'bg-red-500 text-white': _vm.activeMenu == 3,
          'w-4/12 bg-gray-200': Number(_vm.detail.state) == 1,
          'w-6/12 bg-gray-200': Number(_vm.detail.state) == 0,
        },on:{"click":function($event){_vm.activeMenu = 3}}},[_vm._v(" GEÇMİŞ ")])]),(_vm.activeMenu == 1 && _vm.detail)?_c('div',[_c('detailItem',{attrs:{"title":"Görev Durumu","value":_vm.detail.state != 0 ? 'Evet ' : 'Hayır'}}),_c('detailItem',{attrs:{"title":"Sürücü","value":_vm.detail.driverName ? _vm.detail.driverName : 'Belirlenmedi'}}),_c('detailItem',{attrs:{"title":"Sürüş Süresi","value":_vm.detail.takoDate}}),_c('detailItem',{attrs:{"title":"Dorse","value":_vm.detail.dorse ? _vm.detail.dorse : 'Belirlenmedi'}}),_c('detailItem',{attrs:{"title":"Taşıma Kapasitesi","value":_vm.detail.capacity ? _vm.detail.capacity + ' Ton' : 'Dorse Yok'}}),_c('detailItem',{attrs:{"title":"Yakıt Seviyesi","value":_vm.detail.canbusFuelLevel > 0 ? _vm.detail.canbusFuelLevel + ' %' : '0%'}}),_c('detailItem',{attrs:{"title":"Araç Hızı","value":_vm.detail.speed + 'km / h'}}),_c('detailItem',{attrs:{"title":"Bölge","value":_vm.detail.region}})],1):(_vm.activeMenu == 2)?_c('currentDetail',{attrs:{"item":_vm.detail},on:{"selectedActiveDetail":(val) => _vm.changeDetail(val)}}):_c('otherProducts',{attrs:{"vehicleId":_vm.detail.vehicle}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }