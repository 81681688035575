<template>
  <div
    class="fixed xl:top-16 top-0 h-screen xl:h-full w-full bg-white p-5 shadow xl:w-72"
    style="z-index: 21 !important"
  >
    <div class="w-full border-b py-2 relative flex justify-between">
      <div>GRUP DETAYI</div>

      <button @click="$emit('close', true)">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div class="w-full py-2 overflow-y-scroll" style="height: 80vh !important">
      <div
        class="w-full py-5 select-none cursor-pointer px-2 my-2 text-xs rounded bg-gray-100 shadow"
        v-for="(item, index) in List"
        :key="index"
      >
        <div @click="getDetail(item)">
          <i class="fas text-black text-opacity-50 mr-2"></i>
          {{ item.label.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vehicle-tracking",
  props: ["List"],
  data() {
    return {
      load: false,
      selected: null,
    };
  },

  methods: {
    getDetail(item) {
      this.$emit("selected", item.label.text);
    },
  },
};
</script>
