<template>
  <div class="min-h-80 px-6 bg-gray-200 dark:bg-gray-900">
    <addModal @refresh="filterState ? getFilter() : getAll()" />
    <editModal @refresh="filterState ? getFilter() : getAll()" :rowId="selected.id" />
    <!-- <locationDetail :vehicle="selected.id" /> -->
    <vehicleModalVue
      @refresh="filterState ? getFilter() : getAll()"
      :rowId="selected.id"
      :isDisabled="selected.tonnage <= selected.receivedTonnage"
    />
    <addReportsModal />

    <reportModalVue :rowId="selected.id" />
    <div class="w-full">
      <div class="h-full p-4 bg-gray-50 dark:bg-gray-800 rounded shadow-sm mt-4">
        <div class="w-full flex items-center dark:text-gray-400">
          <i class="fas fa-list-alt mr-1 fa-lg pr-1"></i>
          <h5 class="font-bold text-lg">Toplu Planlama</h5>
        </div>
        <div class="w-full">
          <div class="md:flex items-center w-full mt-4">
            <div class="w-full text-left">
              <div class="w-full md:flex items-center justify-between">
                <div class="w-3/12">
                  <button
                    @click="() => $modal.show('add-plan-modal')"
                    class="btn w-36 text-center"
                  >
                    <i class="fas fa-plus mr-2"></i
                    >{{ $t("operations.planning.newBtnTitle") }}
                  </button>
                </div>
                <div class="flex w-5/12">
                  <button
                    @click="$emit('changePage', true)"
                    class="w-40 py-2 rounded bg-white shadow mr-2 text-md 2xl:ml-8 border-b-4"
                    :class="activeState == 1 && 'border-b-4 border-red-700 text-red-700'"
                  >
                    Planlama
                  </button>
                  <button
                    class="w-40 py-2 rounded bg-white shadow text-md"
                    :class="activeState == 2 && 'border-b-4 border-red-700 text-red-700'"
                  >
                    Toplu Planlama
                  </button>
                </div>
                <div class="w-4/12 flex">
                  <searchInput
                    v-model="search"
                    @searcing="() => getAll()"
                    inputClass="w-full  "
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            :class="{ 'fixed top-0 w-full z-50 left-0 h-full bg-white': isFullScreen }"
          >
            <div
              :class="{
                'shadow table-height-value overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4': !isFullScreen,
                'w-full overflow-y-scroll h-full': isFullScreen,
              }"
            >
              <div class="relative">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead
                    class="bg-white border-black border-dotted border-b-2 border-opacity-20"
                  >
                    <tr>
                      <!-- <th scope="col" class="table-th text-center">
                  <input type="checkbox" name="" id="" />
                </th> -->
                      <th
                        scope="col"
                        class="table-th text-left border-r border-opacity-20"
                        style="min-width: 80px !important"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        class="table-th text-left border-r border-opacity-20"
                        style="min-width: 200px !important"
                      >
                        Dolum
                      </th>
                      <th
                        scope="col"
                        class="table-th text-left border-r border-opacity-20"
                        style="min-width: 200px !important"
                      >
                        Teslim
                      </th>
                      <!-- <th
                  
                    scope="col"
                    class="table-th text-left border-r border-opacity-20"
                    style="min-width: 200px !important"
                  >
                    Müşteri
                  </th> -->
                      <th
                        scope="col"
                        class="table-th text-left border-r border-opacity-20"
                        style="min-width: 100px !important"
                      >
                        Ürün
                        <!-- <i class="fas fa-arrow-down"></i> -->
                      </th>
                      <th
                        scope="col"
                        class="table-th text-left border-r border-opacity-20 z-30"
                        style="min-width: 150px !important"
                      >
                        <p class="text-center">Toplam / Kalan</p>
                      </th>
                      <th
                        scope="col"
                        class="table-th text-center border-r border-opacity-20 z-30"
                        style="min-width: 120px !important"
                      >
                        Araçlar
                      </th>

                      <th
                        scope="col"
                        class="table-th text-left z-20 border-r border-opacity-20"
                        style="min-width: 200px !important"
                      >
                        T. Teslim Süresi
                      </th>
                      <th
                        scope="col"
                        class="table-th text-left z-20 border-r border-opacity-20"
                        style="min-width: 80px !important"
                      >
                        Durum
                      </th>

                      <th
                        scope="col"
                        style="min-width: 100px !important"
                        class="table-th text-left"
                      >
                        {{ $t("general.process") }}
                        <button
                          @click="toggleFullScreen(true)"
                          v-if="!isFullScreen"
                          class="absolute right-4"
                        >
                          <img src="@/assets/table/open.svg" alt="" />
                        </button>
                        <button
                          @click="toggleFullScreen(false)"
                          v-if="isFullScreen"
                          class="absolute right-4"
                        >
                          <img class="h-5 w-5" src="@/assets/table/close.svg" alt="" />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="divide-y-2 divide-gray-500"
                    style="background-color: #3a3a3a"
                  >
                    <tr v-for="(item, index) in List" :key="index">
                      <!-- <td class="table-td text-center border-r border-opacity-20">
                  <input type="checkbox" name="" id="" />
                </td> -->
                      <td class="table-td border-r border-opacity-20 cursor-pointer">
                        <span
                          >#
                          {{ item.orderNo }}
                        </span>
                      </td>
                      <td class="table-td border-r border-opacity-20">
                        <p class="text-white" style="font-size: 10px">
                          {{ item.receivedName }}
                        </p>
                        <!-- 
                      <p class="text-white opacity-50">
                        {{ dateFormatter(item.fillingDate) }}
                      </p> -->

                        <p class="" style="font-size: 10px">
                          {{ getLastThreeWords(item.fillingAdress) }}
                        </p>
                      </td>
                      <td class="table-td border-r border-opacity-20">
                        <p class="text-white" style="font-size: 10px">
                          {{ item.deliveredName }}
                        </p>

                        <!-- <p class="text-white opacity-50">
                      {{ dateFormatter(item.complateDate) }}
                    </p> -->

                        <p class="" style="font-size: 10px">
                          {{ getLastThreeWords(item.deliveryAdress) }}
                        </p>
                      </td>
                      <!-- <td class="table-td border-r border-opacity-20">
                    {{ item.customer }}
                  </td> -->
                      <td class="table-td border-r border-opacity-20">
                        {{ item.productType }}
                      </td>
                      <td
                        class="table-td border-r border-opacity-20 cursor-pointer select-none"
                      >
                        {{ item.tonnage + " / " + (item.tonnage - item.receivedTonnage) }}
                        TON
                      </td>
                      <td
                        @click="showVehicleList(item)"
                        class="table-td w-28 border-r border-opacity-20 text-center font-bold cursor-pointer select-none hover:bg-opacity-30 hover:bg-red-500 duration-200 relative"
                        :class="item.rejectStatus > 0 && 'bg-red-600 animate-pulse'"
                      >
                        <div class="flex items-center justify-between px-2">
                          <p class="text-base font-bold">{{ item.vehicleCount }}</p>

                          <img
                            class="w-5 h-5 ml-3"
                            src="@/assets/planing/location.svg"
                            alt=""
                          />
                        </div>
                      </td>
                      <td class="table-td w-28 border-r border-opacity-20">
                        {{ item.estimatedComplateDate | getDateFormat }}
                      </td>
                      <td class="table-td w-28 border-r border-opacity-20">
                        {{ item | getStatus }}
                      </td>
                      <td class="table-td text-center w-56">
                        <button
                          disabled
                          class="process-btn opacity-25"
                          @click="showEditModal(item)"
                        >
                          <i class="fas fa-pen"></i>
                        </button>
                        <button class="process-btn mx-2" @click="showDetailModal(item)">
                          <i class="fas fa-search"></i>
                        </button>
                        <button class="process-btn" @click="deleteItem(item)">
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                    <tableLoader :load="load" :length="List.length" colspan="9" />
                  </tbody>
                </table>
              </div>
            </div>
            <div :class="isFullScreen && 'absolute bottom-0 w-full bg-white'">
              <PaginationComponents
                v-if="totalCount > 0"
                :totalRowCount="totalCount"
                :pageCount="pageCount"
                :currentPage="currentPage + 1"
                @changePage="(r) => (currentPage = r - 1)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Global Components
import dropdownButton from "@/components/general/dropdown.button.vue";
import PaginationComponents from "@/components/general/pagination.vue";
import searchInput from "@/components/general/search-input.vue";
import tableLoader from "@/components/general/table-loader.vue";

import addReportsModal from "./components/add.reports.modal.vue";

// Local Components
import addModal from "./components/add/index.vue";
import editModal from "./components/edit/index.vue";
import changeStatus from "./components/change.status.vue";
import vehicleModalVue from "./components/vehicle-list/index.vue";
import reportModalVue from "./components/report.modal.vue";
// import locationDetail from "./components/vehicle-list/components/map-location.vue";

// networking
import axios from "axios";
import { bulkPlanning } from "@/networking/urlmanager";
import moment from "moment";
export default {
  name: "collectivePlanning-module",
  components: {
    dropdownButton,
    PaginationComponents,
    tableLoader,
    addModal,
    editModal,
    changeStatus,
    addReportsModal,
    vehicleModalVue,
    reportModalVue,
    searchInput,
    // locationDetail,
  },
  data() {
    return {
      List: [],
      load: false,
      search: "",
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      activeState: 2,
      filterState: false,
      filterList: [],
      selected: { id: "", plaque: "", vehicleId: "" },
      isFullScreen: false,
    };
  },
  methods: {
    toggleFullScreen(value) {
      this.isFullScreen = value;
    },
    dateFormatter(val) {
      return moment(val).format("LLL");
    },
    getLastThreeWords(address) {
      if (address) {
        const words = address.split(" ");
        // Eğer kelime sayısı 3'ten azsa, tüm kelimeleri al
        const lastThreeWords =
          words.length <= 3
            ? address
            : words[words.length - 2] + " / " + words[words.length - 3];
        return lastThreeWords;
      } else return;
      // Adresi boşluk karakterlerine göre ayır
    },
    showEditModal(item) {
      this.selected = item;
      this.$modal.show("edit-plan-modal");
    },
    showDetailModal(item) {
      this.selected = item;
      this.$modal.show("planning-report-modal");
    },
    showVehicleList(item) {
      this.selected = item;
      this.$modal.show("vehicle-list-modal");
    },
    getAll(load = true) {
      this.List = [];
      this.load = load;
      axios
        .get(
          bulkPlanning.getAll + "?page=" + this.currentPage + "&search=" + this.search,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getFilter() {
      if (!this.search && !this.filterList.length) this.getAll();
      else {
        this.List = [];
        this.load = true;
        axios
          .post(
            planning.getFilterAll,
            {
              fList: JSON.stringify(
                this.search
                  ? [
                      { name: "name", value: this.search, type: "3" },
                      { name: "explanation", value: this.search, type: "3" },
                    ]
                  : this.filterList
              ),
              page: this.currentPage,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then((res) => {
            this.List = res.data.data.detail;
            this.totalCount = res.data.data.totalCount;
            this.pageCount = res.data.data.pageCount;
          })
          .catch((err) => {
            this.errorBox(err.response);
            console.log(err);
          })
          .finally(() => {
            this.load = false;
          });
      }
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Planı silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              bulkPlanning.delete,
              {
                rowId: item.id.toString(),
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );

              if (!this.filterState) this.getAll();
              else this.getFilter();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    showFilesModal(item) {
      this.selected = item;
      this.$modal.show("files-modal");
    },
    outPointFormatter(name = "", id = 0) {
      if (id) {
        if (name) return name;
        else if (!Number.isInteger(id)) return id.toString().substr(0, 40) + "...";
        else return id;
      } else return name;
    },
  },
  created() {
    this.getAll();

    // this.$store.commit(
    //   "setintervalId",
    //   setInterval(() => {
    //     this.getAll(false);
    //   }, 10000)
    // );
  },
  filters: {
    getDateFormat(val) {
      return moment(val).format("LLL");
    },
    getStatus(val) {
      const diff = moment
        .duration(moment().diff(moment(val.estimatedComplateDate)))
        .asDays();

      if (diff <= 0 && parseFloat(val.tonnage) > parseFloat(val.receivedTonnage))
        return "Devam Ediyor";
      else if (diff <= 0 && parseFloat(val.tonnage) == parseFloat(val.receivedTonnage))
        return "Tamamlandı";
      else return "Gecikti";
    },
  },
  watch: {
    currentPage() {
      if (!this.filterState) this.getAll();
      else this.getFilter();
    },
    filterList() {
      this.getFilter();
    },
    filterState(val) {
      if (!val) this.getAll();
    },
    search(val) {
      if (val.length == 0) this.getAll();
    },
  },
};
</script>

<style></style>
