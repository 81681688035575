<template>
  <span class="hidden xl:inline-block">
    <label v-if="load">
      {{ $t("general.loadingText") }}
    </label>
    <select
      @change="() => change()"
      v-model="selected"
      v-if="!load"
      class="text-base px-2 py-2 mb-1 focus:outline-none bg-transparent dark:text-gray-300"
    >
      <option value="1">Türkçe</option>
    </select>
  </span>
</template>

<script>
import axios from "axios";
import { user } from "@/networking/urlmanager";
export default {
  name: "language-panel",
  data() {
    return {
      selected: "1",
      load: false,
    };
  },
  methods: {
    change() {
      if (this.$store.state.userData.languageId != this.selected) {
        this.load = true;
        axios
          .post(
            user.setLanguage,
            {
              languageId: this.selected,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.userData.token,
              },
            }
          )
          .then(() => {
            this.load = false;
            this.$store.commit("setLanguage", this.selected);
            this.$i18n.locale = this.selected == 1 ? "tr" : "en";

            this.show = false;
          })
          .catch((err) => {
            this.load = false;
            this.errorBox(err.response);
          });
      } else {
        this.$i18n.locale = this.selected == 1 ? "tr" : "en";
      }
    },
  },
};
</script>

<style></style>
