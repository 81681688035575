<template>
  <div class="min-h-80 px-6 bg-gray-200 dark:bg-gray-900" @mousedown="isFilter = false">
    <div class="h-full p-4 bg-gray-50 dark:bg-gray-800 rounded shadow-sm mt-4">
      <div
        :class="isFullScreen && 'fixed top-0 w-full z-50     left-0 h-full  bg-white    '"
      >
        <div class="w-full flex items-center justify-between dark:text-gray-400 gap-20" :class="isFullScreen && 'mt-2 px-2' ">
          <div class="flex items-center w-4/12">
            <i class="fas fa-list-alt mr-1 fa-lg pr-1"></i>
            <h5 class="font-bold text-xl">Planlanan Siparişler</h5>
          </div>

          <div class="w-4/12 flex h-full items-center">
            <button
              @click="getFilter(2)"
              class="rounded bg-white shadow border-b-4 mr-2 text-sm w-40 py-2.5"
              :class="multiple == 2 && ' border-red-700 text-red-700'"
            >
              Normal Sipariş
            </button>
            <button
              @click="getFilter(1)"
              class="rounded bg-white shadow border-b-4 mr-2 text-sm w-40 py-2.5 h-full"
              :class="multiple == 1 && ' border-red-700 text-red-700'"
            >
              Toplu Sipariş
            </button>
          </div>
          <div class="w-4/12">
            <searchInput
              v-model="search"
              @searcing="() => getSearch()"
              inputClass="w-full  "
            />
          </div>
        </div>
        <editItem ref="editItem" :Detail="selected" @refresh="getAll" />
        <div
            :class="
              !isFullScreen
                ? 'shadow table-height-value overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4'
                : 'w-full overflow-y-scroll  h-screen pb-28 mt-4 '
            "
          >
            <div class="relative">
            <table class="min-w-full divide-y divide-gray-200">
              <thead
                class="bg-white border-black border-dotted border-b-2 border-opacity-20"
              >
                <tr>
                  <th
                    scope="col"
                    class="table-th text-center"
                    style="min-width: 40px !important"
                  ></th>
                  <th
                    scope="col"
                    class="table-th text-left border-r border-opacity-20"
                    style="min-width: 110px !important"
                  >
                    #
                  </th>
                  <th
                    scope="col"
                    class="table-th text-left border-r border-opacity-20"
                    style="min-width: 120px !important"
                  >
                    Sipariş Tarihi
                  </th>

                  <th
                    scope="col"
                    class="table-th text-left border-r border-opacity-20"
                    style="min-width: 200px !important"
                  >
                    Müşteri
                  </th>
                  <th
                    scope="col"
                    class="table-th text-left border-r border-opacity-20"
                    style="min-width: 150px !important"
                  >
                    Ürün
                    <i class="fas fa-arrow-down"></i>
                  </th>
                  <th
                    scope="col"
                    class="table-th text-left border-r border-opacity-20 z-30"
                    style="min-width: 150px !important"
                  >
                    Miktar
                  </th>

                  <th
                    v-if="multiple == 2"
                    scope="col"
                    class="table-th text-left z-20 border-r border-opacity-20"
                    style="min-width: 200px !important"
                  >
                    Araç
                  </th>

                  <th
                    scope="col"
                    style="min-width: 120px !important"
                    class="table-th text-left"
                  >
                    {{ $t("general.process") }}
                     <button
                        @click="toggleFullScreen(true)"
                        v-if="!isFullScreen"
                        class="absolute right-2"
                      >
                        <img src="@/assets/table/open.svg" alt="" />
                      </button>
                      <button
                        @click="toggleFullScreen(false)"
                        v-if="isFullScreen"
                        class="absolute right-2"
                      >
                        <img class="h-5 w-5" src="@/assets/table/close.svg" alt="" />
                      </button>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
                <tr v-for="(item, index) in List" :key="index">
                  <td
                    class="table-td border-r border-opacity-20 cursor-pointer select-none z-40 text-center"
                  >
                    {{ currentPage * 20 + (index + 1) }}
                  </td>
                  <td
                    class="table-td border-r border-opacity-20 cursor-pointer select-none"
                  >
                    <span
                      >#
                      {{ item.orderNo }}
                    </span>

                    <p>{{ item.orderName }}</p>
                  </td>
                  <td
                    class="table-td border-r border-opacity-20 cursor-pointer select-none"
                  >
                    {{ item.orderDate | getDateFormat }}
                  </td>
                  <td
                    class="table-td border-r border-opacity-20 cursor-pointer select-none"
                  >
                    {{ item.customer }}
                  </td>
                  <td
                    class="table-td border-r border-opacity-20 cursor-pointer select-none"
                    :title="
                      !isProductValid(item.productType) && 'Ürün Sistemde Bulunamadı'
                    "
                  >
                    {{ item.productType }}
                  </td>
                  <td
                    class="table-td border-r border-opacity-20 cursor-pointer select-none"
                  >
                    {{ item.amount }} TON
                  </td>
                  <td
                    v-if="multiple == 2"
                    class="table-td border-r border-opacity-20 cursor-pointer select-none"
                  >
                    <div
                      class="w-full relative text-white text-opacity-30 hover:text-opacity-60 transition-all duration-200"
                    >
                      <p class="text-white border-b mb-1 py-1 border-opacity-20 w-full">
                        {{ item.vehicle }}
                      </p>
                      <p class="text-white">{{ item.driverName }}</p>
                    </div>
                  </td>

                  <td class="table-td text-center">
                    <div
                      class="flex gap-2 items-center justify-center"
                      :class="multiple != 2 && 'justify-center'"
                    >
                      <button
                        v-if="multiple == 2"
                        @click="() => ((selected = item), $refs.editItem.show())"
                        class="process-btn"
                      >
                        <i class="fas fa-pen"></i>
                      </button>
                      <button class="process-btn" @click="deleteItem(item)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tableLoader :load="load" :length="List.length" colspan="9" />
                <td colspan="8" v-if="planningLoad" class="text-center py-4 text-white">
                  <i class="fas fa-spinner fa-spin"></i> Planlanıyor
                </td>
              </tbody>
            </table>
          </div>
           </div>
           <div
            :class="
              !isFullScreen
                ? 'flex justify-between items-center'
                : 'absolute bottom-0 w-full bg-white flex justify-between items-center pr-2'
            "
          >
          <paginationVue
            v-if="totalCount > 0"
            :totalRowCount="totalCount"
            :currentPage="currentPage + 1"
            :pageCount="pageCount"
            @changePage="(r) => (currentPage = r - 1)"
          />
                </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Global Components
import vehicleSelect from "./components/vehicle-select.vue";
import editItem from "./components/edit.vue";

import personnelSelect from "@/components/devItem/personnelInput.vue";

// networking
import axios from "axios";
import { orders } from "@/networking/urlmanager";
import moment from "moment";
import tableLoader from "@/components/general/table-loader.vue";
import paginationVue from "@/components/general/pagination.vue";

import similarity from "similarity";
import searchInput from "@/components/general/search-input.vue";

export default {
  name: "orders-module",
  components: {
    tableLoader,
    paginationVue,
    vehicleSelect,
    personnelSelect,
    editItem,
    searchInput,
  },
  data() {
    return {
      isFullScreen: false,
      List: [],
      selectedList: [],
      selectedAll: false,
      isFilter: false,

      products: [],
      customers: [],
      load: false,
      planningLoad: false,

      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      search: "",

      selected: "",
      multiple: 2,
    };
  },
  methods: {
      toggleFullScreen(value) {
      this.isFullScreen = value;
    },
    getSearch() {
      (this.currentPage = 0), this.getAll();
    },
    isProductValid(product) {
      const findItem = this.products.find((item) => similarity(item.name, product) > 0.9);
      if (!findItem) return false;
      else {
        if (Array.isArray(findItem.dorseTypes))
          if (findItem.dorseTypes.length == 0) return false;
          else return true;
      }
    },
    getFilter(val) {
      this.multiple = val;
      this.getAll();
    },
    // axios functions
    getAll(load = true) {
      this.List = [];
      this.load = load;
      axios
        .get(
          orders.getPlanningAll +
            "?multiple=" +
            this.multiple +
            "&search=" +
            this.search +
            "&pagination=1" +
            "&page=" +
            this.currentPage,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          const { detail, totalCount, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;

          this.List = detail.map((item, index) => {
            return {
              ...item,
              isCustom: false,
              driverTC: "",
            };
          });
        })
        .catch((err) => {
          console.log(err);
          // this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    async applyItem() {
      try {
        this.load = true;
        if (this.selectedList.length > 0) {
          await axios.post(
            orders.applyVehicle,
            {
              orderList: this.selectedList,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          );
          this.selectedAll = [];
          this.activeState = 1;
          this.getAll();
        } else {
          this.load = false;
          alert("Lütfen ilk önce herhangi bir sipariş seçin");
        }
      } catch (error) {
        this.load = false;
        console.log(error);
        alert("İşlem başarısız oldu");
      }
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Planı silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              orders.delete,
              {
                rowId: item.id.toString(),
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );

              if (!this.filterState) this.getAll();
              else this.getFilter();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
  },
  async created() {
    this.load = true;
    this.getAll();
  },
  filters: {
    getDateFormat(val) {
      if (val) return moment(val).format("LLL");
      else return "Belirlilmemiş";
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    search(val) {
      if (val.length == 0) this.getAll();
    },
  },
};
</script>
