<template>
  <div class="relative">
    <div v-if="load" class="p-4 text-center">
      <i class="fas fa-spinner fa-spin"></i>
      {{ $t("operations.planning.calculatingRoute") }}
    </div>
    <!-- <button
      class="absolute right-2.5 rounded-sm text-black bg-white px-3 py-2 top-2 z-50 text-xl"
      type="button"
     
      v-if="!load"
    >
      <i class="fas fa-expand"></i>
    </button> -->
    <fullWiewPanel
      v-if="showFullDetail"
      @hidePanel="showFullDetail = false"
      :endPosition="endPosition"
      :startPosition="startPosition"
      :polyLine="polyLine"
      :waypoints="waypoints"
      :tolls="tolls"
      :routes="routes[activeRoute]"
      :bridges="bridges"
      :isExplosiveDefault="explosiveState"
      @change="(r) => $emit('change', r)"
      @changeStartPosition="(val) => $emit('changeStartPosition', val)"
      @changeEndPosition="(val) => $emit('changeEndPosition', val)"
      @changeOutpoint="(r) => $emit('changeOutpoint', r)"
      @changeCustomer="(r) => $emit('changeCustomer', r)"
      :customerId="customer"
      :outpointId="outpoint"
    />
    <GmapMap
      v-if="
        !load &&
        startPosition.lat &&
        startPosition.lng &&
        endPosition.lat &&
        endPosition.lng
      "
      @click="showFullDetail = true"
      :center="center"
      :zoom="6"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      }"
      style="width: 100%; border-radius: 20px"
      class="rounded-md"
      :style="'height:' + height"
    >
      <GmapMarker :position="startPosition" @click="center = startPosition" />

      <GmapMarker
        v-for="(item, index) in waypoints"
        :key="index"
        :position="item"
        :label="{
          text: item.no + '. Teslim Noktası',
          color: 'white',
          className:
            'absolute top-0.5 left-0  text-sm  bg-black rounded px-2 text-xs font-bold text-white',
          fontSize: '10px',
        }"
      />

      <GmapMarker
        v-for="(item, index) in bridges"
        :key="'bridge-' + index"
        :icon="require('@/assets/marker/bridge.png')"
        :position="item.position"
        :label="{
          text: item.name,
          color: 'black',
          className:
            'absolute top-1 left-0  text-sm  bg-white rounded px-2 text-xs font-bold',
          fontSize: '10px',
        }"
      />

      <GmapPolyline
        v-bind:path.sync="polyLine"
        v-bind:options="{ strokeColor: '#C43A3A' }"
      >
      </GmapPolyline>

      <!-- :editable="true" -->

      <div v-for="(item, index) in tolls" :key="'toll-' + index">
        <GmapMarker
          v-if="item.type != 'Barrier'"
          :position="{ lat: item.start.lat, lng: item.start.lng }"
          :icon="require('@/assets/marker/hgs.png')"
          @click="center = item"
          :label="{
            text: 'Giriş : ' + item.start.name + '-' + item.end.name,
            color: 'black',
            className:
              'absolute top-1 left-0  text-sm  bg-white rounded px-2 text-xs font-bold',
            fontSize: '10px',
          }"
        />

        <GmapMarker
          v-if="item.type != 'Barrier'"
          :position="{ lat: item.end.lat, lng: item.end.lng }"
          :icon="require('@/assets/marker/hgs.png')"
          @click="center = { lat: item.end.lat, lng: item.end.lng }"
          :label="{
            text: 'Çıkış : ' + item.start.name + '-' + item.end.name,
            color: 'black',
            className:
              'absolute top-1 left-0 text-sm  bg-white  rounded px-2 text-xs font-bold',
            fontSize: '10px',
          }"
        />

        <GmapMarker
          v-if="item.type == 'Barrier'"
          :position="{ lat: item.lat, lng: item.lng }"
          :icon="require('@/assets/marker/barrier.png')"
          @click="center = { lat: item.lat, lng: item.lng }"
          :label="{
            text: item.name,
            color: 'black',
            className:
              'absolute top-1 -right-1 text-sm   rounded bg-white px-2 text-xs font-bold',
            fontSize: '10px',
          }"
        />
      </div>

      <GmapMarker
        :position="endPosition"
        :icon="require('@/assets/marker/flag.png')"
        @click="center = endPosition"
      />
    </GmapMap>

    <button
      type="button"
      v-if="load == false && routes.length > 1"
      @click="changeRoute()"
      class="text-sm bg-white absolute bottom-1 p-2 rounded text-black left-2"
    >
      <i class="fas fa-exchange-alt"></i>
      Alternatif Rota ({{ activeRoute + 1 + "/" + routes.length }})
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { routePlanner } from "@/networking/urlmanager";
import fullWiewPanel from "./fullView.vue";
export default {
  name: "route-viewer",
  props: [
    "center",
    "height",
    "startPosition",
    "endPosition",
    "routeString",
    "editState",
    "explosiveState",
    "customer",
    "outpoint",
    "waypointsList",
  ],
  components: {
    fullWiewPanel,
  },
  data() {
    return {
      routes: [],
      activeRoute: 0,

      waypoints: [],

      bridges: [],
      tolls: [],
      polyLine: [],
      load: true,
      isToll: false,
      showFullDetail: false,
      fuelPrice: 0,
    };
  },
  methods: {
    getRoute(loadState = true) {
      this.load = loadState;
      axios
        .post(
          routePlanner.calculate,
          {
            from: this.startPosition,
            to: this.endPosition,
            waypoints: JSON.stringify(this.waypoints),
            isToll: true,
            isShip: true,
            isAsphalt: true,
            isExplosive: this.explosiveState,
            routeType: "fast",
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.routes = res.data.data;
          this.routeGenerate();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    changeRoute() {
      if (this.activeRoute + 1 < this.routes.length) this.activeRoute++;
      else this.activeRoute = 0;

      this.waypoints = this.waypointsList.map((item) => {
        return {
          no: item.no,
          adress: item.position.adress,
          lat: item.position.lat,
          lng: item.position.lng,
        };
      });

      this.routeGenerate();
    },
    routeGenerate() {
      const data = this.routes[this.activeRoute];
      this.polyLine = data.polyLine;
      this.$emit("changePolyline", this.polyLine);

      this.tolls = data.tolls;
      this.bridges = data.bridges;

      this.$emit("change", {
        cost: data.cost,
        polyLine: JSON.stringify({
          startPosition: this.startPosition,
          waypoints: this.waypoints,
          endPosition: this.endPosition,
        }),
        totalMinute: data.totalMinute,
        navigationLink: data.navigationLink,
        totalKilometer: data.totalKilometer,
        tolls: data.tolls,
        bridges: data.bridges,
      });
    },
    removePlace(index) {
      this.waypoints.splice(index, 1);
      this.getRoute(false);
    },
    getPlace(val) {
      this.waypoints.push({
        lat: val.latLng.lat(),
        lng: val.latLng.lng(),
      });
      this.getRoute(false);
    },
    setPlace(val, index) {
      this.waypoints[index] = {
        lat: val.latLng.lat(),
        lng: val.latLng.lng(),
      };
      this.getRoute(false);
    },
  },
  created() {
    // this.waypoints = this.routeString ? this.routeString.waypoints : [];
    this.waypoints = this.waypointsList.map((item) => {
      return {
        no: item.no,
        adress: item.position.adress,
        lat: item.position.lat,
        lng: item.position.lng,
      };
    });
    this.getRoute();
  },
  watch: {
    waypointsList(val) {
      this.waypoints = val.map((item) => {
        return {
          no: item.no,
          adress: item.position.adress,
          lat: item.position.lat,
          lng: item.position.lng,
        };
      });
      this.getRoute();
    },
  },
};
</script>

<style></style>
