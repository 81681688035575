<template>
  <div class="min-h-80 px-6 bg-gray-200 dark:bg-gray-900">
    <addModal @refresh="getAll()" />
    <editModal @refresh="getAll()" :rowId="selected.id" />
    <div class="h-full p-4 bg-gray-50 dark:bg-gray-800 rounded shadow-sm mt-4">
      <div class="w-full flex items-center dark:text-gray-400">
        <i class="fas fa-list-alt mr-1 fa-lg pr-1"></i>
        <h5 class="font-bold text-xl">Siparişler</h5>
      </div>
      <div
        :class="isFullScreen && 'fixed top-0 w-full z-50    left-0 h-full  bg-white    '"
      >
        <div class="w-full mt-2">
          <div class="md:flex items-center justify-between w-full">
            <div class="w-full xl:w-3/12">
              <div class="md:flex items-center mt-2">
                <button
                  v-if="isPlanning && !planningLoad"
                  @click="() => applyItem()"
                  :disabled="load || selectedList.length == 0"
                  :class="{
                    'ml-2 ': isFullScreen,
                    'bg-opacity-50': load || selectedList.length === 0,
                  }"
                  class="py-2 w-24 bg-black rounded text-center text-white text-sm"
                >
                  {{ !load ? "Kaydet" : "Kayıt Ediliyor" }}
                </button>

                <button
                  v-if="isPlanning && !planningLoad"
                  @click="() => resetAll()"
                  :disabled="load"
                  :class="load && 'bg-opacity-50'"
                  class="w-24 py-2 bg-red-700 rounded text-center text-white ml-2 text-sm"
                >
                  İptal Et
                </button>

                <!-- <button
              @click="() => $modal.show('add-order-modal')"
              class="btn h-12 px-3 text-center py-3 "
            >
              <i class="fas fa-plus mr-2"></i
              >Yeni Sipariş
            </button> -->
                <button
                  v-if="!isPlanning || planningLoad"
                  @click="() => (isPlanning = true)"
                  class="px-3 py-3 bg-red-700 rounded text-left text-sm text-white"
                  :class="isFullScreen && 'ml-2'"
                >
                  Planlama Modunu Aç
                </button>
              </div>
            </div>
            <div
              v-if="isPlanning"
              class="w-6/12 flex items-center transition-all duration-500"
            >
              <button
                @click="activeState = 1"
                class="py-2 w-40 rounded border-b-4 bg-white shadow mr-2 text-sm"
                :class="activeState == 1 && ' border-red-700 text-red-700'"
              >
                Planlanmayanlar ({{ totalCounter.unPlanning }})
              </button>
              <button
                @click="activeState = 2"
                :class="activeState == 2 && ' border-red-700 text-red-700'"
                class="w-40 py-2 rounded bg-white shadow mr-2 text-sm border-b-4"
              >
                Planlananlar ({{ totalCounter.planning }})
              </button>
              <button
                @click="activeState = 3"
                :class="activeState == 3 && ' border-red-700  text-red-700'"
                class="w-40 py-2 rounded bg-white shadow text-sm border-b-4"
              >
                Sorunlu ({{ totalCounter.error }})
              </button>
            </div>
            <div class="w-3/12" :class="isFullScreen && 'mr-2 '">
              <searchInput
                v-model="serchControl"
                @searcing="(r) => (search = r)"
                inputClass="w-full"
              />
            </div>
          </div>

          <div
            :class="
              !isFullScreen
                ? 'shadow table-height-value overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4'
                : 'w-full overflow-y-scroll  h-screen pb-28 mt-4 '
            "
          >
            <div class="relative">
              <table class="min-w-full divide-y divide-gray-200">
                <thead
                  class="bg-white border-black border-dotted border-b-2 border-opacity-20"
                >
                  <tr>
                    <th v-if="isPlanning" scope="col" class="table-th text-center">
                      <input
                        v-if="false"
                        disabled
                        type="checkbox"
                        v-model="selectedAll"
                      />
                    </th>
                    <th
                      scope="col"
                      class="table-th text-left border-r border-opacity-20"
                      style="min-width: 150px !important"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      class="table-th text-left border-r border-opacity-20"
                      style="min-width: 160px !important"
                    >
                      Dolum
                    </th>
                    <th
                      scope="col"
                      class="table-th text-left border-r border-opacity-20"
                      style="min-width: 160px !important"
                    >
                      Teslim
                    </th>
                    <th
                      scope="col"
                      class="table-th text-left border-r border-opacity-20"
                      style="min-width: 120px !important"
                    >
                      Sipariş Tarihi
                    </th>

                    <th
                      scope="col"
                      class="table-th text-left border-r border-opacity-20"
                      style="min-width: 200px !important"
                    >
                      Müşteri
                    </th>
                    <th
                      scope="col"
                      class="table-th text-left border-r border-opacity-20"
                      style="min-width: 110px !important; z-index: 99 !important"
                    >
                      Ürün
                      <i class="fas fa-arrow-down"></i>
                    </th>
                    <th
                      scope="col"
                      class="table-th text-left border-r border-opacity-20 z-30"
                      style="min-width: 80px !important"
                    >
                      Miktar
                      <button
                        @click="toggleFullScreen(true)"
                        v-if="!isFullScreen && activeState == 3"
                        class="absolute right-1"
                      >
                        <img src="@/assets/table/open.svg" alt="" />
                      </button>
                      <button
                        @click="toggleFullScreen(false)"
                        v-if="isFullScreen && activeState == 3"
                        class="absolute right-1"
                      >
                        <img class="h-5 w-5" src="@/assets/table/close.svg" alt="" />
                      </button>
                    </th>

                    <th
                      scope="col"
                      class="table-th text-left z-20 border-r border-opacity-20"
                      style="min-width: 200px !important"
                      v-if="activeState < 3"
                    >
                      Araç
                      <button
                        @click="toggleFullScreen(true)"
                        v-if="!isFullScreen && activeState == 1"
                        class="absolute right-4"
                      >
                        <img src="@/assets/table/open.svg" alt="" />
                      </button>
                      <button
                        @click="toggleFullScreen(false)"
                        v-if="isFullScreen && activeState == 1"
                        class="absolute right-4"
                      >
                        <img class="h-5 w-5" src="@/assets/table/close.svg" alt="" />
                      </button>
                    </th>
                    <th
                      scope="col"
                      class="table-th text-left z-20 border-r border-opacity-20"
                      style="min-width: 200px !important"
                      v-if="activeState == 2"
                    >
                      Sürücü
                    </th>
                    <th
                      scope="col"
                      class="table-th text-left z-20 border-r border-opacity-20"
                      style="min-width: 70px !important"
                      v-if="activeState == 2"
                    >
                      #
                      <button
                        @click="toggleFullScreen(true)"
                        v-if="!isFullScreen && activeState == 2"
                        class="absolute right-4"
                      >
                        <img src="@/assets/table/open.svg" alt="" />
                      </button>
                      <button
                        @click="toggleFullScreen(false)"
                        v-if="isFullScreen && activeState == 2"
                        class="absolute right-4"
                      >
                        <img class="h-5 w-5" src="@/assets/table/close.svg" alt="" />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="divide-y-2 divide-gray-500"
                  style="background-color: #3a3a3a"
                >
                  <tr v-for="(item, index) in getOrderList" :key="index">
                    <td
                      v-if="isPlanning"
                      @click="selectItem(item, index)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none z-40"
                    >
                      <i
                        v-if="activeState == 1"
                        class="bg-white rounded block h-4 w-4"
                      ></i>
                      <i
                        v-else-if="activeState == 2"
                        class="fas fa-check bg-blue-500 text-white rounded"
                        style="padding: 2px"
                      ></i>
                    </td>

                    <td
                      @click="selectItem(item, index)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none"
                    >
                      <span
                        >#
                        {{ item.orderNo }}
                      </span>

                      <p>{{ item.orderName }}</p>
                    </td>

                    <td
                      @click="selectItem(item, index)"
                      class="table-td border-r border-opacity-20 cursor-pointer"
                    >
                      <p class="text-white" style="font-size: 10px">
                        {{ item.receivedName }}
                      </p>

                      <p class="" style="font-size: 10px">
                        {{ getLastThreeWords(item.outpointAdress) }}
                      </p>
                    </td>
                    <td
                      @click="selectItem(item, index)"
                      class="table-td border-r border-opacity-20 cursor-pointer"
                    >
                      <p class="text-white" style="font-size: 10px">
                        {{ item.deliveredName }}
                      </p>

                      <p class="text-white opacity-50">
                        {{ item.deliveryDate | getDateFormat }}
                      </p>

                      <p class="" style="font-size: 10px">
                        {{ getLastThreeWords(item.targetAdress) }}
                      </p>
                    </td>

                    <td
                      @click="selectItem(item, index)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none"
                    >
                      {{ item.orderDate | getDateFormat }}
                    </td>
                    <td
                      @click="selectItem(item, index)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none"
                    >
                      {{ item.customer }}
                    </td>
                    <td
                      @click="selectItem(item, index)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none"
                      :title="
                        !isProductValid(item.productType) && 'Ürün Sistemde Bulunamadı'
                      "
                      :class="
                        !isProductValid(item.productType) && 'bg-red-700  animate-pulse'
                      "
                    >
                      {{ item.productType }}
                    </td>
                    <td
                      @click="selectItem(item, index)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none"
                    >
                      {{ item.tonnage }} TON
                    </td>
                    <td
                      v-if="activeState < 3"
                      class="table-td w-28 border-r border-opacity-20 text-center font-"
                      :colspan="item.isMultiple && 2"
                    >
                      <vehicleSelect
                        v-if="!item.isMultiple && activeState == 1"
                        :optList="getFilterVehicle"
                        @change="(val) => val && selectedCustomItem(val, item)"
                        :disabled="!isPlanning"
                      />
                      <vehicleSelect
                        v-else-if="!item.isMultiple && activeState == 2"
                        v-model="item.vehicle"
                        :optList="getFilterVehicle"
                        @changeName="(val) => (item.vehicleName = val)"
                        @changeDriverName="(driver) => (item.driver = driver)"
                        @changeDorse="(val) => (item.dorse = val)"
                        :disabled="!isPlanning"
                      />

                      <button
                        v-else
                        @click="showConfirm()"
                        class="bg-white px-4 py-3 rounded w-full text-opacity-60 text-black"
                      >
                        TOPLU SİPARİŞ
                      </button>
                    </td>
                    <td
                      v-if="activeState == 2 && !item.isMultiple"
                      class="table-td border-r border-opacity-20 text-center font-"
                    >
                      <personnelSelect
                        :titleNone="true"
                        :hideContainer="true"
                        :value="item.driver"
                        @changeName="(val) => (item.driver = val)"
                        @changeTC="(val) => (item.driverTC = val)"
                        :loading="load1"
                      />
                    </td>
                    <td class="table-td text-center" v-if="activeState == 2">
                      <button class="process-btn" @click="selectItem(item)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  <tableLoader :load="load" :length="getOrderList.length" colspan="11" />
                  <td colspan="8" v-if="planningLoad" class="text-center py-4 text-white">
                    <i class="fas fa-spinner fa-spin"></i> Planlanıyor
                  </td>
                </tbody>
              </table>
            </div>
          </div>

          <div
            :class="
              !isFullScreen
                ? 'flex justify-between items-center'
                : 'absolute bottom-0 w-full bg-white flex justify-between items-center pr-2'
            "
          >
            <paginationVue
              :pageCount="pageCount"
              :currentPage="currentPage"
              :totalRowCount="
                activeState == 1
                  ? totalCounter.unPlanning
                  : activeState == 2
                  ? totalCounter.planning
                  : totalCounter.error
              "
              @changePage="(r) => (currentPage = r)"
            />
            <span class="w-6/12 text-right block"
              >Toplam {{ totalCount }} adet kayıt listelendi</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Global Components
import vehicleSelect from "./components/vehicle-select.vue";
import personnelSelect from "../../../../components/devItem/personnelInput.vue";
import searchInput from "../../../../components/general/search-input.vue";
import dropdownButton from "@/components/general/dropdown.button.vue";
// Local Components
import addModal from "./components/add.modal.vue";
import editModal from "./components/edit.modal.vue";

// networking
import axios from "axios";
import { orders } from "@/networking/urlmanager";
import moment from "moment";
import tableLoader from "@/components/general/table-loader.vue";
import paginationVue from "@/components/general/pagination.vue";
import DiffInput from "@/components/general/diffInput.vue";

import similarity from "similarity";

import { recommendSingleVehicleForOrder } from "./utils/recomendation";
import { getLocationAll, getCustomers, getProducts } from "./utils/detailData";

export default {
  name: "orders-module",
  components: {
    dropdownButton,
    addModal,
    editModal,
    tableLoader,
    paginationVue,
    DiffInput,
    vehicleSelect,
    personnelSelect,
    searchInput,
  },
  data() {
    return {
      currentPage: 0,
      pageCount: 1,
      totalCount: 0,

      isFullScreen: false,
      activeState: 1,
      List: [],
      selectedList: [],
      selectedAll: false,
      load1: false,
      vehicleList: [],
      selectedVehiclesList: [],

      products: [],
      customers: [],
      load: false,
      planningLoad: false,

      search: "",
      serchControl: "",
      isPlanning: false,

      selected: { id: "", plaque: "", vehicleId: "" },
    };
  },
  methods: {
    toggleFullScreen(value) {
      this.isFullScreen = value;
    },
    getLastThreeWords(address) {
      if (address) {
        const words = address.split(" ");
        // Eğer kelime sayısı 3'ten azsa, tüm kelimeleri al
        const lastThreeWords =
          words.length <= 3
            ? address
            : words[words.length - 2] + " / " + words[words.length - 3];
        return lastThreeWords;
      } else return;
      // Adresi boşluk karakterlerine göre ayır
    },
    // utils functions
    isProductValid(product) {
      const findItem = this.products.find((item) => similarity(item.name, product) > 0.9);
      if (!findItem) return false;
      else {
        if (Array.isArray(findItem.dorseTypes))
          if (findItem.dorseTypes.length == 0) return false;
          else return true;
      }
    },
    isContent(val) {
      const {
        customer,
        deliveredName,
        deliveryDate,
        deliveryTime,
        invoicedCompany,
        orderDate,
        orderName,
        orderNo,
        outpointAdress,
        outpointLat,
        outpointLng,
        productType,
        receivedName,
        targetAdress,
        targetPointLat,
        targetPointLng,
        tonnage,
        unitType,
      } = val;

      if (
        !customer ||
        !deliveredName ||
        !deliveryDate ||
        !deliveryTime ||
        !invoicedCompany ||
        !orderDate ||
        !orderName ||
        !orderNo ||
        !outpointAdress ||
        !outpointLat ||
        !outpointLng ||
        !productType ||
        !receivedName ||
        !targetAdress ||
        !targetPointLat ||
        !targetPointLng ||
        !tonnage ||
        !unitType
      ) {
        return false;
      }
      return true;
    },
    isContentNames(val) {
      const {
        customer,
        deliveredName,
        deliveryDate,
        deliveryTime,
        invoicedCompany,
        orderDate,
        orderName,
        orderNo,
        outpointAdress,
        outpointLat,
        outpointLng,
        productType,
        receivedName,
        targetAdress,
        targetPointLat,
        targetPointLng,
        tonnage,
        unitType,
      } = val;

      return [
        !customer ? "Müşteri " : "",
        !deliveredName ? "Teslim Edilen Ad " : "",
        !deliveryDate ? "Teslim Tarihi" : "",
        !deliveryTime ? "Teslimat Saati" : "",
        !invoicedCompany ? "Faturalı Şirket" : "",
        !orderDate ? "Sipariş Tarihi" : "",
        !orderName ? "Sipariş Adı" : "",
        !orderNo ? "Sipariş No" : "",
        !outpointAdress ? "Dolum Noktası Adresi" : "",
        !outpointLat ? "Dolum Noktası Enlem" : "",
        !outpointLng ? "Dolum Noktası Boylam" : "",
        !productType ? "Ürün" : "",
        !receivedName ? "Gönderici Adı" : "",
        !targetAdress ? "Teslim Noktası Adresi" : "",
        !targetPointLat ? "Teslim Noktası Enlem" : "",
        !targetPointLng ? "Teslim Noktası Boylam" : "",
        !tonnage ? "Tonage (Miktar)" : "",
        !unitType ? "Birim" : "",
      ].filter((r) => r != "");
    },
    checkSelectedOrder(val) {
      return this.selectedList.find((item) => {
        return parseInt(item.orderNo) == parseInt(val);
      });
    },
    showConfirm() {
      alert("BU SİPARİŞ KAYIT EDİLDİKTEN SONRA TOPLU PLANLAMA EKRANINDAN PLANLANABİLİR!");
    },

    // selected utils
    async selectItem(item) {
      if (!this.isPlanning) return;

      if (!this.isProductValid(item.productType) || !this.isContent(item)) {
        if (!this.isProductValid(item.productType)) {
          if (
            confirm(
              "ÜRÜN KARTI TANIMLANMAMIŞ VEYA İLGİLİ ÜRÜN KARTINDA İLGİLİ DEĞERLER DOLDURULMAMIŞ!"
            )
          ) {
            this.$router.push("/products");
            return;
          } else return;
        } else {
          this.swalBox(
            "warning",
            "Uyarı",
            "Sipariş Bilgileri Eksik Lütfen Aşağıdaki Alanların Doldurulduğundan Emin Olun : " +
              "\n" +
              this.isContentNames(item).join(","),
            false,
            this.$t("general.OkayTitle")
          );
          return;
        }
      }

      const findState = this.checkSelectedOrder(item.orderNo);
      if (findState) {
        if (confirm("Planlamayı iptal etmek istediğinize emin misiniz?")) {
          const findIndex = this.selectedList.findIndex(
            (sItem) => parseInt(sItem.orderNo) == parseInt(item.orderNo)
          );
          this.selectedList.splice(findIndex, 1);

          this.vehicleList.forEach((item) => {
            if (!this.selectedList.find((vitem) => vitem.vehicle.id == item.id)) {
              item.isAvailable = true;
            }
          });
        }
      } else {
        if (item.tonnage > 28) {
          this.selectedList.push({
            ...item,
            vehicleName: "",
            vehicle: {
              id: "",
              name: "",
            },
            isMultiple: item.tonnage > 28,
            driver: "",
            dorse: "",
            distance: 0,
          });
        } else {
          const detail = await this.processOrderItem(item);
          if (detail) {
            this.selectedList.push(detail);
          }
        }
      }
    },
    async selectedCustomItem(val, item) {
      const findVehicle = this.vehicleList.find((item) => item.id == val.id);
      if (findVehicle) {
        if (findVehicle.isBulkOperation) {
          if (
            !confirm(
              "ARAÇ TOPLU BİR PLANA EKLENMİŞ YİNEDE ARACI BU SİPARİŞE ATAMAK İSTEDİĞİNZE EMİN MİSİNİZ"
            )
          )
            return;
        }
        let list = this.List;

        findVehicle.isAvailable = false;
        this.selectedList.push({
          ...item,
          vehicleName: findVehicle.vehicle,
          vehicle: {
            id: findVehicle.id,
            name: findVehicle.vehicle,
          },
          isMultiple: item.tonnage > 28,
          driver: findVehicle.driverName,
          dorse: findVehicle.dorse,
          distance: 0,
        });

        this.List = [];
        setTimeout(() => {
          this.List = list;
        }, 100);
      }
    },

    // rest all planning
    resetAll() {
      this.vehicleList.forEach((vehicle) => {
        vehicle.isAvailable = true;
      });

      this.List.forEach((item) => {
        item.vehicle = "";
      });

      this.isPlanning = false;
      this.selectedList = [];
      this.selectedAll = false;
      this.activeState = 1;
    },

    // recomendations system
    async processOrderItem(item) {
      const recommended = await this.vehicleRecomended(item);

      if (!recommended) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "UYGUN ARAÇ BULUNAMADI!",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }

      return {
        ...item,
        vehicleName: recommended.vehicle,
        vehicle: {
          id: recommended.id,
          name: recommended.vehicle,
        },
        isMultiple: item.tonnage > 28,
        driver: recommended.driverName,
        dorse: recommended.dorse,
        distance: recommended.distance ? recommended.distance : 0,
      };
    },
    async vehicleRecomended(item) {
      const order = {
        startLat: item.outpointLat,
        startLng: item.outpointLng,
        endLat: item.targetPointLat,
        endLng: item.targetPointLng,
        deliveryDate: item.deliveryDate,
        tonnage: parseFloat(item.tonnage),
        productName: item.productType,
        token: this.$store.state.userData.token,
      };

      const recommendedVehicles = recommendSingleVehicleForOrder(
        order,
        this.vehicleList,
        this.customers,
        this.products,
        false,
        false
      );

      return recommendedVehicles;
    },
    resetOrderItem(item) {
      return {
        ...item,
        vehicle: "",
        vehicleName: "",
        dorse: "",
        driver: "",
        distance: "",
      };
    },

    // axios functions
    getAll(load = true) {
      this.List = [];
      this.load = load;
      axios
        .get(
          orders.getAll + "?search=" + this.search + "&page=" + (this.currentPage - 1),
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.pageCount = res.data.data.pageCount;
          this.totalCount = res.data.data.totalCount;

          this.List = res.data.data.detail.map((item, index) => {
            return {
              ...item,
              isCustom: false,
              driverTC: "",
            };
          });
        })
        .catch((err) => {
          console.log(err);
          // this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    async applyItem() {
      try {
        this.load = true;
        if (this.selectedList.length > 0) {
          await axios.post(
            orders.applyVehicle,
            {
              orderList: this.selectedList,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          );
          this.isPlanning = false;
          this.selectedAll = [];
          this.selectedList = [];
          this.activeState = 1;
          this.getAll();
        } else {
          this.load = false;
          alert("Lütfen ilk önce herhangi bir sipariş seçin");
        }
      } catch (error) {
        this.load = false;
        console.log(error);
        alert("İşlem başarısız oldu");
      }
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Planı silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              orders.delete,
              {
                rowId: item.id.toString(),
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );

              if (!this.filterState) this.getAll();
              else this.getFilter();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    toLocaleLowerCase(val) {
      return val.toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
    },
    searchFilter(order) {
      if (this.search) {
        return this.toLocaleLowerCase(order.orderNo.toString()).includes(
          this.toLocaleLowerCase(this.search)
        ) ||
          this.toLocaleLowerCase(order.customer).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.deliveredName).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.invoicedCompany).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.orderName).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.receivedName).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.tonnage.toString()).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.outpointAdress).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.productType).includes(
            this.toLocaleLowerCase(this.search)
          )
          ? true
          : false;
      } else {
        return true;
      }
    },
  },
  computed: {
    getOrderList() {
      const list = this.List;
      if (this.activeState == 1) {
        return list.filter(
          (order) =>
            this.isProductValid(order.productType) &&
            !this.checkSelectedOrder(order.orderNo) &&
            this.isContent(order) &&
            this.searchFilter(order)
        );
      } else if (this.activeState == 2) {
        if (this.search) {
          return this.selectedList.filter((order) => this.searchFilter(order));
        } else return this.selectedList;
      } else {
        return list.filter(
          (order) =>
            (!this.isProductValid(order.productType) || !this.isContent(order)) &&
            this.searchFilter(order)
        );
      }
    },
    totalCounter() {
      const errorsCount = this.List.filter(
        (order) => !this.isProductValid(order.productType) || !this.isContent(order)
      ).length;
      const planningCount = this.selectedList.length;
      const unPlanningCount = this.List.length - planningCount - errorsCount;
      return {
        unPlanning: unPlanningCount,
        planning: planningCount,
        error: errorsCount,
      };
    },
    getFilterVehicle() {
      return this.vehicleList.map((item) => {
        return {
          name: item.vehicle,
          id: item.id,
          driverName: item.driverName,
          dorse: item.dorse,
          driverTC: item.driverTC,
        };
      });
    },
  },

  async created() {
    this.load = true;
    if (this.vehicleList.length == 0) {
      this.vehicleList = await getLocationAll(this.$store.state.userData.token);
    }
    if (this.customers.length == 0)
      this.customers = await getCustomers(this.$store.state.userData.token);
    if (this.products.length == 0)
      this.products = await getProducts(this.$store.state.userData.token);

    this.getAll();
  },
  filters: {
    getDateFormat(val) {
      if (val) return moment(val).format("LLL");
      else return "Belirtilmemiş";
    },
  },
  watch: {
    currentPage(val) {
      this.getAll();
    },
    serchControl(val) {
      if (val.length == 0) this.search = val;
    },
  },
};
</script>
<style>
.tablefull {
  height: 80vh;
}
</style>
