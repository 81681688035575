<template>
  <modal
    @opened="getAll()"
    name="edit-user-perm-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">Yetkiler</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('edit-user-perm-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="pb-20 xl:h-auto xl:pb-0 bg-white dark:bg-gray-800">
      <div
        v-if="!load"
        class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto pb-20 xl:h-auto xl:pb-3"
        style="height: 30vh"
      >
        <diffInput
          class="mt-4"
          v-for="(item, index) in getModules"
          :title="item.name"
          :key="index"
          :value="getFindItem(item)"
          @change="(r) => changeItem(item, r)"
          :optList="generalPerm"
          type="select"
        />
      </div>

      <label v-if="load" class="text-black block text-center p-5">
        <i class="fas fa-spinner fa-4x fa-spin"></i>
      </label>
      <div class="px-8">
        <PaginationComponents
          v-if="totalCount > 0 && !load"
          :totalRowCount="totalCount"
          :currentPage="currentPage"
          :pageCount="pageCount"
          @changePage="(r) => (currentPage = r)"
        />
      </div>

      <div class="rounded w-full"></div>

      <div class="w-full mt-4 text-right px-4 py-2">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="saveLoad"
          @click="() => save()"
          class="w-full md:w-56"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import PaginationComponents from "@/components/general/pagination.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import axios from "axios";
import { managementUser } from "@/networking/urlmanager";
export default {
  name: "authority-modal",
  props: ["rowId"],
  components: {
    diffInput,
    asyncBtn,
    PaginationComponents,
  },
  data() {
    return {
      load: true,
      saveLoad: false,
      List: [],
      moduleList: [],
      totalCount: 1,
      currentPage: 1,
      pageCount: 2,
      generalPerm: [
        {
          id: "0",
          name: "Yetkisiz",
        },
        {
          id: "1",
          name: "Görüntüleyebilir",
        },
        {
          id: "2",
          name: "Görüntüleyebilir Ve Düzenleyebilir",
        },
      ],
    };
  },
  methods: {
    getAll() {
      this.List = [];
      this.moduleList = [];
      this.load = true;
      axios
        .get(managementUser.getPermissions + "?rowId=" + this.rowId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data[1];

          this.pageCount = 1;
          this.moduleList = res.data.data[0].slice(0, 1);

          this.totalCount = 1;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getFindItem(item) {
      const findState = this.List.find((r) => r.permtypeId == item.id);
      return findState ? findState.editState : "0";
    },
    changeItem(item, value) {
      const findState = this.List.find((r) => r.permtypeId == item.id);

      if (findState) {
        findState.editState = value;
      } else {
        this.List.push({
          editState: value,
          permtypeId: item.id,
        });
      }
    },
    save() {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        this.$t("managementUser.changePermissionText"),
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          this.saveLoad = true;
          axios
            .post(
              managementUser.changePermission,
              {
                rowId: this.rowId.toString(),
                permList: JSON.stringify(this.List),
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            })
            .finally(() => {
              this.saveLoad = false;
            });
        }
      });
    },
  },
  computed: {
    getModules() {
      return this.moduleList.slice((this.currentPage - 1) * 6, this.currentPage * 6);
    },
  },
};
</script>

<style></style>
