<template>
  <modal
    name="add-user-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("managementUser.modal.title") }}
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('add-user-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          :title="$t('managementUser.modal.username')"
          type="Text"
          v-model="username"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          :title="$t('managementUser.modal.fullname')"
          type="Text"
          v-model="fullname"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          :title="$t('managementUser.modal.email')"
          type="email"
          v-model="email"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          :title="$t('managementUser.modal.authority')"
          type="Text"
          v-model="authority"
        />
      </div>
      
      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4 md:mr-2">
          <div
            class="w-full flex relative border border-gray-300 p-3 pt-4 rounded dark:border-gray-500"
          >
            <h6
              class="absolute -top-3 bg-white pr-2 pl-2 dark:bg-gray-900 dark:text-gray-300 rounded"
            >
              {{ $t("managementUser.modal.password") }}
            </h6>
            <VuePassword
              v-model="password"
              class="w-full border-black rounded-r"
              classes="h-12 bg-gray-50 text-lg focus:ring-0 rounded-r dark:bg-gray-800 dark:border-gray-500"
              disableStrength
            />
          </div>
        </div>
        <div class="w-full md:w-6/12 mt-4 md:mr-2">
          <div
            class="w-full flex relative border border-gray-300 p-3 pt-4 rounded dark:border-gray-500"
          >
            <h6
              class="absolute -top-3 bg-white pr-2 pl-2 dark:bg-gray-900 dark:text-gray-300 rounded"
            >
              {{ $t("managementUser.modal.rePassword") }}
            </h6>
            <VuePassword
              v-model="passwordAgain"
              class="w-full border-black rounded-r"
              classes="h-12 bg-gray-50 text-lg focus:ring-0 rounded-r dark:bg-gray-800 dark:border-gray-500"
              disableStrength
            />
          </div>
        </div>
      </div>

      <div class="rounded w-full"></div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components
import VuePassword from "vue-password";

import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import supplyInput from "@/components/devItem/supplyInput.vue";

import axios from "axios";
import { managementUser } from "@/networking/urlmanager";
export default {
  components: {
    VuePassword,
    diffInput,
    asyncBtn,

    supplyInput,
  },
  data() {
    return {
      load: false,
      username: "",
      fullname: "",
      email: "",
      authority: "",
      trackingState: "1",
      supplyId: "",
      password: "",
      passwordAgain: "",
    };
  },
  methods: {
    save() {
      this.load = true;
      axios
        .post(
          managementUser.add,
          {
            namesurname: this.fullname,
            username: this.username,
            authority: this.authority,
            supplyId: this.supplyId.toString(),
            email: this.email,
            password: this.password,
            userType: this.trackingState.toString(),
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.load = false;
          this.fullname = "";
          this.username = "";
          this.authority = "";
          this.email = "";
          this.password = "";
          this.userType = "";

          this.$emit("refresh", true);
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.$modal.hide("add-user-modal");
          });
        })
        .catch((err) => {
          this.load = false;
          this.errorBox(err.response);
        });
    },
  },
};
</script>

<style></style>
