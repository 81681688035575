<template>
  <div class="md:h-screen h-full w-full">
    <form class="p-10 h-full bg-white dark:bg-gray-900 shadow-sm">
      <div class="w-full">
        <div class="w-full border-b border-gray-400 dark:border-gray-200 p-2 pb-3">
          <h4 class="text-black dark:text-gray-100">
            {{ $t("profile.profile") }}
            <!-- <i class="fas fa-spinner fa-spin" v-if="getLoad"></i> -->
          </h4>
        </div>
      </div>

      <div class="md:mt-5 mt-3">
        <div class="md:flex">
          <div class="w-full md:w-6/12 md:mt-0 mt-4 md:mr-2">
            <diffInput
              :title="$t('profile.username')"
              :value="$store.state.userData.username"
              type="Text"
              :disabled="true"
            />
          </div>
          <div class="w-full md:w-6/12 md:mt-0 mt-4">
            <diffInput
              :value="$store.state.userData.fullname"
              :title="$t('profile.fullname')"
              type="Text"
              :disabled="true"
            />
          </div>
        </div>

        <div class="md:flex md:mt-5">
          <div class="w-full md:w-6/12 md:mt-0 mt-4 md:mr-2">
            <diffInput
              :title="$t('profile.email')"
              :value="$store.state.userData.email"
              type="mail"
              :disabled="true"
            />
          </div>
          <div class="w-full md:w-6/12 md:mt-0 mt-4">
            <diffInput
              :value="$store.state.userData.phone"
              :title="$t('profile.phone')"
              type="Text"
              :disabled="true"
            />
          </div>
        </div>

        <div class="w-full md:mt-5">
          <diffInput
            :title="$t('profile.aboutus')"
            :value="$store.state.userData.explanation"
            type="textarea"
            :disabled="true"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Global components
import diffInput from "@/components/general/diffInput.vue";

export default {
  name: "profile-form",
  components: {
    diffInput,
  },
  data() {
    return {};
  },
};
</script>
