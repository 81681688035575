<template>
  <diffInput
    :loading="load"
    type="select"
    :title="title ? title : $t('devItems.productTitle')"
    :optList="List"
    v-model="selected"
    :lightDark="lightDark"
  />
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import axios from "axios";
import { product } from "@/networking/urlmanager";
export default {
  name: "customer-input",
  props: ["value", "title", "lightDark"],
  components: {
    diffInput,
  },
  data() {
    return {
      load: false,
      List: [],
      selected: 0,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    getAll() {
      this.load = false;
      axios
        .get(product.getAllNotPagination, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          //this.List = result.data.data;

          if (result.data.data.length > 0) {
            this.List = [{ id: 0, name: "Tümü" }, ...result.data.data];
          } else {
            this.List = [{ id: 0, name: "Tümü" }];
          }
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.selected = this.value;

    this.getAll();
  },
  watch: {
    selected(val) {
      this.$emit("change", val);
      const findItem = this.List.find((r) => r.id == val);
      if (findItem) this.$emit("changeName", findItem.name);
      if (findItem)
        this.$emit("changeExplosiveState", findItem.typeId == 4 ? true : false);
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>

<style></style>
