<template>
  <tr colspan="3">
    <td class="table-td border-r border-opacity-20">#</td>
    <td class="table-td border-r border-opacity-20">
      <vehicleSelect v-model="vehicle" :optList="getFilterVehicle" :load="vehicleLoad"/>
    </td>
    <td class="table-td border-r border-opacity-20">
      {{ vehicle.address }}  
    </td>
    <td class="text-center">
      <button type="button" class="process-btn" @click="addItem()">
        <i class="fas fa-plus mr-1.5"></i> Ekle
      </button>
    </td>
  </tr>
</template>

<script>
import vehicleSelect from "../../../orders/components/vehicle-select.vue";
import { getLocationAll } from "../../../orders/utils/detailData";
import {calculateDistancePriority} from '../../../orders/utils/recomendation'

export default {
  name: "new-vehicle-row",
  props:["order"],
  components: {
    vehicleSelect,
  },
  data() {
    return {
      vehicle: "",
      vehicleLoad:false,
      dorse: "",
      driverName: "",
      driverTC: "",
      vehicleList: [],
    };
  },
  methods:{
    addItem(){
      this.$emit('addItem',this.vehicle);
      this.vehicle = ""
    }
  },
  computed: {
    getFilterVehicle() {
      return this.vehicleList.map((item) => {
        return {
          name: item.vehicle,
          id: item.id,
          driverName: item.driverName,
          dorse: item.dorse,
          address: item.address,
          latitude:item.latitude,
          longitude:item.longitude,
          driverTC:item.driverTC
        };
      });
    },
  },
  async created() {
    this.vehicleLoad = true
    this.vehicleList = await getLocationAll(this.$store.state.userData.token);
    this.vehicleLoad = false

  },
};
</script>
