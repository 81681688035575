<template>
  <div class="bg-gray-200 h-screen w-screen flex items-center justify-center">
    <div class="text-center md:w-6/12 px-4">
      <img src="@/assets/error-icons/warning.png" class="mb-2 mx-auto w-5/12" alt="" />
      <h1 class="text-black font-bold mb-2">YETKİLENDİRME HATASI !</h1>
      <p class="text-gray-500">
        Bu modül ile ilgili yetkiniz bulunmadığından dolayı işleminiz kısıtlandı lütfen
        yetkilendirilmiş olduğunuz modülleri ziyaret etmeyi deneyiniz !
      </p>

      <button @click="$router.go(-1)" class="btn mt-4">Geri Dön</button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
