<template>
  <div
    class="relative"
    @dblclick="editState = true"
    :style="{ 'min-width:': editState ? '100px !important' : 'auto !important' }"
  >
    <span
      v-if="!editState"
      class="border border-white px-2 py-1 rounded-xl block text-center shadow"
      :class="{
        'text-black': stateId == 2,
      }"
      :style="{
        'background-color': getColor,
      }"
    >
      {{ title }} 
    </span>

    <select
      v-if="editState"
      v-model="selected"
      @change="() => changeState(selected)"
      class="w-full border-gray-200 rounded dark:border-gray-500 dark:text-gray-300 text-black border h-10 px-4 bg-white dark:bg-gray-700"
    >
      <option v-for="item in List" :key="item.id" :value="item.id">
        {{ item.name }}
      </option>
    </select>



    
    <button
      v-if="editState"
      @click="editState = false"
      class="text-sm absolute -top-2 -right-2 text-red-white font-bold bg-red-500 rounded-full h-5 w-5"
    >
      <i class="fas fa-times"></i>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { planning,bulkPlanning } from "@/networking/urlmanager";
export default {
  name: "status-changer",
  props: ["stateId", "defaultTitle", "planId", "typeId","plaque"],
  data() {
    return {
      editState: false,
      title: "",
      load: false,
      List: [],
      selected: "",
    };
  },
  model: {
    prop: "stateId",
    event: "change",
  },
  methods: {
    getAll() {
      this.load = true;
      axios
        .get(planning.getStatusAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          if (this.typeId == 7 || this.typeId == 8) {
            this.List = result.data.data;
          } else {
            this.List = result.data.data.filter((r) => r.id != 10 && r.id != 11);
          }
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    changeState(val) {
      if (this.load == false && this.stateId != this.selected) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Planın statüsünü değiştirmek istediğinize emin misiniz ?",
          true,
          this.$t("general.confirmText"),
          this.$t("general.rejectText")
        ).then((acc) => {
          if (acc.isConfirmed) {
            axios
              .post(
                bulkPlanning.setState,
                {
                 
                  rowId: this.planId.toString(),
                  stateId: val.toString(),
                  plaque:this.plaque
                },
                {
                  headers: {
                    Authorization: "Bareer " + this.$store.state.userData.token,
                  },
                }
              )
              .then(() => {
                this.swalBox(
                  "success",
                  this.$t("general.successTitle"),
                  "",
                  false,
                  this.$t("general.OkayTitle")
                );
                this.$emit("refresh", true);
                this.editState = false;
              })
              .catch((err) => {
                this.errorBox(err.response);
              });
          }
        });
      }
    },
  },
  created() {
    this.getAll();
    this.title = this.defaultTitle;
    this.selected = this.stateId;
  },
  computed: {
    getColor() {
      let color = "";
      switch (this.stateId) {
        case 1:
          color = "#525255 !important";
          break;
        case 2:
          color = "#ffffff !important";
          break;
        case 3:
          color = "#2b2c31 !important";
          break;
        case 4:
          color = "#F3AE1C !important";
          break;
        case 5:
          color = "#3f51a3 !important";
          break;
        case 6:
          color = "#A16DAE !important";
          break;
        case 7:
          color = "#00AEEF !important";
          break;
        case 8:
          color = "#0DBC5E !important";
          break;
        case 9:
          color = "#EE302F !important";
          break;
      }
      return color;
    },
  },
  watch: {
    selected(val) {
      if (val && !this.load) {
        // const findItem = this.List.find((r) => r.id == val);
        this.$emit("change", val);
      }
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>
