<template>
  <div class="py-8 px-8 bg-gray-50 dark:bg-gray-800 mt-4 rounded shadow dark:text-white">
    <pieCart
      :labels="labels"
      v-if="!load"
      type="pie"
      :extantion="'Adet'"
      :data="series"
      title="Sevkiyat Türü Dağılımı"
    />
  </div>
</template>

<script>
import pieCart from "@/components/general/chart/pie.vue";
import axios from "axios";
import { rapor } from "@/networking/urlmanager";
export default {
  name: "price-distribution",
  components: {
    pieCart,
  },
  data() {
    return {
      series: [],
      labels: [],
      load: false,
    };
  },
  methods: {
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(
          rapor.income.transition.getTypeDistribution +
            "?startDate=" +
            this.$store.state.raporPeriot.startDate +
            "&endDate=" +
            this.$store.state.raporPeriot.endDate,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          res.data.data.forEach((r) => {
            this.series.push(r.count);
            this.labels.push(r.name);
          });
        })
        .catch((err) => {
          console.log(err);
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    "$store.state.raporPeriot.startDate"() {
      this.getAll();
    },
    "$store.state.raporPeriot.endDate"() {
      this.getAll();
    },
  },
};
</script>

<style></style>
