<template>
  <modal
    name="planning-state-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    style="z-index: 99999999999 !important"
    :scrollable="true"
  >
    <div class="items-center">
      <div
        class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
      >
        <h4 class="inline-block w-11/12 font-bold">DETAY</h4>
        <button class="p-2 w-1/12" @click="$modal.hide('planning-state-modal')">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="w-full">
        <div class="w-full flex items-center mb-5">
          <button
            class="w-6/12"
            @click="activeState = 1"
            :class="activeState == 1 && 'border-b border-red-500 pb-3'"
          >
            DURUM
          </button>
          <button
            class="w-6/12"
            @click="activeState = 2"
            :class="activeState == 2 && 'border-b border-red-500 pb-3'"
          >
            TAMAMLANANLAR
          </button>
        </div>

        <generalStatus :list="detailList" v-if="activeState == 1" />
        <!-- <complatedStatus v-else-if="activeState == 2" /> -->
        <complatedStatus @completed-vehicles="handleComplatedVehicle" v-else-if="activeState == 2" />

        <div class="text-right flex justify-end pb-2 pt-0" >
          <button
            :disalbed="load"
            @click="() => excelExport()"
            class="hover:bg-green-500 px-4 py-2 bg-green-600 text-white"
          >
            <span v-if="!load">
              <i class="fas fa-file-excel"></i>
              EXCEL'E AKTAR
            </span>
            <span v-else>
              <i class="fas fa-spinner fa-spin"></i>
              AKTARILIYOR
            </span>
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
// networki
import moment from "moment";

// local component
import linkBtn from "@/components/devItem/link-btn.vue";

//global components
import tableLoader from "@/components/general/table-loader.vue";
import diffInput from "@/components/general/diffInput.vue";
import axios from "axios";

import { tracking } from "@/networking/urlmanager.js";

import generalStatus from "./components/status.vue";
import complatedStatus from "./components/complated.vue";
export default {
  components: {
    diffInput,
    tableLoader,
    moment,
    linkBtn,
    generalStatus,
    complatedStatus,
  },
  name: "detail-table",
  props: ["detailList"],
  data() {
    return {
      load: false,
      fileLoad: false,
      List: [],
      reports: [],
      status: "",
      addDate: "",
      activeState: 1,
      completedVehicleList:[],
    };
  },
  methods: {
    excelExport() {
      this.load = true;
      axios
        .post(
          tracking.excelExport,
          {
            list:
              this.activeState == 1
                ? this.detailList.map((item) => {
                    return {
                      orderNo: item.orderNo,
                      vehicle: item.plate,
                      customer: item.planning.customer,
                      stateName: item.planning.stateName,
                      stateChangeDate: this.formattedDate(item.planning.stateChangeDate),
                    };
                  })
                : this.completedVehicleList.map((item) => {
                    return {
                      ...item,
                      date: moment(item.date).format("MMMM"),
                    };
                  }),
            type: this.activeState,
          },

          {
            responseType: "blob",
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);

          // Dosyayı indirme bağlantısını oluşturun
          const a = document.createElement("a");
          a.href = url;
          a.download = "exportedExcel.xlsx";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          // alert("İŞLEM BAŞARISIZ OLDU ANKET VERİLERİ GETİRİLEMEDİ !");
        })
        .finally(() => {
          this.load = false;
        });
    },
    formattedDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("DD MMMM YYYY HH:mm");
    },
    handleComplatedVehicle(val){
      this.completedVehicleList=val
    }
  },
};
</script>

<style>
.modal-content {
  z-index: 9999999999999 !important;
}
</style>
