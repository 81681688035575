<template>
  <div class="rounded w-full relative">
    <div
      class="w-full flex relative rounded border border-gray-300 p-2 pt-4 dark:border-gray-500"
    >
      <h6
        class="absolute bg-white pr-2 pl-2 dark:text-gray-300 rounded text-black text-sm -top-3"
      >
        Taşınamazlar
      </h6>

      <multiselect
        :options="List"
        :value="getList()"
        @select="onSelect"
        @remove="onRemove"
        :multiple="true"
        placeholder="Sonrasında taşınamayacak ürünleri seçiniz"
        label="name"
        track-by="id"
        selectLabel="EKLE"
        deselectLabel="KALDIR"
        :preselect-first="true"
        :close-on-select="false"
        :clear-on-select="false"
      >
      </multiselect>
    </div>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import axios from "axios";
import { product } from "@/networking/urlmanager";
export default {
  name: "properties-select",
  props: ["value", "rowId", "isEdit"],
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedList: [],
      List: [],
      load: false,
    };
  },
  methods: {
    onSelect(val) {
      const findIndex = this.selectedList.findIndex(
        (item) => item.id == val.id
      );
      if (findIndex > -1) {
        const item = this.selectedList[findIndex];
        if (this.isEdit && item.status == 3 && item.rowId)
          this.selectedList[findIndex].status = 1;
        if (item.status != 1) this.selectedList[findIndex].status = 2;
      } else {
        const tag = {
          id: val.id,
          name: val.name,
          status: 2,
        };
        this.selectedList.push(tag);
      }
    },
    onRemove(val) {
      const findIndex = this.selectedList.findIndex((r) => r.id == val.id);
      if (this.isEdit) this.selectedList[findIndex].status = 3;
      else this.selectedList.splice(findIndex, 1);
    },
    getList() {
      return this.selectedList.filter((r) => r.status != 3);
    },

    getAll() {
      this.load = true;
      axios
        .get(product.getAllNotPagination, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          this.List = result.data.data;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.selectedList = this.value;

    this.getAll();
  },
  watch: {
    selected(val) {
      if (val && !this.load) {
        this.$emit("change", val);
      }
    },
    value(val) {
      if (val) this.selectedList = val;
    },
  },
};
</script>

<style></style>
