<template>
  <modal
    name="add-plan-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '55%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("operations.planning.modal.newTitle") }}
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('add-plan-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full mb-2">
        <orderInput @changeObject="(item) => getDetail(item)" />
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="date"
            minlength="1"
            :required="true"
            v-model="orderDate"
            title="Sipariş Tarihi"
            :disabled="true"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <diffInput
            type="date"
            minlength="1"
            :required="true"
            v-model="complateDate"
            title="Kapanış Tarihi"
            :disabled="true"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="text"
            minlength="1"
            :required="true"
            v-model="customer"
            :disabled="true"
            title="Müşteri"
          />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            :title="$t('operations.planning.modal.billedCompany')"
            v-model="invoicedCompany"
            :disabled="true"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput type="text" :disabled="true" title="Alıcı" v-model="receivedName" />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            :disabled="true"
            title="Gönderici"
            v-model="deliveredName"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput
            type="text"
            v-model="productType"
            :required="true"
            title="Mal Sınıfı"
            :disabled="true"
          />
        </div>
        <div class="w-full mt-4 md:w-4/12 pl-2">
          <diffInput
            type="text"
            :required="true"
            v-model="amount"
            title="Miktar"
            :disabled="true"
          />
        </div>
        <div class="w-full mt-4 md:w-2/12 pl-2">
          <diffInput
            type="text"
            :required="true"
            title="Birim"
            v-model="unitType"
            :disabled="true"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-6/12">
          <diffInput
            type="datetime-local"
            v-model="fillingDate"
            :max="deliveryDate"
            :required="true"
            :title="$t('operations.planning.modal.fillingTime')"
          />
        </div>
        <div class="w-full md:w-6/12 md:pl-2">
          <diffInput
            :min="fillingDate"
            type="datetime-local"
            v-model="deliveryDate"
            :required="true"
            :disabled="true"
            :title="$t('operations.planning.modal.deliveryTime')"
          />
        </div>
      </div>

      <waypoints
        :minDate="fillingDate"
        :maxDate="deliveryDate"
        v-model="waypointsList"
        :maxTonnage="amount"
      />
      <div class="w-full mt-4">
        <routeViewiver
          v-if="
            // changeLocationState &&
            fillingPoint.lat > 0 &&
            fillingPoint.lng > 0 &&
            deliveryPoint.lat > 0 &&
            deliveryPoint.lng > 0
          "
          ref="routerViev"
          :customer="receivedName"
          :outpoint="deliveredName"
          :center="{
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          :startPosition="{
            adress: fillingPoint.adress,
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          @changeStartPosition="(val) => (fillingPoint = val)"
          @changeEndPosition="(val) => (deliveryPoint = val)"
          @changeOutpointId="(r) => (receivedName = r)"
          @changeCustomerId="(r) => (deliveredName = r)"
          @changePolyline="(r) => changePolyline(r)"
          :endPosition="{
            adress: deliveryPoint.adress,
            lat: Number(deliveryPoint.lat),
            lng: Number(deliveryPoint.lng),
          }"
          :height="'200px'"
          way
          @change="(r) => (costDistribution = r)"
          :waypointsList="waypointsList"
        />
      </div>

      <div class="md:flex mt-5">
        <div class="w-full md:w-full relative">
          <diffInput
            type="text"
            :required="true"
            title="Araç"
            v-model="vehicle"
            :disabled="true"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-6/12 relative">
          <diffInput
            type="text"
            v-model="dorse"
            :required="true"
            :disabled="true"
            :title="$t('operations.planning.modal.trailer')"
          />
        </div>
        <div class="w-full md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            v-model="personnel"
            :required="true"
            :disabled="true"
            :title="$t('operations.planning.modal.driver')"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <diffInput
            type="number"
            v-model="hakedisKilometer"
            :required="true"
            :disabled="true"
            title="Tek yön Kilometresi"
          />
        </div>
        <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
          <diffInput
            type="number"
            :required="true"
            title="Ay içi Sefer Sayısı"
            :max="99"
            :valCount="50"
            v-model="transitionCount"
            :min="1"
            :step="1"
          />
        </div>
        <!-- v-model="" -->
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <diffInput
            type="text"
            :value="Money(hakedisKilometer * transitionCount * 2)"
            :required="true"
            :disabled="true"
            title="Toplam Kilometre"
          />
        </div>
      </div>

      <div class="mt-4 md:flex items-center">
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <diffInput
            type="text"
            :value="Money(totalFuelPrice) + ' ₺'"
            :disabled="true"
            title="Yakıt Maliyeti"
          />
        </div>
        <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
          <diffInput
            type="text"
            :value="Money(otherExpensePrice) + ' ₺'"
            :disabled="true"
            title="Diğer Masraflar"
          />
        </div>
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <diffInput
            type="text"
            :disabled="true"
            :value="Money(tonnagePrice) + ' ₺'"
            title="Ton Maliyeti"
          />
        </div>
      </div>

      <div class="mt-4 md:flex items-center">
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <diffInput
            type="text"
            @click="$modal.show('add-hgs-detail-modal')"
            :value="Money(costDistribution.cost.cash) + ' ₺'"
            :disabled="true"
            :title="$t('operations.planning.modal.tollHighwayCost')"
          />
        </div>
        <div class="w-full md:w-4/12 mt-4 md:mt-0 md:px-2">
          <diffInput
            type="text"
            :value="expensivePrice + ' ₺'"
            :disabled="true"
            title="Maliyet Tutarı"
          />
        </div>
        <div class="w-full md:w-4/12 mt-4 md:mt-0">
          <diffInput
            type="price"
            v-model="totalPrice"
            title="Gerçek Satış Fiyatı"
            :max="50000000"
            :min="1"
          />
        </div>
      </div>

      <div class="mt-4 md:flex items-center">
        <div
          class="w-full mt-4 md:mt-0"
          :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12'"
        >
          <diffInput
            type="select"
            :optList="[
              {
                id: 1,
                name: 'Eklensin',
              },
              {
                id: 2,
                name: 'Eklenmesin',
              },
            ]"
            v-model="isAmortisman"
            title="Amortisman Maliyeti"
          />
        </div>
        <div
          class="w-full mt-4 md:mt-0 md:px-2"
          :class="isOneWay == 1 ? 'md:w-4/12' : 'md:w-6/12 md:pl-2'"
        >
          <diffInput
            type="select"
            :optList="[
              {
                id: 1,
                name: 'Evet',
              },
              {
                id: 2,
                name: 'Hayır',
              },
            ]"
            v-model="isOneWay"
            title="Tek Yön"
          />
        </div>
        <div class="w-full md:w-4/12 mt-4 md:mt-0" v-if="isOneWay == 1">
          <diffInput
            type="number"
            v-model="dayCount"
            title="Gün Sayısı"
            :max="1000"
            :min="1"
          />
        </div>
      </div>

      <div class="w-full mt-4">
        <diffInput
          type="textarea"
          v-model="explanation"
          :title="$t('general.note')"
          maxlength="500"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </modal>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import gmapInputVue from "@/components/general/gmapInput.vue";
import axios from "axios";
import { planning } from "@/networking/urlmanager";
import orderInput from "@/components/devItem/orderInput.vue";

// local Components
import routeViewiver from "./route-viewiver.vue";
import polyLine from "google-polyline";
import { calculator } from "./utils/cost";
import moment from "moment";
import waypoints from "./waypoints.vue";

export default {
  name: "add-plan-modal",
  components: {
    diffInput,
    asyncBtn,
    gmapInputVue,
    routeViewiver,
    orderInput,
    waypoints,
  },
  data() {
    return {
      orderNo: "",
      orderName: "",
      orderDate: "",
      complateDate: "",
      customer: "",
      invoicedCompany: "",
      receivedName: "",
      deliveredName: "",
      fillingPoint: { lat: 0, lng: 0, adress: "" },
      deliveryPoint: { lat: 0, lng: 0, adress: "" },
      productType: "",
      amount: "",
      unitType: "",
      fillingDate: "",
      deliveryDate: "",
      hakedisKilometer: "",
      transitionCount: 1,
      personnel: "",
      personnelTC: "",
      vehicle: "",
      dorse: "",
      driver: "",
      costDistribution: { cost: { cash: 0 } },
      explanation: "",

      expensivePrice: 0,
      totalPrice: 0,
      totalFuelPrice: 0,
      otherExpensePrice: 0,
      tonnagePrice: 0,
      polyline: "",

      isAmortisman: 1,
      isOneWay: 1,
      dayCount: 1,

      expenseDetail: {},
      waypointsList: [],
      getLoad: false,
      changeLocationState: false,
      explosiveState: false,
      load: false,
    };
  },
  methods: {
    resetAll() {
      this.orderNo = "";
      this.orderName = "";
      this.orderDate = "";
      this.complateDate = "";
      this.customer = "";
      this.invoicedCompany = "";
      this.receivedName = "";
      this.deliveredName = "";
      this.fillingPoint = { lat: 0, lng: 0, adress: "" };
      this.deliveryPoint = { lat: 0, lng: 0, adress: "" };
      this.productType = "";
      this.amount = "";
      this.unitType = "";
      this.fillingDate = "";
      this.deliveryDate = "";
      this.hakedisKilometer = "";
      this.transitionCount = 1;
      this.personnel = "";
      this.personnelTC = "";
      this.vehicle = "";
      this.dorse = "";
      this.driver = "";
      this.costDistribution = { cost: { cash: 0 } };
      this.recomendPrice = "";
      this.realSalePrice = "";
      this.explanation = "";
      this.expensivePrice = 0;
      this.polyline = "";
    },
    getDetail(item) {
      this.orderNo = item.orderNo;
      this.orderName = item.name;
      this.orderDate = moment(item.orderDate).format("YYYY-MM-DD");
      this.customer = item.customer;
      this.invoicedCompany = item.invoicedCompany;
      this.receivedName = item.receivedName;
      this.deliveredName = item.deliveredName;
      this.productType = item.productType;
      this.amount = item.amount;
      this.unitType = item.unitType;
      this.fillingPoint = {
        lat: item.outpointLat,
        lng: item.outpointLng,
        adress: item.outpointAdress,
      };
      this.deliveryPoint = {
        lat: item.targetPointLat,
        lng: item.targetPointLng,
        adress: item.targetAdress,
      };

      this.vehicle = item.vehicle;
      this.dorse = item.dorse;
      this.personnel = item.driverName;
      this.personnelTC = item.driverTC;
      this.deliveryDate = item.deliveryDate
        ? item.deliveryDate
        : moment().format("YYYY-MM-DDT:HH:mm");

      this.changeLocation();
    },
    save() {
      this.load = true;
      axios
        .post(
          planning.add,
          {
            orderNo: this.orderNo,
            orderName: this.orderName,
            orderDate: this.orderDate,
            complateDate: this.complateDate,
            customer: this.customer,
            invoicedCompany: this.invoicedCompany,
            receivedName: this.receivedName,
            deliveredName: this.deliveredName,
            productType: this.productType,
            amount: this.amount,
            unitType: this.unitType,
            fillingDate: this.fillingDate,
            deliveryDate: this.deliveryDate,

            vehicle: this.vehicle ? this.vehicle : "34ABC222",
            dorse: this.dorse,
            driver: this.personnel,
            driverTC: this.personnelTC,

            kilometer: this.hakedisKilometer,
            expensePrice: this.expensivePrice,
            totalPrice: this.totalPrice,
            explanation: this.explanation,
            polyline: this.polyline,

            outpointAdress: this.fillingPoint.adress,
            outpointLat: this.fillingPoint.lat,
            outpointLng: this.fillingPoint.lng,
            targetpointAdress: this.deliveryPoint.adress,
            targetpointLat: this.deliveryPoint.lat,
            targetpointLng: this.deliveryPoint.lng,

            // expense detail
            tollsPrice: (
              this.costDistribution.cost.cash *
              this.transitionCount *
              2
            ).toFixed(2),
            fuelPrice: this.expenseDetail.fuelTotalPrice.toFixed(2),
            adbluePrice: this.expenseDetail.totalAdbluePrice.toFixed(2),
            whellPrice: this.expenseDetail.totalWhellPrice.toFixed(2),
            engineOilPrice: this.expenseDetail.totalEngineOilPrice.toFixed(2),
            filtersPrice: this.expenseDetail.TotalfilterPrice.toFixed(2),
            brakepadPrice: this.expenseDetail.totalBrakePadPrice.toFixed(2),
            diskPrice: this.expenseDetail.totalDiskPrice.toFixed(2),
            clutchPrice: this.expenseDetail.totalClutchPrice.toFixed(2),
            periodMainstancePrice: this.expenseDetail.totalperiodicMaintenanceCost.toFixed(
              2
            ),
            TrafficInsuranceAndinsurancePrice: this.expenseDetail.totalTrafficInsurancePriceAndinsurancePrice.toFixed(
              2
            ),
            otherCost: this.expenseDetail.otherCost.toFixed(2),

            isAmortisman: this.isAmortisman,
            isOneWay: this.isOneWay,
            dayCount: this.dayCount,
            transitionCount: this.transitionCount,
            waypoint: this.waypointsList,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((result) => {
          const detail = result.data.data;
          if (!detail.status) {
            this.swalBox(
              "warning",
              this.$t("general.warningTitle"),
              result.data.message,
              false,
              this.$t("general.OkayTitle")
            ).then(() => {
              this.resetAll();
              this.load = false;
              this.$emit("refresh", true);
              this.$modal.hide("add-plan-modal");
            });
          } else {
            this.swalBox(
              "success",
              this.$t("general.successTitle"),
              "",
              false,
              this.$t("general.OkayTitle")
            ).then(() => {
              this.resetAll();
              this.load = false;
              this.$emit("refresh", true);
              this.$modal.hide("add-plan-modal");
            });
          }
        })
        .catch((err) => {
          this.errorBox(err.response);
          console.log(err);
          this.load = false;
        });
    },
    changeFillingPoint(item) {
      this.changeLocationState = false;
      this.fillingPoint = item;
      this.receivedName = item.adress;
      if (this.deliveryPoint.lat > 0 && this.deliveryPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeDeliveryPoint(item) {
      this.changeLocationState = false;
      this.deliveryPoint = item;
      this.deliveredName = item.adress;

      if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    changeLocation() {
      this.changeLocationState = false;
      if (
        (this.fillingPoint.lat != 0 && this.fillingPoint.lng != 0) ||
        (this.deliveryPoint.lat != 0 && this.deliveryPoint.lng != 0)
      ) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    getTimeDetail(mins) {
      var h = (mins / 60) | 0,
        m = mins % 60 | 0;
      return h + " Saat" + " " + m + " Dakika";
    },
    changePolyline(val) {
      this.polyLine = polyLine.encode(val);
    },
    async calculatePrice() {
      let montlyKilometer = this.hakedisKilometer * this.transitionCount * 2;
      let hgsPrice =
        parseFloat(this.costDistribution.cost.cash) * this.transitionCount * 2;
      if (this.isOneWay == 1) {
        montlyKilometer = this.hakedisKilometer * this.transitionCount;
        hgsPrice = parseFloat(this.costDistribution.cost.cash);
      }
      this.expenseDetail = await calculator(
        this.$store.state.userData.token,
        1,
        montlyKilometer * 12,
        montlyKilometer,
        hgsPrice,
        this.isAmortisman == 1,
        this.isOneWay == 1,
        this.dayCount
      );

      const { total, fuelTotalPrice, totalAdbluePrice } = this.expenseDetail;

      this.totalFuelPrice = fuelTotalPrice + totalAdbluePrice;
      this.otherExpensePrice = total - this.totalFuelPrice;

      if (this.isOneWay == 1) {
        this.tonnagePrice = (total / montlyKilometer / 27) * this.hakedisKilometer;
      } else {
        this.tonnagePrice = (total / montlyKilometer / 27) * this.hakedisKilometer * 2;
      }
      this.expensivePrice = total;
    },
  },
  watch: {
    async transitionCount() {
      await this.calculatePrice();
    },
    async costDistribution(val) {
      this.hakedisKilometer = val.totalKilometer;
      await this.calculatePrice();
    },
    async isAmortisman() {
      await this.calculatePrice();
    },
    isOneWay() {
      this.calculatePrice();
    },
    dayCount() {
      this.calculatePrice();
    },
  },
};
</script>

<style></style>
