<template>
  <div class="px-2 max-h-96 overflow-y-auto">
    <div>
      <cardItem
        title="YAKIT GİDERİ"
        :value="Money(detail.fuel.totalprice) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showFeulBreakOne = !showFeulBreakOne"
        :loading="loadFuelBreak"
      />
      <fuelBreakOne v-if="showFeulBreakOne" @loading="(r) => (loadFuelBreak = r)" />
    </div>
    <div>
      <cardItem
        title="BAKIM ONARIM GİDERİ"
        :value="Money(detail.repair) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showRepairBreakOne = !showRepairBreakOne"
        :loading="loadRepairBreak"
      />
      <repairBreakOne v-if="showRepairBreakOne" @loading="(r) => (loadRepairBreak = r)" />
    </div>
    <div>
      <cardItem
        title="YIKAMA GİDERİ"
        :value="Money(detail.wash) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showWashBreakOne = !showWashBreakOne"
        :loading="loadWashBreak"
      />
      <washBreakOne v-if="showWashBreakOne" @loading="(r) => (loadWashBreak = r)" />
    </div>
    <div>
      <cardItem
        title="HGS VE ÜCRETLİ GEÇİŞ GİDERİ"
        :value="Money(detail.transition + detail.hgs) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showHgsBreak = !showHgsBreak"
        :loading="loadHgsBreak"
      />
      <hgsBreakOne
        v-if="showHgsBreak"
        :hgs="detail.hgs"
        :transition="detail.transition"
        @loading="(r) => (loadHgsBreak = r)"
      />
    </div>
    <div>
      <cardItem
        title="GENEL GİDERLER"
        :value="Money(detail.generalExpense) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
      />
    </div>
    <div>
      <cardItem
        title="TAŞERON GİDERLERİ"
        :value="Money(getSubscontructor) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showSubConstructorBreakOne = !showSubConstructorBreakOne"
        :loading="loadSubConstructorBreakOne"
      />
      <subContructorStepOne
        v-if="showSubConstructorBreakOne"
        :detail="detail.subscontructor"
        @loading="(r) => (loadSubConstructorBreakOne = r)"
      />
    </div>
    <div>
      <cardItem
        title="PERSONEL GİDERLERİ"
        :value="Money(detail.personnel) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showPersonnelBreakOne = !showPersonnelBreakOne"
        :loading="loadPersonnelBreakOne"
      />
      <personnelBreakOne
        v-if="showPersonnelBreakOne"
        @loading="(r) => (loadPersonnelBreakOne = r)"
      />
    </div>
    <div>
      <cardItem
        title="KİRA GİDERLERİ"
        :value="Money(detail.rent) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showRentBreakOne = !showRentBreakOne"
        :loading="loadRentBreakOne"
      />
      <rentBreakOne
        v-if="showRentBreakOne"
        :detail="detail"
        @loading="(r) => (loadRentBreakOne = r)"
      />
    </div>
  </div>
</template>

<script>
import cardItem from "@/components/general/card.item.vue";
import fuelBreakOne from "./fuel/break.one.vue";
import hgsBreakOne from "./hgs/break.one.vue";
import repairBreakOne from "./repair/break.one.vue";
import washBreakOne from "./wash/break.one.vue";
import subContructorStepOne from "./subconstructor/break.one.vue";
import personnelBreakOne from "./personnel/break.one.vue";
import rentBreakOne from "./rent/break.one.vue";
export default {
  name: "expense-break-step-one",
  props: ["detail"],
  components: {
    cardItem,
    fuelBreakOne,
    repairBreakOne,
    washBreakOne,
    subContructorStepOne,
    personnelBreakOne,
    rentBreakOne,
    hgsBreakOne,
  },
  data() {
    return {
      showFeulBreakOne: false,
      loadFuelBreak: false,

      showRepairBreakOne: false,
      loadRentBreakOne: false,
      loadPersonnelBreakOne: false,
      loadSubConstructorBreakOne: false,
      loadHgsBreak: false,
      loadWashBreak: false,
      loadRepairBreak: false,
      showWashBreakOne: false,
      showSubConstructorBreakOne: false,
      showPersonnelBreakOne: false,
      showRentBreakOne: false,
      showHgsBreak: false,
    };
  },
  computed: {
    getSubscontructor() {
      let total = 0;
      if (this.detail.subscontructor.length) {
        this.detail.subscontructor
          .map((r) => r.total - r.expense)
          .forEach((el) => {
            total += el;
          });
      }

      return total;
    },
  },
};
</script>

<style></style>
