<template>
  <div
    class="fixed xl:top-16 top-0 h-screen xl:h-full w-full bg-white p-5 shadow xl:w-72"
    style="z-index: 22 !important"
    v-if="show"
  >
    <div class="w-full border-b py-2 relative flex justify-between" v-if="detail">
      <button class="text-black text-3xl">Geri</button>
      <span
        ><span class="font-bold block mt-2">{{ detail.title }}</span>
        {{ $t("vehicleTracking.licensePlateDetail") }}</span
      >
      <button
        @click="hideModal()"
        class="text-black rounded-full w-7 h-7 flex items-center justify-center"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div
      class="w-full py-2 overflow-y-scroll"
      v-if="detail"
      style="height: 80vh !important"
    >
      <div class="w-full flex items-center justify-between mb-5">
        <button
          @click="activeMenu = 1"
          :class="{
            'bg-red-500 text-white': activeMenu == 1,
            'w-4/12 bg-gray-200': Number(detail.state) == 1,
            'w-6/12 bg-gray-200': Number(detail.state) == 0,
          }"
          class="text-xs shadow-sm rounded-l p-2"
        >
          GENEL
        </button>
        <button
          @click="activeMenu = 2"
          v-if="Number(detail.state) == 1"
          :class="
            activeMenu == 2
              ? 'bg-red-500 text-white'
              : Number(detail.state) == 0
              ? 'bg-gray-50 bg-opacity-60 text-opacity-40 text-black'
              : 'bg-gray-200'
          "
          class="text-xs shadow-sm w-4/12 p-2"
        >
          MEVCUT
        </button>
        <button
          @click="activeMenu = 3"
          :class="{
            'bg-red-500 text-white': activeMenu == 3,
            'w-4/12 bg-gray-200': Number(detail.state) == 1,
            'w-6/12 bg-gray-200': Number(detail.state) == 0,
          }"
          class="text-xs shadow-sm rounded-r w-4/12 p-2"
        >
          GEÇMİŞ
        </button>
      </div>

      <div v-if="activeMenu == 1 && detail">
        <detailItem title="Görev Durumu" :value="detail.state != 0 ? 'Evet ' : 'Hayır'" />

        <detailItem
          title="Sürücü"
          :value="detail.driverName ? detail.driverName : 'Belirlenmedi'"
        />
        <detailItem title="Sürüş Süresi" :value="detail.takoDate" />

        <detailItem title="Dorse" :value="detail.dorse ? detail.dorse : 'Belirlenmedi'" />
        <detailItem
          title="Taşıma Kapasitesi"
          :value="detail.capacity ? detail.capacity + ' Ton' : 'Dorse Yok'"
        />
        <detailItem
          title="Yakıt Seviyesi"
          :value="detail.canbusFuelLevel > 0 ? detail.canbusFuelLevel + ' %' : '0%'"
        />
        <detailItem title="Araç Hızı" :value="detail.speed + 'km / h'" />
        <detailItem title="Bölge" :value="detail.region" />
      </div>
      <currentDetail
        @selectedActiveDetail="(val) => changeDetail(val)"
        :item="detail"
        v-else-if="activeMenu == 2"
      />
      <otherProducts :vehicleId="detail.vehicle" v-else />
    </div>
  </div>
</template>

<script>
import currentDetail from "./currentDetail.vue";
import detailItem from "./detailItem.vue";
import otherProducts from "./otherProducts.vue";
import eventBus from "@/eventBus.js";

import axios from "axios";
import { tracking } from "@/networking/urlmanager";
export default {
  name: "vehicle-tracking",
  props: ["detail"],
  components: {
    currentDetail,
    detailItem,
    otherProducts,
  },
  data() {
    return {
      List: [],
      load: false,
      selected: [],
      activeMenu: 1,
      show: false,
    };
  },
  methods: {
    changeDetail(val) {
      this.$emit("selectedActiveDetail", val);
    },
    showModal() {
      this.show = true;
      this.activeMenu = 1;
    },
    hideModal() {
      this.show = false;
      this.$emit("closeModal", true);
    },
    getAll() {
      axios
        .get(tracking.getGroupAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((response) => {
          const list = response.data.data;
          this.List = list;
          this.selected = list[0];
          this.load = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    selected(val) {
      this.$emit("selected", val);
    },
  },
};
</script>
