<template>
  <div class="w-full py-4">
    <h4 class="mb-2 border-b pb-2 font-bold" v-if="List.length > 0">ARA NOKTALAR</h4>

    <div
      class="w-full items-center justify-center max-h-60 overflow-y-auto"
      v-if="addState"
    >
      <div class="w-full flex">
        <div class="w-2/12 mt-3">
          <diffInput
            step="1"
            min="1"
            max="40"
            type="number"
            :hideContainer="true"
            title="Sıra"
            :disabled="true"
            v-model="no"
          />
        </div>
        <div class="w-10/12 mt-3 ml-2">
          <gmapInputVue
            @change="(val) => (position = val)"
            :defaultAdress="position.adress"
            title="Konum"
            :hideMap="true"
            :hideContainer="true"
          />
        </div>
      </div>
      <div class="w-full flex">
        <div class="w-3/12 mt-3">
          <diffInput
            step="1"
            min="1"
            type="number"
            title="Miktar"
            v-model="amount"
            :hideContainer="true"
          />
        </div>
        <div class="w-5/12 mt-3 ml-2">
          <diffInput
            title="Tarih"
            type="datetime-local"
            v-model="date"
            :hideContainer="true"
            :min="minDate"
            :max="maxDate"
          />
        </div>
        <div class="w-4/12 mt-3 ml-2 text-right pr-6 border py-1">
          <button
            type="button"
            @click="save()"
            class="text-green-500 mr-2 py-1 px-2 rounded border border-green-500 text-sm"
          >
            <i class="fas fa-check"></i>
          </button>
          <button
            type="button"
            @click="addState = false"
            class="text-red-500 py-1 px-2 rounded border border-red-500 text-sm"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>

    <div
      class="w-full border-b py-2 px-2 my-2"
      v-if="List.length > 0"
      style="background-color: #3a3a3a"
    >
      <div
        class="flex items-center pb-2 justify-center border-t"
        v-for="(item, index) in List"
        :key="index"
      >
        <div class="w-2/12 border-r text-white">{{ item.no }}.Numara</div>
        <div class="w-3/12 border-r pl-3 text-sm text-white">
          {{ item.position.adress }}
        </div>
        <div class="w-2/12 border-r pl-3 text-white">{{ item.amount }} TON</div>
        <div class="w-4/12 border-r pl-3 text-sm text-white">
          {{ item.date | dateFormatter }}
        </div>
        <div class="w-1/12 mt-3 ml-2 text-center">
          <button
            type="button"
            @click="removeItem(index)"
            class="text-red-500 py-1 px-2 rounded border border-red-500 text-sm"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <button
      v-if="!addState"
      type="button"
      @click="addState = true"
      class="w-full p-2 bg-red-700 text-white rounded mt-2"
    >
      Yeni Ara Nokta
    </button>
  </div>
</template>

<script>
import gmapInputVue from "@/components/general/gmapInput.vue";
import { calculateDistancePriority } from "../../orders/utils/recomendation";
import diffInput from "@/components/general/diffInput.vue";
import moment from "moment";

export default {
  props: ["value", "maxTonnage", "maxDate", "minDate"],
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    diffInput,
    gmapInputVue,
  },
  data() {
    return {
      List: [],

      tonnage: 0,
      // add
      addState: false,
      no: 1,
      name: 0,
      position: { lat: 0, lng: 0, adress: "" },
      amount: 0,
      date: "",
    };
  },
  methods: {
    save() {
      try {
        if (this.no <= 0) {
          alert("ARA NOKTALARDA SIRALAMA 1 DEN BAŞLAMALIDIR!");
          return;
        }

        if (!this.date) {
          alert("Tarih Alanı boş bırakılamaz!");
          return;
        }

        if (!this.position.adress) {
          alert("Ara noktanın konumunu seçmelisiniz!");
          return;
        }
        if (moment(this.date).diff(this.maxDate, "minutes") > 1) {
          alert(
            "Ara nokta için planlanan süre operasyonunun tamamlanma süresinden büyük olamaz!"
          );
          return;
        }

        if (moment(this.date).diff(this.minDate, "minutes") < 1) {
          alert(
            "Ara nokta için planlanan süre operasyonun başlangıç süresinden küçük olamaz!"
          );
          return;
        }

        if (this.List.length > 0) {
          const item = this.List[this.List.length - 1];

          const oldItemDate = new moment(item.date);

          const distance =
            calculateDistancePriority(
              {
                startLat: item.position.lat,
                startLng: item.position.lng,
              },
              {
                latitude: this.position.lat,
                longitude: this.position.lng,
              }
            ) / 1000; // metre to km

          const diff = moment(this.date).diff(oldItemDate, "minutes");

          const targetHour = (distance / 60) * 60;

          if (diff < 0) {
            alert(
              "Lütfen dikkat edin, daha önceden belirlenmiş teslimat tarihinden önce bir teslimat yapmamız mümkün değildir."
            );
            return;
          } else if (diff < targetHour) {
            alert(
              "Lütfen dikkat edin, daha önceden belirlenmiş teslimat minimum " +
                moment(oldItemDate).add(targetHour, "minutes").format("LLL") +
                " önce bir teslimat yapmamız mümkün değildir."
            );
            return;
          }
        }

        this.tonnage += parseFloat(this.amount);

        if (this.tonnage > this.maxTonnage - 1) {
          alert("ARA NOKTALARA BIRAKILACAK ÜRÜN MİKTARI TOPLAM MİKTARDAN FAZLA OLAMAZ");
          this.tonnage -= parseFloat(this.amount);
          return;
        }

        this.List.push({
          no: this.no,
          name: this.name,
          position: this.position,
          amount: this.amount,
          date: this.date,
        });

        this.no = this.List.length + 1;
        this.name = "";
        this.position = { adress: "" };
        this.amount = "";
        this.date = "";
      } catch (error) {
        console.log(error);
        alert("Ara nokta eklenemedi!");
      }
    },
    removeItem(val) {
      try {
        if (confirm("ARA NOKTAYI SİLMEK İSTEDİĞİNİZE EMİN MİSİNİZ")) {
          this.tonnage -= parseFloat(this.List[val].amount);
          this.List.splice(val, 1);

          setTimeout(() => {
            this.List = this.List.map((item, index) => {
              return {
                no: index + 1,
                name: item.name,
                position: item.position,
                amount: item.amount,
                date: item.date,
              };
            });

            this.no = this.List.length + 1;
          }, 50);
        }
      } catch (error) {
        console.log(error);
        alert("Ara nokta silinemedi!");
      }
    },
  },
  created() {
    this.List = [];
  },
  filters: {
    dateFormatter(val) {
      return moment(val).format("LLLL");
    },
  },
  watch: {
    value(val) {
      this.List = val;
    },
    List(val) {
      this.$emit("change", val);
    },
  },
};
</script>
