<template>
  <div>
    <div class="md:flex items-center">
      <div class="w-full text-left">
        <div class="w-full md:flex items-center justify-between">
          <div class="w-4/12">
            <button
              @click="() => $emit('save', true)"
              class="bg-red-700 text-white py-2 text-center rounded-md w-44"
            >
              <i class="fas fa-plus mr-2"></i
              >{{ $t("operations.planning.newBtnTitle") }}
            </button>
          </div>

          <div class="w-4/12">
            <searchInput
              v-model="search"
              @searcing="() => $emit('searcing', true)"
              inputClass="w-full  "
            />
          </div>

          <div class="justify-end flex w-4/12">
            <button
              @click="$emit('changePage', true)"
              class="px-6 py-2.5 rounded border-b-4 bg-white shadow mr-2 text-sm"
              :class="activeState == 1 && 'border-red-700 text-red-700'"
            >
              Planlama
            </button>
            <button
              @click="$emit('changePage', true)"
              class="px-6 py-2.5 rounded border-b-4 bg-white shadow mr-2 text-sm"
              :class="activeState == 2 && ' border-red-700 text-red-700'"
            >
              Toplu Planlama
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchInput from "@/components/general/search-input.vue";

export default {
  props: ["activeState"],
  components: {
    searchInput,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      search: "",
    }
  },
  watch: {
    search(val) {
      this.$emit("change", val);
    },
    value(val) {
      this.search = val;
    },
  },
};
</script>