<template>
  <div class="bg-gray-200 h-80 w-screen flex items-center justify-center">
    <div class="text-center md:w-6/12 px-4">
      <img src="@/assets/error-icons/warning.png" class="mb-2 mx-auto w-5/12" alt="" />
      <h1 class="text-black font-bold my-4">
        HERHANGİ BİR MODÜL İLE İLGİLİ YETKİLENDİRMENİZ BULUNMUYOR
      </h1>
      <p class="text-gray-500">
        Herhangi bir modül ile ilgili yetkilendirilmediğinizden dolayı bu sayfaya
        yönlendirildiniz lütfen yöneticiniz ile iletişime geçerek yetki talep etmeyi
        deneyiniz !
      </p>

      <button @click="signOut()" class="btn mt-4">Çıkış Yap</button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
