<template>
  <div class="h-full p-4 bg-gray-50 dark:bg-gray-800 rounded shadow-sm mt-4">
    <div class="w-full">
      <div class="md:flex items-center">
        <div class="w-full md:w-9/12 flex">
          <div class="w-full p-2 flex items-center dark:text-gray-400">
            <i class="bg-red-600 w-3 rounded h-9 mr-2"></i>
            <h5>Müşteri Gelir Dağılımı</h5>
          </div>
        </div>
        <searchInput v-model="search" @searcing="() => getFilter()" />
      </div>

      <div
        class="shadow overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4"
        style="min-height: 300px !important; max-height: 500px !important"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-white">
            <tr>
              <th scope="col" class="table-th text-left">#</th>
              <th scope="col" class="table-th text-left border-l">Müşteri</th>
              <th scope="col" class="table-th text-left border-l">Toplam</th>
            </tr>
          </thead>
          <tbody
            class="divide-y divide-gray-200 tbody-bg-color cursor-pointer select-none"
          >
            <tr class="pt-8" v-for="(item, index) in getFilter()" :key="index">
              <td class="table-td">{{ index + 1 }}</td>
              <td class="table-td border-l">{{ item.name ? item.name : item.id }}</td>
              <td class="table-td border-l">{{ Money(item.total) }} ₺</td>
            </tr>
            <tableLoader :load="load" :length="List.length" colspan="3" />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import tableLoader from "@/components/general/table-loader.vue";
import searchInput from "@/components/general/search-input.vue";

import axios from "axios";
import { rapor } from "@/networking/urlmanager";
export default {
  name: "customer-distribution-table",
  components: {
    searchInput,
    tableLoader,
  },

  data() {
    return {
      List: [],
      load: false,
      search: "",
    };
  },
  methods: {
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(
          rapor.income.transition.getCustomerDistribution +
            "?startDate=" +
            this.$store.state.raporPeriot.startDate +
            "&endDate=" +
            this.$store.state.raporPeriot.endDate,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getFilter() {
      return this.List.filter((r) => {
        if (r.name)
          return r.name.toString().toLowerCase().includes(this.search.toLowerCase());
        else return r.id.toString().toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    "$store.state.raporPeriot.startDate"() {
      this.getAll();
    },
    "$store.state.raporPeriot.endDate"() {
      this.getAll();
    },
  },
};
</script>

<style></style>
