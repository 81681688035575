<template>
  <span class="w-full relative block">
    <SideBarItem
      @getAll="() => ((selectedFleet = 0), (selectedGroup = 0), (search = ''), getAll())"
      @selected="(r) => ((selectedFleet = r), getAll())"
      @selectedGroup="(r) => ((selectedGroup = r), getAll())"
      @selectedItem="(val) => setPlaqueSelected(val)"
      @closeModal="() => resetItemDetail()"
    />
    <ProductSideBarItem
      ref="prodDetail"
      :detail="detail"
      @closeModal="() => resetItemDetail()"
      @selectedActiveDetail="(val) => (selectedActiveDetail = val)"
    />

    <FilterPanel
      :detail="filter"
      :dataDetail="markers"
      ref="filterPanel"
      @change="(r) => getFilterAll(r)"
    />

    <GoupDetailPanel
      @close="hoverList = []"
      :List="hoverList"
      v-if="hoverList.length > 0"
      @selected="(val) => setPlaqueSelected(val)"
    />

    <detailModal :detailList="markers" />

    <button
      class="bg-gray-800 mr-2 p-1 h-20 w-20 text-xs rounded-xl shadow-2xl absolute bottom-20 right-3 z-50 flex items-center justify-center"
      :class="load ? 'text-white' : sync.state ? 'text-green-500' : 'text-red-500'"
      v-if="showSyncBtn"
    >
      <span v-if="load || filterLoad">
        <i class="fas fa-spinner fa-3x fa-spin"></i>
      </span>
      <span v-else-if="!sync.state" title="Senkronize Edilemedi !, Tekrar Deneniyor">
        <i class="fas fa-exclamation-triangle fa-3x animate-pulse"></i>
      </span>
      <span v-else :title="sync.date">
        <i class="fas fa-check fa-3x"></i>
      </span>
    </button>
    <div
      class="fixed right-64 z-10 w-3/12 flex items-center"
      style="right: 290px; top: 75px"
    >
      <button
        @click="() => (mapStatus = !mapStatus)"
        class="px-2 ml-2 h-10 w-14 rounded-lg"
        :class="mapStatus == false ? 'bg-black ' : 'bg-white'"
      >
        <i
          class="fas fa-globe text-xl"
          :class="mapStatus == false ? 'text-white' : 'text-black'"
        ></i>
      </button>

      <button
        @click="() => $modal.show('planning-state-modal')"
        class="ml-2 mr-2 bg-white rounded-md shadow-xl w-14 h-10 px-2"
      >
        <i class="fas fa-search"></i>
      </button>

      <input
        type="search"
        class="w-full p-2 border-b-2 rounded focus:outline-none bg-white border-red-500"
        placeholder="Aramak için yazınız..."
        v-model="search"
        @keypress.enter="performSearchOnEnter()"
      />

      <button
        @click="() => $refs.filterPanel.showModal()"
        class="mx-2 bg-white rounded-md shadow-xl w-14 h-10 px-2"
      >
        <i class="fas fa-filter"></i>
      </button>
    </div>
    <div
      v-if="load"
      class="w-full z-20 fixed flex top-0 h-screen bg-black bg-opacity-70 items-center justify-center"
      style="left: 0%"
    >
      <i class="fas fa-spinner fa-spin fa-2x text-white"></i>
    </div>

    <GmapMap
      ref="gmap"
      :center="position"
      :zoom="zoomLevel"
      :map-type-id="!mapStatus ? 'hybrid' : 'roadmap'"
      style="width: 100%; height: 100vh !important"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      }"
    >
      <GmapCluster @click="handleClusterMouseOver" v-if="!selectedItem">
        <GmapMarker
          v-for="(m, index) in filterList()"
          :key="index"
          :title="m.title"
          @click="() => showItemDetail(m)"
          :label="{
            text: m.title, // + '-' + m.speedDirection
            color: 'white',
            className:
              'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
            fontSize: '8.5px',
          }"
          :position="m.position"
          :icon="m.icon"
          rotation="30"
        >
        </GmapMarker>
      </GmapCluster>
      <!--SADECE ACTİVE MENÜ 2 DE GÖZÜKECEK-->
      <GmapMarker
        v-if="selectedItem"
        :title="selectedItem.title"
        :label="{
          text: selectedItem.title,
          color: 'white',
          className:
            'absolute bottom-3 -right-8 text-xs  px-2 text-xs font-bold bg-black rounded',
          fontSize: '8.5px',
        }"
        :position="selectedItem.position"
        :icon="selectedItem.icon"
      >
      </GmapMarker>

      <GmapMarker
        :title="
          selectedActiveDetail.state <= 3
            ? 'Yükleme Noktası'
            : selectedActiveDetail.customer
        "
        :label="{
          text:
            selectedActiveDetail.state <= 3
              ? 'Yükleme Noktası'
              : selectedActiveDetail.customer,
          color: 'white',
          className:
            'absolute top-4 px-2 rounded left-0 text-xs  px-2 text-xs font-bold bg-red-500',
          fontSize: '12px',
        }"
        :position="selectedActiveDetail.targetPoint"
        v-if="
          selectedItem &&
          selectedActiveDetail.targetPoint.lat &&
          selectedActiveDetail.targetPoint.lng
        "
      >
      </GmapMarker>

      <GmapMarker
        :title="'Yükleme Noktası'"
        :label="{
          text: 'Yükleme Noktası',
          color: 'white',
          className:
            'absolute top-4 px-2 rounded left-0 text-xs  px-2 text-xs font-bold bg-red-500',
          fontSize: '12px',
        }"
        :position="selectedActiveDetail.outpoint"
        v-if="
          selectedItem &&
          selectedActiveDetail.state > 3 &&
          selectedActiveDetail.outpoint.lat &&
          selectedActiveDetail.outpoint.lng
        "
      >
      </GmapMarker>

      <GmapPolyline
        v-if="selectedActiveDetail.polyline && selectedItem"
        v-bind:path.sync="selectedActiveDetail.polyline"
        v-bind:options="{ strokeColor: '#C43A3A' }"
      >
      </GmapPolyline>
    </GmapMap>
  </span>
</template>

<script>
import axios from "axios";
import { tracking } from "@/networking/urlmanager";
import moment from "moment";
import SideBarItem from "./sidebar.vue";
import ProductSideBarItem from "./productDetail/index.vue";
import FilterPanel from "./filter/index.vue";
import GoupDetailPanel from "./groupDetail.vue";

import detailModal from "./detail/index";
export default {
  name: "maps",

  data() {
    return {
      activeMenu: true,
      mapStatus: true,
      position: { lat: 41.015137, lng: 28.97953 },
      center: { lat: 41.015137, lng: 28.97953 },
      zoomLevel: 5,
      showDetail: false,
      markers: [],
      selectedGroup: "",
      selectedFleet: "",
      styles: {
        position: "absolute",
        top: "-20px",
      },
      filter: {
        region: { key: null, id: 1 },
        workStatus: 1,
        productId: null,
        productName: null,
        deliveryDate: { start: null, end: null },
        deliveryState: 1,
      },
      selectedActiveDetail: {
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      },
      load: false,
      isSearch: false,
      search: "",
      detail: null,
      selectedItem: null,
      hoverList: [],
      sync: {
        date: moment().fromNow(),
        state: false,
      },
      showSyncBtn: false,
      filterLoad: true,
      isRequestPending: true,
    };
  },
  components: {
    SideBarItem,
    ProductSideBarItem,
    FilterPanel,
    GoupDetailPanel,
    detailModal,
  },
  methods: {
    //Plaka arama kısmı
    performSearchOnEnter() {
      // this.filterLoad=true,
      this.isSearch = true;
      if (this.search == "") {
        this.isSearch = false;
      }
      this.getAll(true);
    },
    handleClusterMouseOver(cluster) {
      // Cluster içindeki işaretlerin isimlerini toplamak için bir dizi oluşturun
      if (typeof cluster.markers_ != "undefined") {
        this.hoverList = cluster.markers_;
      }
    },

    showItemDetail(item) {
      this.selectedItem = item;
      this.selectedActiveDetail = {
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      };
      this.hoverList = [];
      this.$refs.prodDetail.showModal();
    },
    resetItemDetail() {
      this.selectedItem = null;
      this.hoverList = [];
      this.selectedActiveDetail = {
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      };
      this.getAll(false);
    },

    filterList() {
      if (!this.selectedItem) return this.markers;
      else return [this.detail];
    },

    resetAll() {
      this.selectedItem = null;
      this.detail = null;
      this.selectedActiveDetail = {
        fillingPoint: { lat: 0, lng: 0 },
        deliveryPoint: { lat: 0, lng: 0 },
        customer: "",
      };
    },
    getAll(load = true) {
      this.load = load;
      const fleetId = this.selectedFleet ? this.selectedFleet : 0;
      const groupId = this.selectedGroup ? this.selectedGroup : 0;
      axios
        .get(tracking.getAll + "?fleetId=" + fleetId + "&groupId=" + groupId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          console.log("res.data.data:",res.data.data);
          setTimeout(() => {
            this.sync.state = true;
            this.showSyncBtn = false;
            this.load = false;
            this.sync.date = moment().format("LLL");
          }, 500);

          if (Array.isArray(res.data.data)) {
            if (res.data.data.length > 0) {
              const {
                workStatus,
                productId,
                deliveryDate,
                deliveryState,
                productName,
                customerName,
              } = this.filter;
              let list = [];
              if (this.isSearch) {
                const searchLower = this.search
                  .toString()
                  .toLocaleLowerCase("tr-TR")
                  .replace(/[\u0300-\u036f\s]/g, "");
                list = res.data.data.filter((item) => {
                  return (
                    (item.vehicle &&
                      item.vehicle
                        .toString()
                        .toLocaleLowerCase("tr-TR")
                        .replace(/[\u0300-\u036f\s]/g, "")
                        .includes(searchLower)) ||
                    (item.driverName &&
                      item.driverName
                        .toString()
                        .toLocaleLowerCase("tr-TR")
                        .replace(/[\u0300-\u036f\s]/g, "")
                        .includes(searchLower)) ||
                    (item.orderNo &&
                      item.orderNo
                        .toString()
                        .toLocaleLowerCase("tr-TR")
                        .replace(/[\u0300-\u036f\s]/g, "")
                        .includes(searchLower))
                  );
                });
                // search den boş veri dönerse grup detay tablosunu da sıfırlıyoruz.
                if (list.length === 0) this.hoverList = [];
              } else {
                list = res.data.data;
              }
              this.markers = list
                .filter((el) => {
                  return (
                    (this.filter.region.key == null ||
                      this.filter.region.key.toLowerCase().trim() ==
                        el.region.toLowerCase().trim()) &&
                    (workStatus == 1 || workStatus - 2 == Number(el.state)) &&
                    (productId
                      ? Number(productId) == 0 ||
                        this.productController(el.productId, productName)
                      : true) &&
                    (customerName ? el?.planning?.customer == customerName : true) &&
                    (deliveryState == 1 ||
                      (deliveryDate.end && deliveryDate.start
                        ? moment(deliveryDate.start).isSameOrBefore(
                            moment(el.deliveryDate)
                          ) &&
                          moment(deliveryDate.end).isSameOrAfter(moment(el.deliveryDate))
                        : moment(deliveryDate.end).isSame(moment(el.deliveryDate))))
                  );
                })
                .map((el) => {
                  let icon = null;

                  if (el.speed == 0) {
                    icon = require("@/assets/marker/2.png");
                  } else {
                    switch (el.speedDirection) {
                      case 0:
                        icon = require("@/assets/marker/0.png");
                        break;
                      case 1:
                        icon = require("@/assets/marker/1.png");
                        break;
                      case 3:
                        icon = require("@/assets/marker/3.png");
                        break;
                      case 4:
                        icon = require("@/assets/marker/4.png");
                        break;
                      default:
                        icon = require("@/assets/marker/5.png");
                        break;
                    }
                  }

                  // if (el.speedDirection == 0) icon = "@/assets/marker/0.png";

                  return {
                    ...el,
                    id: el.id,
                    title: el.vehicle,
                    region: el.region,
                    position: {
                      lat: parseFloat(el.latitude),
                      lng: parseFloat(el.longitude),
                    },
                    adress: el.address,
                    vehicle: el.vehicle,
                    dorse: el.dorse,
                    driverName: el.driverName,
                    state: el.state,
                    isBulkOperation: el.isBulkOperation,
                    driver: el.driverDetail,
                    outpointLat: el.outpointLat,
                    outpointLng: el.outpointLng,
                    targettLat: el.targettLat,
                    targettLng: el.targettLng,
                    deliveryDate: el.deliveryDate,
                    deliveryTime: el.deliveryTime,
                    stateId: el.stateId,
                    planning: el.planning,
                    speedDirection: el.speedDirection,
                    canbusFuelLevel: el.canbusFuelLevel,
                    batteryLevel: el.batteryLevel,

                    takoDate: el.driverDetail
                      ? moment
                          .utc(el.driverDetail.totalRunTime - 45 * 60000)
                          .format("HH:mm:ss")
                      : 0,
                    icon: icon,
                  };
                });
            }
          }
          this.isRequestPending = false;
          this.filterLoad = false;
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.showSyncBtn = this.sync.state != true;
            this.sync.state = false;
            this.load = false;
          }, 500);
          this.isRequestPending = false;

          this.errorBox(err.response);
        });
    },
    productController(val1, val2) {
      if (val1 && val2) {
        const value1 = val1.toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
        const value2 = val2.toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");

        return value1.includes(value2);
      } else {
        return false;
      }
    },
    getDetail(data) {
      setTimeout(() => {
        this.position = data.position;
        this.detail = data;
      }, 50);

      this.zoomLevel = 9;
    },
    closeDetail() {
      this.selectedItem = 0;
      this.search = "";
      // this.zoomLevel=5;
      this.detail = null;
      this.showDetail = false;
    },
    getFilter() {
      this.selectedItem = this.getFilterList[0];
    },
    getFilterAll(val) {
      this.filter = val;
      this.getAll();
    },
    setPlaqueSelected(val) {
      this.selectedItem = this.markers.find((r) => r.title.includes(val));
      this.hoverList = [];
      this.$refs.prodDetail.showModal();
    },
  },
  computed: {
    getFilterList() {
      return this.markers.filter((r) =>
        r.title
          .toLowerCase()
          .replace(/\s/g, "")
          .includes(this.search.toLowerCase().replace(/\s/g, ""))
      );
    },
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.to != "vehicleTrackingPage") {
        clearInterval(this.$store.state.intervalId);
      }
      next();
    });
    this.getAll();
    this.$store.commit(
      "setintervalId",
      setInterval(() => {
        if (!this.isRequestPending) {
          this.isRequestPending = true;
          this.getAll(false);
        }
      }, 3500)
    );
  },
  destroyed() {
    // Component yok edildiğinde interval durdurulur.
    clearInterval(this.$store.state.intervalId);
  },
  watch: {
    selectedItem(val) {
      if (val) {
        this.getDetail(this.markers.find((r) => r.id == val.id));
        this.selectedActiveDetail = {
          targetPoint: { lat: 0, lng: 0 },
          customer: "",
          polyline: [],
          state: 1,
        };
      }
    },
    selectedGroup() {
      this.getAll();
    },
    selectedFleet() {
      this.getAll();
    },
    search(val) {
      if (val.length == 0) this.performSearchOnEnter();
    },
    showSyncBtn(val) {
      if (val == true && this.sync.state)
        setTimeout(() => {
          this.showSyncBtn = false;
        }, 2500);
    },
  },
};
</script>
<style>
.gm-style > div > div > div > div > div > div > div[style*="color: rgb(254, 254, 254)"] {
  background: blue;
  border-radius: 50%;
  line-height: 20px;
  height: 20px;
  width: 20px;
  font-size: 12px !important;
  text-align: center;
  font-weight: 700;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
</style>
