<template>
  <div
    class="relative cursor-pointer select-none"
    @dblclick="unapprovedPlan()"
    :style="{
      'min-width:': editState ? '100px !important' : 'auto !important',
    }"
  >
    <span
      v-if="!editLoad && !editState"
      class="border border-white px-2 py-1 rounded-xl block text-center shadow"
      :class="{
        'text-black': stateId == 2,
      }"
      :style="{
        'background-color': getColor,
      }"
    >
      {{ title }}
    </span>

    <span
      v-if="editLoad"
      class="block w-32 p-2 border-gray-200 rounded-lg dark:border-gray-500 dark:text-gray-300 text-black border px-4 bg-white dark:bg-gray-700 items-center justify-start"
    >
      <div>
        <i class="fas fa-spinner fa-spin"></i>
        Kayıt Ediliyor
      </div>
    </span>

    <select
      v-if="!editLoad && editState"
      v-model="selected"
      @change="() => changeState(selected)"
      class="border-gray-200 w-32 rounded dark:border-gray-500 dark:text-gray-300 text-black border h-10 px-4 bg-white dark:bg-gray-700"
    >
      <option v-for="item in List" :key="item.id" :value="item.id">
        {{ item.name }}
      </option>
    </select>

    <button
      v-if="!editLoad && editState"
      @click="editState = false"
      class="text-sm absolute -top-2 -right-2 text-red-white font-bold bg-red-500 rounded-full h-5 w-5"
    >
      <i class="fas fa-times"></i>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { planning } from "@/networking/urlmanager";
export default {
  name: "status-changer",
  props: ["stateId", "defaultTitle", "planId", "typeId", "note"],
  data() {
    return {
      editState: false,
      title: "",
      load: false,
      editLoad: false,
      List: [],
      selected: "",
    };
  },
  model: {
    prop: "stateId",
    event: "change",
  },
  methods: {
    unapprovedPlan() {
      if (this.stateId == 10) {
        this.swalBox(
          "warning",
          "SÜRÜCÜ MAZERETİ",
          this.getMesage(this.note)[0]
            ? this.getMesage(this.note)[0]
            : "Mazeret bilgisi bulunamadı...",
          true,
          "Aynı Sürücüye Ver",
          "Yeni Sürücü Seç"
        ).then((acc) => {
          if (acc.isConfirmed) {
            this.editState = this.stateId !== 8;
          } else if (acc.dismiss === "cancel") {
            this.$emit("changeVehicle", true);
            this.$modal.show("change-vehicle-modal");
          }
        });
      } else {
        if (!(this.stateId == 8 || this.stateId == 9)) {
          this.editState = true;
        }
      }
    },
    getMesage(val) {
      try {
        return JSON.parse(val);
      } catch (error) {
        if (Array.isArray(val)) return val;
        else {
          return [];
        }
      }
    },
    getAll() {
      this.load = true;
      axios
        .get(planning.getStatusAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          if (this.typeId == 7 || this.typeId == 8) {
            this.List = result.data.data;
          } else {
            this.List = result.data.data;
          }
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    changeState(val) {
      if (this.load == false && this.stateId != this.selected) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Planın statüsünü değiştirmek istediğinize emin misiniz ?",
          true,
          this.$t("general.confirmText"),
          this.$t("general.rejectText")
        ).then((acc) => {
          if (acc.isConfirmed) {
            this.editLoad = true;
            axios
              .post(
                planning.setState,
                {
                  rowId: this.planId.toString(),
                  stateId: val.toString(),
                },
                {
                  headers: {
                    Authorization: "Bareer " + this.$store.state.userData.token,
                  },
                }
              )
              .then((result) => {
                const detail = result.data.data;
                if (!detail.status) {
                  this.swalBox(
                    "warning",
                    this.$t("general.warningTitle"),
                    result.data.message,
                    false,
                    this.$t("general.OkayTitle")
                  );
                } else {
                  this.swalBox(
                    "success",
                    this.$t("general.successTitle"),
                    "",
                    false,
                    this.$t("general.OkayTitle")
                  );
                }

                this.$emit("refresh", true);
                this.editState = false;
              })
              .catch((err) => {
                this.errorBox(err.response);
              })
              .finally(() => {
                this.editState = false;
                this.editLoad = false;
              });
          }
        });
      }
    },
  },
  created() {
    this.getAll();
    this.title = this.defaultTitle;
    this.selected = this.stateId;
  },
  computed: {
    getColor() {
      let color = "";
      switch (this.stateId) {
        case 1:
          color = "#525255 !important";
          break;
        case 2:
          color = "#ffffff !important";
          break;
        case 3:
          color = "#2b2c31 !important";
          break;
        case 4:
          color = "#F3AE1C !important";
          break;
        case 5:
          color = "#3f51a3 !important";
          break;
        case 6:
          color = "#A16DAE !important";
          break;
        case 7:
          color = "#00AEEF !important";
          break;
        case 8:
          color = "#0DBC5E !important";
          break;
        case 9:
          color = "#EE302F !important";
          break;
      }
      return color;
    },
  },
  watch: {
    defaultTitle(val) {
      this.title = val;
    },
    selected(val) {
      if (val && !this.load) {
        // const findItem = this.List.find((r) => r.id == val);
        this.$emit("change", val);
      }
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>
