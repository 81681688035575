<template>
  <div>
    <diffInput
      :loading="load"
      type="select"
      :title="!titleNone ? (title ? title : $t('devItems.personnelTitle')) : ''"
      :optList="filterList"
      v-model="selected"
      :lightDark="lightDark"
      :required="required"
      :disabled="disabled"
      :hideContainer="hideContainer"
      :asyncFind="asyncFind"
    />
  </div>
  <!-- :manuelSearch="true"   bu alan varken search yapılmıyordu  -->
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import { personnelManagement } from "@/networking/urlmanager";
export default {
  name: "personnel-input",
  props: [
    "value",
    "title",
    "disabled",
    "lightDark",
    "required",
    "allType",
    "hideContainer",
    "titleNone",
  ],
  components: {
    diffInput,
    Multiselect,
  },
  data() {
    return {
      load: false,
      List: [],
      selected: "",
      filterList: [],
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    async getAll() {
      this.List = [];
      this.load = true;
      try {
        const result = await axios.get(personnelManagement.getAllNotPagination, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        });

        this.List.push({
          id: 0,
          name: "SEÇİLMEDİ",
        });
        this.List.push(...result.data.data);
        this.filterList.push(...result.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.load = false;
      }
    },
    asyncFind(val) {
      this.filterList = this.List.filter((item) => {
        return item.name
          .toLocaleLowerCase("tr-TR")
          .replace(/[\u0300-\u036f\s]/g, "")
          .includes(val.toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, ""));
      });
    },
  },
  async created() {
    await this.getAll();

    const val = this.value;

    if (typeof val == "number") this.selected = val;
    else if (parseInt(val) > 0) {
      try {
        this.selected = val;
      } catch (error) {
        this.selected = this.List.find((r) => r.name == val).id;
      }
    } else {
      this.selected = this.List.find((r) => r.name == val).id;
    }
  },
  watch: {
    typeId(val) {
      if (val) this.getAll();
    },
    selected(val) {
      this.$emit("change", val);
      if (val) {
        const findItem = this.List.find((r) => r.id == val);
        this.$emit("changeObject", findItem);
        this.$emit("changeName", findItem.name);
        this.$emit("changeTC", findItem.id);
      } else {
        this.$emit("changeName", "");
        this.$emit("changeTC", "");
      }
    },
    value(val) {
      if (typeof val == "number") this.selected = val;
      else if (parseInt(val) > 0) {
        try {
          this.selected = val;
        } catch (error) {
          this.selected = this.List.find((r) => r.name == val).id;
        }
      } else {
        this.selected = this.List.find((r) => r.name == val).id;
      }
    },
  },
};
</script>

<style></style>
