<template>
  <div class="px-2" >
    <div>
      <cardItem
        title="ARAÇ KİRA TOPLAMI"
        :value="Money(detail.vehicle) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showDetail = showDetail == 1 ? 0 : 1"
        :loading="loadDetail && showDetail == 1"
      />
      <breakTwo :typeId="1" v-if="showDetail == 1" @loading="r=>loadDetail=r" />
    </div>
    <div>
      <cardItem
        title="DORSE KİRA TOPLAMI"
        :value="Money(detail.dorse) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showDetail = showDetail == 2 ? 0 : 2"
        :loading="loadDetail && showDetail == 2"

      />
      <breakTwo :typeId="2" v-if="showDetail == 2" @loading="r=>loadDetail=r" />
    </div>

        <cardItem
          title="TANK KİRA TOPLAMI"
          :value="Money(detail.tank) + ' ₺'"
          icon="fas fa-money-bill fa-3x opacity-80"
          @click="showDetail = showDetail == 3 ? 0 : 3"
            :loading="loadDetail && showDetail == 3"

        />
        <breakTwo :typeId="3" v-if="showDetail == 3" @loading="r=>loadDetail=r" />
  </div>
</template>

<script>
import cardItem from "@/components/general/card.item.vue";
import breakTwo from "./break.two.vue";
// networking
import axios from "axios";
import { rapor } from "@/networking/urlmanager";
export default {
  name: "expense-rent-step-two",
  components: {
    cardItem,
    breakTwo,
  },
  data() {
    return {
      showBreak: false,
      detail: {},
      load: false,
      showDetail: "",
      loadDetail:false,
    };
  },
  methods: {
    getDetail() {
      this.load = true;
      axios
        .get(
          rapor.expense.rent.getDistribution +
            "?startDate=" +
            this.$store.state.raporPeriot.startDate +
            "&endDate=" +
            this.$store.state.raporPeriot.endDate,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.detail = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    setScroolPosition(val){
      console.log(val)
    }
  },
  created() {
    this.getDetail();

   
  },
  watch: {
    "$store.state.raporPeriot.startDate"() {
      this.getDetail();
    },
    "$store.state.raporPeriot.endDate"() {
      this.getDetail();
    },
    load(val) {
      this.$emit("loading", val);
    },
    
  },
};
</script>

<style></style>
