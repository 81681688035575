<template>
  <modal
    name="detail-transition-vehicle-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '70%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">GEÇİŞ DETAYLARI</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('detail-transition-vehicle-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
    >
      <div
        class="shadow overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg"
        style="min-height: 300px !important; max-height: 500px !important"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-white">
            <tr>
              <th scope="col" class="table-th text-left border-l">Tarih</th>
              <th scope="col" class="table-th text-left border-l">TÜR</th>
              <th scope="col" class="table-th text-left border-l">Tutar</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 tbody-bg-color">
            <tr class="pt-8">
              <td class="table-td border-l w-32">22-06-2022</td>
              <td class="table-td border-l">YANICI GEÇİŞ</td>
              <td class="table-td border-l">385.20 ₺</td>
            </tr>
            <tr class="pt-8">
              <td class="table-td border-l w-32">20-06-2022</td>
              <td class="table-td border-l">ÇANAKKALE-BANDIRMA</td>
              <td class="table-td border-l">511.00 ₺</td>
            </tr>
            <tr class="pt-8">
              <td class="table-td border-l w-32">18-06-2022</td>
              <td class="table-td border-l">Erdek-Tekirdağ</td>
              <td class="table-td border-l">250.00 ₺</td>
            </tr>
            <tr class="pt-8">
              <td class="table-td border-l w-32">17-06-2022</td>
              <td class="table-td border-l">YANICI GEÇİŞ</td>
              <td class="table-td border-l">385.20 ₺</td>
            </tr>
            <tableLoader :load="load" :length="List.length" colspan="5" />
          </tbody>
        </table>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "vehicle-transition-detail-detail-modal",
  props: ["detail"],
  data() {
    return {
      List: [],
      load: false,
    };
  },
};
</script>

<style></style>
