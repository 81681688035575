<template>
  <modal
    name="planning-report-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '85%'"
    @opened="getAll()"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">SEVKİYAT RAPORU</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('planning-report-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <vehicleStatusModal :type="2" :Detail="selected" />
    <div
      class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
          <tr>
            <!-- <th scope="col" class="table-th text-center">
              <input type="checkbox" name="" id="" />
            </th> -->
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 150px !important"
            >
              Araç
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 150px !important"
            >
              Sürücü
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 150px !important"
            >
              Tonaj
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 150px !important"
            >
              Dolum Tarihi
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 150px !important"
            >
              Teslim Tarihi
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20 z-50"
              style="min-width: 150px !important"
            >
              Durum
            </th>

            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 200px !important"
            >
              Eklenme Tarihi
            </th>
            <th
              scope="col"
              class="table-th text-left border-r border-opacity-20"
              style="min-width: 80px !important"
            ></th>
          </tr>
        </thead>
        <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
          <tr v-for="item in List" :key="item.id">
            <!-- <td class="table-td text-center border-r border-opacity-20">
              <input type="checkbox" name="" id="" />
            </td> -->
            <td class="table-td border-r border-opacity-20">
              {{ item.plaque }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.driverName }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.tonnage }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.fillingDate | dateFormat }}
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.deliveryDate | dateFormat }}
            </td>
            <td class="table-td border-r border-opacity-20">
              <collectiveplanningstatus
                :defaultTitle="item.stateName"
                :stateId="item.stateId"
                :typeId="item.typeId"
                :planId="item.id"
                :plaque="item.plaque"
                @refresh="() => getAll()"
              />
            </td>
            <td class="table-td border-r border-opacity-20">
              {{ item.addDate | dateFormat }}
            </td>

            <td class="table-td border-r border-opacity-20">
              <button class="process-btn" @click="detailItem(item)">
                <i class="fas fa-search"></i>
              </button>
            </td>
          </tr>
          <tableLoader :load="load" :length="List.length" colspan="8" />
        </tbody>
      </table>
    </div>
    <div class="px-4">
      <PaginationComponents
        v-if="totalCount > 0"
        :totalRowCount="totalCount"
        :pageCount="pageCount"
        :currentPage="currentPage + 1"
        @changePage="(r) => (currentPage = r - 1)"
      />
    </div>
  </modal>
</template>

<script>
//Global Components
import tableLoader from "@/components/general/table-loader.vue";
import axios from "axios";
import PaginationComponents from "@/components/general/pagination.vue";
import collectiveplanningstatus from "../components/collectiveplanningstatus.vue";
import { bulkPlanning } from "@/networking/urlmanager";
import moment from "moment";
import vehicleStatusModal from "../../plan/components/vehicleStateModel/index.vue";

export default {
  props: ["rowId"],
  components: {
    tableLoader,
    PaginationComponents,
    collectiveplanningstatus,
    vehicleStatusModal,
  },
  data() {
    return {
      load: false,
      List: [],

      selected: null,
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
    };
  },
  methods: {
    detailItem(item) {
      this.selected = item;
      this.$modal.show("vehicleState-modal");
    },
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(bulkPlanning.getReportAll + "?rowId=" + this.rowId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  filters: {
    dateFormat(val) {
      return moment(val).format("LLL");
    },
  },
};
</script>

<style></style>
