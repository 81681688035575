<template>
  <div class="h-screen bg-white md:py-24 py-8" align="center">
    <img
      class="md:w-4/12 max-sm:w-full pb-4 sm:px-10 md:px-0"
      src="@/assets/auth/logo.png"
      alt=""
    />
    <h2 class="md:text-4xl text-3xl font-bold mb-2" style="color: #261f53">
      PAROLAMI UNUTTUM
    </h2>
    <h6 class="text-sm mt-4" style="color: #261f53">
      Lütfen tüm bilgileri eksiksiz olarak doldurunuz
    </h6>
    <form class="md:px-20 px-10 mt-6 sm:w-full md:w-6/12">
      <div class="w-full text-left mt-5" align="center">
        <label style="color: #a8a8a8"> Kullanıcı Adı </label>
        <input
          v-model="username"
          type="text"
          class="form-text w-full focus:ring-blue-500 focus:border-blue-500 p-2 mt-2 rounded shadow border-indigo-900 border"
          required
        />
      </div>
      <div class="w-full text-left mt-5" align="center">
        <label style="color: #a8a8a8"> E-posta </label>
        <input
          v-model="email"
          type="email"
          class="form-password focus:ring-blue-500 focus:border-blue-500 w-full p-2 mt-2 rounded shadow border-indigo-900 border"
          required
        />
      </div>
      <div class="w-full mt-2">
        <asynBtn
          type="submit"
          :bgDark="true"
          :loadState="load"
          loadTitle="Gönderiliyor"
          containerClass="w-full mt-5 asynBtn"
          icon="fas fa-sign-in-alt"
          text="Gönder"
          style="background-color: #261f53"
        />
      </div>
    </form>
    <div class="w-full mt-5 mb-5 text-center">
      <button class="hover:text-blue-900" type="button" @click="$emit('signIn')">
        <i class="fas fa-arrow-left"></i>
        Geri Dön
      </button>
    </div>
  </div>
</template>

<script>
import asynBtn from "@/components/general/asyncBtn";
export default {
  name: "forgatForm",
  components: {
    asynBtn,
  },
  data() {
    return {
      username: "",
      email: "",
      topId: "",
      defaultSecurity: "",
      securityCode: "",
      load: false,
    };
  },
};
</script>
<style>
.asynBtn:hover {
  opacity: 0.5;
}
</style>
