<template>
  <div>
    <multiselect
      v-model="selected"
      :options="vehicleList"
      :hide-selected="true"
      :loading="loading"
      label="name"
      @remove="$emit('change', 0)"
      track-by="id"
      placeholder="Seçilmedi"
      selectLabel="Seç"
      selectedLabel="Seçildi"
      deselectLabel="Seçimi Kaldır"
      openDirection="bottom"
      :disabled="disabled"
    >
    </multiselect>

  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { getLocationAll } from "../../orders/utils/detailData";

export default {
  components: {
    Multiselect,
  },
  name: "diffent-input",
  model: {
    props: "value",
    event: "change",
  },
  props: ["value", "optList", "disabled"],
  data() {
    return {
      selected: "",
      vehicleList:[],
      loading:false,
    };
  },
  methods: {
    async getAll() {
      if (this.vehicleList.length == 0) {
        this.loading = true;
        let vehicleData = await getLocationAll(
          this.$store.state.userData.token
        );
        this.vehicleList = await vehicleData.map((item) => {
          return {
            name: item.vehicle,
            id: item.id,
            driverName: item.driverName,
            dorse: item.dorse,
          };
        });

        this.selected = this.vehicleList.find((item) => item.name == this.value);
        this.loading = false;
      }
    },
  },

  watch: {
    value(val) {
      this.selected = val;
    },
    vehicleList(val){
      this.selected = val.find((item) => item.name == this.value);
    },
    selected(val) {
      this.$emit("change", val);

      if (this.vehicleList.length > 0) {
        const findItem = this.vehicleList.find((item) => item.id == val.id);

        this.$emit("changeName", val.name);
        this.$emit("changeDriverName", findItem.driverName);
        this.$emit("changeDorse", findItem.dorse);
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

