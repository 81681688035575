<template>
  <div class="w-full relative">
    <div class="w-full flex items-center justify-center" style="height: 67vh" v-if="load">
      <i class="fas fa-spinner fa-3x fa-spin"></i>
    </div>

    <div v-if="!load && markers.length">
      <GmapMap
        ref="gmap"
        :center="position"
        :zoom="zoomLevel"
        style="width: 100%; height: 67vh !important"
        :options="{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false,
        }"
      >
        <GmapMarker
          v-for="m in markers"
          :key="m.id"
          :title="m.title"
          :label="{
            text: m.title, // + '-' + m.speedDirection
            color: 'white',
            className:
              'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
            fontSize: '8.5px',
          }"
          :position="m.position"
          :icon="m.icon"
        >
        </GmapMarker>
      </GmapMap>
    </div>
    <div
      v-if="!load && markers.length == 0"
      class="w-full flex items-center justify-center"
      style="height: 50vh"
    >
      <div class="inline text-center">
        <i class="fas fa-exclamation-triangle fa-4x"></i>
        <p class="block mt-5 px-20 leading-7">
          Araç konumu bulunamadı lütfen aracın takip sisteminin doğru çalıştığından emin
          olun !
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { tracking } from "@/networking/urlmanager";
export default {
  name: "collectivelocation-location-detail",
  props: ["vehicles"],
  data() {
    return {
      position: { lat: 41.015137, lng: 28.97953 },
      center: { lat: 41.015137, lng: 28.97953 },
      zoomLevel: 5,
      showDetail: false,
      markers: [],
      styles: {
        position: "absolute",
        top: "-20px",
      },
      load: true,
    };
  },
  methods: {
    getAll(load = true) {
      this.load = load;
      axios
        .get(tracking.getAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.load = false;
          if (Array.isArray(res.data.data)) {
            if (res.data.data.length > 0) {
              let markers = res.data.data.filter((r) => {
                return this.vehicles.find(
                  (a) =>
                    a.plaque.toLowerCase().replace(/\s/g, "") == r.vehicle.toLowerCase()
                );
              });

              this.center = { lat: markers[0].latitude, lng: markers[0].longitude };
              this.position = { lat: markers[0].latitude, lng: markers[0].longitude };
              this.markers = markers.map((el) => {
                let icon = null;

                if (el.speed == 0) {
                  icon = require("@/assets/marker/2.png");
                } else {
                  switch (el.speedDirection) {
                    case 0:
                      icon = require("@/assets/marker/0.png");
                      break;
                    case 1:
                      icon = require("@/assets/marker/1.png");
                      break;
                    case 3:
                      icon = require("@/assets/marker/3.png");
                      break;
                    case 4:
                      icon = require("@/assets/marker/4.png");
                      break;
                    default:
                      icon = require("@/assets/marker/5.png");
                      break;
                  }
                }

                return {
                  id: el.id,
                  title: el.vehicle,
                  region: el.region,
                  position: {
                    lat: parseFloat(el.latitude),
                    lng: parseFloat(el.longitude),
                  },
                  adress: el.address,
                  vehicle: el.vehicle,
                  dorse: el.dorse,
                  driverName: el.driverName,
                  state: el.state,
                  isBulkOperation: el.isBulkOperation,
                  icon: icon,
                };
              });
              this.detail = this.markers[0];
              this.zoomLevel = 8;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.load = false;
          // this.errorBox(err.response);
        });
    },
    reset() {
      this.load = true;
      this.position = { lat: 41.015137, lng: 28.97953 };
      this.zoomLevel = 5;
      this.markers = [];
    },
  },
  created() {
    this.getAll();
  },
};
</script>

<style></style>
