<template>
  <div class="px-2">
    <cardItem
      v-for="item in List"
      :key="item.id"
      :title="item.name"
      :value="Money(item.total) + ' ₺'"
      icon="fas fa-money-bill fa-3x opacity-80"
      @click="(r) => $emit('openVehicleDetail', item)"
    />
  </div>
</template>

<script>
import cardItem from "@/components/general/card.item.vue";
import axios from "axios";
import { rapor } from "@/networking/urlmanager";
export default {
  name: "income-plan-step-two",
  props: ["supplyId"],
  components: {
    cardItem,
  },
  data() {
    return {
      showBreak: false,
      List: [],
      load: false,
      selected: "",
    };
  },
  methods: {
    showDetail(item) {
      this.selected = item.id;
      this.showBreak = !this.showBreak;
    },
    getDetail() {
      this.load = true;
      axios
        .get(
          rapor.income.transition.getVehicleDistribution +
            "?startDate=" +
            this.$store.state.raporPeriot.startDate +
            "&endDate=" +
            this.$store.state.raporPeriot.endDate +
            "&supplyId=" +
            this.supplyId,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getDetail();
  },
  watch: {
    "$store.state.raporPeriot.startDate"() {
      this.getAll();
    },
    "$store.state.raporPeriot.endDate"() {
      this.getAll();
    },
    load(val) {
      this.$emit("loading", val);
    },
  },
};
</script>

<style></style>
