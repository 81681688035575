<template>
  <div class="h-full p-4 bg-gray-50 dark:bg-gray-800 rounded shadow-sm mt-4">
    <div class="w-full">
      <div class="md:flex items-center">
        <div class="w-full md:w-9/12 flex">
          <div class="w-full p-2 flex items-center dark:text-gray-400">
            <i class="bg-red-600 w-3 rounded h-9 mr-2"></i>
            <h5>Araç Bazlı Gelir - Gider Dağılımı</h5>
          </div>
        </div>
        <searchInput v-model="search" />
      </div>

      <detailModal :detail="selected" />
      <div
        class="shadow overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4"
        style="min-height: 300px !important; max-height: 500px !important"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-white">
            <tr>
              <th scope="col" class="table-th text-left sticky left-0 z-20 bg-gray-800">
                Plaka
              </th>
              <th scope="col" class="table-th text-left border-l z-20">Gelir</th>
              <th scope="col" class="table-th text-left border-l z-20">Gider</th>
              <th scope="col" class="table-th text-left border-l z-20">Kazanç</th>
              <th scope="col" class="table-th text-left z-20">Y/G Oranı</th>
            </tr>
          </thead>

          <tbody
            class="divide-y divide-gray-200 bg-gray-700 cursor-pointer select-none"
            v-for="(main, mainIndex) in getList"
            :key="mainIndex"
          >
            <td class="table-td sticky left-0 bg-gray-800 uppercase z-auto border-r">
              <div class="flex items-center justify-between">
                <h3 class="text-lg">
                  {{ main[0].supplyName }}
                </h3>
              </div>
            </td>
            <td class="table-td sticky left-0 bg-gray-800 uppercase z-auto border-r">
              <h3 class="text-sm font-bold">{{ Money(getTotal(main).income) }} ₺</h3>
            </td>
            <td class="table-td sticky left-0 bg-gray-800 uppercase z-auto border-r">
              <h3 class="text-sm font-bold">{{ Money(getTotal(main).expense) }} ₺</h3>
            </td>
            <td class="table-td sticky left-0 bg-gray-800 uppercase z-auto border-r">
              <h3 class="text-sm font-bold">{{ Money(getTotal(main).total) }} ₺</h3>
            </td>
            <td
              class="table-td sticky left-0 bg-gray-800 uppercase z-auto bor text-center"
            >
              <h3 class="text-sm">{{ main.length }} Araç</h3>
            </td>

            <tr
              class="pt-8"
              v-for="(item, index) in main"
              :key="index"
              @click="showDetailModal(item)"
            >
              <td class="table-td sticky left-0 bg-gray-700 uppercase">
                {{ item.plaque }}
              </td>
              <td class="table-td border-l">{{ Money(item.income.planningIncome) }} ₺</td>
              <td class="table-td border-l">{{ Money(item.expense.total) }} ₺</td>
              <td class="table-td border-l">
                {{ Money(item.income.planningIncome - item.expense.total) }} ₺
              </td>
              <td
                class="px-4 py-2 whitespace-nowrap text-sm text-white text-center uppercase border-l"
              >
                {{ getFuelRatio(item) }}
                %
              </td>
            </tr>
          </tbody>

          <tbody v-if="load" class="bg-gray-800">
            <tableLoader :load="load" :length="List.length" colspan="5" />
          </tbody>
        </table>
      </div>
      <PaginationComponents
        v-if="totalCount > 0"
        :totalRowCount="totalCount"
        :currentPage="currentPage + 1"
        :pageCount="pageCount"
        @changePage="(r) => (currentPage = r - 1)"
      />
    </div>
  </div>
</template>

<script>
import PaginationComponents from "@/components/general/pagination.vue";
import tableLoader from "@/components/general/table-loader.vue";
import searchInput from "@/components/general/search-input.vue";

import detailModal from "./detail.vue";

import axios from "axios";
import { rapor } from "@/networking/urlmanager";
export default {
  name: "vehicle-based-table",
  components: {
    searchInput,
    tableLoader,
    detailModal,
    PaginationComponents,
  },
  data() {
    return {
      List: [],
      load: false,
      selected: {
        id: 0,
        plaque: "",
        haveType: 0,
        typeId: 0,
        supplyId: 0,
        income: { planningIncome: 0 },
        expense: {
          total: 0,
          fuelExpense: { totalprice: 0, totalcount: 0 },
          repairExpense: 0,
          washExpense: 0,
          personnelExpense: 0,
          rentExpense: { total: 0, vehicle: 0, dorse: 0, tank: 0 },
          hgsExpense: 0,
          transition: 0,
          subscontructor: 0,
        },
      },

      search: "",
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
    };
  },
  methods: {
    showDetailModal(item) {
      this.selected = item;
      this.$modal.show("detail-vehicle-modal");
    },
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(
          rapor.getVehicleDistribution +
            "?startDate=" +
            this.$store.state.raporPeriot.startDate +
            "&endDate=" +
            this.$store.state.raporPeriot.endDate +
            "&page=" +
            this.currentPage,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          console.log(err);
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getFuelRatio(item) {
      const fuelTotal = parseFloat(
        item.expense.fuelExpense.totalprice > 0 ? item.expense.fuelExpense.totalprice : 0
      );
      const totalPrice = parseFloat(
        item.income.planningIncome ? item.income.planningIncome : 0
      );

      if (fuelTotal > 0 && totalPrice > 0)
        return Math.floor((fuelTotal / totalPrice) * 100);
      else return 0;
    },
    getTotal(item) {
      let total = 0;
      let expense = 0;
      let income = 0;
      item.forEach((el) => {
        expense += el.expense.total;
        income += el.income.planningIncome;

        total += el.income.planningIncome - el.expense.total;
      });

      return {
        total,
        expense,
        income,
      };
    },
  },
  computed: {
    getList() {
      return this.List.filter((r) =>
        r.plaque.toLowerCase().includes(this.search.toLowerCase())
      ).reduce(function (rv, x) {
        (rv[x["supplyId"]] = rv[x["supplyId"]] || []).push(x);
        return rv;
      }, {});
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    "$store.state.raporPeriot.startDate"() {
      this.getAll();
    },
    "$store.state.raporPeriot.endDate"() {
      this.getAll();
    },
  },
};
</script>
