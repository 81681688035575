<template>
  <modal
    name="add-customer-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '35%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("operations.customers.modal.newCustomer") }}
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('add-customer-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full">
        <diffInput
          :title="$t('operations.customers.modal.customerName')"
          v-model="name"
          maxlength="500"
          minlength="2"
          required
          type="Text"
        />
      </div>
      <div class="w-full mt-4">
        <diffInput
          title="Öncelik Puanı (1-10 arasında)"
          v-model="priorty"
          maxlength="10"
          minlength="10"
          required
          type="number"
          :step="1"
        />
      </div>

      <div class=" mt-4">
        <diffInput
          title="Hassasiyet (metre)"
          v-model="sensibility"
          min="10"
          max="20000"
          required
          type="number"
          :step="1"
        />


      </div>
     
      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full "
        />
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components

import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

// networking
import axios from "axios";
import { customer } from "@/networking/urlmanager";

export default {
  name: "add-customer-modal",
  components: {
    diffInput,
    asyncBtn
  },
  data() {
    return {
      name: "",
      priorty:0,
      sensibility:0,
      load: false,
    };
  },
  methods: {
    changeAdress(val) {
      this.adress = val.adress;
      this.latitude = val.lat;
      this.longitude = val.lng;
    },
    save() {
      this.load = true;
      try {
        axios
          .post(
            customer.add,
            {
              name: this.name,
              priorty:this.priorty,
              sensibility:this.sensibility
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then(() => {
            this.swalBox(
              "success",
              this.$t("general.successTitle"),
              "",
              false,
              this.$t("general.OkayTitle")
            ).then(() => {
              this.name = "";
              this.priorty= "1";
              
              this.load = false;
              this.$emit("refresh", true);
              this.$modal.hide("add-customer-modal");
            });
          })
          .catch((err) => {
            this.load = false;
            this.errorBox(err.response);
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
