<template>
  <diffInput :optList="filterType" v-model="select" type="select" title="Müşteri" />
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
export default {
  name: "custoemr-filter-input",
  props: ["dataDetail"],
  model: {
    event: "change",
    prop: "value",
  },
  components: {
    diffInput,
  },
  data() {
    return {
      select: "",
      filterType: [],
    };
  },

  created() {
    this.filterType = [];

    this.dataDetail?.forEach((item, index) => {
      const findItem = this.filterType.find(
        (val) => val?.name == item?.planning?.customer
      );
      if (!findItem)
        this.filterType.push({
          id: index,
          key: index,
          name: item?.planning?.customer,
        });
    });

    this.select = this.value.id;
  },
  watch: {
    value(val) {
      this.select = val.id;
    },
    select(val) {
      if (val != undefined) {
        const findItem = this.filterType.find((item) => item.id == val);
        this.$emit("change", findItem);
        this.$emit("changeName", findItem.name);
      }
    },
  },
};
</script>

<style></style>
