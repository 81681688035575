<template>
  <div class="min-h-80 p-6 bg-gray-200 dark:bg-gray-900">
   

    <add-modal @refresh="getAll" />
    <editModal :detail="selected" @refresh="getAll" ref="editModal" />
    <authorityModal :rowId="selected.id" />
    <div class="h-full p-4 bg-gray-50 dark:bg-gray-800 rounded shadow-sm mt-4">
      <div class="w-full flex items-center dark:text-gray-400">
      <i class="fas fa-users mr-2 fa-lg"></i>
      <h5 class="font-bold text-xl">{{ $t("managementUser.pageTitle") }}</h5>
    </div>
      <div class="w-full pt-5">
        <div class="md:flex items-center">
          <div class="w-full md:w-9/12 text-left">
            <div class="w-full inline-block">
              <button
                class="btn text-center px-1 py-2"
                @click="() => $modal.show('add-user-modal')"
              >
                <i class="fas fa-plus mr-2"></i>Yeni Kullanıcı
              </button>
            </div>
          </div>
          <searchInput v-model="search" @searcing="() => getAll()" />
        </div>

        <div
          class="shadow table-height-value  overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4"

        >
          <table class="min-w-full divide-y divide-gray-200 relative">
            <thead
              class="bg-white dark:bg-gray-700 border-black border-dotted border-b-2 border-opacity-20"
              style="z-index: 9999999 !important"
            >
              <tr>
                <th scope="col" class="table-th text-left">#</th>
                <th
                  scope="col"
                  class="table-th text-left"
                  style="min-width: 200px !important"
                >
                  {{ $t("managementUser.fullname") }}
                </th>
                <th
                  scope="col"
                  class="table-th text-left"
                  style="min-width: 200px !important"
                >
                  {{ $t("managementUser.authority") }}
                </th>
                <th
                  scope="col"
                  class="table-th text-left"
                  style="min-width: 200px !important"
                >
                  {{ $t("managementUser.email") }}
                </th>
                
                <th scope="col" class="table-th text-center">
                  {{ $t("managementUser.proccess") }}
                </th>
              </tr>
            </thead>
            <tbody
              class="divide-y-2 divide-gray-500"
              style="background-color: #3a3a3a; z-index: 99 !important"
            >
              <tr v-for="(item, index) in List" :key="item.id">
                <td class="table-td text-left">{{ (index + 1) * (currentPage + 1) }}</td>
                <td class="table-td text-left">{{ item.namesurname }}</td>
                <td class="table-td text-left">{{ item.authority }}</td>
                <td class="table-td text-left">{{ item.email }}</td>
               
                
                <td class="table-td flex justify-center items-center">
                  <button class="process-btn mr-2" @click="() => showEdit(item)">
                    <i class="fas fa-pen"></i>
                  </button>

                  <button class="process-btn md:mr-2" @click="() => showPerm(item)">
                    <i class="fas fa-th-list"></i>
                  </button>

                  <button
                    class="process-btn"
                    @click="item.activeState == 1 ? passive(item) : active(item)"
                  >
                    <i
                      :class="item.activeState == 1 ? 'fas fa-trash' : 'fas fa-check'"
                    ></i>
                  </button>
                </td>
              </tr>

              <tableLoader :load="load" :length="List.length" colspan="5" />
            </tbody>
          </table>
        </div>
        <PaginationComponents
          v-if="totalCount > 0"
          :totalRowCount="totalCount"
          :currentPage="currentPage + 1"
          :pageCount="pageCount"
          @changePage="(r) => (currentPage = r - 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Global Components
import PaginationComponents from "@/components/general/pagination.vue";
import searchInput from "@/components/general/search-input.vue";
import tableLoader from "@/components/general/table-loader.vue";
import { ToggleButton } from "vue-js-toggle-button";

// Local Components
import addModal from "./components/add.modal.vue";
import editModal from "./components/edit.modal.vue";
import authorityModal from "./components/authority.modal.vue";

// netoworking
import axios from "axios";
import { managementUser } from "@/networking/urlmanager";
export default {
  name: "manamgenet-user-page",
  components: {
    //Global
    PaginationComponents,
    searchInput,
    tableLoader,

    ToggleButton,

    // //local
    addModal,
    editModal,
    authorityModal,
  },
  data() {
    return {
      List: [],
      load: false,
      safeLoad: true,
      search: "",
      totalCount: 10,
      pageCount: 2,
      currentPage: 0,

      selected: {},
    };
  },
  methods: {
    showPerm(item) {
      this.selected = item;
      this.$modal.show("edit-user-perm-modal");
    },
    showEdit(item) {
      this.selected = item;
      this.$modal.show("edit-user-modal");
    },
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(managementUser.getAll + "?page=" + this.currentPage + "&search="+this.search, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    changeSafeState(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        this.$t("managementUser.changeSafeStateText"),
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          this.safeLoad = true;
          axios
            .post(
              managementUser.changeSafeState,
              {
                rowId: item.id.toString(),
                stateId: item.safeLevel == 1 ? "0" : "1",
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              item.safeLevel = item.safeLevel == 1 ? 0 : 1;
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
            })
            .catch((err) => {
              this.errorBox(err.response);
            })
            .finally(() => {
              this.safeLoad = false;
            });
        } else {
          this.getAll();
        }
      });
    },
    passive(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        this.$t("managementUser.passiveUserText"),
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              managementUser.passive,
              {
                rowId: item.id.toString(),
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    active(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        this.$t("managementUser.activeUserText"),
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              managementUser.active,
              {
                rowId: item.id.toString(),
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    search(val){
      if(val.length == 0) this.getAll()
    }
  },
  
};
</script>

<style></style>
