import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    raporPeriot:{
      startDate:'',
      endDate:''
    },

    exchangePrice:[],

    navState:true,
    darkMode:false,
    lockNavState:true,
    firstAuth:true,
    isAuth:false,
    intervalId:0,
    userData:{
        id:'',
        fullname:'',
        email:'',
        token:'',
        typeId:1,
        explanation:'',
        profileImage:'',
    }
  },
  mutations: {
    setintervalId(state,data){
      state.intervalId=data;
    },
    setPeriot(state,data){
      state.raporPeriot=data;
    },
    setExchangePrice(state,data){
      state.exchangePrice=data;
    },
    signIn(state,data){
      state.userData=data;
      state.isAuth=true;
    },
    signOut(state){
      state.userData={id:'',fullname:'',email:'',token:'',typeId:1};
      state.isAuth=false;
    },
    setProfile(state,data){
      state.userData.fullname=data.fullname;
      state.userData.email=data.email;
      state.userData.phone=data.phone;
      state.userData.explanation=data.explanation;
    },
    setProfileImage(state,data){
      state.userData.profileImage=data;
    },


    activeDarkMode(state){
      state.darkMode=true;
    },
    activeLightMode(state){
      state.darkMode=false;
    },

    activeFirstAuth(state){
      state.firstAuth=true;
    },
    complateFirstAuth(state){
      state.firstAuth=false;
    },
    
    setNavState(state){
      state.navState=!state.navState;
      state.lockNavState=state.navState ;
    },

    hideNavState(state){
      state.navState=false;
    },


    setMouseState(state,val){
      if(!state.lockNavState) state.navState=val;
    }
    
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
