<template>
  <div>
    <diffInput
      :optList="filterType"
      v-model="select"
      type="select"
      title="Boşa Çıkma Tarihi"
    />

    <div class="w-full px-2" v-if="select == 4">
      <div class="my-4">
        <diffInput
          :max="endDate"
          v-model="startDate"
          type="date"
          title="Başlangıç"
        />
      </div>
      <diffInput :min="startDate" v-model="endDate" type="date" title="Bitiş" />
    </div>
  </div>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import moment from "moment";
export default {
  name: "delivery-input",
  props: ["value", "detail"],
  model: {
    event: "change",
    prop: "value",
  },
  components: {
    diffInput,
  },
  data() {
    return {
      select: 1,
      startDate: null,
      endDate: null,
      filterType: [
        {
          id: 1,
          name: "Tümü",
        },
        {
          id: 2,
          name: "Bu Gün",
        },
        {
          id: 3,
          name: "Yarın",
        },
        {
          id: 4,
          name: "Özel Tarih",
        },
      ],
    };
  },
  created() {
    this.select = this.value;

    this.startDate = this.detail.start;
    this.endDate = this.detail.end;
  },
  watch: {
    detail(val) {
      this.startDate = val.start;
      this.endDate = val.end;
    },
    startDate(val) {
      this.$emit("changeStartDate", val);
    },
    endDate(val) {
      this.$emit("changeEndDate", val);
    },
    value(val) {
      if(val) this.select = val;
    },
    select(val) {
      if (val) {
        if (val < 4)
          this.$emit(
            "changeEndDate",
            val == 2
              ? moment().format("YYYY-MM-DD")
              : val == 3
              ? moment().add("1", "days").format("YYYY-MM-DD")
              : null
          );
        this.$emit("change", val);
      }
    },
  },
};
</script>

<style></style>
