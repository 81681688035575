<template>
  <div class="px-2">
    <div v-for="(item, index) in detail" :key="index">
      <cardItem
        @click="
          () =>
            selected.id != index
              ? (selected = { id: index, detail: item })
              : (selected = { id: null, detail: null })
        "
        :title="item.name"
        :value="Money(item.total - item.expense) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
      />
      <stepTwo v-if="selected.id == index" :detail="selected.detail" />
    </div>
  </div>
</template>

<script>
import stepTwo from "./break.two.vue";
import cardItem from "@/components/general/card.item.vue";
export default {
  name: "expense-subscontructor-step-one",
  props: ["detail"],
  components: {
    cardItem,
    stepTwo,
  },
  data() {
    return {
      selected: {},
    };
  },
};
</script>

<style></style>
