<template>
  <div
    class="fixed xl:top-16 top-0 h-screen xl:h-full w-full bg-white p-5 z-20 xl:right-0 shadow xl:w-72"
  >
    <div class="w-full border-b py-2 relative flex justify-between">
      ARAÇ GRUPLARI
      <button @click="() => ((selected = null), $emit('getAll'))">Tümü</button>
    </div>

    <div class="w-full flex items-center justify-center">
      <button
        @click="activeMenu = 1"
        class="text-xs shadow-sm rounded-l p-2 w-6/12"
        :class="activeMenu == 1 && 'bg-red-500 text-white'"
      >
        GENEL
      </button>
      <button
        @click="activeMenu = 2"
        class="text-xs shadow-sm rounded-r p-2 w-6/12"
        :class="activeMenu == 2 && 'bg-red-500 text-white'"
      >
        MÜDAHALE ({{ totalWarningCount }})
      </button>
    </div>

    <div class="w-full py-2 overflow-y-scroll" style="height: 85vh !important">
      <div v-if="activeMenu == 1">
        <div
          class="w-full py-5 select-none cursor-pointer px-2 text-xs rounded"
          :class="
            selected == item && selectedGroup
              ? 'bg-gray-100 shadow border-b'
              : selected == item && !openSub && !selectedGroup
              ? 'border-l-4 bg-gray-100 shadow border-red-500'
              : selected == item && openSub && 'bg-gray-100 shadow'
          "
          v-for="item in List"
          :key="item.id"
        >
          <div @click="getDetail(item)">
            <i
              class="fas text-black text-opacity-50 mr-2"
              :class="item == selected && openSub ? 'fa-minus' : 'fa-plus'"
            ></i>
            {{ item.fleetName }}
          </div>
          <div class="px-4 text-black mt-5" v-if="openSub && item == selected">
            <div
              @click="selectedGroup = groupItem"
              v-for="(groupItem, index) in subList"
              :key="index"
              class="my-2 px-5 py-2 cursor-pointer select-none"
              :class="selectedGroup == groupItem && 'border-l-4 border-red-500'"
            >
              {{ groupItem.groupName }}
            </div>
          </div>
        </div>

        <div class="text-center text-xs mt-2" v-if="List.length <= 0">
          Hehrangi bir grup bilgisi bulunamadı
        </div>
      </div>

      <div v-if="activeMenu == 2">
        <div
          v-for="(item, index) in getWarning()"
          :key="index"
          @click="activeDetail(item)"
          class="w-full py-5 select-none cursor-pointer px-2 text-xs rounded bg-gray-100 shadow border-b mt-2"
          :class="selectedItem == item && 'border-l-4 border-red-500'"
        >
          {{ item.title }}
        </div>
        <div class="text-center text-xs mt-2" v-if="totalWarningCount <= 0">
          Hehrangi bir uyarı bulunamadı
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { tracking } from "../../../networking/urlmanager";
import moment from "moment";
export default {
  name: "vehicle-tracking",
  data() {
    return {
      List: [],
      subList: [],
      activeMenu: 1,
      selectedGroup: null,
      load: false,
      selected: null,
      openSub: false,
      selectedItem: null,
      totalWarningCount: 0,
    };
  },
  methods: {
    activeDetail(item) {
      this.$emit("selectedItem", item.vehicle);
    },
    getAll() {
      this.load = true;
      axios
        .get(tracking.getFleetAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((response) => {
          const list = response.data.data;
          this.List = list;
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDetail(item) {
      this.load = true;
      this.subList = [];
      this.$emit("selected", item.fleetId);
      this.selected = item;
      this.openSub = !this.openSub;
      this.selectedGroup = null;
      axios
        .get(tracking.getGroupAll + "?fleetId=" + this.selected.fleetId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((response) => {
          this.subList = response.data.data;
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },

    haversineDistance(coords1, coords2) {
      const R = 6371e3; // Dünyanın yarıçapı metre cinsinden
      const lat1Rad = (Math.PI * coords1.lat) / 180;
      const lat2Rad = (Math.PI * coords2.lat) / 180;
      const deltaLat = ((coords2.lat - coords1.lat) * Math.PI) / 180;
      const deltaLon = ((coords2.lng - coords1.lng) * Math.PI) / 180;

      const a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1Rad) *
          Math.cos(lat2Rad) *
          Math.sin(deltaLon / 2) *
          Math.sin(deltaLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c; // Sonuç metre cinsindendir.
    },
    getWarning() {
      const list = this.$parent.markers.filter((item) => {
        if (item.planning) {
          const distance =
            this.haversineDistance(
              item.position,
              item.stateId <= 3
                ? { lat: parseFloat(item.outpointLat), lng: parseFloat(item.outpointLng) }
                : { lat: parseFloat(item.targettLat), lng: parseFloat(item.targettLng) }
            ) / 1000;

          const hizDakika = 80 / 60;
          const tahminiSure = distance / hizDakika;

          var duration = moment.duration(
            moment(item.planning.targetDate).diff(moment().add(tahminiSure, "minutes"))
          );
          if (item.state == 1 && duration.asMinutes() <= 0) {
            return item;
          }
        }
      });

      this.totalWarningCount = list.length;

      return list;
    },
  },
  created() {
    this.getAll();
    this.getWarning();
  },
  watch: {
    "$parent.markers"(val) {
      if (val.length > 0) this.getWarning();
    },
    // selected(val){
    //     if(val!=null) this.$emit('selected',val.fleetId);
    //     else this.$emit('selected',null);
    // },
    selectedGroup(val) {
      if (val != null) this.$emit("selectedGroup", val.groupId);
      else this.$emit("selectedGroup", null);
    },
  },
};
</script>
