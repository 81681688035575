<template>
  <div class="px-2">
    <div v-for="item in List" :key="item.id">
      <cardItem
        :title="item.name"
        :value="Money(item.total) + ' ₺'"
        icon="fas fa-money-bill fa-3x opacity-80"
        @click="showDetail(item)"
      />
      <stepTwo v-if="showBreakTwo && selected == item.id" :typeId="item.id" />
    </div>
  </div>
</template>

<script>
// local components
import stepTwo from "./break.two.vue";

// global components
import cardItem from "@/components/general/card.item.vue";

import axios from "axios";
import { rapor } from "@/networking/urlmanager";
export default {
  name: "expense-personnel-step-two",
  components: {
    cardItem,
    stepTwo,
  },
  data() {
    return {
      showBreakTwo: false,
      List: [],
      load: false,
      selected: "",
    };
  },
  methods: {
    showDetail(item) {
      this.selected = item.id;
      this.showBreakTwo = !this.showBreakTwo;
    },
    getDetail() {
      this.load = true;
      axios
        .get(
          rapor.expense.personnel.getDistribution +
            "?startDate=" +
            this.$store.state.raporPeriot.startDate +
            "&endDate=" +
            this.$store.state.raporPeriot.endDate,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getDetail();
  },
  watch: {
    "$store.state.raporPeriot.startDate"() {
      this.getDetail();
    },
    "$store.state.raporPeriot.endDate"() {
      this.getDetail();
    },
    load(val) {
      this.$emit("loading", val);
    },
  },
};
</script>

<style></style>
