<template>
  <div
    class="fixed w-full h-screen left-0 top-0 bg-white dark:bg-gray-800"
    style="z-index: 9999 !important"
  >
    <div
      class="fixed top-0 right-0 h-full bg-black bg-opacity-50 flex items-center justify-center text-white"
      style="
        z-index: 99999 !important;
        width: 78% !important;
        height: 90% !important;
        margin-top: 80px;
      "
      v-if="load"
    >
      <i class="fas fa-spinner fa-spin fa-3x"></i>
    </div>

    <hgsDetail
      name="hgs-detail-modal-full-screen"
      :List="tollsList"
      :bridges="bridgesList"
    />

    <div class="w-full flex items-center justify-center h-full">
      <div
        style="width: 22%"
        class="bg-white dark:bg-gray-900 shadow border-r h-full p-4 overflow-y-auto max-h-screen"
      >
        <div class="flex items-center justify-between mb-6">
          <label class="text-2xl my-5 font-bold block"
            >Genel Rota Bilgileri</label
          >
        </div>

        <diffInput
          title="Tehlikeli Madde Durumu"
          type="text"
          :disabled="true"
          :value="isExplosiveDefault ? 'Evet' : 'Hayır'"
        />
        <diffInput
          title="Kilometre"
          type="text"
          class="mt-6"
          :disabled="true"
          :value="totalKilometer + ' km'"
        />

        <diffInput
          title="Yolda Geçicek Zaman"
          type="text"
          class="mt-6"
          :disabled="true"
          :value="getTimeDetail(totalMinute)"
        />
        <div @click="$modal.show('hgs-detail-modal-full-screen')">
          <diffInput
            title="Otoyol Maliyeti"
            type="button"
            class="mt-6 text-left"
            @click="$modal.show('hgs-detail-modal-full-screen')"
            :value="Money(route.cost.cash) + ' ₺'"
          />
        </div>

        <diffInput
          title="Yakıt Maliyeti"
          type="text"
          class="mt-6"
          :disabled="true"
          :value="Money(route.cost.fuel) + ' ₺'"
        />

        <diffInput
          title="Toplam Maliyet"
          type="text"
          class="mt-6"
          :disabled="true"
          :value="Money(route.cost.cash + route.cost.fuel) + '₺'"
        />

        <button
          class="z-50 bg-red-500 py-3 mt-6 rounded w-full shadow text-white"
          @click="closeFullPanel()"
        >
          Geri Dön
        </button>
      </div>

        <!-- @click="(r) => getPlace(r)" -->
        <GmapMap
          v-if="
            startPosition.lat &&
            startPosition.lng &&
            endPosition.lat &&
            endPosition.lng
          "
          :center="center"
          :zoom="6"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
          }"
          style="width: 100%; border-radius: 20px"
          class="rounded-md"
          :style="'height:100%'"
        >
          <GmapMarker
            :position="startPosition"
            @click="center = startPosition"
          />


          <GmapMarker
          v-for="(item, index) in waypointsList"
          :key="index"
          :position="item"
  
        :label="{
          text: item.no+'. Teslim Noktası',
          color: 'white',
          className:
            'absolute top-0.5 left-0  text-sm  bg-black rounded px-2 text-xs font-bold text-white',
          fontSize: '10px',
        }"
          />


          <GmapMarker
            v-for="(item, index) in bridgesList"
            :key="'bridge-' + index"
            :icon="require('@/assets/marker/bridge.png')"
            :position="item.position"
            :label="{
              text: item.name,
              color: 'black',
              className:
                'absolute top-1 left-0  text-sm  bg-white rounded px-2 text-xs font-bold',
              fontSize: '10px',
            }"
          />

          <GmapPolyline
            v-bind:path.sync="polyLineList"
            v-bind:options="{ strokeColor: '#C43A3A' }"
          >
          </GmapPolyline>

          <!-- :editable="true" -->

          <div v-for="(item, index) in tollsList" :key="'toll-' + index">
            <GmapMarker
              v-if="item.type != 'Barrier'"
              :position="{ lat: item.start.lat, lng: item.start.lng }"
              :icon="require('@/assets/marker/hgs.png')"
              @click="center = item"
              :label="{
                text: 'Giriş : ' + item.start.name + '-' + item.end.name,
                color: 'black',
                className:
                  'absolute top-1 left-0  text-sm  bg-white rounded px-2 text-xs font-bold',
                fontSize: '10px',
              }"
            />

            <GmapMarker
              v-if="item.type != 'Barrier'"
              :position="{ lat: item.end.lat, lng: item.end.lng }"
              :icon="require('@/assets/marker/hgs.png')"
              @click="center = { lat: item.end.lat, lng: item.end.lng }"
              :label="{
                text: 'Çıkış : ' + item.start.name + '-' + item.end.name,
                color: 'black',
                className:
                  'absolute top-1 left-0 text-sm  bg-white  rounded px-2 text-xs font-bold',
                fontSize: '10px',
              }"
            />

            <GmapMarker
              v-if="item.type == 'Barrier'"
              :position="{ lat: item.lat, lng: item.lng }"
              :icon="require('@/assets/marker/barrier.png')"
              @click="center = { lat: item.lat, lng: item.lng }"
              :label="{
                text: item.name,
                color: 'black',
                className:
                  'absolute top-1 -right-1 text-sm   rounded bg-white px-2 text-xs font-bold',
                fontSize: '10px',
              }"
            />
          </div>

          <GmapMarker
            :position="endPosition"
            :icon="require('@/assets/marker/flag.png')"
            @click="center = endPosition"
          />
        </GmapMap>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { routePlanner } from "@/networking/urlmanager";

import { ToggleButton } from "vue-js-toggle-button";

import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import gmapInputVue from "@/components/general/gmapInput.vue";
import hgsDetail from "../../transition/hgs-detail.vue";

export default {
  name: "full-routes",
  props: [
    "waypoints",
    "startPosition",
    "endPosition",
    "polyLine",
    "tolls",
    "kilometer",
    "routes",
    "bridges",
    "isExplosiveDefault",
    "customerStateId",
    "outpointStateId",
    "outpointId",
    "customerId",
  ],
  components: {
    diffInput,
    ToggleButton,
    asyncBtn,
    hgsDetail,
    gmapInputVue
  },

  data() {
    return {
      center: null,
      totalKilometer: 0,
      totalMinute: 0,
      loadState: false,
      isToll: true,
      isShip: true,
      isAsphalt: true,
      isExplosive: false,
      routeType: 1,

      waypointsList: [],
      polyLineList: [],
      tollsList: [],
      bridgesList: [],
      load: false,

      route: {
        cost: {
          cash: 0,
          fuel: 0,
        },
      },
    };
  },
  methods: {
    getTimeDetail(mins) {
      let minutes = parseFloat(mins);
      var h = (minutes / 60) | 0,
        m = minutes % 60 | 0;

      return h + " Saat" + " " + m + " Dakika";
    },
    getRoute(loadState = true) {
      this.load = loadState;
      axios
        .post(
          routePlanner.calculate,
          {
            from: this.startPosition,
            to: this.endPosition,
            waypoints: JSON.stringify(this.waypointsList),
            isToll: this.isToll,
            isShip: this.isShip,
            isAsphalt: this.isAsphalt,
            isExplosive: this.isExplosive,
            routeType: this.routeType == 1 ? "fast" : "short",
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.route = res.data.data[0];

          this.$emit("changeEndPosition", this.endPosition);
          this.$emit("changeCustomerState", this.customerStateId);
          this.$emit("changeCustomerId", this.customerId);

          this.$emit("changeOutpointStateId", this.outpointStateId);
          this.$emit("changeOutpointId", this.outpointId);
          this.$emit("changeStartPosition", this.startPosition);

          this.routeGenerate();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    routeGenerate() {
      const data = this.route;

      this.polyLineList = data.polyLine;
      this.tollsList = data.tolls;
      this.bridgesList = data.bridges;

      this.totalKilometer = data.totalKilometer;
      this.totalMinute = data.totalMinute;

      this.$emit("change", {
        cost: data.cost,
        polyLine: JSON.stringify({
          startPosition: this.startPosition,
          waypoints: this.waypoints,
          endPosition: this.endPosition,
        }),
        totalMinute: data.totalMinute,
        navigationLink: data.navigationLink,
        totalKilometer: data.totalKilometer,
        tolls: data.tolls,
        bridges: data.bridges,
      });
    },
    removePlace(index) {
      this.waypointsList.splice(index, 1);
      this.getRoute(false);
    },
    getPlace(val) {
      this.waypointsList.push({
        lat: val.latLng.lat(),
        lng: val.latLng.lng(),
      });

      this.getRoute(false);
    },
    setPlace(val, index) {
      this.waypointsList[index] = {
        lat: val.latLng.lat(),
        lng: val.latLng.lng(),
      };
      this.getRoute(false);

      // this.getRoute(false);
    },
    closeFullPanel() {
      this.$parent.routes[0] = this.route;
      this.$parent.waypoints = this.waypointsList;
      this.$parent.bridges = this.bridgesList;
      this.$parent.tolls = this.tollsList;
      this.$parent.polyLine = this.polyLineList;
      this.$emit("hidePanel", true);
    },

    changeEndLocation(val) {
      this.endPosition = val;
      // this.getRoute();
    },
    changeStartLocation(val) {
      this.startPosition = val;
      // this.getRoute();
    },
  },
  created() {
    this.center = this.startPosition;
    this.totalKilometer = this.routes.totalKilometer;
    this.totalMinute = this.routes.totalMinute;

    this.waypointsList = this.waypoints;
    this.tollsList = this.tolls;
    this.bridgesList = this.bridges;
    this.polyLineList = this.polyLine;
    this.isExplosive = this.isExplosiveDefault;

    this.route = this.routes;
  },
};
</script>

<style></style>
