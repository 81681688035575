<template>
  <div class="w-full h-full">
    <div class="w-full md:w-9/12 flex">
      <div class="w-full p-2 flex items-center dark:text-gray-400">
        <i class="bg-red-600 w-3 rounded h-9 mr-2"></i>
        <h5>
          {{ title }}
        </h5>
      </div>
    </div>
    <apexchart
      v-if="data"
      :type="type"
      :options="options"
      height="200px"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "pie-chart",
  props: ["labels", "data", "title", "type", "extantion", "eventList"],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      options: {
        legend: {
          show: false,
          position: "right",
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],

        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              if (this.eventList) {
                const findItem = this.eventList.find(
                  (r) =>
                    r.name.toString().toLowerCase() ==
                    config.w.config.labels[config.dataPointIndex].toString().toLowerCase()
                );
                if (findItem) {
                  findItem.click();
                }
              }
            },
          },
          stacked: true,
          id: "income-distrubiton",
        },

        labels: [],

        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          fillSeriesColor: true,
          style: {
            fontSize: "14px",
            fontFamily: "Tahoma",
            textColor: "white",
          },
          y: {
            formatter: (value) => {
              return this.Money(value) + " " + this.extantion;
            },
          },
        },
      },
      series: [],
    };
  },
  created() {
    this.options.labels = this.labels;
    this.series = this.data;
  },
  watch: {
    data(val) {
      this.options.labels = this.labels;
      this.series = val;
    },
  },
};
</script>

<style></style>
