<template>
  <div class="max-w-max w-full justify-center">
    <div v-if="priviewImage" align="center">
      <h4
        class="w-full h-32 flex justify-center text-center text-4xl"
        v-if="!priview || loadState"
      >
        <span class="pt-10" v-if="loadState">
          <i class="fas fa-spinner fa-spin m-auto text-2xl"></i>
        </span>
        <span v-if="!loadState">
          {{ sizeText }}
        </span>
      </h4>
    </div>
    <div class="max-w-max relative">
      <img
        :src="priview"
        class="object-cover w-32 h-32 rounded-full"
        v-if="priview && !loadState"
      />
      <label>
        <div
          v-if="!loadState"
          class="m-auto absolute top-0 right-0 bg-white-custom h-7 w-7 flex items-center justify-center rounded-full"
        >
          <div
            class="cursor-pointer border shadow rounded-full bg-gray-200 text-gray-600 p-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </div>
        </div>
        <input
          type="file"
          accept="image/*"
          :disabled="loadState"
          class="w-full mt-8 shadow rounded hidden"
          v-show="imageFile"
          @change="onFileChange"
        />
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImageUploadComponent",
  props: {
    loadState: {
      type: Boolean,
      default: false,
    },
    isCircle: {
      type: Boolean,
      required: false,
      default: false,
    },
    imageFile: {
      type: Boolean,
      required: false,
      default: true,
    },
    sizeText: {
      type: String,
      required: true,
    },
    defaultUrl: {
      type: String,
      default: "",
    },
    priviewImage: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      priview: "",
      file: '"',
    };
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
      this.priview = URL.createObjectURL(this.file);
      this.$emit("getFile", this.file);
      this.$emit("getPriview", this.priview);
    },
  },
  created() {
    this.priview = this.defaultUrl;
  },
  watch: {
    defaultUrl(val) {
      this.priview = val;
    },
  },
};
</script>
