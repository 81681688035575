// live service
// const baseURL="http://176.236.213.238:8004";
// const baseURL="http://localhost";
const baseURL="https://sirius.tetglobal.com/api";

//calculator
export const calculate = {
  add: baseURL + "/calculator/add",
  getAll: baseURL + "/calculator/get/all",
  delete: baseURL + "/calculator/delete",
  edit: baseURL + "/calculator/edit",
};

export const general = {
  getCityAll: baseURL + "/general/get/city/all",
  getDistrictAll: baseURL + "/general/get/district/all",
  haveTypeAll: baseURL + "/general/get/havetype/all",
  getFileUrl: baseURL + "/general/get/file/url",
  getFileTypeAll: baseURL + "/general/get/file/types",
  getPriceTypes: baseURL + "/general//get/price/types",
  exchangePrice: baseURL + "/general/get/excahnge/now",
};
export const bulkPlanning = {
  getAll: baseURL + "/operation/bulk/planning/get/all",
  getRecomendedController:
    baseURL + "/operation/bulk/planning/get/recomended/controller",
  getSingle: baseURL + "/operation/bulk/planning/get/single",
  add: baseURL + "/operation/bulk/planning/add",
  edit: baseURL + "/operation/bulk/planning/edit",
  delete: baseURL + "/operation/bulk/planning/delete",
  setState: baseURL + "/operation/bulk/planning/set/state",
  vehiclesAll: baseURL + "/operation/bulk/planning/get/vehicles/all",
  vehicleAdd: baseURL + "/operation/bulk/planning/add/vehicle",
  vehicleDelete: baseURL + "/operation/bulk/planning/delete/vehicle",

  // reports
  getReportAll: baseURL + "/operation/bulk/planning/get/reports/all",
};

export const tracking ={
  getAll: baseURL + "/tracking/get/all",
  getFleetAll: baseURL + "/tracking/get/fleet/all",
  getGroupAll: baseURL + "/tracking/get/group/all",
  getLastTransition: baseURL + "/tracking/get/last/transition",
  getCurrentTransition: baseURL + "/tracking/get/active/transition",
  getComplatedList:baseURL+"/tracking/get/complated/transition",
  excelExport:baseURL+"/tracking/export/excel",
  TrackingMonthlyKilometerRaport:baseURL+"/tracking/get/kilometer/raport"
};

export const user = {
  signIn: baseURL + "/users/sign/in",
  signControl: baseURL + "/users/sign/control",
  fargotPasswordSender: baseURL + "/users/fargot/password/sender",
  fargotPasswordSet: baseURL + "/users/fargot/password/set",
  setProfile: baseURL + "/users/set/profile",
  setPassword: baseURL + "/users/set/password",
  setLanguage: baseURL + "/users/set/language",
  setProfileImage: baseURL + "/users/set/profile/image",
  getProfileImage: baseURL + "/users/get/profile/image",
  setMode: baseURL + "/users/set/mode",
  complateFirstAuth: baseURL + "/users/complate/first/auth",
  signOut: baseURL + "/users/sign/out",

  feedback: {
    getAll: baseURL + "/users/get/feedback",
    add: baseURL + "/users/add/feedback",
  },
};

export const managementUser = {
  getAll: baseURL + "/management/user/get/all",
  getFilterAll: baseURL + "/management/user/get/filter/all",
  getAllNotPagination: baseURL + "/management/user/get/all/not/pagination",
  getTypeAll: baseURL + "/management/user/get/type/all",

  add: baseURL + "/management/user/add",
  edit: baseURL + "/management/user/edit",
  passive: baseURL + "/management/user/passive",
  active: baseURL + "/management/user/active",
  changeSafeState: baseURL + "/management/user/change/safe/state",

  getPermissions: baseURL + "/management/user/get/permission",
  changePermission: baseURL + "/management/user/change/permission",
};
export const managementCompany = {
  getDetail: baseURL + "/management/company/get/detail",
  setDetail: baseURL + "/management/company/set/detail",
};
export const managementCosting = {
  getAll: baseURL + "/management/costing/get/all",
  save: baseURL + "/management/costing/save",
};

export const managementPrice = {
  getAll: baseURL + "/management/price/get/all",
  getAllNotPagination: baseURL + "/management/price/get/all/not/pagination",

  getFilterAll: baseURL + "/management/price/get/filter/all",
  add: baseURL + "/management/price/add",
  edit: baseURL + "/management/price/edit",
  passive: baseURL + "/management/price/delete",
  active: baseURL + "/management/price/reactive",
};
export const managementOutpoint = {
  getAll: baseURL + "/management/outpoint/get/all",
  getNotPagination: baseURL + "/management/outpoint/get/all/not/pagination",
  getFilterAll: baseURL + "/management/outpoint/get/filter/all",
  add: baseURL + "/management/outpoint/add",
  edit: baseURL + "/management/outpoint/edit",
  passive: baseURL + "/management/outpoint/delete",
  active: baseURL + "/management/outpoint/reactive",
};
export const personnelManagement = {
  getAll: baseURL + "/human/resource/personnel/get/all",
  getFilterAll: baseURL + "/human/resource/personnel/get/filter/all",
  getSingle: baseURL + "/human/resource/personnel/get/single",
  getTypeAll: baseURL + "/human/resource/personnel/get/type/all",
  getAllNotPagination:
    baseURL + "/human/resource/personnel/get/all/not/pagination",

  add: baseURL + "/human/resource/personnel/add",
  edit: baseURL + "/human/resource/personnel/edit",
  delete: baseURL + "/human/resource/personnel/delete",
  reactive: baseURL + "/human/resource/personnel/reactive",

  getGeneralTypeAll: baseURL + "/human/resource/personnel/get/general/type/all",
};
export const fuelManagement = {
  getAll: baseURL + "/fuel/get/all",
  getFilterAll: baseURL + "/fuel/get/filter/all",
  getSingle: baseURL + "/fuel/get/single",

  add: baseURL + "/fuel/add",
  edit: baseURL + "/fuel/edit",
  transfer: baseURL + "/fuel/transfer",
  delete: baseURL + "/fuel/delete",

  excelExport: baseURL + "/fuel/excel/export",
};
export const washManagement = {
  getAll: baseURL + "/wash/get/all",
  getFilterAll: baseURL + "/wash/get/filter/all",
  getSingle: baseURL + "/wash/get/single",

  add: baseURL + "/wash/add",
  edit: baseURL + "/wash/edit",
  passive: baseURL + "/wash/delete",
  excelExport: baseURL + "/wash/excel/export",

  // general
  getTypeAll: baseURL + "/wash/get/type/all",
};

export const vehicle = {
  getAll: baseURL + "/inventory/vehicle/get/all",
  getFilterAll: baseURL + "/inventory/vehicle/get/filter/all",
  getSingle: baseURL + "/inventory/vehicle/get/single",

  getAllNotPagination: baseURL + "/inventory/vehicle/get/all/not/pagination",
  getTypeAllVehicle: baseURL + "/inventory/vehicle/get/type/all",
  getTypeAll: baseURL + "/inventory/vehicle/get/type/general/all",
  getStates: baseURL + "/inventory/vehicle/get/state/general/all",

  add: baseURL + "/inventory/vehicle/add",
  edit: baseURL + "/inventory/vehicle/edit",
  delete: baseURL + "/inventory/vehicle/delete",
  reactive: baseURL + "/inventory/vehicle/reactive",
};

export const dorse = {
  getAll: baseURL + "/inventory/dorse/get/all",
  getFilterAll: baseURL + "/inventory/dorse/get/filter/all",
  getSingle: baseURL + "/inventory/dorse/get/single",

  getGeneralTypeAll: baseURL + "/inventory/dorse/get/type/general/all",
  getTypeAll: baseURL + "/inventory/dorse/get/type/general/all",
  getAllNotPagination: baseURL + "/inventory/dorse/get/all/not/pagination",

  add: baseURL + "/inventory/dorse/add",
  edit: baseURL + "/inventory/dorse/edit",

  delete: baseURL + "/inventory/dorse/delete",
  reactive: baseURL + "/inventory/dorse/reactive",
};
export const tank = {
  getAll: baseURL + "/inventory/tank/get/all",
  getFilterAll: baseURL + "/inventory/tank/get/filter/all",
  getSingle: baseURL + "/inventory/tank/get/single",
  getAllNotPagination: baseURL + "/inventory/tank/get/all/not/pagination",
  add: baseURL + "/inventory/tank/add",
  edit: baseURL + "/inventory/tank/edit",
  delete: baseURL + "/inventory/tank/delete",
  reactive: baseURL + "/inventory/tank/reactive",
};
export const repaire = {
  getAll: baseURL + "/repair/get/all",
  getFilterAll: baseURL + "/repair/get/filter/all",
  getSingle: baseURL + "/repair/get/single",

  add: baseURL + "/repair/add",
  edit: baseURL + "/repair/edit",
  delete: baseURL + "/repair/delete",
  excelExport: baseURL + "/repair/excel/export",

  receipts: {
    getAll: baseURL + "/repair/receipt/get/all",
    getTypeAll: baseURL + "/repair/receipt/get/type/all",

    add: baseURL + "/repair/receipt/add",
    edit: baseURL + "/repair/receipt/edit",
    delete: baseURL + "/repair/receipt/delete",
  },
};
export const hgs = {
  getAll: baseURL + "/account/hgs/get/all",
  getFilterAll: baseURL + "/account/hgs/get/filter/all",
  add: baseURL + "/account/hgs/add",
  edit: baseURL + "/account/hgs/edit",
  delete: baseURL + "/account/hgs/delete",

  excelExport: baseURL + "/account/hgs/excel/export",
};
export const supply = {
  getTotalDetail: baseURL + "/account/supply/get/total/detail",
  getAll: baseURL + "/account/supply/get/all",
  getFilterAll: baseURL + "/account/supply/get/filter/all",
  getSingle: baseURL + "/account/supply/get/single",

  getTypeAll: baseURL + "/account/supply/get/type/all",
  getAllNotPagination: baseURL + "/account/supply/get/all/not/pagination",

  add: baseURL + "/account/supply/add",
  edit: baseURL + "/account/supply/edit",
  delete: baseURL + "/account/supply/delete",

  getGeneralTypeAll: baseURL + "/account/supply/type/get/general/all",

  types: {
    getAll: baseURL + "/account/supply/type/get/all",
    add: baseURL + "/account/supply/type/add",
    delete: baseURL + "/account/supply/type/delete",
  },

  prices: {
    getAll: baseURL + "/account/supply/price/get/all",
    add: baseURL + "/account/supply/price/add",
    edit: baseURL + "/account/supply/price/edit",
    delete: baseURL + "/account/supply/price/delete",
  },
  excelExport: baseURL + "/account/supply/excel/export",
};
export const supplyPayment = {
  getTotal: baseURL + "/account/supply/payment/get/total",
  getAll: baseURL + "/account/supply/payment/get/all",
  getFilterAll: baseURL + "/account/supply/get/single",

  add: baseURL + "/account/supply/payment/add",
  edit: baseURL + "/account/supply/payment/edit",
  delete: baseURL + "/account/supply/payment/delete",
  excelExport: baseURL + "/account/supply/payment/excel/export",
};

export const generalExpense = {
  getAll: baseURL + "/account/general-expense/get/all",
  getFilterAll: baseURL + "/account/general-expense/get/filter/all",
  getSingle: baseURL + "/account/general-expense/get/single",

  add: baseURL + "/account/general-expense/add",
  edit: baseURL + "/account/general-expense/edit",
  delete: baseURL + "/account/general-expense/delete",
  receipts: {
    getAll: baseURL + "/account/general-expense/receipt/get/all",
    getTypeAll: baseURL + "/account/general-expense/receipt/get/type/all",

    add: baseURL + "/account/general-expense/receipt/add",
    edit: baseURL + "/account/general-expense/receipt/edit",
    delete: baseURL + "/account/general-expense/receipt/delete",
  },
};

export const transition = {
  getAll: baseURL + "/operation/transition/get/all",
  getFilterAll: baseURL + "/operation/transition/get/filter/all",
  getSingle: baseURL + "/operation/transition/get/single",
  getAllNotPagination: baseURL + "/operation/transition/get/all/not/pagination",

  add: baseURL + "/operation/transition/add",
  edit: baseURL + "/operation/transition/edit",
  delete: baseURL + "/operation/transition/delete",
};
export const product = {
  getAll: baseURL + "/operation/product/get/all",
  getFilterAll: baseURL + "/operation/product/get/filter/all",
  getSingle: baseURL + "/operation/product/get/single",
  getAllNotPagination: baseURL + "/operation/product/get/all/not/pagination",
  getWithFilterAll: baseURL + "/operation/product/get/product/and/connect/dorse/type",

  add: baseURL + "/operation/product/add",
  edit: baseURL + "/operation/product/edit",
  delete: baseURL + "/operation/product/delete",

  // general
  getSpecification: baseURL + "/operation/product/get/specification/all",
  getProductTypes: baseURL + "/operation/product/get/product-type/all",
  getSdsFiles: baseURL + "/operation/product/get/sds/all",
  getDorseTypes: baseURL + "/operation/product/get/general/dorse/type/all",

  properties: {
    add: baseURL + "/operation/product/properties/add",
    delete: baseURL + "/operation/product/properties/delete",
  },

  requiments: {
    add: baseURL + "/operation/product/requiments/add",
    delete: baseURL + "/operation/product/requiments/delete",
  },

  getTables: baseURL + "/operation/product/get/tables/general/all",
  getImmovables: baseURL + "/operation/product/get/immovables/all",
};
export const customer = {
  getAll: baseURL + "/operation/customer/get/all",
  getFilterAll: baseURL + "/operation/customer/get/filter/all",
  getSingle: baseURL + "/operation/customer/get/single",
  getAllNotPagination: baseURL + "/operation/customer/get/all/not/pagination",

  add: baseURL + "/operation/customer/add",
  edit: baseURL + "/operation/customer/edit",
  delete: baseURL + "/operation/customer/delete",

  requiments: {
    add: baseURL + "/operation/customer/requiments/add",
    delete: baseURL + "/operation/customer/requiments/delete",
  },

  outpoints: {
    getAll: baseURL + "/operation/customer/get/outpoint/all",
    add: baseURL + "/operation/customer/outpoint/add",
    edit: baseURL + "/operation/customer/outpoint/edit",
    delete: baseURL + "/operation/customer/outpoint/delete",
  },
};

export const orders = {
  getAll: baseURL + "/operation/order/get/all/orders",
  getPlanningAll: baseURL + "/operation/order/get/all",

  getNotPaginationAll: baseURL + "/operation/order/get/not/pagination/all",
  getSingle: baseURL + "/operation/order/get/single",

  applyVehicle: baseURL + "/operation/order/apply/vehicle",

  editPlaningOrder: baseURL + "/operation/order/edit/vehicle/or/driver",

  add: baseURL + "/operation/order/add",
  edit: baseURL + "/operation/order/edit",
  delete: baseURL + "/operation/order/delete",
};
export const planning = {
  getAll: baseURL + "/operation/planning/get/all",
  getFilterAll: baseURL + "/operation/planning/get/filter/all",
  getSingle: baseURL + "/operation/planning/get/single",
  getStatusAll: baseURL + "/operation/planning/get/general/status/all",
  getTypeAll: baseURL + "/operation/planning/get/general/type/all",
  add: baseURL + "/operation/planning/add",
  edit: baseURL + "/operation/planning/edit",
  changeVehicle: baseURL + "/operation/planning/set/vehicle",
  setState: baseURL + "/operation/planning/set/state",
  delete: baseURL + "/operation/planning/delete",
  getVehiceStatus: baseURL + "/operation/planning/get/vehicle/status",
  readFile:baseURL+"/operation/planning/read/file",

  transition: {
    add: baseURL + "/operation/planning/transition/add",
    edit: baseURL + "/operation/planning/transition/edit",
    delete: baseURL + "/operation/planning/transition/delete",
  },

  excelExport: baseURL + "/operation/planning/excel/export",
};

export const routePlanner = {
  calculate: baseURL + "/route/planner/calculate",
};

export const files = {
  getAll: baseURL + "/files/get/all",
  getOldAll: baseURL + "/files/get/old/all",
  getTracking: baseURL + "/files/get/tracking",

  add: baseURL + "/files/add",
  edit: baseURL + "/files/edit",
  delete: baseURL + "/files/delete",
};

export const rapor = {
  generalMonth: baseURL + "/report/general/get/general/month",
  getVehicleDistribution: baseURL + "/report/general/get/vehicle/distribution",
  income: {
    transition: {
      getCustomerDistribution:
        baseURL + "/report/income/transition/get/customer/distribution",
      getPriceDistribution:
        baseURL + "/report/income/transition/get/price/distribution",
      getTypeDistribution:
        baseURL + "/report/income/transition/get/type/distribution",

      getSupplyDistribution:
        baseURL + "/report/income/transition/get/supply/distribution",
      getVehicleDistribution:
        baseURL + "/report/income/transition/get/supply/vehicle/distribution",
      getVehicleDetail:
        baseURL + "/report/income/transition/get/vehicle/detail/all",
    },
  },
  expense: {
    fuel: {
      getSupplyDistribution:
        baseURL + "/report/expense/fuel/get/supply/distribution",
      getVehicleDetail: baseURL + "/report/expense/fuel/get/vehicle/detail/all",
    },
    wash: {
      getSupplyDistribution:
        baseURL + "/report/expense/wash/get/supply/distribution",
      getVehicleDetail: baseURL + "/report/expense/wash/get/vehicle/detail/all",
    },
    repair: {
      getSupplyDistribution:
        baseURL + "/report/expense/repair/get/supply/distribution",
      getVehicleDetail:
        baseURL + "/report/expense/repair/get/vehicle/detail/all",
    },
    rent: {
      getDistribution: baseURL + "/report/expense/rent/get/distribution",
      getDetail: baseURL + "/report/expense/rent/get/detail",
    },
    personnel: {
      getDistribution:
        baseURL + "/report/expense/personnel/get/type/distribution",
      getDistributionDetail:
        baseURL + "/report/expense/personnel/get/type/detail/all",
    },
  },

  
};
