<template>
  <div
    class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4">
    <div class="flex items-center justify-between bg-white px-4 pb-4">
      <div class="flex items-center">
        <div>
          <label class="block text-xs">Başlangıç</label>
          <input type="date" v-model="startDate" :max="endDate" class="p-2 rounded border-b" />
        </div>
        <div class="ml-4 border-l pl-4">
          <label class="block text-xs">Bitiş</label>
          <input type="date" :min="startDate" :max="maxDate" v-model="endDate" class="p-2 rounded border-b" />
        </div>
        <button @click="clearDates" class="ml-4 px-2 py-1 bg-red-600 text-white rounded">
          Temizle
        </button>
      </div>
      <div class="relative">
        <input type="text" v-model="searchTerm" placeholder="Ara..." class="p-2 rounded border-b" />
      </div>
    </div>
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
        <tr>
          <th scope="col" class="table-th text-left border-r border-opacity-20" style="min-width: 100px !important">
            Sefer No
          </th>
          <th scope="col" class="table-th text-left border-r border-opacity-20" style="min-width: 150px !important">
            Araç
          </th>

          <th scope="col" class="table-th text-left border-r border-opacity-20" style="min-width: 200px !important">
            Müşteri
          </th>

          <th scope="col" class="table-th text-left border-r border-opacity-20" style="min-width: 200px !important">
            Durum
          </th>

          <th scope="col" class="table-th text-left border-r border-opacity-20" style="min-width: 200px !important">
            Sipariş Tarihi
          </th>
          <th scope="col" class="table-th text-left border-r border-opacity-20" style="min-width: 200px !important">
            Tahmini Varış Zamanı
          </th>
        </tr>
      </thead>
      <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
        <tr v-for="(item, index) in filteredList" :key="index">
          <td class="table-td border-r border-opacity-20">
            {{ item.orderNo }}
          </td>
          <td class="table-td border-r border-opacity-20">
            <p>
              {{ item.plate }}
            </p>
          </td>
          <td class="table-td border-r border-opacity-20">
            {{ item.planning.customer }}
          </td>
          <td class="table-td border-r border-opacity-20">
            {{ item.planning.stateName }}
          </td>
          <td class="table-td border-r border-opacity-20">
            <p>
              {{ formattedDate(item.planning.orderDate) }}
            </p>
          </td>
          <td class="table-td border-r border-opacity-20">
            <p>
              {{
            item.estimatedTargetDistance == 0 ? " -" : estimatedFillingHour(item.estimatedTargetDistance)
          }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "detail-components",
  props: ["list"],
  data() {
    return {
      searchTerm: "",
      startDate: "",
      endDate: "",
      maxDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    };
  },
  methods: {
    formattedDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("DD MMMM YYYY HH:mm");
    },
    estimatedFillingHour(distance) {
      const arrivalTime = moment().add(distance / 60, 'hours');
      return moment(arrivalTime).format("DD MMMM YYYY HH:mm");
    },
    clearDates() {
      this.startDate = "";
      this.endDate = "";
    },
  },
  computed: {
    getDetailList() {
      return this.list?.slice().sort((a, b) => moment(a.planning.orderDate, "YYYY-MM-DD HH:mm").toDate() - moment(b.planning.orderDate, "YYYY-MM-DD HH:mm").toDate());
    },
    filteredList() {
      const searchTermLower = this.searchTerm.toLowerCase();
      return this.getDetailList.filter(item => {
        const itemDate = item.planning.orderDate;
        const plate = item.plate ? item.plate.toLowerCase() : '';
        const orderNo = item.orderNo ? String(item.orderNo) : '';
        const customer = item.planning && item.planning.customer ? item.planning.customer.toLowerCase() : '';

        const matchesSearch = plate.includes(searchTermLower) || orderNo.includes(searchTermLower) || customer.includes(searchTermLower);
        const withinStartDate = !this.startDate || moment(itemDate).isSameOrAfter(this.startDate, 'day');
        const withinEndDate = !this.endDate || moment(itemDate).isSameOrBefore(this.endDate, 'day');

        return matchesSearch && withinStartDate && withinEndDate;
      });
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
