<template>
  <modal
    name="vehicle-list-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '50%'"
    @opened="getAll()"
  >
    <addModal :rowId="rowId" @refresh="() => ($emit('refresh', true), getAll())" />
    <div
      class="pb-2 pr-4 pt-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <div class="w-full flex items-center justify-center">
        <button
          @click="active = false"
          class="w-5/12 py-2"
          :class="!active ? 'border-b-2 border-black' : ''"
        >
          ARAÇ LİSTESİ
        </button>
        <button
          @click="active = true"
          class="w-5/12 py-2"
          :class="active ? 'border-b-2 border-black' : ''"
        >
          HARİTA
        </button>

        <div class="w-2/12 text-right">
          <button class="pb-1 px-2" @click="$modal.hide('vehicle-list-modal')">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>

      <!-- <h4 class="inline-block w-11/12">ARAÇ LİSTESİ</h4> -->
    </div>
    <locationDetail v-if="active" :vehicles="List" />
    <div class="w-full mt-4" v-if="!active">
      <button
        @click="$modal.show('add-vehicle-modal')"
        class="ml-4 text-center"
        :disabled="isDisabled"
        :class="isDisabled ? 'btn-disabled' : 'btn'"
      >
        <i class="fas fa-plus"></i> Yeni Araç
      </button>
      <div
        class="shadow table-height-value border-b overflow-y-scroll border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4 mx-4"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
            <tr>
              <th scope="col" class="table-th text-center">#</th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 150px !important"
              >
                Araç
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 100px !important"
              >
                Dorse
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 150px !important"
              >
                Sürücü
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 150px !important"
              >
                Sefer Sayısı
              </th>
              <th scope="col" class="table-th text-left border-r border-opacity-20"></th>
            </tr>
          </thead>
          <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
            <tr v-for="(item, index) in List" :key="item.id">
              <td class="table-td text-center border-r border-opacity-20">
                {{ index + 1 + currentPage * 30 }}
              </td>
              <td class="table-td border-r border-opacity-20">
                <div class="flex justify-between items-center">
                  <p>{{ item.plaque }}</p>
                  <span
                    class="px-2 bg-red-600 rounded-xl text-white text-xs inline-block font-extralight"
                    v-if="item.isCustom == 1 || item.isReject == 1"
                  >
                    {{ item.isReject == 1 ? "Reddetti" : "Manuel Atama" }}
                  </span>
                </div>
              </td>
              <td class="table-td border-r border-opacity-20">
                <p>{{ item.dorse }}</p>
              </td>
              <td class="table-td border-r border-opacity-20">
                <p>{{ item.name }}</p>
              </td>
              <td class="table-td border-r border-opacity-20 font-bold text-center">
                <p>{{ item.transitionCount }}</p>
              </td>

              <td class="table-td border-r border-opacity-20">
                <button
                  :class="
                    isDisabled
                      ? 'text-white border rounded px-2 py-1 border-opacity-30 bg-opacity-30 text-opacity-30'
                      : 'process-btn'
                  "
                  :disabled="isDisabled"
                  @click="deleteItem(item)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            <tableLoader :load="load" :length="List.length" colspan="6" />
          </tbody>
        </table>
      </div>
      <div class="px-4">
        <PaginationComponents
          v-if="totalCount > 0"
          :totalRowCount="totalCount"
          :pageCount="pageCount"
          :currentPage="currentPage"
          @changePage="(r) => (currentPage = r - 1)"
        />
      </div>
    </div>
  </modal>
</template>

<script>
//Global Components
import tableLoader from "@/components/general/table-loader.vue";
import addModal from "./add.vue";
import axios from "axios";
import PaginationComponents from "@/components/general/pagination.vue";
import locationDetail from "./components/map-location.vue";
import { bulkPlanning } from "@/networking/urlmanager";
import moment from "moment";
export default {
  props: ["rowId", "isDisabled"],
  components: {
    tableLoader,
    PaginationComponents,
    addModal,
    locationDetail,
  },
  data() {
    return {
      load: false,
      List: [],
      totalCount: 0,
      pageCount: 0,
      currentPage: 0,
      active: false,
    };
  },
  methods: {
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(
          bulkPlanning.vehiclesAll + "?rowId=" + this.rowId + "&page=" + this.currentPage,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Aracı planlamadan çıkarmak istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              bulkPlanning.vehicleDelete,
              {
                rowId: item.id.toString(),
                plaque: item.plaque.toString(),
                bulkId: this.rowId,
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.$emit("refresh", true);
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
  filters: {
    dateFormat(val) {
      return moment(val).format("LLLL");
    },
  },
};
</script>

<style></style>
