const { default: axios } = require("axios");
const { managementCosting } =require('../../../../../../networking/urlmanager');


const periot={
    km_40000:40000,
    km_80000:80000,
    km_150000:150000,
    km_180000:180000,
    km_280000:280000,
}

let  

    insurancePrice=0,
    trafficInsurance=0,
    mtvPrice=0,
    mtv2Price=0,
    inspectionPrice=0,


    whellPrice=0,
    whellConsumption=12,
    engineOil=0,
    engineConsumption=40,
    fuelFilterPrice=0,
    fuelFilterConsumption=1,
    separFilterPrice=0,
    separFilterConsumption=1,
    oilFilterPrice=0,
    oilFilterConsumption=1,
    airFilterPrice=0,
    airFilterConsumption=1,
    airDryerFilterPrice=0,
    airDryerFilterConsumption=1,

    brakePadPrice=0,
    brakePadChangePrice=0,
    brakePadConsumptionPrice=5,

    diskPrice=0,
    diskChangePrice=350,
    diskPriceConsumptionPrice=5,


    clutchPrice=0,
    clutchChangePrice=2500,
    clutchConsumptionPrice=1,

    periodicMaintenanceCost=0,
    
    kilometerForFuel=0.34,
    motorinPrice=38/1.2,
    adbluePrice=21/1.2,
    adblueUnitPrice=0.023,


    contactInformationPrice=385.76,
    operationPerWashPrice=300,
    otherPersonnelPerVehiclePrice=4875,

    depreciationPrice=50000,
    driverWagePrice=41000;




async function calculator(
    token,vehicleCount=1,totalYearKilometer=1,totalMontlyKilometer=0,hgsPrice=0,isAmortisman=true,isOneWay=true,dayCount=0){

        
        const res=await axios.get(managementCosting.getAll, {
            headers: {
              Authorization: "Bareer " + token,
            },
          })
              
        const detail=await res.data.data;
        insurancePrice=parseFloat(detail[0].price);
        trafficInsurance=parseFloat(detail[1].price);
        mtvPrice=parseFloat(detail[2].price);
        mtv2Price=parseFloat(detail[3].price);
        inspectionPrice=parseFloat(detail[4].price);

        whellPrice=parseFloat(detail[5].price); 
        engineOil=parseFloat(detail[6].price);
        fuelFilterPrice=parseFloat(detail[7].price);
        separFilterPrice=parseFloat(detail[8].price);
        oilFilterPrice=parseFloat(detail[9].price);
        airFilterPrice=parseFloat(detail[10].price);
        airDryerFilterPrice=parseFloat(detail[11].price);
        
        brakePadPrice=parseFloat(detail[12].price);
        brakePadChangePrice=parseFloat(detail[13].price);

        diskPrice=parseFloat(detail[14].price);
        diskChangePrice=parseFloat(detail[15].price);


        clutchPrice=parseFloat(detail[16].price);
        clutchChangePrice=parseFloat(detail[17].price);

        periodicMaintenanceCost=parseFloat(detail[18].price);


        const fuelTotalPrice=totalMontlyKilometer*kilometerForFuel*motorinPrice;
        const totalAdbluePrice=totalMontlyKilometer*adbluePrice*adblueUnitPrice;
        const totalWhellPrice=((whellPrice*whellConsumption)/periot.km_150000)*totalMontlyKilometer;

        const totalEngineOilPrice=((engineOil*engineConsumption)/periot.km_40000)*totalMontlyKilometer;
        const TotalfilterPrice=
        (fuelFilterPrice*fuelFilterConsumption/periot.km_40000)*totalMontlyKilometer +
        (separFilterPrice*separFilterConsumption/periot.km_40000)*totalMontlyKilometer +
        (oilFilterPrice*oilFilterConsumption/periot.km_40000)*totalMontlyKilometer +
        (airFilterPrice*airFilterConsumption/periot.km_40000)*totalMontlyKilometer +
        (airDryerFilterPrice*airDryerFilterConsumption/periot.km_40000)*totalMontlyKilometer;

        const totalBrakePadPrice=(((brakePadPrice*brakePadConsumptionPrice)+brakePadChangePrice)/periot.km_80000)*totalMontlyKilometer;
        const totalDiskPrice=(((diskPrice*diskPriceConsumptionPrice)+diskChangePrice)/periot.km_180000)*totalMontlyKilometer;
        const totalClutchPrice=(((clutchPrice*clutchConsumptionPrice)+clutchChangePrice)/periot.km_280000)*totalMontlyKilometer;

        const totalperiodicMaintenanceCost=(periodicMaintenanceCost/periot.km_40000)*totalMontlyKilometer
        const totalTrafficInsurancePriceAndinsurancePrice=(trafficInsurance+insurancePrice+mtvPrice+mtv2Price+inspectionPrice)/12
        const otherCost=contactInformationPrice+operationPerWashPrice+otherPersonnelPerVehiclePrice+hgsPrice;


          
          
        let totalCost=
        fuelTotalPrice+
        totalAdbluePrice+
        totalWhellPrice+
        totalEngineOilPrice+
        TotalfilterPrice+
        totalBrakePadPrice+
        totalDiskPrice+
        totalClutchPrice+
        totalperiodicMaintenanceCost
        ;
        
        
        
        if(isOneWay) {
            totalCost+=(driverWagePrice/30)*dayCount;
            totalCost+=(totalTrafficInsurancePriceAndinsurancePrice/30)*dayCount;
            totalCost+=(otherCost/30)*dayCount;
            if(isAmortisman) totalCost+=(depreciationPrice/30)*dayCount;
        }
        else {
            totalCost+=driverWagePrice;
            totalCost+=totalTrafficInsurancePriceAndinsurancePrice;
            totalCost+=otherCost;
            if(isAmortisman) totalCost+=depreciationPrice;
        }

        // console.log(totalCost,totalMontlyKilometer)
        return {
            total:totalCost.toFixed(2),
            fuelTotalPrice,
            totalAdbluePrice,
            totalWhellPrice,
            totalEngineOilPrice,
            TotalfilterPrice,
            totalBrakePadPrice,
            totalDiskPrice,
            totalClutchPrice,
            totalperiodicMaintenanceCost,
            totalTrafficInsurancePriceAndinsurancePrice,
            otherCost,
            depreciationPrice,
            driverWagePrice
        }
        
        
        
}

module.exports = {
    calculator
}