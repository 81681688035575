<template>
  <div class="w-full h-full bg-gray-100 dark:bg-gray-900">
    <div class="p-4">
      <div class="md:flex pb-3 md:pl-2 items-center">
        <div class="w-full flex items-center dark:text-gray-400">
          <i class="fas fa-bars mr-2 fa-lg"></i>
          <h5 class="font-bold text-2xl">Genel Raporlama</h5>
        </div>
        <div
          class="w-full xl:w-3/12 inline-block md:pl-2 text-right md:flex justify-end mt-4 xl:mt-0"
        >
          <input
            type="month"
            class="w-full border-gray-200 rounded dark:bg-gray-700 dark:border-gray-500 dark:text-gray-300 text-black border h-10 px-4"
            v-model="datePeriot"
          />
        </div>
      </div>

      <div class="md:flex mt-1 ">
        <div class="xl:w-3/12 md:w-3/12 xl:pr-2  ">
          <cardItem
            title="TOPLAM GELİR"
            :value="Money(detail.income) + ' ₺'"
            icon="fas fa-money-bill fa-3x opacity-80"
            @click="showBreakIncome = !showBreakIncome"
            :loading="load"
            
          />
          <incomeBreakOne v-if="showBreakIncome" :totalPrice="detail.income"  />
        </div>
        <div class="xl:w-3/12 md:w-3/12 xl:pr-2  xl:ml-0 md:ml-10">
          <cardItem
            title="TOPLAM GİDER"
            :value="Money(detail.expense.total) + ' ₺'"
            icon="fas fa-money-bill fa-3x opacity-80"
            @click="showBreakExpense = !showBreakExpense"
            :loading="load"
          />
          <expenseBreakOne v-if="showBreakExpense" :detail="detail.expense.detail" />
        </div>
        <div class="xl:w-3/12 md:w-3/12 xl:pr-2  xl:ml-0 md:ml-10">
          <cardItem
            title="TOPLAM KAZANÇ"
            :value="Money(detail.revenue) + ' ₺'"
            icon="fas fa-money-bill fa-3x opacity-80"
            :loading="load"
          />
        </div>
        <div class="xl:w-3/12 md:w-3/12  xl:ml-0 md:ml-10">
          <cardItem
            title="TOPLAM SEVKİYAT"
            :value="detail.count.toString()"
            icon="fas fa-money-bill fa-3x opacity-80"
            :loading="load"
          />
        </div>
      </div>
      <div class="w-full mt-5">
        <vehicleBasedTable />
      </div>

      <div class="w-full mt-5">
        <customerDistribution />
      </div>

      <div class="w-full">
        <div class="md:flex">
          <div class="xl:w-6/12 md:w-6/12 ">
            <priceDistribution />
          </div>
          <div class="xl:w-6/12 md:w-6/12 md:pl-4 xl:pl-4">
            <typeDistribution />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// local Components
import vehicleBasedTable from "./components/vehicle-based/index.vue";
import incomeBreakOne from "./components/income/general.vue";
import expenseBreakOne from "./components/expnese/index.vue";
import customerDistribution from "./components/income/planning/customerDistribution.vue";

import priceDistribution from "./components/income/planning/priceDistribution.vue";
import typeDistribution from "./components/income/planning/typeDistribution.vue";

// Global Components
import cardItem from "@/components/general/card.item.vue";

import moment from "moment";
import axios from "axios";
import { rapor } from "@/networking/urlmanager";

export default {
  name: "dashboard",
  components: {
    cardItem,
    vehicleBasedTable,

    customerDistribution,
    priceDistribution,
    typeDistribution,

    incomeBreakOne,
    expenseBreakOne,
  },
  data() {
    return {
      datePeriot: "",
      detail: {
        income: 0,
        expense: 0,
        revenue: 0,
        count: 0,
      },
      load: false,

      showBreakIncome: false,
      loadBreakIncome: false,
      showBreakExpense: false,
    };
  },
  methods: {
    getDetail() {
      this.load = true;
      axios
        .get(
          rapor.generalMonth +
            "?startDate=" +
            this.$store.state.raporPeriot.startDate +
            "&endDate=" +
            this.$store.state.raporPeriot.endDate,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((result) => {
          this.detail = result.data.data;
        })
        .catch((err) => {
          console.log(err.response);
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getDetail();
    this.datePeriot = moment(this.$store.state.raporPeriot.startDate).format("YYYY-MM");
  },
  watch: {
    datePeriot(val) {
      this.$store.commit("setPeriot", {
        startDate: moment(val).startOf("month").format("YYYY-MM-DD"),
        endDate: moment(val).endOf("month").format("YYYY-MM-DD"),
      });
      this.getDetail();
    },
    
  },
};
</script>
