<template>
  <modal
    name="excel-export-planning-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">{{ $t("general.exportToExcel") }}</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('excel-export-planning-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form
      @submit.prevent="getExport"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
    >
      <div class="w-full">
        <diffInput v-model="startDate" :title="$t('general.minimumDate')" type="date" />
      </div>

      <div class="w-full mt-4">
        <diffInput v-model="endDate" :title="$t('general.maximumDate')" type="date" />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components

import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import axios from "axios";

import { planning } from "@/networking/urlmanager";
export default {
  components: {
    diffInput,
    asyncBtn,
  },
  data() {
    return {
      load: false,
      startDate: "",
      endDate: "",
    };
  },
  methods: {
    getExport() {
      this.load = true;
      axios
        .get(
          planning.excelExport +
            "?startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          var _url =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            Buffer.from(res.data.data).toString("base64");
          window.open(_url, "_blank").focus();
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
};
</script>

<style></style>
