<template>
  <div class="md:h-screen h-full w-full">
    <div class="p-10 h-full bg-white dark:bg-gray-900 shadow-sm">
      <div class="w-full">
        <div class="w-full border-b border-gray-400 dark:border-gray-200 p-2 pb-3">
          <h4 class="text-black dark:text-gray-100">
            {{ $t("profile.getFeedback") }}
          </h4>
        </div>
      </div>
      <detailModal :explanation="selected.proccessExplanation" />
      <div class="h-full p-4 bg-gray-50 dark:bg-gray-900 rounded shadow-sm mt-4">
        <div class="w-full">
          <div
            class="shadow overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4"
            style="min-height: 300px !important; max-height: 500px !important"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead
                class="bg-white border-black border-dotted border-b-2 border-opacity-20"
              >
                <tr>
                  <th scope="col" class="table-th text-left">#</th>
                  <th scope="col" class="table-th text-left">{{ $t("general.type") }}</th>
                  <th scope="col" class="table-th text-left">{{ $t("general.title") }}</th>
                  <th scope="col" class="table-th text-left">{{ $t("general.explanation") }}</th>
                  <th scope="col" class="table-th text-left">{{ $t("general.date") }}</th>
                  <th scope="col" class="table-th text-left">{{ $t("general.status") }}</th>
                  <th scope="col" class="table-th text-center">{{ $t("general.detail") }}</th>
                </tr>
              </thead>
              <tbody class="divide-y-2 divide-gray-500 dark:bg-gray-700 bg-gray-800">
                <tr v-for="(item, index) in List" :key="index">
                  <td class="table-td text-left">#{{ item.id }}</td>
                  <td class="table-td text-left">
                    {{ item.typeId == 1 ? "Hata Bildirimi" : "Öneri" }}
                  </td>
                  <td class="table-td text-left">{{ item.title }}</td>
                  <td class="table-td text-left">{{ item.explanation }}</td>
                  <td class="table-td text-left">{{ item.addDate }}</td>
                  <td class="table-td text-left">
                    {{
                      item.stateId == 1
                        ? "İletildi"
                        : item.stateId == 2
                        ? "Sorun Çözümlendi"
                        : "Sorun Belirlenemedi !"
                    }}
                  </td>
                  <td class="table-td flex items-center justify-center">
                    <button class="process-btn mr-2" @click="showDetailModal(item)">
                      <i class="fas fa-search"></i>
                    </button>
                  </td>
                </tr>
                <tableLoader :load="load" :length="List.length" colspan="10" />
              </tbody>
            </table>
          </div>
          <PaginationComponents
            v-if="totalCount > 0"
            :totalRowCount="totalCount"
            :currentPage="currentPage + 1"
            :pageCount="pageCount"
            @changePage="(r) => (currentPage = r - 1)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// local components
import detailModal from "./feedback.detail.modal.vue";

// Global components
import PaginationComponents from "@/components/general/pagination.vue";
import tableLoader from "@/components/general/table-loader.vue";
import axios from "axios";
import { user } from "@/networking/urlmanager";

export default {
  name: "feedback-details",
  components: {
    PaginationComponents,
    tableLoader,
    detailModal,
  },
  data() {
    return {
      List: [],
      load: false,
      totalCount: 10,
      pageCount: 2,
      currentPage: 0,
      selected: {},
    };
  },
  methods: {
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(user.feedback.getAll + "?page=" + this.currentPage, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    showDetailModal(item) {
      this.selected = item;
      this.$modal.show("detail-feedback-modal");
    },
  },
  created() {
    this.getAll();
  },
};
</script>
