<template>
  <button @click="getDetail()">
    <span v-if="load">
      <i class="fas fa-spinner fa-spin"></i>
      Yükleniyor
    </span>
    <span class="text-red-500" v-if="!load"> Görüntüle </span>
  </button>
</template>

<script>
import axios from "axios";
import { general } from "../../networking/urlmanager";
export default {
  name: "async-link",
  props: ["fileUrl"],
  data() {
    return {
      load: false,
    };
  },
  methods: {
    getDetail() {
      this.load = true;
      axios
        .get(general.getFileUrl + "?url=" + this.fileUrl, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          window.open(res.data.data, "_blank").focus();
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
};
</script>

<style></style>
