<template>
  <div class="w-full">
    <div class="w-full flex items-center justify-center text-xl">
      <button
        class="w-6/12 h-12 px-4"
        :class="
          pageState == 1 ? 'bg-black  text-white' : ' bg-black bg-opacity-60   text-white'
        "
        @click="pageState = 1"
      >
        <i class="fas fa-list-alt pr-1"></i>
        Siparişler
      </button>
      <button
        class="w-6/12 h-12 px-4"
        :class="
          pageState == 2 ? 'bg-black  text-white ' : ' bg-black bg-opacity-60 text-white'
        "
        @click="pageState = 2"
      >
        <i class="fas fa-list-alt pr-1"></i>
        Planlamalar
      </button>
    </div>

    <!-- <delayedOrders @viewBulkPlanning="pageState = 2 , planType= 2"/>  -->
    <orderPage v-if="pageState == 1" />
    <plannig @changePage="() => (planType = 2)" v-if="pageState == 2 && planType == 1" />
    <bulkPlanning
      @changePage="() => (planType = 1)"
      v-if="pageState == 2 && planType == 2"
    />
  </div>
</template>

<script>
// Local Components
//import collectivePlanning from "./collectivePlanning/index.vue";
import orderPage from "./orders/index.vue";
import plannig from "../planning/plan/index.vue";
import bulkPlanning from "./collectivePlanning/index.vue";
//import delayedOrders from "@/components/devItem/delayedOrders.vue";

// networking
export default {
  name: "planning-module",
  components: {
    // collectivePlanning,
    orderPage,
    plannig,
    bulkPlanning,
    // delayedOrders
  },
  data() {
    return {
      pageState: 1, // 1=order | 2=planning
      planType: 1,
    };
  },
};
</script>
