<template>
  <div>
    <div
      class="min-h-80 px-6 bg-gray-200 dark:bg-gray-900"
      @mousedown="statusFilter = false"
    >
      <addModal @refresh="getAll()" />
      <editModal @refresh="filterState ? getFilter() : getAll()" :rowId="selected.id" />
      <addReportsModal />
      <vehicleStateModel :type="1" ref="vehicleStateModel" :Detail="selected" />
      <locationDetailModal :vehicle="selected.vehicle" />
      <changeVehicleModal :detail="selected" @refresh="getAll()" />

      <div class="w-full">
        <div class="h-full p-4 bg-gray-50 dark:bg-gray-800 rounded shadow-sm mt-4">
          <div class="w-full flex items-center dark:text-gray-400">
            <i class="fas fa-bars mr-1 fa-lg"></i>
            <h5 class="font-bold text-xl pl-1">
              {{ $t("operations.planning.pageTitle") }}
            </h5>
          </div>
          <div class="w-full">
            <div class="md:flex items-center mt-4">
              <div class="w-full text-left">
                <div class="w-full md:flex items-center justify-between">
                  <div class="w-3/12">
                    <button
                      @click="() => $modal.show('add-plan-modal')"
                      class="btn w-36 text-center"
                    >
                      <i class="fas fa-plus mr-2"></i
                      >{{ $t("operations.planning.newBtnTitle") }}
                    </button>
                  </div>
                  <div class="flex w-5/12">
                    <button
                      class="w-40 py-2 rounded bg-white shadow mr-2 2xl:ml-8 text-md"
                      :class="
                        activeState == 1 && 'border-b-4 border-red-700 text-red-700'
                      "
                    >
                      Planlama
                    </button>
                    <button
                      @click="$emit('changePage', true)"
                      class="w-40 py-2 rounded bg-white shadow text-md border-b-4"
                      :class="
                        activeState == 2 && 'border-b-4 border-red-700 text-red-700'
                      "
                    >
                      Toplu Planlama
                    </button>
                  </div>
                  <div class="w-4/12 flex">
                    <searchInput
                      v-model="search"
                      @searcing="() => getSearch()"
                      inputClass="w-full  "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="{ 'fixed top-0 w-full z-50 left-0 h-full bg-white': isFullScreen }"
            >
              <div
                :class="{
                  'shadow table-height-value overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4': !isFullScreen,
                  'w-full overflow-y-scroll h-full': isFullScreen,
                }"
              >
                <div class="relative">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead
                      class="bg-white border-black border-dotted border-b-2 border-opacity-20"
                    >
                      <tr>
                        <!-- <th scope="col" class="table-th text-center">
                      <input type="checkbox" name="" id="" />
                    </th> -->
                        <th
                          scope="col"
                          class="table-th text-left border-r border-opacity-20"
                          style="min-width: 70px !important"
                        >
                          #
                        </th>

                        <th
                          scope="col"
                          class="table-th text-left border-r border-opacity-20"
                          style="min-width: 280px !important"
                        >
                          {{ $t("operations.planning.filling") }}
                        </th>
                        <th
                          scope="col"
                          class="table-th text-left border-r border-opacity-20"
                          style="min-width: 280px !important"
                        >
                          {{ $t("operations.planning.delivery") }}
                          <!-- <i class="fas fa-arrow-down"></i> -->
                        </th>
                        <th
                          scope="col"
                          class="table-th text-left border-r border-opacity-20"
                          style="min-width: 100px !important"
                        >
                          Ürün
                        </th>
                        <th
                          scope="col"
                          class="table-th text-left border-r border-opacity-20 z-30"
                          style="min-width: 150px !important"
                        >
                          {{ $t("operations.planning.vehicle") }}
                        </th>

                        <th
                          scope="col"
                          class="table-th text-left z-20 border-r border-opacity-20"
                          style="min-width: 140px !important"
                        >
                          <div class="flex items-center">
                            <button class="font-bold" @click="cycleOrder">
                              {{ $t("general.status") }}
                              <i
                                v-if="
                                  order[currentIndex].orderId == 1 &&
                                  this.statusId == false
                                "
                                class="fas fa-arrow-down"
                              ></i>
                              <i
                                v-if="
                                  order[currentIndex].orderId === 2 &&
                                  this.statusId == false
                                "
                                class="fas fa-arrow-up"
                              ></i>

                              <i
                                v-if="
                                  order[currentIndex].orderId === 0 &&
                                  this.statusId == false
                                "
                                class="font-bold"
                                >-</i
                              >
                            </button>
                            <button class="absolute right-2">
                              <div class="flex gap-2">
                                <img
                                  @mousedown.stop="toggleStatusFilter()"
                                  class="w-5 h-5 mt-1"
                                  src="@/assets/planing/filteredit.svg"
                                  alt=""
                                />

                                <button
                                  v-if="!this.statusId == 0"
                                  class="text-red-700 font-bold text-xl"
                                  @click="stopFiltering"
                                >
                                  <i class="fas fa-times"></i>
                                </button>
                              </div>
                            </button>

                            <div
                              @mousedown.stop="statusFilter = true"
                              :class="{
                                'invisible opacity-0 transition-all ease-in duration-100 ': !statusFilter,
                              }"
                              class="w-44 h-auto overflow-y-scroll z-50 right-0 rounded mt-1 text-xs absolute bg-white top-7 border border-gray-300 text-black shadow-md"
                            >
                              <div>
                                <div class="h-24">
                                  <button
                                    v-for="(item, index) in statusState"
                                    :key="index"
                                    @click="setStatusFilter(item.id)"
                                    :class="{ 'bg-red-700 text-white ': item.active }"
                                    class="w-full py-1 border-b hover:bg-red-700 hover:text-white border-gray-100 text-left px-2"
                                  >
                                    {{ item.name }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </th>
                        <th
                          scope="col"
                          style="min-width: 140px !important"
                          class="table-th text-left"
                        >
                          {{ $t("general.process") }}
                          <button
                            @click="toggleFullScreen(true)"
                            v-if="!isFullScreen"
                            class="absolute right-4"
                          >
                            <img src="@/assets/table/open.svg" alt="" />
                          </button>
                          <button
                            @click="toggleFullScreen(false)"
                            v-if="isFullScreen"
                            class="absolute right-4"
                          >
                            <img class="h-5 w-5" src="@/assets/table/close.svg" alt="" />
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="divide-y-2 divide-gray-500"
                      style="background-color: #3a3a3a"
                    >
                      <tr v-for="(item, index) in List" :key="index">
                        <!-- <td class="table-td text-center border-r border-opacity-20">
                      <input type="checkbox" name="" id="" />
                    </td> -->
                        <td class="table-td border-r border-opacity-20">
                          <span class="block">
                            {{ item.typeName }}
                          </span>
                          <span
                            >#
                            {{ item.orderNo }}
                          </span>
                        </td>
                        <td class="table-td border-r border-opacity-20">
                          <p class="text-white" style="font-size: 10px">
                            {{ item.receivedName }}
                          </p>

                          <p class="text-white opacity-50">
                            {{ dateFormatter(item.fillingDate) }}
                          </p>

                          <p class="" style="font-size: 10px">
                            {{ getLastThreeWords(item.outpointAdress) }}
                          </p>
                        </td>
                        <td class="table-td border-r border-opacity-20">
                          <p class="text-white" style="font-size: 10px">
                            {{ item.deliveredName }}
                          </p>

                          <p class="text-white opacity-50">
                            {{ dateFormatter(item.deliveryDate) }}
                          </p>

                          <p class="" style="font-size: 10px">
                            {{ getLastThreeWords(item.targetAdress) }}
                          </p>
                        </td>
                        <td class="table-td border-r border-opacity-20">
                          <p class="text-gary-100" style="font-size: 10px">
                            {{ item.productType }}
                          </p>
                        </td>
                        <td
                          class="table-td border-r border-opacity-20 cursor-pointer select-none"
                          @click="
                            () => (
                              (selected = item), $modal.show('location-detail-modal')
                            )
                          "
                        >
                          <div
                            class="w-full relative text-white text-opacity-30 hover:text-opacity-60 transition-all duration-200"
                          >
                            <p
                              class="text-white border-b my-1 border-opacity-20 w-8/12"
                              style="font-size: 10px"
                            >
                              {{ item.vehicle }}
                            </p>
                            <p class="text-white" style="font-size: 10px">
                              {{ item.driver }}
                            </p>
                            <img
                              class="absolute right-2 -top-0 w-6 h-6"
                              src="@/assets/planing/location.svg"
                              alt=""
                            />
                          </div>
                        </td>
                        <td class="table-td w-36 border-r border-opacity-20">
                          <changeStatus
                            :defaultTitle="item.stateName"
                            :stateId="item.stateId"
                            :typeId="item.typeId"
                            :planId="item.id"
                            :note="item.note"
                            @changeVehicle="() => (selected = item)"
                            @refresh="() => getAll()"
                          />
                        </td>
                        <td class="table-td text-center w-42">
                          <button
                            :disabled="item.stateId >= 8"
                            class="process-btn mr-2"
                            :class="item.stateId >= 8 && ' bg-opacity-70'"
                            @click="showEditModal(item)"
                          >
                            <i
                              :class="item.stateId >= 8 && 'text-white text-opacity-50'"
                              class="fas fa-pen"
                            ></i>
                          </button>
                          <button
                            class="process-btn mr-2"
                            @click="vehicleStateModel(item)"
                          >
                            <i class="fas fa-search"></i>
                          </button>
                          <button
                            :disabled="item.stateId >= 8"
                            :class="item.stateId >= 8 && ' bg-opacity-70'"
                            class="process-btn"
                            @click="deleteItem(item)"
                          >
                            <i
                              :class="item.stateId >= 8 && 'text-white text-opacity-50'"
                              class="fas fa-trash"
                            ></i>
                          </button>
                        </td>
                      </tr>
                      <tableLoader :load="load" :length="List.length" colspan="7" />
                    </tbody>
                  </table>
                </div>
              </div>

              <div :class="isFullScreen && 'absolute bottom-0 w-full bg-white'">
                <PaginationComponents
                  v-if="totalCount > 0"
                  :totalRowCount="totalCount"
                  :pageCount="pageCount"
                  :currentPage="currentPage + 1"
                  @changePage="(r) => (currentPage = r - 1)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Global Components
import dropdownButton from "@/components/general/dropdown.button.vue";
import PaginationComponents from "@/components/general/pagination.vue";
import tableLoader from "@/components/general/table-loader.vue";
import addReportsModal from "./components/add.reports.modal.vue";
import changeVehicleModal from "./components/change.vehicle.vue";
import searchInput from "@/components/general/search-input.vue";

// Local Components
import addModal from "./components/add.modal.vue";
import editModal from "./components/edit.modal.vue";
import changeStatus from "./components/change.status.vue";
import vehicleStateModel from "./components/vehicleStateModel/index.vue";
import locationDetailModal from "./components/map-location.vue";
import planingNavbar from "../components/planingNavbar.vue";

// networking
import axios from "axios";
import { planning } from "@/networking/urlmanager";
import moment from "moment";

export default {
  name: "planning-module",
  components: {
    dropdownButton,
    PaginationComponents,
    tableLoader,
    vehicleStateModel,
    addModal,
    editModal,
    changeStatus,
    addReportsModal,
    locationDetailModal,
    changeVehicleModal,
    searchInput,
    planingNavbar,
  },
  data() {
    return {
      isFullScreen: false,
      List: [],
      load: false,
      sendStatus: false,
      activeState: 1,
      search: "",
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      intervalId: 0,
      statusFilter: false,
      selectedStatusId: null,
      filterState: false,
      filterList: [],
      selected: { id: "", plaque: "", vehicleId: "" },
      status: true,
      statusState: [
        {
          id: 0,
          name: "Hepsi",
          active: false,
        },
        {
          id: 8,
          name: "Tamamlandı",
          active: false,
        },
        {
          id: 1,
          name: "Onay Bekliyor",
          active: false,
        },
        {
          id: 2,
          name: "Onaylandı",
          active: false,
        },
        {
          id: 3,
          name: "Yükleme Noktasına Gidiyor",
          active: false,
        },
        {
          id: 4,
          name: "Yüklemede ",
          active: false,
        },
        {
          id: 5,
          name: "Yola Çıktı",
          active: false,
        },
        {
          id: 6,
          name: "Teslim Noktasına Ulaştı",
          active: false,
        },
        {
          id: 7,
          name: "Tahliyede",
          active: false,
        },

        {
          id: 9,
          name: "İptal Edildi",
          active: false,
        },
        {
          id: 10,
          name: "Onaylanmadı",
          active: false,
        },
      ],
      order: [
        {
          orderId: 1,
          orderName: "A-Z Sırala",
          active: false,
        },
        {
          orderId: 2,
          orderName: "Z-A Sırala",
          active: false,
        },
        {
          orderId: 0,
          orderName: "Varsayılan Sıralama",
          active: false,
        },
      ],
      isFilterOpen: false,
      statusId: 0,
      currentIndex: 0,
    };
  },

  methods: {
    toggleFullScreen(value) {
      this.isFullScreen = value;
    },
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    getLastThreeWords(address) {
      if (address) {
        const words = address.split(" ");
        // Eğer kelime sayısı 3'ten azsa, tüm kelimeleri al
        const lastThreeWords =
          words.length <= 3
            ? address
            : words[words.length - 2] + " / " + words[words.length - 3];
        return lastThreeWords;
      } else return;
      // Adresi boşluk karakterlerine göre ayır
    },
    vehicleStateModel(item) {
      this.selected = item;
      this.$modal.show("vehicleState-modal");
    },
    toggleStatusFilter() {
      this.statusFilter = !this.statusFilter;

      // Filtreleme düğmesine tıklandığında, seçilen A-Z YE SIRALAMA  STATÜSÜNÜ sıfırla
      // this.selectedStatusId = null;
    },
    showEditModal(item) {
      this.selected = item;
      setTimeout(() => {
        this.$modal.show("edit-plan-modal");
      }, 50);
    },
    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen;
    },
    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen;
    },
    //DURUMA BASINCA FİLTRELEME YAPMA İŞLEMİ HER SEFERİNDE orderId 1 arttırır
    cycleOrder() {
      this.currentIndex = (this.currentIndex + 1) % this.order.length;
      const currentOrder = this.order[this.currentIndex];
      this.updateOrder(currentOrder.orderId);
    },
    stopFiltering() {
      this.orderId = "";
      this.statusId = 0;
      this.currentIndex = 2;
      this.currentPage = 0;

      //this.selectedStatusId
      this.getAll();
    },
    updateOrder(orderId) {
      this.statusId = 0;
      this.order.forEach((item) => {
        item.active = item.orderId === orderId;
      });
      this.statusFilter = false;
      // stateNameOrder'ı güncelle ve getAll fonksiyonunu çağır
      this.stateNameOrder = orderId;
      this.currentPage = 0;
      //this.selectedStatusId
      this.getAll();
    },
    setStatusFilter(statusId) {
      this.statusId = statusId;
      if (this.statusId === 0) {
        this.currentIndex = 2;
      }

      this.currentPage = 0;
      this.statusFilter = false;
      this.load = true;
      this.getAll();
    },
    getAll(load = true) {
      // Statü parametresi verilmediyse veya statü değişiklik göstermiyorsa işlemi sonlandır
      if (this.statusId !== false) {
        if (this.status == true) {
          this.currentIndex = 2;
          this.status = false;
        }
        if (this.statusId === undefined) {
          // Sayfa değişikliğinde sadece sayfa parametresini güncelle ve filtreleme yapma
          this.statusId = this.selectedStatusId;
        } else {
          // eğer statü sıralaması yapılmışken order sıralaması kalmışsa sıfırlansın
          if (this.statusId !== 0) this.stateNameOrder = "";
          // Filtreleme yapıldığında seçilen statüyü sakla ve sayfa numarasını sıfırla
          this.selectedStatusId = this.statusId;
        }
        this.List = load ? [] : this.List;
        this.load = load;
        axios
          .get(
            planning.getAll +
              "?search=" +
              this.search +
              "&page=" +
              this.currentPage +
              "&status=" +
              this.statusId +
              "&stateNameOrder=" +
              this.stateNameOrder,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.userData.token,
              },
            }
          )
          .then((res) => {
            this.List = res.data.data.detail;
            this.totalCount = res.data.data.totalCount;
            this.pageCount = res.data.data.pageCount;
          })
          .catch((err) => {
            this.errorBox(err.response);
          })
          .finally(() => {
            this.load = false;
            this.sendStatus = false;
          });
      }
    },

    getFilter() {
      if (!this.search && !this.filterList.length) this.getAll();
      else {
        this.List = [];
        this.load = true;
        axios
          .post(
            planning.getFilterAll,
            {
              fList: JSON.stringify(
                this.search
                  ? [
                      { name: "name", value: this.search, type: "3" },
                      { name: "explanation", value: this.search, type: "3" },
                    ]
                  : this.filterList
              ),
              page: this.currentPage,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then((res) => {
            this.List = res.data.data.detail;
            this.totalCount = res.data.data.totalCount;
            this.pageCount = res.data.data.pageCount;
          })
          .catch((err) => {
            this.errorBox(err.response);
            console.log(err);
          })
          .finally(() => {
            this.load = false;
          });
      }
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Planı silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              planning.delete,
              {
                rowId: item.id.toString(),
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );

              if (!this.filterState) this.getAll();
              else this.getFilter();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    dateFormatter(val) {
      return moment(val).format("LLL");
    },
  },
  created() {
    this.getAll();

    this.intervalId = setInterval(() => {
      if (!this.sendStatus) {
        this.sendStatus = true;
        this.getAll(false);
      }
    }, 3000);
  },
  destroyed() {
    // Component yok edildiğinde interval durdurulur.
    clearInterval(this.intervalId);
  },
  watch: {
    currentPage() {
      if (!this.filterState) this.getAll();
      else this.getFilter();
    },
    filterList() {
      this.getFilter();
    },
    filterState(val) {
      if (!val) this.getAll();
    },
  },
};
</script>
