<template>
  <multiselect
    v-model="selected"
    :options="optList"
    :hide-selected="true"
    label="name"
    @remove="$emit('change', 0)"
    track-by="id"
    placeholder="Seçilmedi"
    selectLabel="Seç"
    selectedLabel="Seçildi"
    deselectLabel="Seçimi Kaldır"
    openDirection="bottom"
    :disabled="disabled"
    :loading="load"
  >
  </multiselect>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import { getLocationAll } from "../utils/detailData";
export default {
  components: {
    Multiselect,
  },
  name: "diffent-input",
  model: {
    props: "value",
    event: "change",
  },
  props: ["value", "optList", "disabled", "load"],
  data() {
    return {
      selected: "",
    };
  },
  methods: {
    async getAll() {
      await getLocationAll(this.$store.state.auth.token);
    },
  },
  created() {
    this.selected = this.value;
  },
  watch: {
    value(val) {
      this.selected = val;
    },
    selected(val) {
      this.$emit("change", val);

      if (this.optList.length > 0 && val) {
        const findItem = this.optList.find((item) => item.id == val.id);

        this.$emit("changeName", val.name);
        this.$emit("changeDriverName", findItem.driverName);
        this.$emit("changeDorse", findItem.dorse);
      }
    },
  },
};
</script>

<style></style>
