<template>
  <modal
    name="edit-planing-order"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '25%'"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">Araç Düzenle</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('edit-planing-order')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
    >
      <div class="w-full mb-5 border rounded px-2 py-3 relative">
        <label class="absolute -top-3 left-3 text-sm block w-auto bg-white"
          >Çekici Plakası</label
        >
        <vehicleInputVue
          :optList="getFilterVehicle"
          :load="vehicleLoad"
          v-model="vehicle"
          @changeDriverName="(val) => (driverName = val)"
          @changeDorse="(val) => (dorseName = val)"
        />
      </div>

      <div class="w-full pb-5">
        <diffInput :value="dorseName" :disabled="true" title="Dorse" />
      </div>
      <div class="w-full mb-5">
        <personelSelect
          :value="driverName ? driverName : ''"
          @changeTC="(val) => (driverTC = val)"
          @changeName="(val) => (driverName = val)"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components

import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import axios from "axios";

import vehicleInputVue from "../../orders/components/vehicle-select.vue";
import personelSelect from "@/components/devItem/personnelInput.vue";

import { orders } from "@/networking/urlmanager";
import { getLocationAll } from "../../orders/utils/detailData";
export default {
  props: ["Detail"],
  components: {
    diffInput,
    asyncBtn,
    vehicleInputVue,
    personelSelect,
  },
  data() {
    return {
      load: false,
      vehicleList: [],

      vehicleLoad: false,

      vehicle: "",
      dorseName: "",
      driverName: "",
      driverTC: "",
    };
  },
  methods: {
    show() {
      this.$modal.show("edit-planing-order");
    },
    hide() {
      this.$modal.hide("edit-planing-order");
    },
    detail() {
      this.vehicle = this.vehicleList.find((r) => r.vehicle == this.Detail.vehicle);

      this.vehicle = {
        name: this.vehicle.vehicle,
        id: this.vehicle.id,
        driverName: this.vehicle.driverName || "",
        dorse: this.vehicle.dorse,
      };
    },
    save() {
      this.load = true;
      if (!this.driverTC) {
        alert(
          "UYARI!",
          "SÜRÜCÜ TC BİLGİSİ BULUNAMADI LÜTFEN SİBERDEN İGİLİ SÜRÜCÜNÜN TC'SİNİ GÜNCELLEYİN !"
        );
        return;
      }
      axios
        .post(
          orders.editPlaningOrder,
          {
            rowId: this.Detail.id,
            driverName: this.driverName,
            vehicle: this.vehicle.name,
            dorse: this.vehicle.dorse,
            driverTC: this.driverTC,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.load = false;
            this.$emit("refresh", true);
            this.hide();
          });
        })
        .catch((err) => {
          console.log(err);
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  computed: {
    getFilterVehicle() {
      return this.vehicleList.map((item) => {
        return {
          name: item.vehicle,
          id: item.id,
          driverName: item.driverName,
          dorse: item.dorse,
        };
      });
    },
  },
  async created() {
    this.vehicleLoad = true;
    this.vehicleList = await getLocationAll(this.$store.state.userData.token);
    this.vehicleLoad = false;
    this.detail();
  },
  watch: {
    Detail() {
      this.detail();
    },
  },
};
</script>

<style></style>
