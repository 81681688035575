<template>
  <div class="md:h-screen h-full w-full">
    <form @submit.prevent="save" class="p-10 h-full bg-white dark:bg-gray-900 shadow-sm">
      <div class="w-full">
        <div class="w-full border-b border-gray-400 dark:border-gray-200 p-2 pb-3">
          <h4 class="text-black dark:text-gray-100">
            {{ $t("profile.setProfile") }}
            <!-- <i class="fas fa-spinner fa-spin" v-if="getLoad"></i> -->
          </h4>
        </div>
      </div>

      <div class="md:mt-5 mt-3">
        <div class="md:flex">
          <div class="w-full md:w-6/12 md:mt-0 mt-4 md:mr-2">
            <diffInput
              :title="$t('profile.username')"
              :disabled="true"
              :value="username"
              type="Text"
            />
          </div>
          <div class="w-full md:w-6/12 md:mt-0 mt-4">
            <diffInput
              :title="$t('profile.fullname')"
              type="Text"
              v-model="fullname"
              :required="true"
            />
          </div>
        </div>

        <div class="md:flex md:mt-5">
          <div class="w-full md:w-6/12 md:mt-0 mt-4 md:mr-2">
            <diffInput
              :title="$t('profile.email')"
              type="email"
              v-model="email"
              :required="true"
            />
          </div>
          <div class="w-full md:w-6/12 md:mt-0 mt-4">
            <diffInput
              :title="$t('profile.phone')"
              type="phone"
              v-model="phone"
              :required="true"
            />
          </div>
        </div>

        <div class="w-full md:mt-5">
          <diffInput
            :title="$t('profile.aboutus')"
            type="textarea"
            v-model="explanation"
          />
        </div>
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </div>
</template>

<script>
// Global components
import asyncBtn from "@/components/general/asyncBtn.vue";
import diffInput from "@/components/general/diffInput.vue";
import axios from "axios";
import { user } from "@/networking/urlmanager.js";

export default {
  name: "profile-form",
  components: {
    asyncBtn,
    diffInput,
  },
  data() {
    return {
      username: "",
      fullname: "",
      email: "",
      phone: "",
      explanation: "",
      load: false,
    };
  },
  methods: {
    save() {
      this.load = true;
      axios
        .post(
          user.setProfile,
          {
            fullname: this.fullname,
            email: this.email,
            phone: this.phone,
            explanation: this.explanation,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.$store.commit("setProfile", {
            fullname: this.fullname,
            email: this.email,
            phone: this.phone,
            explanation: this.explanation,
          });
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            this.$t("user.saveSetProfileMessage"),
            false,
            this.$t("general.OkayTitle")
          );
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    const row = this.$store.state.userData;
    this.username = row.username;
    this.fullname = row.fullname;
    this.email = row.email;
    this.phone = row.phone;
    this.explanation = row.explanation;
  },
};
</script>
