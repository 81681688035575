<template>
  <div class="inline py-2">
    <button
      @click="() => (show = !show)"
      type="button"
      class="w-full md:w-44 pt-2 pb-2 hover:bg-blue-300 text-white transition-all duration-200 shadow-lg"
      style="background-color: #af0707ef !important"
      :class="[containerClass, { 'rounded-b-0 rounded-t': show, rounded: !show }]"
    >
      {{ title }}
    </button>
    <div class="relative -mt-5 w-full z-50">
      <div
        class="bg-white dark:bg-gray-600 absolute shadow mt-5 w-full left-0 md:w-44 rounded-b dark:text-gray-200"
        tabindex="1"
        align="left"
        v-if="show"
      >
        <div class="w-full" @click="show = false">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "containerClass"],
  data() {
    return {
      show: false,
      selected: [1],
    };
  },
};
</script>

<style></style>
