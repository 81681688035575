<template>
  <modal
    name="edit-product-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
    @opened="getDetail()"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">
        {{ $t("operations.products.modal.editProduct") }}
      </h4>
      <button class="p-2 w-1/12" @click="$modal.hide('edit-product-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <span
      v-if="getLoad"
      class="block p-4 text-center dark:text-gray-200 dark:bg-gray-900"
    >
      <i class="fas fa-spinner fa-spin fa-3x"></i>
    </span>
    <div
      v-if="!getLoad"
      class="dark:bg-gray-900 dark:text-white overflow-y-auto h-full pb-20 xl:h-auto xl:pb-0 min-w-full"
    >
      <form
        v-if="!getLoad"
        @submit.prevent="save"
        class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 mt-4"
      >
        <div class="w-full">
          <diffInput
            :title="$t('operations.products.modal.productName')"
            v-model="name"
            type="Text"
          />
        </div>
        <div class="w-full mt-4">
          <typeSelect v-model="typeId" :multiple="false" />
        </div>
        <div class="mt-5">
          <propertiesSelect :isEdit="true" v-model="properties" />
        </div>
        <div class="mt-5">
          <dorseTypeSelect :isEdit="true" v-model="dorseTypes" />
        </div>
        <div class="mt-5">
          <immovablesSelect :isEdit="true" v-model="immovables" />
        </div>

        <div class="w-full mt-4">
          <diffInput
            :title="$t('operations.products.modal.explanation')"
            v-model="explanation"
            type="textarea"
          />
        </div>
        <div class="w-full mt-4 text-right">
          <asyncBtn
            icon="fas fa-check"
            :text="$t('general.saveButtonTitle')"
            :loadTitle="$t('general.saveButtonLoadTitle')"
            :loadState="load"
            class="w-full md:w-56"
          />
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import typeSelect from "./selectors/types.select.vue";
import dorseTypeSelect from "./selectors/dorse.type.select.vue";
import propertiesSelect from "./selectors/properties.select.vue";
import immovablesSelect from "./selectors/immavables.select.vue";

import axios from "axios";
import { product } from "@/networking/urlmanager";
export default {
  name: "edit-product-modal",
  props: ["detail"],
  components: {
    diffInput,
    asyncBtn,

    // LOCAL Components
    typeSelect,
    dorseTypeSelect,
    propertiesSelect,
    immovablesSelect,
  },
  data() {
    return {
      load: false,
      getLoad: false,
      stateId: "1",
        name:'',
        typeId:'',
        
      tables: [],
      properties: [],
      requiments: [],
      immovables: [],
      dorseTypes: [],

      explanation:''
    };
  },
  methods: {
    getDetail() {
      this.getLoad = true;
      axios
        .get(product.getSingle + "?rowId=" + this.detail.id, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          const detail = res.data.data[0][0];

          this.name = detail.name;
          this.typeId = detail.typeId;
          this.explanation = detail.explanation;

          // this.tables = res.data.data[3];
          // this.requiments = res.data.data[2];

          if (res.data.data[1].length > 0) {
            this.properties = res.data.data[1].map((item) => {
              return {
                id: item.id,
                name: item.name,
                rowId: item.rowId,
                status: 1,
              };
            });
          } else this.properties = [];

          if (res.data.data[4].length > 0) {
            this.immovables = res.data.data[4].map((item) => {
              return {
                id: item.id,
                name: item.name,
                rowId: item.rowId,
                status: 1,
              };
            });
          } else this.immovables = [];

          if (res.data.data[5].length > 0) {
            this.dorseTypes = res.data.data[5].map((item) => {
              return {
                id: item.typeId,
                name: item.name,
                rowId: item.id,
                status: 1,
              };
            });
          } else this.dorseTypes = [];
        })
        .catch((err) => {
          this.errorBox(err.response);
          console.log(err);
        })
        .finally(() => {
          this.getLoad = false;
        });
    },
    save() {
      this.load = true;
      axios
        .post(
          product.edit,
          {
            rowId: this.detail.id.toString(),
            name: this.name,
            typeId: this.typeId.toString(),
            explanation: this.explanation,
            dorseTypes: this.dorseTypes,
            properties: this.properties,
            immovables: this.immovables,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.requiments = [];
            this.properties = [];

            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("edit-product-modal");
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.load = false;
        });
    },
  },
};
</script>

<style></style>
