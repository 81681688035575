var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex text-left border-gray-700 py-3",class:{
    'px-5': _vm.$store.state.navState && !_vm.inline,
    'justify-center': !_vm.$store.state.navState && !_vm.inline,
    'border-t-2 border-b-2 py-3 w-full': !_vm.inline,
  }},[_c('button',{staticClass:"text-right py-0 h-10 dark:text-gray-300 flex items-center",class:{
      'bg-gray-700  rounded px-2': _vm.darkMode,
    },on:{"click":function($event){return _vm.activeDarkMode()}}},[_c('i',{staticClass:"fas mr-1 fa-moon"}),_c('span',{staticClass:"text-xs ml-2"},[_vm._v(" "+_vm._s(_vm.$t("general.darkModeTitle")))])]),_c('button',{staticClass:"w-6/12 text-left py-0 px-2 h-10 text-xs flex items-center ml-4",class:{
      'bg-gray-700 rounded px-2': !_vm.darkMode,
    },on:{"click":_vm.activeLightMode}},[_c('i',{staticClass:"fas fa-sun mr-2"}),_vm._v(" "+_vm._s(_vm.$t("general.lightModeTitle"))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }