<template>
  <div class="min-h-80 p-6 bg-gray-200 dark:bg-gray-900">
    <addModal ref="addItem" @refresh="getAll()" />
    <editModal @refresh="getAll()" :detail="selected" />

    <div class="h-full p-4 bg-gray-50 dark:bg-gray-800 rounded shadow-sm mt-4">
      <div class="w-full flex items-center dark:text-gray-400">
        <i class="fas fa-bars mr-2 fa-lg"></i>
        <h5 class="font-bold text-xl">{{ $t("operations.products.pageTitle") }}</h5>
      </div>
      <div class="w-full pt-5">
        <div class="md:flex items-center">
          <div class="w-full md:w-9/12 text-left">
            <div class="w-full inline-block">
              <button @click="$refs.addItem.show()" class="btn">
                <i class="fas fa-plus mr-2"></i>{{ $t("operations.products.newProduct") }}
              </button>
            </div>
          </div>
          <searchInput v-model="search" @searcing="() => getSearch()" />
        </div>

        <div :class="{ 'fixed top-0 w-full z-50 left-0 h-full bg-white': isFullScreen }">
          <div
            :class="{
              'shadow table-height-value overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4': !isFullScreen,
              'w-full overflow-y-scroll h-full': isFullScreen,
            }"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead
                class="bg-white border-black border-dotted border-b-2 border-opacity-20"
              >
                <tr>
                  <th scope="col" class="table-th text-left">
                    {{ $t("operations.products.productName") }}
                  </th>
                  <th scope="col" class="table-th text-left">Ürün Türü</th>
                  <th
                    scope="col"
                    class="table-th text-center"
                    style="min-width: 120px !important"
                  >
                    İşlem
                    <button
                      @click="toggleFullScreen(true)"
                      v-if="!isFullScreen"
                      class="absolute right-4"
                    >
                      <img src="@/assets/table/open.svg" alt="" />
                    </button>
                    <button
                      @click="toggleFullScreen(false)"
                      v-if="isFullScreen"
                      class="absolute right-4"
                    >
                      <img class="h-5 w-5" src="@/assets/table/close.svg" alt="" />
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
                <tr v-for="(item, index) in List" :key="index">
                  <td class="table-td text-left">
                    {{ item.name }}
                  </td>
                  <td class="table-td text-left">
                    {{ item.typeName }}
                  </td>

                  <td class="table-td text-center">
                    <button class="process-btn mr-2" @click="showEditModal(item)">
                      <i class="fas fa-pen"></i>
                    </button>
                    <button class="process-btn" @click="deleteItem(item)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
                <tableLoader :load="load" :length="List.length" colspan="10" />
              </tbody>
            </table>
          </div>
          <div :class="isFullScreen && 'absolute bottom-0 w-full bg-white'">
            <PaginationComponents
              v-if="totalCount > 0"
              :totalRowCount="totalCount"
              :currentPage="currentPage + 1"
              :pageCount="pageCount"
              @changePage="(r) => (currentPage = r - 1)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Local Components
import addModal from "./components/add.modal.vue";
import editModal from "./components/edit.modal.vue";

// global Components
import PaginationComponents from "@/components/general/pagination.vue";
import tableLoader from "@/components/general/table-loader.vue";
import searchInput from "@/components/general/search-input.vue";
import asyncLinkVue from "@/components/general/asyncLink.vue";

// netoworking
import axios from "axios";
import { product } from "@/networking/urlmanager";

export default {
  name: "product-module",
  components: {
    PaginationComponents,
    searchInput,
    tableLoader,

    addModal,
    editModal,
    asyncLinkVue,
  },
  data() {
    return {
      isFullScreen: false,
      List: [],
      load: false,
      search: "",
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,

      selected: { id: "" },
    };
  },
  methods: {
    toggleFullScreen(value) {
      this.isFullScreen = value;
    },
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    showEditModal(item) {
      this.selected = item;
      this.$modal.show("edit-product-modal");
    },
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(product.getAll + "?page=" + this.currentPage + "&search=" + this.search, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Ürünü silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              product.delete,
              {
                rowId: item.id.toString(),
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    search(val) {
      if (val.length == 0) this.getAll();
    },
  },
};
</script>

<style></style>
