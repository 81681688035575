<template>
  <div
    class="fixed xl:top-16 top-0 h-screen xl:h-full w-full bg-white p-5 z-20 xl:right-0 shadow xl:w-72"
    v-if="show"
  >
    <div class="w-full border-b py-2 relative flex justify-between">
      GELİŞMİŞ FİLTRELEME
      <button
        @click="hideModal()"
        class="text-black rounded-full w-7 h-7 flex items-center justify-center"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div class="w-full py-6 overflow-y-scroll" style="height: 80vh !important">
      <div>
        <geofilterInput v-model="region" />
      </div>
      <div class="mt-6">
        <wordStatusInput v-model="workStatus" />
      </div>
      <div class="mt-6">
        <productInput
          v-model="productId"
          title="Taşınan Ürün"
          @changeName="(r) => (productName = r)"
        />
      </div>
      <div class="mt-6">
        <customerFilterInputVue
          v-model="customerId"
          title="Müşteri"
          :dataDetail="dataDetail"
          @changeName="(r) => (customerName = r)"
        />
      </div>
      <div class="mt-6">
        <deliveryInput
          v-model="deliveryState"
          @changeStartDate="(val) => (deliveryDate.start = val)"
          @changeEndDate="(val) => (deliveryDate.end = val)"
          :detail="deliveryDate"
        />
      </div>

      <asyncBtn
        icon="fas fa-filter"
        text="Filtrele"
        loadTitle="Filtreleniyor"
        :loadState="load"
        class="w-full md:w-full mt-6"
        @click="() => filterItem()"
      />

      <button @click="() => filterClear()" class="mt-4 text-base text-center w-full">
        Filtreyi Temizle
      </button>
    </div>
  </div>
</template>

<script>
import geofilterInput from "./geofilter.input.vue";
import wordStatusInput from "./workStatus.input.vue";
import deliveryInput from "./delivery.input.vue";
import customerFilterInputVue from "./customer.filter.input.vue";

import productInput from "@/components/devItem/productInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

import axios from "axios";
import { tracking } from "@/networking/urlmanager";
export default {
  name: "vehicle-filtering",
  props: ["detail", "dataDetail"],
  components: {
    geofilterInput,
    wordStatusInput,
    customerFilterInputVue,

    productInput,
    deliveryInput,
    asyncBtn,
  },
  data() {
    return {
      region: {
        key: null,
        id: 1,
      },
      workStatus: 1,

      customerId: "",
      customerName: "",

      productName: "",
      productId: "",
      deliveryState: 1,
      deliveryDate: {
        start: null,
        end: null,
      },

      load: false,
      show: false,
    };
  },
  methods: {
    showModal() {
      this.show = true;
    },
    hideModal() {
      this.show = false;
      this.$emit("closeModal", true);
    },
    getAll() {
      axios
        .get(tracking.getGroupAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((response) => {
          const list = response.data.data;
          this.List = list;
          this.selected = list[0];
          this.load = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    filterItem() {
      this.$emit("change", {
        region: this.region,
        workStatus: this.workStatus,
        productId: this.productId ? this.productId : null,
        productName: this.productName ? this.productName : null,
        deliveryDate: this.deliveryDate ? this.deliveryDate : null,
        deliveryState: this.deliveryState ? this.deliveryState : 1,
        customerName: this.customerName ? this.customerName : null,
      });

      this.hideModal();
    },

    filterClear() {
      this.$emit("change", {
        region: "",
        workStatus: 1,
        productId: null,
        productName: null,
        deliveryDate: null,
        deliveryState: 1,
      });
      this.hideModal();
    },
  },
  created() {
    const val = this.detail;
    this.region = val.region;
    this.workStatus = val.workStatus;
    this.productId = val.productId;
    this.productName = val.productName;
    this.deliveryDate = val.deliveryDate;
    this.deliveryState = val.deliveryState;
    this.customerName = val.customerName;
  },
  watch: {
    detail(val) {
      this.region = val.region;
      this.workStatus = val.workStatus;
      this.productId = val.productId;
      this.deliveryDate = val.deliveryDate;
      this.deliveryState = val.deliveryState;
    },
  },
};
</script>
