module.exports = {
    general: {
        warningTitle: 'UYARI !',
        successTitle: 'İŞLEM BAŞARILI !',
        OkayTitle: 'Tamam',
        darkModeTitle: 'KARANLIK',
        lightModeTitle: 'AYDINLIK',
        selectedText: 'Seçildi',
        saveButtonTitle: 'Kaydet',
        saveButtonLoadTitle: 'Kayıt Ediliyor',
        loadingText: 'Yükleniyor...',
        findforText: 'Bulmak için yazın...',
        notfoundListText: 'Listelenebilecek herhangi bir kayıt bilgisi bulunamadı !',
        confirmText: "Evet,eminim",
        rejectText: "Hayır,değilim",
        notSelectedText: "Seçilmedi",
        pleaseFillRequired: "Lütfen gerekli alanları eksiksiz olarak doldurunuz !",
        excelExport: "Excel'e Aktar",
        add: "Ekle",
        newProccess: '+ Yeni İşlem',
        new: 'Yeni',
        process: "İşlem",
        status: "Durum",
        active: "Aktif",
        passive: 'Pasif',
        exportToExcel: "Excel'e Aktar",
        detail: "Detay",
        worth: "Değer",
        explanation: "Açıklama",
        minimumDate: "Minimum Tarih",
        maximumDate: "Maximum Tarih",
        type: "Tür",
        title: "Başlık",
        date: "Tarih",
        price: "Fiyat",
        typeName: "Tür Adı",
        plate: "Plaka",
        automaticSelection: "Otomatik Seçim",
        process: "İşlem",
        total: "Toplam",
        edit: "Düzenle",

        filterType: "Filtre Türü",
        filterValue: "Değer",
        applyFilter: 'Filtreyi Uygula',
        filterViewTitle: 'Filteleri Görüntüle',
        shipmentType: "Sevkiyat Türü",


        features: "özellikler",
        sign: "Tabela",
        note: 'Not'
    },
    devItems: {
        transitionTitle: 'Geçiş',
        productTitle: 'Taşınacak Ürün',
        customerTitle: 'Müşteri',
        outpointTitle: 'Yükleme Noktası',
        supplyTitle: 'Cari',
        supplyTypeTitle: 'Cari Türü',
        priceTagTitle: 'Fiyat Etiketi',
        planningType: 'Sevkiyat Türü',
        priceTypeTitle:"Birim",
        vehicleTitle: "Araçlar",
        dorseTitle: "Dorse",
        dorseTypeTitle: 'Dorse Türü',
        tankTitle: "ISO TANK",
        userTitle: 'Kullanıcı',
        vehicleTypeTitle: "Araç Türleri",
        vehicleStateTitle: "Araç Durum",
        personnelTitle: "Personel",
        personnelTypeText: "Personel Türü",
        washTypeInput: 'Yıkama Türleri',
        repairTypeInput: 'Kalem Türü',
        cityTitle: 'İl',
        districtTitle: 'İlçe',
        adressTitle: 'Adres',
        haveTypeTitle: 'Varlık Durumu'

    },
    profile: {
        // sidebar titles
        profile: 'Profil',
        setProfile: 'Profili Düzenle',
        setPassword: 'Parolayı Düzenle',
        getFeedback: 'Geri Bildirimler',

        // content titles
        username: 'Kullanıcı Adı',
        fullname: 'Ad Soyad',
        email: 'e-posta',
        phone: 'Telefon Numarası',
        aboutus: 'Hakkında',

        modal: {
            oldPassword: 'Eski Parola',
            newPassword: 'Yeni Parola',
            reNewPassword: 'Tekrar Yeni Parola',
            feedbackDetail: "Geri Bildirim Detayı"

        }
    },
    user: {
        signInTitle: 'GİRİŞ YAPIN',
        signOut: 'Çıkış Yap',
        signInSubTitle: 'Lütfen tüm bilgileri eksiksiz olarak doldurunuz',
        username: 'Kullanıcı Adı',
        password: "Parola",
        companyCode: "Firma Kodu",
        rememberMe: "Beni Hatırla",
        signIn: "Giriş Yap",
        signInLoad: "Bilgileriniz Kontrol Ediliyor",
        fargotPassword: "Parolamı Unuttum",


        // set profile 
        saveSetProfileMessage: 'Profil bilgileriniz başarı ile düzenlendi',

        // set password
        changePasswordTitle: 'Parolanızı Düzenle',
        saveSetPasswordMessage: 'Parolanız başarı ile düzenlendi',
        passwordNotMatch: 'Parolalar eşleşmiyor !',


        // change profile
        changeProfileImage: "Profil fotoğrafınız başarı ile düzenlendi"

    },
    complateProfile: {

        // step one
        stepOneTitle: 'Profil Bilgilerinizi Tamamlayın',
        fullname: 'Adınız ve Soyadınız',
        email: 'E-posta Adresiniz',
        phone: 'Telefon Numaranız',
        position: 'Pozisyonunuz',
        notNullProfileImage: 'Lütfen ilk önce bir profil fotoğrafı seçiniz !',
        language: 'Dil',


        // step two 
        stepTwoTitle: 'Kullanmak İstediğiniz Temayı seçin',

        // step three
        stepThreeTitle: 'Kullanım Koşullarımız Gözden Geçirin',
        subTitle: 'Gizlilik Sözleşmesi',

        // step four
        stepFourTitle: 'Profiliniz Tamamlanıyor',
        stepFourLoadSubTitle: 'Bilgileriniz Kayıt Ediliyor',
        stepFourLoadExplanation: 'Bu işlemin tamamlanma süresi internet hızınıza bağlı olarak değişkenlik gösterebilir.',

        stepFourComplateSubTitle: 'Bilgileriniz Başarı ile kayıt edildi',
        stepFourComplateExplanation: 'Anasayfaya Yönlendiriliyorsunuz lütfen bekleyiniz',

        // general titles
        nextStep: 'Sonraki Adım',
        backStep: 'Önceki Adım',
    },
    managementUser: {
        pageTitle: 'Kullanıcılar',

        // table th
        fullname: 'Ad-Soyad',
        authority: 'Pozisyon',
        email: 'E-posta',
        type: 'Tür',
        safe: 'Kasa Durumu',
        proccess: 'İşlem',

        changePermissionText: 'Kullanıcının Yetkisini Değiştirmek istediğinize emin misiniz',
        passiveUserText: 'Kullanıcıyı silmek istediğinize emin misiniz ?',
        activeUserText: 'Kullanıcıyı Tekrar Aktif hale getirmek istediğinize emin misiniz ?',
        changeSafeStateText: 'Kullanıcının kasa yetkisini değiştirmek istediğinize emin misiniz ?',

        modal: {
            edittitle: "Kullanıcı Düzenle",
            title: 'Yeni Kullanıcı Oluştur',
            buttonTitle: 'Yeni Kullanıcı',
            username: 'Kullanıcı Adı',
            email: "E-posta",
            fullname: 'Ad Soyad',
            authority: 'Pozisyon',
            trackingState: 'Takip Modu',
            password: 'Parola',
            rePassword: 'Parola Tekrarı'
        }
    },
    managementCompany: {
        changeDetailText: 'Firma ayarlarını değiştirmek istediğinize emin misiniz ?'
    },
    managementPrices: {
        pageTitle: 'Fiyat Etiketleri',

        // table headers
        name: 'Etiket Adı',
        explanation: 'Açıklama',
        price: 'Fiyat',
        proccess: 'İşlem',

        newPriceButtonTitle: 'Yeni Fiyat Etiketi',
        newPriceTitle: 'Yeni Fiyat Etiketi',
        passiveText: 'Fiyat etiketini silmek istediğinize emin misiniz ?',
        activeText: 'Fiyat etiketini Tekrar Aktif hale getirmek istediğinize emin misiniz ?',
    },
    managementOutpoint: {
        pageTitle: 'Dolum Noktaları',
        newButtonTitle: 'Yeni Dolum Noktası',

        newModalTitle: 'YENİ DOLUM NOKTASINI OLUŞTUR',
        editModalTitle: 'DOLUM NOKTASINI DÜZENLE',

        // tables headers
        name: 'Dolum Noktası',
        explanation: 'Açıklama',
        proccess: 'İşlem',

        isNotNullLocationText: 'Adres  ve konum bilgileri boş bırakılamaz !',
        passiveText: 'Dolum Noktasını silmek istediğinize emin misiniz ?',
        activeText: 'Dolum Noktasını Tekrar Aktif hale getirmek istediğinize emin misiniz ?',
    },

    repaire: {
        deleteReceiptText: 'Bakım onarım Kaydını silmek istediğinize emin misiniz ?',
    },
    operations: {
        ////transitions index
        planning: {
            pageTitle: 'Planlama',
            newBtnTitle: 'Yeni Sevkiyat',
            calculatingRoute: "Rota Hesaplanıyor",


            // tables head
            filling: 'Dolum',
            delivery: 'Teslim',
            vehicle: 'Araç',
            generalTitle: 'Genel Bilgiler',

            modal: {
                newTitle: 'Yeni Sevkiyat',
                editTitle: 'Sevkiyat Düzenle',

                shippingNumber: 'Sevkiyat Numarası',
                Shipmentstatus: 'Sevkiyat Durumu',
                shipmentType: 'Sevkiyat Türü',
                priority: 'Öncelik',
                invoiceStatus: 'Fatura Durumu',
                billedCompany: 'Fatura Edilen Firma',
                agreedCompany: 'Anlaşılan Firma',
                customer: 'Teslim Edilecek Firma',
                product: 'Taşınacak Ürün',
                fillingPoint: 'Dolum Noktası',
                fillingTime: 'Dolum Zamanı',

                deliveryPoint: 'Teslim Noktası',
                deliveryTime: 'Teslim Zamanı',

                notFoundRouteMessage: 'Müşteri konum bilgisi bulunamadığından rota hesaplanamadı !',


                vehicleInformation: 'Araç Durumu',
                vehicle: 'Araç',
                trailer: 'Dorse',
                tankName:"Tank/Konteyner",
                driver: 'Sürücü',

                washingCondition: 'Yıkama Durumu',
                progressMileage: 'Hakediş Kilometresi',
                progressMileageAndTonnage: 'Kilometre / Tonaj',
                kilometerPrice: 'Kilometre Fiyatı',
                unitPriceReceived: 'Alınan Birim Fiyat',
                unitPriceSold: 'Verilen Birim Fiyat',
                transitions: 'Özel Geçişler',
                fuelCost: 'Yakıt Maliyeti',
                tollHighwayCost: 'Otoyol Maliyeti',
                earning: 'Kazanç',
                saleTotal: 'Satış Toplamı',

            },

            transitionModal: {
                title: 'Geçişler',
                transitionType: 'Geçiş Türü',
                price: 'Geçiş Ücreti',
                date: 'Geçiş Tarihi'
            },

            statusPanel: {
                title: 'Durum Geçmişi',
                statue: 'Durum',
                date: 'Tarih'


            }

        },
        customers: {
            customersTitle: "Müşteriler",
            newCustomer: "Yeni Müşteri",
            customerName: "Müşteri İsmi",
            province: " Bölge",
            district: "İlçe",
            status: "Durum",

            modal: {
                customerName: "Müşter İsmi",
                newCustomer: "Yeni Müşteri",
                phoneNumber: "Telefon Numarası",
                taxAdministration: "Vergi Dairesi",
                sensibility: "Hasasiyet",
                custormerTitle: "Müşteriler",
                authorizedName: "Yetkili",
                EMailAddress: "E-mail",
                TaxNumber: "Vergi Numarası",
                explanation: "Açıklama",
                editCustomer: "Müşteri Düzenle",
                
                operationsGeneral: "Genel Operasyonlar",
                exitPoints: "Çıkış Noktaları",

                operationsNew: "Yeni Operasyon",
                newExitPoint: "Yeni Çıkış Noktası",
                goTargetKilometers: "Hedeflenen Gidiş Kilometre",
                taxNumber: "Vergi Numarası",
                returnTargetKilometers: "Hedeflenen Dönüş Kilometre",
                progressMileage: "Hakediş Kilometre",
                editExitPoint: "Çıkış Noktasını Düzenle",
                noExitPointsToListWereFound:"Listelenecek Çıkış Noktası Bulunamadı",
            }
        },
        products: {
            pageTitle: "Ürünler",
            newProduct: "Yeni Ürün",
            productName: "Ürün Adı",
            sauceFile: "Dosya",
            status: "Durum",

            modal: {
                newProduct: "Yeni Ürün",
                productName: 'Ürün Adı',
                explanation: 'Açıklama',
                features: 'Özellikler',
                sauceFile: 'Sos Dosyası',
                type: "Tür",
                editProduct: 'Ürün Düzenle',
                general: 'Genel',
                requirements: 'Gereksinimler',
                signs: 'İşaretler',
                new: 'Yeni',
                noFeatureToList: 'Listelenecek Özellik Yok',
                noRequirementsToListWereFound: 'Listelenecek Gereklilik Bulunamadı',
                noSignageFoundToBeListed: 'Listelenecek Tabela Bulunamadı',
            }

        },



        transition: {
            pageTitle: 'Geçişler',
            newBtn: 'Yeni Geçiş',

            // table
            transitionName: 'Geçiş',
            price: 'Fiyat',
            status: 'Durum',
            proccess: 'İşlem',


            modal: {
                addTitle: 'Yeni Geçiş',
                editTitle: 'Geçiş Düzenleme',

                transition: 'Geçiş Adı',
                price: 'Fiyat',
                explanation: 'Açıklama',
            }
        },
    },
    operating: {

        fuel: {
            pageTitle: 'Yakıt Yönetimi',
            newProcessTitle: '+ Yeni İşlem',
            newReceipt: "Yeni Fiş",



            // table header
            processDate: 'Fiş Tarihi',
            state: 'Durum',
            vehicle: 'Araç',
            supply: 'Tedarikçi',
            ratio: 'Yakar ',
            diffrentKilometer: 'Kilometre Farkı',
            received: 'Alınan',
            total: 'Toplam',
            proccess: 'İşlem',

            warningVehicleConnectedOilSupplyText: 'Seçilen araç hernagi bir cariye bağlanmamış lütfen ilgili bağlama işlemini yapıp daha sonra tekrar deneyiniz !',

            modal: {
                newFuelPlug: "Yeni Yakıt Transferi",
                date: "Tarih",
                receiptNumber: "Fiş No",
                fuelingCompany: "Yakıt Alınan Firma",
                fuelCondition: "Yakıt Durumu",
                quantityLiters: "Miktar (Litre)",
                amountVatİncluded: "Tutar (Kdv Dahil)",
                vehicleMileage: "Araç Kilometresi",
                explanation: "Açıklama",
                exportToExcel: "Excel'e Aktar",
                minimumDate: "Minimum Tarih",
                maximumDate: "Maximum Tarih",
                receiptDate: "Transfer Tarihi",
                FluePlugEdit: "Yakıt Fişini Düzenle",




            }
        },
        maintenance: {

            pageTitle: "Bakım Onarım",
            newReceiptInvoice: "Yeni Fiş/Fatura",
            fileDate: "Dosya Tarihi",
            type: "Tür",
            maintenanceCompany: "Bakım Yapan Firma",
            paymentType: "Ödeme Türü",
            TotalAmount: "Toplam Tutar",

            modal: {

                minimumDate: "Minimum Tarih",
                maximumDate: "Maximum Tarih",
                general: "Genel",
                documentNumber: "Belge No",
                documentType: "Belge Türü",
                invoiceDate: "Fatura Tarihi",
                supplierApproval: "Tedarikçi Onayı",
                paymentType: "Ödeme Türü",
                maturity: "Vade",
                vehicleType: "Araç Türü",
                supplierAndCostOwner: "Tedarikçi Ve Maliyet Sahibi",
                costOwnerType: "Maliyet Sahibi Türü",
                costOwner: "Maliyet Sahibi",
                transactionDatesAndExplanation: " İşlem Tarihleri Ve Açıklama",
                introductionToCare: "Bakıma Giriş",
                outOfMaintenance: "Bakımdan Çıkış",
                explanation: "Açıklama",
                pencils: "Kalemler",
                plugPricing: "Fiş Fiyatlandırma",
                quantity: "Miktar",
                unitPrice: "Birim Fiyat",
                discount: "İskonto",
                edit: "Düzenle",
                serviceReceived: "Alınan Hizmet",
                total: "Toplam",
                new: "Yeni",


            }
        },
        washing: {
            pageTitle: "Yıkama",
            newProcess: "+ Yeni İşlem",
            newReceipt: "Yeni Fiş",
            receiptDate: "Fiş Tarihi",
            washer: "Yıkama Yapan Firma",
            plate: "Plaka",
            amount: "Tutar",
            process: "İşlem",
            washingType: "Yıkama Türü",

            modal: {
                newWasherPlug: "Yeni Yıkama Fişi",
                receiptNumber: "Fiş No",
                date: "Tarih",
                vehicle: "Araç",
                costOwner: "Maliyet Sahibi",
                editFuelReceipt: "Yakıt Fişi Düzenle",
            }
        }
    },
    vehicleTracking: {
        licensePlateDetail: "Plakalı Araç Detayı",
        gpsStatus: "Gps Durumu :",
        motionStatus: "Hareket Durumu :",
        vehicleMileage: "Araç Kilometresi :",
        address: "Adres :",
    },
    humanResources: {
        scrore: {
            pageTitle: "Puantaj",
            driver: "Sürücü",
            worked: "Çalıştı",
            authorized: "İzinli",
            reported: "Raporlu",
            rest: "Dinlenme",
            return: "Geri Dönüş",
            shift: "Mesai",
            chauffeur: "Gördes Şoför",

            modal: {
                allowanceTypes: "Harcirah Türleri",
                speciesName: "Tür Adı",
                worked: "Çalıştı",
                normalOperation: "Normal Çalışma",
                active: "Aktif",
                addAllowanceType: "Harcirah Türü Ekle",
                notSelected: "Seçilmedi",
            }
        },
        staff: {
            newTransaction: "+ Yeni İşlem",
            pageTitle: "Personeller",
            newStaff: "Yeni Personel",
            employee: "Çalışan",
            startingDate: "Başlangıç Tarihi",
            affiliatedCompany: "Bağlı Olduğu Şirket",
            phoneNumber: "Telefon Numarası",
            situation: "Durum",
            process: "İşlem",

            modal: {
                addNewStaff: "Yeni Personel Ekle",
                nameAndSurname: "Adı Ve Soy Adı",
                turkishIdentityNumber: "TC. Kimlik Numarası",
                dateOfBirth: "Doğum Tarihi",
                placeOfBirth: "Doğum Yeri",
                hexCode: "Hes Kod",
                secondPhoneNumber: "İkinci Telefon Numarası",
                modalaffiliatedCompany: "Modele Bağlı Şirket",
                startingDate: "Başlangıç Tarihi",
                address: "Adres",
                secondAddress: "İkinci Adres",
                monthlyProgressPayment: "Maaş",
                foreclosureStatus: "Haciz Durumu",
                password: "Şifre",
                organizeStaff: "Personel Düzenle",
                phoneNumber: "Telefon Numarası"


            }
        },

        files: {
            addNewFile: "Yeni Dosya Ekle",
            expiryDate: "Son Kullanma Tarihi",
            file: "Dosya",
            editFile: "Dosya Düzenle",
            documents: "Belgeler",
            oldDocuments: "Eski Belgeler",
            newFile: "Yeni Dosya",
            oldFile: "Eski Dosya",
            deadline: "Son Tarih",
            view: "Görüş",
            paperwork: "Evrak Takibi",
            fileType: "Dosya Türü",
        }
    },
    inventory: {
        trailers: "Dorseler",
        newVehicle: "Yeni Araç",
        plate: "Plaka",
        assetStatus: "Varlık Durumu",
        startingDate: "Başlangıç Tarihi",
        availability: "Müsaitlik",
        tonnage: "Tonaj",
        newTank: "Yeni Tank",
        tankNumber: "Tank Numarası",
        affiliatedcompany: "Bağlı Olduğu Firma",
        vehicles: "Araçlar",
        trailer: "Dorse",
        brand: "Marka",
        driver: "Sürücü",

        modal: {
            addNewTrailer: "Yeni Dorse Ekle",
            generalİnformation: "Genel Bilgiler",
            trailerPlate: "Dorse Plakası",
            assetStatusAndCurrentAccounts: "VARLIK DURUMU VE BAĞLI OLDUĞU CARİLER",
            affiliatedCompany: "Bağlı Olduğu Firma",
            maintenance: "Bakım Onarım",
            rentalWife: "Kira Carisi",
            rentAmount: "Kira Tutarı",
            editTrailer: "Dorse Düzenle",
            addNewTank: "Yeni Tank Ekle",
            addnewVehicle: "Yeni Araç Ekle",
            tonnageCapacity: "Yük Kapasitesi (Ton)",
            chassisNumber: "Şase Numarası",
            fuelCapacity: "Yakıt Kapasitesi",
            startingInformation: "Başlangıç Bilgileri",
            amountOfFuel: "Yakıt Miktarı",
            washing: "Yıkama",
            fuel: "Yakıt",
            tankEdit: "Tank Düzenle",
            editVehicle: "Araç Düzenle",
            connectedToTheCurrent: "Bağlı Olduğu Cari",
        }
    },
    accounting: {
        newReceipt: "Yeni Fiş",
        invoiceTracking: "Fatura Takip",
        overheads: "Genel Giderler",
        fileNumber: "Dosya No",
        fileDate: "Dosya Tarihi",
        invoiceIssuer: "Fatura Kesen",
        costOwner: "Maliyet Sahibi",
        amount: "Tutar",
        concubines: "Cariler",
        supply: "Cariler",
        newSupply: "Yeni Cariler",
        newSupplyBtn: "Yeni Cari",
        supplyName: "Cari Adı",
        maturity: "Vade",

        modal: {
            newHgsAdd: "Yeni HGS Ekle",
            hgsEditReceipt: "HGS FİŞİ DÜZENLE",
            newOverheads: "YENİ GENEL GİDER",
            paymentType: "Ödeme Türü",
            companyIssuingInvoice: "Faturayı Kesen Firma",
            invoicedCompany: "Fatura Kesilen Firma",
            costOwnerType: "Maliyet Sahibi Türü",
            costOwnerCompany: "Maliyet Sahibi Firma",
            costOwnerPersonnel: "Maliyet Sahibi Personel",
            pencils: "Kalemler",
            quantity: "Miktar",
            unitPrice: "Birim Fiyatı",
            kdvRatio: "KDV Oranı",
            discountRate: "İskonto Oranı",
            serviceReceived: "Alınan Hizmet",
            discount: "İskonto",
            fileType: "Dosya Türü",
            TotalAmount: "Toplam Tutar",
            generalExpenseDetail: "GENEL GİDER DETAYI",
            general: 'Genel',
            addNewCurrent: "YENİ CARİ EKLE",
            authorizedName: "Yetkili Adı",
            phone: 'Telefon Numarası',
            emailAdress: 'e-posta adresi',
            paymentTerm: "Ödeme Vadesi (Gün)",
            paymentDescription: "Ödeme Açıklaması",
            operationalStatus: "Operasyonel Durum",
            typeLinks: "Tür Bağlantıları",
            currentEdit:"CARİ DÜZENLE",
            species:"Türler",
            Prices:"Fiyatlar",
        }
    },
    navbar: {
        menu:"Menü",
        homePage:"Genel Raporlama",
        vehicleTracking:"Araç Takip",
        humanResources:"İnsan Kaynakları",
        maintenance:"Bakım Onarım",
        washing:"Yıkama",
        fuel:"Yakıt",
        exit:"Çıkış Yap",
        operation:"Operasyon",
        accounting:"Muhasebe",
        inventory:"Envanter",
        controlBoard:"Kontrol Paneli",
    }
}