<template>
  <div class="px-2">
    <cardItem
      title="HGS"
      :value="Money(hgs) + '₺'"
      icon="fas fa-money-bill fa-3x opacity-80"
    />
    <cardItem
      title="ÜCRETLİ GEÇİŞLER"
      :value="Money(transition) + ' ₺'"
      icon="fas fa-money-bill fa-3x opacity-80"
    />
  </div>
</template>

<script>
import cardItem from "@/components/general/card.item.vue";
export default {
  name: "expense-transition-break-step-two",
  props: ["hgs", "transition"],
  components: {
    cardItem,
  },
};
</script>

<style></style>
